const state = {
    paymentAgreement: null,
    payment: null,
    plan: null,
    planProvider: null,
    planPaymentPossible: false,
    processedGuestPayment: {
        result: null,
        payment: null,
        account: null,
        subAccount: null
    }
};

const getters = {
    paymentAgreement(state) {
        return state.paymentAgreement;
    },
    plan(state) {
        return state.plan;
    },
    planProvider(state) {
        return state.planProvider;
    },
    planPaymentPossible(state) {
        return state.planPaymentPossible;
    },
    payment(state) {
        return state.payment;
    },
    processedGuestPayment(state) {
        return state.processedGuestPayment;
    }
};

const mutations = {
    setReceiptPaymentAgreement(state, val) {
        state.paymentAgreement = val;
    },
    setReceiptPayment(state, val) {
        state.payment = val;
    },
    setReceiptPlan(state, val) {
        state.plan = val;
    },
    setPlanReceiptProvider(state, val) {
        state.planProvider = val;
    },
    setPlanReceiptPaymentPossible(state, val) {
        state.planPaymentPossible = val;
    },
    setProcessedGuestPayment(state, val) {
        state.processedGuestPayment = val;
    },
};

const actions = {};

export default {
    state, getters, mutations, actions,
};
