<template>
    <div class="base-tooltip" data-testid="base-tooltip">
        <slot></slot>
        <v-tooltip scroll-strategy="none" :open-on-click="isMobile" :class="isMobile ? 'mobile' : null" v-bind="$attrs" activator="parent" :location="location">
            <div>
                <h3>{{ header }}</h3>
                <span>{{ tip }}</span>
            </div>
        </v-tooltip>
    </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'BaseTooltip',
    props: {
        location: String,
        header: String,
        tip: String,
    },
    components: {
        BaseIcon,
    },
    computed: {
        isMobile() {
            return /(phone|mobile)/i.test(navigator.userAgent);
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.base-tooltip {
    position: relative;
}

.v-tooltip > .v-overlay__content {
  font-size: 1.4rem !important;
  font-family: 'Roboto',"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: white !important;
  color: $global-color-font-new !important;
  box-shadow: 0 0.3rem 1rem 0.4rem rgba(158, 158, 158, .6);
  border-radius: 5px;
  padding: .8rem 1.6rem !important;
  overflow: visible !important;

  div {
    max-width: 25rem;
    overflow-wrap: break-word;
    line-height: 1.4rem;
    padding-bottom: .25rem;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        color: $global-color-font-new;
    }

    .close-button {
        color: black;
        border: solid 1px black;
    }

    h3 {
        font-weight: 500;
        text-decoration: underline solid;
        margin-bottom: .25rem;
    }

    span {
        font-size: 1.2rem;
    }
  }
}

.v-tooltip > .v-overlay__content {
	position: absolute;
	background: white;
}

.v-tooltip > .v-overlay__content:after, .v-tooltip > .v-overlay__content:before {
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.v-tooltip > .v-overlay__content:after {
	border-width: .8rem;
	margin-left: -.8rem;
}

.v-tooltip > .v-overlay__content:before {
	border-width: .8rem;
	margin-left: -.8rem;
}

/*
    Bottom tooltips
*/
.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: bottom center"]:after, .v-overlay__content[style*="--v-overlay-anchor-origin: bottom center"]:before {
    bottom: 100%;
	left: 50%;
}

.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: bottom center"]:after {
    border-bottom-color: white;
}

/*
    Top tooltips
*/
.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: top center"]:after, .v-overlay__content[style*="--v-overlay-anchor-origin: top center"]:before {
    top: 100%;
	left: 50%;
}

.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: top center"]:after {
    border-top-color: white;
}

/*
    Right tooltips
*/
.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: right center"]:after, v-overlay__content[style*="--v-overlay-anchor-origin: right center"]:before {
    right: 100%;
	bottom: calc(50% - .8rem);
}

.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: right center"]:after {
    border-right-color: white;
}

/*
    Left tooltips
*/
.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: left center"]:after, .v-overlay__content[style*="--v-overlay-anchor-origin: left center"]:before {
    left: calc(100% + .8rem);
	bottom: calc(50% - .8rem);
}

.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: left center"]:after {
    border-left-color: white;
}

/*
    Move arrow on top and bottom tooltips slightly to accomodate narrower screen
*/
.mobile {
    &.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: top center"]:before, 
    &.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: top center"]:after,
    &.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: bottom center"]:before, 
    &.v-tooltip > .v-overlay__content[style*="--v-overlay-anchor-origin: bottom center"]:after {
            left: 40%;
    }
}

</style>