<template>
    <div v-if="showComponent">  
        <account-info-card
            :accountInfo="getAccountInfo"
            data-testid="bill-summary-account-info"
        ></account-info-card>
        <need-help-card
            @composemessage="composeMessage"
            :provider="providerDetailsProvider"
            data-testid="bill-summary-need-help"
        ></need-help-card>
    </div>
</template>

<script>

import AccountInfoCard from './AccountInfoCard.vue';
import NeedHelpCard from './NeedHelpCard.vue';

export default ({
    name: 'BillSummaryLeftColumn',
    components: {
        AccountInfoCard,
        NeedHelpCard,
    },
    computed: {
        currentBill() {
            return this.$store.getters.currentBill;
        },
        showComponent() {
        	return this.currentBill && this.$vuetify.display.lgAndUp;
        },
        messageProviderData() {
            return { useCurrentBill: true };
        },
        providerDetailsProvider() {
            return { 
              ...this.currentBill.providerDetails,
              canMessage: this.currentBill.providerDetails.features.indexOf('messaging') > -1,
            };
        },
        getAccountInfo() {
            return this.currentBill.accountInfo;
        },
    },
    methods: {
        composeMessage() {
            let state = 'BillSummary';
            let params = {
                secureCode: this.currentBill.secureCode,
            };
            let emit = ['message:compose', { secureCode: this.currentBill.secureCode }];
            const authParams = {
                state, params, emit,
                bill: {
                    amount: this.currentBill.billAmount,
                    secureCode: this.currentBill.secureCode
                }
            };
            const onAuthSuccess = () => {
                this.$store.dispatch('linkBill', { amount: this.currentBill.billAmount, sCode: this.currentBill.secureCode }).then(() => {
                    if (this.currentBill.providerDetails.requiresRedirectToBranded) {
                        this.emitter.emit('redirect:tab', {
                            provider: this.currentBill.providerDetails,
                            context: {
                                emit: emit,
                                stateGo: {
                                    to: state,
                                    toParams: params
                                },
                            },
                        });
                    } else {
                        this.emitter.emit('message:compose', this.messageProviderData);
                    }
                }).catch((message) => {
                    console.error(message);
                });
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
        },
    }
});
</script>
<style lang="scss" scoped>
</style>