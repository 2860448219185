<template>
    <div v-if="paymentAgreement">
        <div class="receipt-left-col-per block-segment">
            <h1 class="call-out provider-dashboard agreement-state">{{paymentAgreementState}}</h1>
            <div class="sub-content">{{amountStatus}}</div>

            <div class="receipt-created-stamp">
                {{ $translate('labels.createdArg', { date: $formatDate(paymentAgreement.dateCreated, 'MM/DD/YY') }) }}
            </div>
        </div>

        <div class="separator"></div>
    </div>
</template>

<script>
export default {
    name: 'AgreementReceiptLeftColumnPersist',
    computed: {
        paymentAgreement() {
            return this.$store.getters.paymentAgreement;
        },
        paymentAgreementState() {
            if (this.paymentAgreement && this.paymentAgreement.dateCanceled) {
                return this.$translate('labels.canceledPayment');
            }
            return this.$translate('labels.futurePayment');
        },
        amountStatus() {
            if (!this.paymentAgreement) {
                return null;
            }
            const formattedOrigAmount = this.$formatCurrency(this.paymentAgreement.amount);
            if (this.paymentAgreement.dateCanceled) {
                return this.$translate('labels.argCanceled', { amount: formattedOrigAmount })
            }
            return this.$translate('labels.argAuthorized', { amount: formattedOrigAmount });
        },
    },
};

</script>

<style lang="scss">

</style>