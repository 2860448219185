import _ from 'lodash';
import { formatCurrency, translate, MoneyUtils } from '../utils/utils';
import SubAccount from './SubAccount';

// Bill object used to help grab/calculate pieces of the
// bill response from the server into more consumable methods
class Bill {
    constructor(billServerResponse, scodeUsedForSearch) {
        _.assign(this, billServerResponse);
        this.billSource = billServerResponse;
        this._paymentOptions = null; //needs the _ since assign will assign a value to 'paymentOptions'
        this._paymentOptionsRemainder = null;
        this.scodeUsedForSearch = (scodeUsedForSearch && scodeUsedForSearch.length === 11) ? scodeUsedForSearch : this.billSource ? this.billSource.secureCode : null;

        if (!this.billSource.subAccounts) {
            this.billSource.subAccounts = [];
        } else {
            this.billSource.subAccounts = this.billSource.subAccounts.map(rawSubAccount => new SubAccount(rawSubAccount));
        }
        this.subAccounts = this.billSource.subAccounts;
        // Access of the bill original data can be found here.
        // We are giving this a double underscore identifier so to
        // deter from usage. Plese add a facade layer on this constructor
        // if there is a field on the __bill__ that you need publicly
        // available.
        this.__bill__ = this.billSource;

    }

    getTotalAmount() {
        return this.billSource.totalAmount;
    }

    // currently not supported for bills
    getPaymentOptionsRemainder() {
        if (this._paymentOptionsRemainder !== null) {
            return this._paymentOptionsRemainder;
        }
        this._paymentOptionsRemainder = [];

        return this._paymentOptionsRemainder;
    }

    // Get the payment options that are also allowed on the
    // bill. We consider a custom amount and the bill amount as
    // items that will always be presented to the user.
    getPaymentOptions(){

        if(this._paymentOptions !== null){
            return this._paymentOptions;
        }
        this._paymentOptions = [];

        _.map(this.billSource.paymentOptions, (option) => {

            var key = '',
                translationVars = {
                    calculatedTotal: formatCurrency(option.amount),
                    discountPolicy: option.discountPolicy
                };

            // Type shortcode
            switch(option.type.toUpperCase()){
                case 'CB':
                    key = 'payment.amount.payAccountBalArg';
                    break;
                case 'CBD':
                    key = 'payment.amount.payAccountBalArgDiscount';
                    break;
                case 'SB':
                    key = 'payment.amount.payBillBalArg';
                    break;
                case 'SBD':
                    key = 'payment.amount.payBillBalArgDiscount';
                    break;
            }

            option.dialog = translate(key, translationVars);

            this._paymentOptions.push(option);
        });
        return this._paymentOptions;
    }

    // get the specific details for a given payment option
    getPaymentOption(index){
        if(this._paymentOptions === null){
            // hydrate the options if this
            // is called before get paymentOptions
            this.getPaymentOptions();
        }
        return this._paymentOptions[index];
    }

    // get the specific details for a given payment option
    getPaymentOptionRemainder(index) {
        if (this._paymentOptionsRemainder === null) {
            // hydrate the options if this
            // is called before get paymentOptions
            this.getPaymentOptionsRemainder();
        }
        return this._paymentOptionsRemainder[index];
    }

    // get the details for the provider associated with this bill
    getProviderDetails(){
        return this.billSource.providerDetails;
    }

    // get the details for the provider associated with this bill
    getAccountDetails(){
        return this.billSource.accountDetails;
    }

    // get the address from the bill if it meets the minimum
    // criteria
    getGuarantorAddress(){

        var details = this.billSource.guarantorDetails;

        if(!details){
            return null;
        }

        // we require a minimum set of points before we will use this address
        // todo: might make sense to allow clicking of patial complete address
        // which will prepopulate the billing form where you can complete it
        if(details.address && details.city && details.state && details.zip){
            return {
                address: details.address,
                address2: details.address2,
                city: details.city,
                state: details.state,
                zip: details.zip
            };
        }

        return null;
    }

    isProviderActive(){
        return this.billSource.providerDetails.active;
    }

    canMessage(){
        return this.isProviderActive() && _.includes(this.billSource.providerDetails.features, 'messaging');
    }

    // is today past the (latest -- implicit)bills due date
    isPastDue(){
        // dueDate override means that the bill can never be past due
        if(!this.isProviderActive() || !this.billSource.dueDate || this.billSource.providerDetails.patientDueDateOverrideText) {
            return false;
        }

        var today = new Date(),
            due = new Date(this.billSource.dueDate);

        // compare on midnight of the dates
        today.setHours(0,0,0,0);
        due.setHours(0,0,0,0);

        return due < today;
    }

    hasZeroBalance(){
        return parseFloat(this.billSource.accountBalance.amount || 0) === 0;
    }

    hasNegativeBalance(){
        return parseFloat(this.billSource.accountBalance.amount || 0) < 0;
    }

    // get the diff of what the bill has vs what
    // is owed on the account
    balanceDiff(){
        return MoneyUtils.subtract(this.billSource.accountBalance.amount, this.billSource.billAmount);
    }

    reflectsDbu(){

        if(!this.isProviderActive()){
            return false;
        }

        return _.indexOf(['dbu', 'mbu'], _.trim(this.billSource.accountBalance.lastAction).toLowerCase()) >= 0 &&
                    !MoneyUtils.equals(this.billSource.accountBalance.amount, this.billSource.billAmount);
    }

    // when a user uses an scode to search for a bill and
    // there is a newer bill on the account, we will get the latest
    // bill. This function tells us if this is the case
    newerBillReturned(){
        return this.billSource.secureCode !== this.scodeUsedForSearch;
    }

    hasFinancingOption(){
        return this.isProviderActive() && this.billSource.hasFinancing;
    }

    hasSubAccounts() {
        return this.billSource.subAccountsEnabled && this.billSource.subAccounts.length;
    }

    getSubAccounts() {
        return this.billSource.subAccounts;
    }

    hasSummary() {
        return typeof this.billSource.summary != 'undefined' && this.billSource.summary != null;
    }

    hasOtherAdjustments() {
        return this.billSource.summary.otherAdjustments != 'undefined' && this.billSource.summary.otherAdjustments != null && this.billSource.summary.otherAdjustments != '0.00';
    }

    hasInsurancePolicies() {
        return Array.isArray(this.billSource.insurancePolicies) && this.billSource.insurancePolicies.length > 0;
    }

    hasAdjustmentReasons() {
        return Array.isArray(this.billSource.adjustmentReasons) && this.billSource.adjustmentReasons.length > 0;
    }

    getAdjustmentReasonsDescriptions() {
        return this.billSource.adjustmentReasons.map(function(r){
            return r.description;
        });
    }
}

export default Bill;
