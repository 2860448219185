<template>
<div :class="{'feedback-modal-payment': afterPayment}">
    <modal can-close :show="isVisible" @close="close" class="feedback-modal-container">
        <div v-if='afterPayment' :class="{'payment-survey': scoreClean===true}" >
            <div :class="iconClass" v-html="intentIcon"></div>
            <h1 class="modal-header h-style-1" v-html="thanksHeader"></h1>
        </div>
        <div class="feedback-modal">
            <div class='feedback-modal-background'>
                <h2 class="modal-subheader h-style-4">{{ feedbackTitle }}</h2>

                <div class="button-group nps-buttons">
                    <button :class="{'button-selected': feedback.npsScore === 0}" ref="btn0" @click="setScore(0)">
                        0
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 1}" ref="btn1" @click="setScore(1)">
                        1
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 2}" ref="btn2" @click="setScore(2)">
                        2
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 3}" ref="btn3" @click="setScore(3)">
                        3
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 4}" ref="btn4" @click="setScore(4)">
                        4
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 5}" ref="btn5" @click="setScore(5)">
                        5
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 6}" ref="btn6" @click="setScore(6)">
                        6
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 7}" ref="btn7" @click="setScore(7)">
                        7
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 8}" ref="btn8" @click="setScore(8)">
                        8
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 9}" ref="btn9" @click="setScore(9)">
                        9
                    </button>
                    <button :class="{'button-selected': feedback.npsScore === 10}" ref="btn10" @click="setScore(10)">
                        10
                    </button>
                </div>

                <div class="nps-labels">
                    <span class='left-label'>
                        {{ $translate('dialogs.feedbackLabelNPS0') }}
                    </span>
                    <span class='right-label'>
                        {{ $translate('dialogs.feedbackLabelNPS10') }}
                    </span>
                </div>
            </div>
            <form name="forms.feedbackForm" @submit.prevent @submit="sendFeedback()" :class="{'form-clean': scoreClean}" novalidate>
                <div class="comment-prompt" v-html="commentPrompt"></div>

                <div class="feedback-options" v-if="feedback.npsScore < 7 && feedback.options && feedback.options.length > 0">
                    <div class="input-area-wrapper" v-for="(option, i) in feedback.options" :key="i">
                        <div class="input-area-field-wrapper no-initial-border inline-input-type">
                            <input :id="`feedback-option-${option.optionId}`"
                                name="selectedFeedbackOptions[]"
                                type="checkbox"
                                v-model="option.selected">

                            <label :for="`feedback-option-${option.optionId}`">{{ option[lang] }}</label>
                        </div>
                    </div>
                    <div class="input-area-wrapper">
                        <div class="input-area-field-wrapper no-initial-border inline-input-type">
                            <input id="feedback-option-other"
                                name="selectedFeedbackOptions[]"
                                type="checkbox"
                                v-model="feedback.other">

                            <label for="feedback-option-other">{{ $translate('dialogs.feedbackOptionOther') }}</label>
                        </div>
                    </div>
                </div>

                <div class="input-area-wrapper" v-if="feedback.npsScore >= 7 || feedback.other || !feedback.options || feedback.options.length < 1">
                    <div class="input-area-field-wrapper no-initial-border inline-input-type">
                        <textarea :placeholder="$translate('dialogs.feedbackCommentPlaceholder')" maxlength="5000" v-model="feedback.comment" class="fs-block"></textarea>
                    </div>
                </div>
                <button @click.prevent="sendFeedback()" class="button-primary send-feedback" id='submit-feedback'>{{ buttonText }}</button>
            </form>
        </div>
    </modal>
</div>
</template>
<script>

import Modal from './Modal.vue';

export default {
    name: 'FeedbackModal',
    components: {
        Modal
    },
    data: () => ({
        isVisible: false,
        iconClass: 'icon-check',
        feedbackTitle: null,
        thanksHeader: null,
        intentIcon: null,
        commentPrompt: null,
        buttonText: null,
        afterPayment: false,
        scoreClean: true,
        paymentId: null,
        feedbackSource: null,
        feedback: {
            comment: '',
        },
        submitting: false,
        translationData: null,
    }),
    computed: {
        config() {
            return this.$store.getters.config;
        },
        lang() {
            return this.config.preferredLanguage || 'en';
        },
        score() {
            return this.feedback.npsScore;
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
    },
    methods: {
        reset() {
            this.feedback = {
                comment: '',
            };
            this.submitting = false; 
            this.isVisible = false;
            this.scoreClean = true;
            this.buttonText = this.$translate('actions.sendFeedback');
        },
        getSelectedFeedbackOptions() {
            if (this.feedback.npsScore < 7) {
                return this.feedback.options.filter(function(option) {
                    return option.selected === true;
                }).map(function(option) {
                    return option.optionId;
                });
            } else {
                return null;
            }
        },
        sendFeedback() {
            if(this.afterPayment){
                // not validating anything - so no need to check if form is valid
                if (this.submitting) {
                    return;
                }
        
                this.submitting = true;
                this.buttonText = this.$translate('actions.sendingFeedback');
                //new - after payment feedback - updates all vaules 
                this.$store.dispatch('updateFeedback', {
                    id: this.feedback.feedbackId,
                    token: this.feedback.feedbackToken,
                    score: this.score,
                    comment: this.feedback.comment,
                    paymentId: this.paymentId,
                    reasons: this.getSelectedFeedbackOptions(),
                    isGuestPay: this.isGuestPay,
                })
                .finally(() => { 
                    this.feedback.npsScore = null;
                    this.commentPrompt = null;
                    this.reset();   
                });
            } else {
                if (this.submitting) {
                    return;
                }
        
                this.submitting = true;
                this.buttonText = this.$translate('actions.sendingFeedback');
                //email feedback 
                //no error handling below.  we have no legit situations where this call would fail.  if it does, we'll pretend that it went through and clean-up after ourselves
                this.$store.dispatch('editFeedback', {
                    id: this.feedback.feedbackId,
                    token: this.feedback.feedbackToken,
                    score: this.score,
                    comment: this.feedback.comment,
                    useNPS: true,
                    reasons: this.getSelectedFeedbackOptions()
                }).finally(() => { 
                    this.reset();

                    this.$router.push({name: 'Home'}).catch(() => {});

                    // overlay the thank you modal
                    this.emitter.emit('simpleModal:showPrompt',{
                        header: this.$translate('dialogs.thanksForFeedback'),
                        intent: 'success'
                    });      
                });
            }
        },
        setScore(newScore) {
            this.scoreClean = false;
            this.feedback.npsScore = newScore;
            this.$refs["btn" + newScore].classList.add('button-selected');
            if (this.afterPayment){
                this.addFeedbackAfterPayment(newScore);
            }
            this.feedbackTitle = this.$translate('dialogs.feedbackTitleNpsLink', this.translationData);
            if (this.feedback.npsScore > 6) {
                this.commentPrompt = this.$translate('dialogs.feedbackSubtitleNpsHappy');
            } else {
                this.commentPrompt = this.$translate('dialogs.feedbackSubtitleNpsSad');
            }
            this.$forceUpdate();
        },
        addFeedbackAfterPayment(score) {
            this.$store.dispatch('updateFeedbackScore', { paymentId: this.paymentId, score: score, id: this.feedback.feedbackId, isGuestPay: this.isGuestPay }).then((data) => {
                this.feedback.feedbackId = data;
            });
        },
        close() {
            this.reset();
            if (this.afterPayment){
                this.isVisible = false;
                this.feedback.npsScore = null;
                this.commentPrompt = null;
                this.reset(); 
            } else {
                this.$router.push({name: 'Landing'}).catch(() => {});
            }
        },
        feedbackUrl(score, token) {
            this.afterPayment = false;
            this.feedback.feedbackToken = token;
            this.feedback.useNPS = true;
            var defaultScore = 10; //default to happy if there's nothing passed-in
            var scoreInt = score ? parseInt(score) : defaultScore;
            if (scoreInt > 10) {
                scoreInt = 10;
            } else if (scoreInt < 1) {
                scoreInt = 0;
            }

            this.$store.dispatch('addFeedback', {
                token: this.feedback.feedbackToken,
                score: scoreInt,
                useNPS: true
            }).then((data) => {
                this.feedbackSource = data.feedbackSource;
                this.translationData = {
                    providerName: data.providerName,
                };
                this.providerName = data.providerName;

                this.feedback.feedbackId = data.feedbackId;
                this.setScore(scoreInt);

                this.feedback.options = data.options;

                //don't show until after first ajax finishes.  otherwise, we give the impression that they can submit a comment
                //(and they can't - because they're missinging the id)
                this.isVisible = true;
            });
        }
    },
    created() {
        this.buttonText = this.$translate('actions.sendFeedback');

        this.emitter.on('feedbackModal:showPrompt', (data) => {
            this.$store.dispatch('getFeedbackOptions', { isGuestPay: this.isGuestPay }).then((resp) => {
                this.feedback.options = resp;
                this.$forceUpdate();
            });
            
            this.scoreClean = true;
            this.afterPayment = true;
            this.paymentId = data.receipt.paymentId;

            this.feedbackSource = 'postPayment';
            this.translationData = {
                providerName: data.providerName,
            };

            if( data.receipt.vendor && data.receipt.vendor.toLowerCase() === 'curae') {
                this.thanksHeader = this.$translate('payment.financing.success.financingSuccessHeader', {vendor: data.receipt.vendor});
            } else {
                this.thanksHeader = this.$translate('dialogs.thanksForPayment', this.translationData);   
            }

            this.feedbackTitle = this.$translate('dialogs.feedbackTitleNpsLink', this.translationData);
            this.iconClass = 'icon-check';
            this.intentIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="75px" height="75px">' +
                                            '<g fill="none" stroke="#fff" stroke-width="2">' +
                                            '<circle cx="36" cy="36" r="35" style=""></circle>' +
                                            '<path d="M17.417,37.778l9.93,9.909l25.444-25.393" style=""></path>' +
                                            '</g>'+
                                            '</svg>';

            this.isVisible = true;
            if (data.score) {
                this.feedbackUrl(data.score, data.token);
            }
        });

        this.emitter.on('$stateChangeSuccess', (toState, toParams) => {
            if (toState.name === 'Feedback') {
                this.afterPayment = false;
                this.feedback.feedbackToken = toParams.token;
                this.feedback.useNPS = true;
                var defaultScore = 10; //default to happy if there's nothing passed-in
                var score = toParams.score ? parseInt(toParams.score) : defaultScore;

                if (score > 10) {
                    score = 10;
                } else if (score < 1) {
                    score = 0;
                }
                
                this.$store.dispatch('addFeedback', {
                    token: this.feedback.feedbackToken,
                    score: score,
                    useNPS: true
                }).then((data) => {
                    this.feedbackSource = data.feedbackSource;
                    this.translationData = {
                        providerName: data.providerName,
                    };
                    this.providerName = data.providerName;

                    this.feedback.feedbackId = data.feedbackId;
                    this.setScore(score);

                    this.feedback.options = data.options;

                    //don't show until after first ajax finishes.  otherwise, we give the impression that they can submit a comment 
                    //(and they can't - because they're missinging the id)
                    this.isVisible = true;
                });
            }
        });
    },
};
</script>
<style lang="scss">
</style>