<template>
    <div v-if="providerId">
        <div class="block-segment">
                <ProviderLogo :provider-id="providerId" :alt-text="providerName"></ProviderLogo>
        </div>
    </div>
</template>

<script>
import ProviderLogo from './ProviderLogo.vue';

export default {
    name: 'ProviderLogoLeftColumnPersist',
    components: {
        ProviderLogo,
    },
    props: {
        provider: Object,
    },
    data: () => ({
        providerId: null,
        providerName: null,
    }),
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        }
    },
    watch: {
        provider() {
            this.setFields();
        },
        currentProvider() {
            this.setFields();
        },
    },
    methods: {
        setFields() {
            if (this.provider) {
                this.providerId = this.provider.id;
                this.providerName = this.provider.name;
            } else if (this.currentProvider) {
                this.providerId = this.currentProvider.id;
                this.providerName = this.currentProvider.name;
            }
        },
    },
    mounted() {
        this.setFields();
    },
};
</script>