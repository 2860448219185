<template>
    <div class="messages message-details" v-if="thread">

        <MessageDetailsHeading :thread="thread"></MessageDetailsHeading>

        <ul class="no-list-style">
            <li class="message" v-for="message in messages" :key="message.id">
                <MessageDetailsMessage :message="message"></MessageDetailsMessage>
            </li>
        </ul>

        <div class="u-separator"></div>

        <div v-if="(thread.status !== 'closed') && messagingEnabled">
            <MessageDetailsCreateMessageArea
                :sendDialog="sendDialog"
                :errorNotifications="errorNotifications">
            </MessageDetailsCreateMessageArea>
        </div>
        

        <div v-if="(thread.status === 'closed') || !messagingEnabled">
            <MessageDetailsThreadClosed :messagingEnabled="messagingEnabled" :providerPhoneNumber="providerPhoneNumber"></MessageDetailsThreadClosed>
        </div>
    </div>
</template>



<script>
import MessageDetailsHeading from './MessageDetailsHeading.vue';
import MessageDetailsMessage from './MessageDetailsMessage.vue';
import MessageDetailsCreateMessageArea from './MessageDetailsCreateMessageArea.vue';
import MessageDetailsThreadClosed from './MessageDetailsThreadClosed.vue';
import _ from 'lodash';



export default {
    name: 'MessageDetailsContent',
    components: {
        MessageDetailsHeading,
        MessageDetailsMessage,
        MessageDetailsCreateMessageArea,
        MessageDetailsThreadClosed
    },
    data() {
        return {
            messages: [],
            errorNotifications: null,
            loadingIndicator: null,
            messageDetails: null,
            submitting: false,
            sendDialog: '',
            messagingEnabled: false,
            providerPhoneNumber: null,
        }
    },
    computed: {
        stateObject() {
            return this.$store.getters.stateObject;
        },
        threadId() {
            return this.$store.getters.threadId;
        },
        thread() {
            return this.$store.getters.thread;
        }
    },
    created() {
        this.messages = null;
        this.errorNotifications = {};
        this.messageDetails = {
            message : '',
            attachments: null
        };
        this.sendDialog = this.$translate('actions.sendMessage');
        this.submitting = false;

        //listeners
        this.emitter.on('messageDetails:sendReply', this.sendReply);
        this.emitter.on('messageDetails:replySentSuccessfully', () => {
            // TODO: it may make more sense to simply inline add the 
            // content to the dom than to reload the data.
            this.$store.dispatch('getDetails', {id: this.threadId}).then((details) => {
                this.storeDetails(details);
            });
        });
        this.emitter.on('messageDetails:viewEstimate', (estimateId) => {
            this.$router.push({
                name: 'SubAccountDetails',
                params: {
                    providerId: this.thread.providerId,
                    estimateId: estimateId
                },
            });
        });

        // TODO: there should be a better way to determine breadcrumbs
        this.$store.dispatch('getProviders').then((providers) => {
            var hasAccessToMessage = _.some(providers, (provider) => {
                return provider.canMessage;
            });
            // show the back to messages breadcrumb if we have access to
            // that section of the application
            if (hasAccessToMessage) {
                if (this.stateObject.previous.name === 'Messages'){
                    this.$store.dispatch('showBreadCrumbs', {
                        label: this.$translate('actions.backToMessages'),
                        onClick: () => {
                            this.$router.push({name: 'Messages'});
                        },
                    });
                }
            }
        }).catch((er) => {
            console.error(er);
        });
    },
    mounted() {
        //try to load the message thread on refresh from the URL
        this.$nextTick(() => {
            this.loadingIndicator = this.$store.dispatch('loadLoadingIndicator');
            var pathname = window.location.hash || window.location.pathname;
            var isDetailsUrl = /messages\/\d+$/.test(pathname);
            if (isDetailsUrl) {
                this.$store.commit('setThreadId', parseInt(String(pathname).split('/').pop()));
            }
            this.$nextTick(() => {

                if (this.threadId == null) {
                    this.$router.push({name: 'ProvidersSummary'});
                } else {
                    this.loadMessages();
                }
            });
        });
    },
    destroyed() {
        this.emitter.off('messageDetails:sendReply');
        this.emitter.off('messageDetails:replySentSuccessfully');
        this.emitter.off('messageDetails:viewEstimate');
        //clear the state once we have left the screen
        this.$store.commit('setThread', null);
        this.$store.commit('setThreadId', null);
    },
    methods: {
        monitorVirusScanStatus(attachmentIds) {
            this.$store.dispatch('refreshVirusScanStatus', attachmentIds).then((resp) => {
                if (resp.changeDetected) {
                    this.loadMessages();
                } else {
                    setTimeout(() => {
                        this.monitorVirusScanStatus(attachmentIds);
                    }, 2500);
                }
            }).catch(() => {
                console.error('Error scanning for viruses');
            });
        },

        storeDetails(details) {
            this.messages = details.sort((a, b) => {return a.id - b.id});
            var pendingAttachments = [];
            this.messages.forEach((message) => {
                var pendingAttachmentsForMessage = [];
                if (message.attachments) {
                    pendingAttachmentsForMessage = message.attachments.filter((attachment) => {
                        return 'pending' === attachment.virusScanStatus;
                    });
                }
                pendingAttachments = pendingAttachments.concat(pendingAttachmentsForMessage.map((attachment) => {
                    return attachment.id;
                }));
            });
            if (pendingAttachments.length > 0) {
                this.monitorVirusScanStatus(pendingAttachments);
            }
        },
        
        // Load up our message threads
        loadMessages() {
            this.$store.dispatch('getDetails', {id: this.threadId}).then((details) => {
                this.storeDetails(details);
            }).catch((err) => {
                console.error('Unable to load thread details: ' + err);
                this.$router.push({name: 'ProvidersSummary'});
            }).finally(() => {
                this.loadingIndicator.then((key) => {
                    this.$store.dispatch('completeLoadingIndicator', key);
                });
            });

            this.$store.dispatch('getMessages', {forceFetch: true}).then((threads) => {
                if (!threads) {
                    throw new Error('Unable to load threads');
                }
                // load the thread into memory so we can populate the peripheral areas of the ui
                this.$store.commit('setThread', _.first(_.filter(threads, (thread) => {
                    let threadId = thread ? thread.id : null;
                    return _.parseInt(threadId) === _.parseInt(this.threadId);
                })));
                // make sure we always have a valid message thread
                if(!this.thread){
                    throw new Error('thread not found');
                }
                // Load the provider for this thread
                return this.$store.dispatch('getProviderById', this.thread.providerId).then((provider) => {
                    this.messagingEnabled = provider && provider.features && provider.hasFeature('messaging');
                    this.providerPhoneNumber = provider.billingPhoneNumber;
                }).catch((err) => {
                    throw new Error('Unable to load provider by id');
                });
            }).catch((err) => {
                console.error(err);
            });
        },

        sendReply(data){
            this.errorNotifications = {};
            if(this.submitting){
                return;
            }
            if(_.isEmpty(data.message)){
                if (data.attachments && data.attachments.length) {
                    this.errorNotifications.messageRequiredForAttachments = true;
                } else {
                    this.errorNotifications.messageRequired = true;   
                }
                return;
            }
            this.sendDialog = this.$translate('actions.sending');
            this.submitting = true;
            this.$store.dispatch('sendMessage', {messageDetails: data, threadId: this.threadId}).then(() => {
                this.emitter.emit('messageDetails:replySentSuccessfully');
                this.$store.dispatch('getDetails', {id: this.threadId}).then((details) => {
                    this.storeDetails(details);
                });
            }).catch((resp) => {
                const errorNotifications = {};
                const r = resp.data || resp;
                if ('TOO_MANY_FILES' === r.errorCode) {
                    errorNotifications.tooManyFiles = true;
                } else if ('GENERIC_FILE_ERROR' === r.errorCode) {
                    errorNotifications.genericFileError = true;
                } else if ('FILE_TOO_LARGE' === r.errorCode) {
                    errorNotifications.fileTooLarge = true;
                } else if ('INVALID_FILE_TYPE' === r.errorCode) {
                    errorNotifications.invalidFileType = true;
                } else {
                    errorNotifications.genericFileError = true;
                }
                this.errorNotifications = errorNotifications;
            }).finally(() => {
                this.submitting = false;
                this.sendDialog = this.$translate('actions.sendMessage');
            });
        },
    },
    

};
</script>