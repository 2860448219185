<template>
    <div class="list">
        <div class="block-segment bordered-segment" v-for="message in messages" :key="key(message.id)">
            <MessagesListItem :message="message"></MessagesListItem>
        </div>
    </div>
</template>

<script>

import MessagesListItem from './MessagesListItem.vue';
import { randomId } from '../utils/utils';

export default {
    name: 'MessagesList',
    components: {
        MessagesListItem,
    },
    props: {
        messages: Array,
    },
    methods: {
        key(value) {
            return randomId(3) + value;
        }
    }
};
</script>

