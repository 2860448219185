<template>
    <div v-if="payment">
        <div class="receipt-left-col-per block-segment">
            <h1 class="call-out provider-dashboard receipt-state">{{receiptState}}</h1>
            <div class="sub-content">{{amountStatus}}</div>

            <div class="receipt-created-stamp">
                {{ $translate('labels.createdArg', { date: $formatDate(payment.dateCreated, 'MM/DD/YY') }) }}
            </div>
        </div>

        <div class="separator"></div>
    </div>
</template>

<script>
import { paymentStates, MoneyUtils } from '../utils/utils';

export default {
    name: 'ReceiptLeftColumnPersist',
    computed: {
        payment() {
            return this.$store.getters.payment;
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
        receiptState() {
            if (!this.payment) {
                return this.$translate('receipt.receipt');
            }
            switch(this.payment.status.toUpperCase()) {
                case paymentStates.SCHEDULED:
                    return this.$translate('labels.futurePayment');
                case paymentStates.DECLINED:
                    return this.$translate('labels.declinedPayment');
                case paymentStates.CANCELLED:
                case paymentStates.VOIDED:
                    return this.$translate('labels.canceledPayment');
                default:
                    // left column values
                    return this.$translate('receipt.receipt');
            };
        },
        amountStatus() {
            if (!this.payment) {
                return null;
            }
            let totalAmount = MoneyUtils.add.apply(null, _.map(this.payment.reversals, (rev) => {
                    return rev.amount;
                }).concat(this.payment.amount));
            let formattedOrigAmount = this.$formatCurrency(this.payment.amount);
            let formattedTotalAmount = this.$formatCurrency(totalAmount);
            
            switch(this.payment.status.toUpperCase()) {
                case paymentStates.SCHEDULED:
                    return this.$translate( 'labels.argScheduled', { amount: formattedOrigAmount});
                case paymentStates.DECLINED:
                    return this.$translate( 'labels.argDeclined', { amount: formattedOrigAmount});
                case paymentStates.CANCELLED:
                case paymentStates.VOIDED:
                    return this.$translate( 'labels.argCanceled', { amount: formattedOrigAmount});
                default:
                    // left column values
                    return this.$translate('labels.argPaid' , { amount: formattedTotalAmount});
            };
        },
    },
};

</script>

<style lang="scss">

</style>