<template>
    <div v-if="showComponent" class="vuetify" data-testid="account-info-card">
        <content-card :headerTitle="$translate('labels.accountInfo')" headerIcon="mdi-folder-plus-outline" padded>
            <account-info
                :accountInfo="accountInfo"
            ></account-info>
        </content-card>
    </div>
</template>

<script>
    import AccountInfo from './AccountInfo.vue';
    import ContentCard from './ContentCard.vue';
    export default {
        name: 'AccountInfoCard',
        components: {
            AccountInfo,
            ContentCard,
        },
        props: {
            accountInfo: Object,
        },
        computed: {
            showComponent() {
                return this.accountInfo && (this.accountInfo.patientName || this.accountInfo.guarantorName || this.accountInfo.accountNumber || this.accountInfo.primaryInsurance);
            }
        }  
    };
</script>
