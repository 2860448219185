<template>
    <div class="payment-flow-curae-accept-step vuetify">
        <div class="financing-logo-container">
            <img class="financing-logo" src="assets/images/curaeLogo.png" />
        </div>
        <h1 class="accept-title">{{ $translate('paymentFlow.financing.curae.existingAccountHeader') }}</h1>
        <base-form ref="form" @submit.prevent>
            <div class="section account-summary">
                <h2>{{ $translate('paymentFlow.financing.curae.accountInfo') }}</h2>
                <div class="vuetify">
                    {{ $translate('paymentFlow.financing.curae.accountNumber', { accountNumber: existingCuraeOption.accountNumber }) }}<br/>
                    {{ $translate('paymentFlow.financing.curae.availableCredit', { availableCredit: $formatCurrency(existingCuraeOption.availableCreditFloat) }) }}
                </div>
            </div>
            <div class="section authorize-transaction">
                <h2>
                    {{ $translate('paymentFlow.financing.curae.authorizeTransactionTitle', { money: $formatCurrency(amountCovered) }) }}
                    <span v-if="existingCuraeOption.amountDueNotCovered">*</span>
                </h2>
                <div class="horizontal-fields consent vuetify">
                    <base-checkbox
                        v-model="authorizeTransactionCheck"
                        class="vuetify fs-block consent-checkbox"
                        name="authorizeTransactionCheck"
                        ref="authorizeTransactionCheck"
                        :error-messages="v$.authorizeTransactionCheck.$errors.map(e => e.$message)"
                        @action="v$.authorizeTransactionCheck.$touch"
                        @click="() => { authorizeTransactionCheckDirty = true }"
                    ></base-checkbox>
                    <div class="consent-label" v-html="getAuthorizeTransactionCheckText()"></div>
                </div>
                <div v-if="!authorizeTransactionCheck && authorizeTransactionCheckDirty" class="error-message">
                    <span class="error-message">{{  $translate('paymentFlow.financing.curae.authorizeTransactionError') }}</span>
                </div>
            </div>
        </base-form>
        <div v-if="financingError" class="financing-error">
            <base-icon :size="$vuetify.display.lgAndUp ? 24 : 20" icon="mdi-alert-circle"></base-icon>
            <div class="financing-error-message">{{ financingError }}</div>
        </div>
        <div v-if="existingCuraeOption.amountDueNotCovered" class="partial-payment">
            * {{ $translate('paymentFlow.financing.curae.partialPaymentDisclaimer', { amount: $formatCurrency(existingCuraeOption.amountDueNotCovered) }) }}
        </div>
        <div class="navigation-buttons">
            <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
            <base-spacer></base-spacer>
            <base-btn @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
            <base-btn @click="existingCuraeAccount()" :disabled="!authorizeTransactionCheck || isGettingFinancingOptions" class="vuetify continue-btn v-button-primary">
                {{ $translate('paymentFlow.financing.curae.confirmAndProcess') }}
            </base-btn>
        </div>
        <div class="bank-account">{{ $translate('paymentFlow.financing.curae.bankAccount') }}</div>
    </div>
    </template>
    <script>
    import BaseForm from './BaseForm.vue';
    import BaseBtn from './BaseBtn.vue';
    import BaseExpansionPanel from './BaseExpansionPanel.vue';
    import { translate } from './../utils/utils.js';
    import BaseIcon from './BaseIcon.vue';
    import BaseSpacer from './BaseSpacer.vue';
    import BaseCheckbox from './BaseCheckbox.vue';

    import useValidate from '@vuelidate/core';
    import { required, helpers } from '@vuelidate/validators';
    
    export default {
        name: 'PaymentFlowCuraeExistingStep',
        components: {
            BaseForm,
            BaseBtn,
            BaseExpansionPanel,
            BaseIcon,
            BaseSpacer,
            BaseCheckbox
        },
        props: {
            financingError: String,
            isGettingFinancingOptions: Boolean,
            existingCuraeOption: Object,
            totalAmount: Number,
            providerName: String
        },
        data: () => ({
            v$: useValidate(),
            authorizeTransactionCheck: false,
            authorizeTransactionCheckDirty: false,
        }),
        validations() {
            return {
                authorizeTransactionCheck: { required: this.requiredMsg }
            }
        },
        computed: {
            requiredMsg() {
                return helpers.withMessage(this.$translate('errors.fieldRequired'), required);
            },
            amountCovered() {
                return this.existingCuraeOption.amountDueNotCovered ? this.existingCuraeOption.availableCreditFloat : this.totalAmount;
            },
            paymentFormId() {
                return this.existingCuraeOption.paymentForms[0].id;
            }
        },
        methods: {
            translate: translate,
            existingCuraeAccount() {
                this.$emit('existing-curae-account', {
                    authorizeTransactionCheck: this.authorizeTransactionCheck,
                    paymentFormId: this.paymentFormId
                });
            },
            cancel() {
                this.$emit('cancel');
            },
            back() {
                this.$emit('back');
            },
            getAuthorizeTransactionCheckText() {
                return translate('paymentFlow.financing.curae.existingAccountDisclaimer', { provider: this.providerName });
            }
        },
        created() {
            this.$emit('scroll-to-top');
        },
        mounted() {
            this.$emit('loaded');
        },
    };
    </script>
    <style lang="scss">
    @import '../styles/variables.scss';
    .payment-flow-curae-accept-step {
        h1 {
            color: $global-color-font-new;
            font-size: 1.4rem;
            line-height: 2.0rem;
            @include wider-than-tablet {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
        .vuetify.offer-summary.v-card.v-sheet {
            margin: 0;
            margin-top: 0.5rem;
            border: 1px solid white;
            background-color: white;
        }
        .bank-account {
            color: $global-color-font-new;
            font-size: 1.2rem;
            line-height: 1.6rem;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .once-accepted {
            color: $global-color-font-new;
            font-size: 1.2rem;
            line-height: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .v-form {
            .section {
                margin-top: 2.5rem;
                h2 {
                    margin-bottom: 0.3rem;
                    color: $global-color-font-new;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                }
                .error-message span.error-message  {
                    color: $global-color-warning;
                    display: flex;
                    padding-left: 6%;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                }
            }
        }
        .partial-payment {
            padding-top: 1rem;
        }
    }
    </style>
    