<template>
    <table class="receipt-payment-line-items  dashboard-summary-table" v-show="lineItems && lineItems.length > 0">
        <thead>
            <tr>
                <td class="date">{{$translate('labels.date')}}</td>
                <td :class="{'desc': !isPaymentPlan, 'plan-desc': isPaymentPlan}">{{$translate('labels.details')}}</td>
                <td v-if="isPaymentPlan" class="status">{{$translate('labels.status')}}</td>
                <td class="amount">{{$translate('labels.amount')}}</td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in lineItems" :key="index" class="line-item">
                <td class="date">{{ $formatDate(item.date, 'MM/DD/YY') }}</td>
                <td :class="{'desc': !isPaymentPlan, 'plan-desc': isPaymentPlan}" v-html="item.friendlyDialog"></td>
                <td v-if="isPaymentPlan" :class="{'status': true, 'receipt-update-line-item-content': item.isUpdate}">{{ item.status }}</td>
                <td :class="{'amount': true, 'receipt-update-line-item-content': item.isUpdate && isPaymentPlan}" v-html="item.amountDialog"></td>
            </tr>

            <tr v-if="!isPaymentPlan && summationTotalFormatted" class="receipt-summation">
                <td class="date"></td>
                <td class="desc">{{$translate('labels.amountPaid')}}</td>
                <td class="amount"> {{ summationTotalFormatted }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>


export default {
    name: 'ReceiptLineItems',
    props: {
        lineItems: {
            type: Array,
            default: null,
        },
        isPaymentPlan: {
            type: Boolean,
            default: false,
        },
        summationTotalFormatted: {
            type: String,
            default: null,
        },
    },
};

</script>

<style lang="scss">

</style>