<template><v-snackbar v-bind="$attrs" location="top" class="base-snackbar vuetify" :class="{ 'error-message': isErrorMessage }"><slot></slot></v-snackbar></template>

<script>
export default {
    name: 'BaseSnackbar',
    props: {
        isErrorMessage: {
            type: Boolean,
            default: false,
        }
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.base-snackbar {
    margin-top: 5.5rem; // this is assuming that the snackbar is top aligned
    .v-snackbar__wrapper {
        color: $global-color-font-new;
        background-color: #ecf4f0;
        .v-snackbar__content {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
    .base-icon {
        margin-right: 1rem;
        color: $global-color-font-new;
        height: 24px;
        width: 24px;
    }
}
.error-message {
    .v-sheet.v-snack__wrapper {
        background-color: $global-color-warning-light;
    }
}
</style>