<template>
    <div>
        <Modal :canClose="false" :show="showingCheckEmail" class="my-chart-sso-modal my-chart-check-email">
            <form @submit.prevent="checkForPatientWalletAccount()" name="forms.myChartForm" novalidate>
                <h2 class="modal-header h-style-2" v-html="welcomeMessage"></h2>
                <div
                    class="modal-text"
                    v-html="$translate('myChartSSO.patientWalletDescription')"
                    ></div>
                <div class="input-area-wrapper solo-input"
                :class="{errored: (emailInput.$invalid && emailInput.$touched) || (forms.myChartForm.$invalid && forms.myChartForm.$submitted), 'input-focused': emailInput.$focused}">
                    <div class="input-area-field-wrapper">
                    <input
                        name="checkEmail"
                        type="email"
                        class="fs-block"
                        :placeholder="$translate('myChartSSO.enterEmail')"
                        v-model="fields.email"
                        @blur="touchedCheckEmail(); emailInput.$focused = false;"
                        @input="validateEmail()"
                        @focus="emailInput.$focused = true;"
                        />
                    </div>
                    <div class="input-area-feedback">
                        <div class="vue-messages" v-if="emailInput.$error && emailInput.$error.alreadyLinked">
                            <div class="vue-message">
                                {{$translate('myChartSSO.emailAlreadyLinked')}}
                            </div>
                        </div>
                        <div class="vue-messages" v-if="forms.myChartForm.$error && forms.myChartForm.$error.email">
                            <div class="vue-message invalid-email">
                                {{$translate('errors.email')}}
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    id="check-for-patient-wallet-button"
                    class="button button-primary"
                    tabindex="8"
                    type="submit"
                    v-html="$translate((searching ? 'myChartSSO.checkForPatientWalletAccountPending' : 'myChartSSO.checkForPatientWalletAccount'))"
                ></button>
            </form>
        </Modal>
        <!-- User has entered their email, and now needs to verify because an existing account was found -->
        <Modal :canClose="false" :show="showingEmailVerify" class="my-chart-sso-modal my-chart-email-verify">
            <form @submit.prevent="verifyEmail()">
                <h2 class="modal-header h-style-2" v-if="foundMatch">
                    {{ $translate('myChartSSO.verifyEmail.foundMatch.header') }}
                </h2>
                <h2 class="modal-header h-style-2" v-if="!foundMatch">
                    {{ $translate('myChartSSO.verifyEmail.noMatch.header') }}
                </h2>
                <!-- envelope icon -->
                <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="max-width: 100px;margin-bottom: -30px;fill:#616161;"><g transform="translate(0,-952.36218)"><path style="text-indent:0;text-transform:none;direction:ltr;block-progression:tb;baseline-shift:baseline;color:#000000;enable-background:accumulate;" d="m 6.0000002,973.36217 a 2.0002,2.0002 0 0 0 -2,2 l 0,54.00003 a 2.0002,2.0002 0 0 0 2,2 l 87.9999998,0 a 2.0002,2.0002 0 0 0 2,-2 l 0,-54.00003 a 2.0002,2.0002 0 0 0 -2,-2 l -87.9999998,0 z m 5.4687498,4 77.0625,0 L 50,1009.7684 11.46875,977.36217 z m -3.4687498,2.28125 25.9687498,21.84378 -25.9687498,23.375 0,-45.21878 z m 83.9999998,0 0,45.21878 -25.96875,-23.375 L 92,979.64342 z m -54.9375,24.43748 11.65625,9.8125 a 2.0002,2.0002 0 0 0 2.5625,0 l 11.65625,-9.8125 25.875,23.2813 -77.625,0 25.875,-23.2813 z" fill-opacity="1" stroke="none" marker="none" visibility="visible" display="inline" overflow="visible"/></g></svg><br>
                <Notification class="notification-inline failure" v-show="codeNotSent">
                    <span>
                        <base-icon icon="mdi-close-circle"></base-icon>
                    </span>
                    {{ $translate('firstComm.codeNotSent')}}
                </Notification>
                <Notification class="notification-inline success" v-show="codeSent">
                    <span>
                        <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                    </span>
                    {{ $translate('firstComm.codeSent')}}
                </Notification>
                <div class="modal-text fs-block" v-html="verifyEmailPrompt"></div>
                <div class="interaction-feedback" v-if="badVerificationAttempt" v-html="$translate('verification.codeInvalid')"></div>
                <div class="input-area-field-wrapper solo-input">
                    <input
                    name="emailVerificationCode"
                    type="text"
                    class="fs-block"
                    required
                    placeholder="Verification Code"
                    v-model="fields.verificationCode"
                    />
                    <a class="inline" @click="resendVerification()"
                    >{{ $translate('myChartSSO.verifyEmail.resend') }}</a>
                </div>
                <div
                    v-if="!foundMatch"
                    id="terms-of-use-input"
                    class="input-area-wrapper"
                    :class="{errored: termsRequiredError}"
                    >
                    <div class="input-area-field-wrapper no-initial-border inline-input-type">
                        <input
                            id="acceptTerms"
                            name="acceptTerms"
                            type="checkbox"
                            v-model="fields.acceptTerms"
                            tabindex="8"
                            @change="termsUpdated()"
                            />
                        <label
                            for="acceptTerms"
                            class="create-account-terms"
                            v-html="$translate('labels.acceptanceOfTerms')"
                        ></label>
                    </div>
                    <div class="input-area-feedback">
                        <div class="vue-messages" v-if="termsRequiredError">
                            <div class="vue-message terms-required">
                                {{$translate('errors.termsAcceptanceRequired')}}
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    id="verify-email-button"
                    class="button button-primary"
                    :disabled="verifying"
                    >
                    {{ $translate((verifying ? 'myChartSSO.verifyEmail.actionPending' :
                    'myChartSSO.verifyEmail.action')) }}
                </button>
                <div>
                    <a @click="useDifferentEmail()"
                    >{{ $translate('myChartSSO.verifyEmail.useDifferentEmail') }}</a>
                </div>
            </form>
        </Modal>
        <!-- Entering the ebill flow -->
        <Modal :canClose="false"
            :show="showingFinishRegistration"
            class="my-chart-sso-modal login-modal"
        >
            <h2 class="modal-header h-style-2">
            {{ $translate('myChartSSO.finishRegistration.header') }}
            </h2>
            <div class="modal-text" v-html="$translate('myChartSSO.finishRegistration.prompt')"></div>
            <form @submit.prevent name="finishRegistrationForm" novalidate>
            <div class="split-input-areas-container">
                <!-- FIRST NAME -->
                <div class="input-area-wrapper"
                    :class="{errored: finishRegistrationForm.firstName.$invalid && finishRegistrationForm.$submitted, 'input-focused': finishRegistrationForm.firstName.$focused}"
                >
                <div class="input-area-field-wrapper">
                    <span :class="{'icon-active': finishRegistrationForm.firstName.$dirty}">\
                        <base-icon icon="mdi-account"></base-icon>
                    </span>

                    <input
                        name="firstName"
                        type="text"
                        id="finishRegistration-first"
                        class="fs-block input-with-icon"
                        @input="firstNameValidName()"
                        :placeholder="$translate('labels.firstName')"
                        v-model="finishRegistrationFields.firstName"
                        tabindex="1"
                        @focus="finishRegistrationForm.firstName.$focused = true"
                        @blur="finishRegistrationForm.firstName.$focused = false"
                    />
                </div>

                <div class="input-area-feedback">
                    <div class="vue-messages first-name-errors" v-if="finishRegistrationForm.firstName.$error && finishRegistrationForm.firstName.$error.required">
                        <div class="vue-message">
                            {{$translate('errors.genericRequired')}}
                        </div>
                    </div>
                </div>
                </div>

                <!-- LAST NAME -->
                <div class="input-area-wrapper"
                    :class="{errored: finishRegistrationForm.lastName.$invalid && finishRegistrationForm.$submitted, 'input-focused': finishRegistrationForm.lastName.$focused}"
                >
                    <div class="input-area-field-wrapper">
                        <div>
                            <span :class="{'icon-active':finishRegistrationForm.lastName.$dirty}">
                                <base-icon icon="mdi-account"></base-icon>
                            </span>
                        </div>

                        <input
                            name="lastName"
                            type="text"
                            id="finishRegistration-last"
                            class="fs-block input-with-icon"
                            :placeholder="$translate('labels.lastName')"
                            v-model="finishRegistrationFields.lastName"
                            tabindex="2"
                            @input="lastNameValidName()"
                            @focus="finishRegistrationForm.lastName.$focused = true"
                            @blur="finishRegistrationForm.lastName.$focused = false"
                        />
                    </div>

                    <div class="input-area-feedback">
                        <div class="vue-messages last-name-errors" v-if="finishRegistrationForm.lastName.$error && finishRegistrationForm.lastName.$error.required">
                            <div class="vue-message">
                                {{$translate('errors.genericRequired')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- EMAIL -->
            <div class="input-area-wrapper" :class="{'input-focused': finishRegistrationForm.email.$focused}">
                <div class="input-area-field-wrapper">
                    <span>
                        <base-icon icon="mdi-email"></base-icon>
                    </span>

                <!-- disabled since this should be autofilled from token -->
                <input
                    name="email"
                    type="email"
                    class="fs-block input-with-icon"
                    disabled
                    autocapitalize="off"
                    autocorrect="off"
                    autosuggest="off"
                    id="finishRegistration-email"
                    :placeholder="$translate('labels.email')"
                    v-model="finishRegistrationFields.email"
                    tabindex="3"
                    maxlength="255"
                    @focus="finishRegistrationForm.email.$focused = true"
                    @blur="finishRegistrationForm.email.$focused = false"
                />
                </div>
            </div>

            <!-- PHONE -->
            <div class="input-area-wrapper"
                :class="{errored: finishRegistrationForm.phone.$invalid && finishRegistrationForm.phone.$touched, 'input-focused': finishRegistrationForm.phone.$focused}"
            >
                <div class="input-area-field-wrapper">
                <span :class="{'icon-active': finishRegistrationForm.phone.$dirty}">
                    <base-icon icon="mdi-phone"></base-icon>
                </span>

                <input
                    name="phone"
                    type="text"
                    id="finishRegistration-phone"
                    class="fs-block input-with-icon"
                    :placeholder="$translate('labels.phoneNumber')"
                    v-model="finishRegistrationFields.phoneNumber"
                    tabindex="4"
                    maxlength="12"
                    @input="phoneNumberTouched()"
                    @focus="finishRegistrationForm.phone.$focused = true"
                    @blur="finishRegistrationForm.phone.$focused = false; validatePhoneNumber();"
                />
                </div>

                <div class="input-area-feedback">
                <div class="vue-messages" v-if="finishRegistrationForm.phone.$error">
                    <div class="vue-message phone-format" v-if="finishRegistrationForm.phone.$error.minlength">
                    {{$translate('errors.phoneFormat')}}
                    </div>
                    <div class="vue-message not-sms-eligible" v-if="finishRegistrationForm.phone.$error.smsEligible">
                    {{$translate('errors.smsEligible')}}
                    </div>
                </div>
                </div>
            </div>

            <!-- PASSWORD -->
            <div class="input-area-wrapper login-password-input-area"
                :class="{
                            errored: finishRegistrationForm.password.$invalid && finishRegistrationForm.$submitted,
                            'login-password-incorect-blurred': finishRegistrationForm.password.$dirty && finishRegistrationForm.password.$invalid,
                            'input-focused': finishRegistrationForm.password.$focused
                            }"
            >
                <div class="input-area-field-wrapper">
                    <span :class="{'icon-active': finishRegistrationForm.password.$dirty}">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>

                <input
                    name="password"
                    type="password"
                    id="finishRegistration-pass"
                    maxlength="255"
                    class="fs-block input-with-icon"
                    :placeholder="$translate('labels.password')"
                    v-model="finishRegistrationFields.password"
                    tabindex="5"
                    @input="validatePassword()"
                    @focus="finishRegistrationForm.password.$focused = true"
                    @blur="finishRegistrationForm.password.$focused = false"
                />
                </div>

                <div class="input-area-rules">
                    <ul>
                        <li
                            class="min-char-rule"
                            :class="{'rule-met': finishRegistrationFields.password && finishRegistrationFields.password.length > 0 && !finishRegistrationForm.password.$error.minCharactersRule}"
                        >
                            <span v-if="!finishRegistrationForm.password.$error.minCharactersRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-circle"></base-icon>
                            </span>
                            {{$translate('login.minCharactersRule')}}
                        </li>
                        <li
                            class="upper-case-rule"
                            :class="{'rule-met': finishRegistrationFields.password && finishRegistrationFields.password.length > 0 && !finishRegistrationForm.password.$error.upperCaseRule}"
                        >
                            <span v-if="!finishRegistrationForm.password.$error.upperCaseRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-circle"></base-icon>
                            </span>
                            {{$translate('login.upperCaseRule')}}
                        </li>
                        <li
                            class="digit-rule"
                            :class="{'rule-met': finishRegistrationFields.password && finishRegistrationFields.password.length > 0 && !finishRegistrationForm.password.$error.digitRule}"
                        >
                            <span v-if="!finishRegistrationForm.password.$error.digitRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-circle"></base-icon>
                            </span>
                            {{$translate('login.digitRule')}}
                        </li>
                    </ul>
                </div>
            </div>

            <!-- CONFIRM PASSWORD -->
            <div class="input-area-wrapper"
                :class="{errored: finishRegistrationForm.password.$valid && finishRegistrationForm.confirmPassword.$invalid && finishRegistrationForm.$submitted, 'input-focused': finishRegistrationForm.confirmPassword.$focused}"
            >
                <div class="input-area-field-wrapper">
                    <span :class="{'icon-active': finishRegistrationForm.confirmPassword.$dirty}">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>

                <input
                    name="confirmPassword"
                    type="password"
                    id="finishRegistration-con-pass"
                    :match="finishRegistrationFields.password"
                    class="fs-block input-with-icon"
                    :placeholder="$translate('labels.passwordConfirm')"
                    v-model="finishRegistrationFields.confirmPassword"
                    tabindex="6"
                    @input="validateConfirmPassword()"
                    @focus="finishRegistrationForm.confirmPassword.$focused = true"
                    @blur="finishRegistrationForm.confirmPassword.$focused = false"
                />
                </div>
                <div class="input-area-feedback">
                <div class="vue-messages" v-if="finishRegistrationForm.confirmPassword.$error && (finishRegistrationForm.confirmPassword.$error.required || finishRegistrationForm.confirmPassword.$error.mismatch)">
                    <div class="vue-message confirm-password-required" v-if="finishRegistrationForm.confirmPassword.$error.required">
                    {{$translate('errors.genericRequired')}}
                    </div>
                    <div class="vue-message confirm-password-mismatch" v-if="finishRegistrationForm.confirmPassword.$error.mismatch">
                    {{$translate('errors.confirmPassword')}}
                    </div>
                </div>
                </div>
            </div>

            <div class="register-checkboxes">
                <!-- SMS OPT-IN -->
                <div
                class="input-area-wrapper"
                :class="{errored: finishRegistrationForm.receivesSms.$invalid && finishRegistrationForm.$submitted}"
                >
                <div
                    class="input-area-field-wrapper no-initial-border inline-input-type"
                >
                    <input
                        id="finishRegistration-receivesSms"
                        name="receivesSms"
                        type="checkbox"
                        v-model="finishRegistrationFields.receivesSms"
                        tabindex="7"
                    />

                    <label
                        for="finishRegistration-receivesSms"
                        class="create-account-terms"
                        v-html="$translate('labels.receivesSms')"
                    ></label>
                </div>
                </div>
            </div>

            <button
                class="button button-primary"
                :disabled="submittingFinishRegistration"
                @click="finishRegistration();"
                tabindex="8"
            >
                {{ $translate('myChartSSO.finishRegistration.action')}}
            </button>
            </form>
        </Modal>
    </div>
</template>

<script>
import Modal from './Modal.vue';
import Notification from './Notification.vue';

import { validEmail, getValidName } from '../utils/utils';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'MyChartSsoModal',
    components: {
        Modal,
        Notification,
        BaseIcon
    },
    data() {
        return {
            showingCheckEmail: false,
            showingEmailVerify: false,
            foundMatch: false,
            badVerificationAttempt: false,
            showingFinishRegistration: false,
            submittingFinishRegistration: false,

            forms: {
                myChartForm: {
                    $invalid: false,
                    $error: {
                        email: false,
                    }
                }
            },

            fields: {
                acceptTerms: false,
                email: '',
                verificationCode: ''
            },
            source: '',
            token: '',
            userFirstName: '',
            userLastName: '',

            // please make this better when we can rewrite this instead of port it asap
            finishRegistrationForm: {
                $submitted: false,
                $invalid: false,
                firstName: {
                    $invalid: false,
                    $dirty: false,
                    $error: {},
                    $focused: false,
                },
                lastName: {
                    $invalid: false,
                    $dirty: false,
                    $error: {},
                    $focused: false,
                },
                email: {
                    $invalid: false,
                    $dirty: false,
                    $touched: false,
                    $error: {},
                    $focused: false,
                },
                phone: {
                    $invalid: false,
                    $dirty: false,
                    $touched: false,
                    $error: {
                        minlength: false,
                        smsEligible: false,
                    },
                    $focused: false,
                },
                password: {
                    $valid: false,
                    $invalid: false,
                    $dirty: false,
                    $error: {},
                    $focused: false,
                },
                confirmPassword: {
                    $invalid: false,
                    $dirty: false,
                    $error: {},
                    $focused: false,
                },
                receivesSms: {
                    $invalid: false,
                    $dirty: false,
                    $error: {},
                },
            },
            finishRegistrationFields: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                password: '',
                receivesSms: '',
                phoneNumber: '',
            },

            emailInput: {
                $invalid: false,
                $touched: false,
                $focused: false,
                $error: {}
            },

            codeSent: false,
            codeNotSent: false,
            verifyEmailPrompt: '',
            verifying: false,
            searching: false,
            welcomeMessage: '',
            findData: {},
            callback: () => {},

            // some fields to enforce required fields
            termsRequiredError: false,
        };
    },
    methods: {
        termsUpdated() {
            if (!this.fields.acceptTerms) {
                this.termsRequiredError = true;
            } else {
                this.termsRequiredError = false;
            }
        },
        firstNameValidName() {
            if (!this.finishRegistrationFields.firstName) {
                this.finishRegistrationForm.$invalid = true;
                this.finishRegistrationForm.firstName.$invalid = true;
                this.finishRegistrationForm.firstName.$error.required = true;
                return;
            }
            this.finishRegistrationForm.firstName.$invalid = false;
            this.finishRegistrationForm.firstName.$error.required = false;
            this.finishRegistrationForm.firstName.$dirty = true;
            this.finishRegistrationFields.firstName = getValidName(this.finishRegistrationFields.firstName);
        },
        lastNameValidName() {
            if (!this.finishRegistrationFields.lastName) {
                this.finishRegistrationForm.$invalid = true;
                this.finishRegistrationForm.lastName.$invalid = true;
                this.finishRegistrationForm.lastName.$error.required = true;
                return;
            }
            this.finishRegistrationForm.lastName.$invalid = false;
            this.finishRegistrationForm.lastName.$error.required = false;
            this.finishRegistrationForm.lastName.$dirty = true;
            this.finishRegistrationFields.lastName = getValidName(this.finishRegistrationFields.lastName);
        },
        phoneNumberTouched() {
            // this method only gets called when the user changes the input, not on form submit, so set touched
            this.finishRegistrationForm.phone.$touched = true;
            // run our frontend validation
            this.validatePhoneNumber();
            // if it passes our other frontend validation, ping the backend to validate the number
            if (!this.finishRegistrationForm.phone.$invalid && this.finishRegistrationFields.phoneNumber && this.finishRegistrationFields.phoneNumber !== '') {
                // pass just the numbers to this function
                this.$store.dispatch('getSmsEligibility', this.finishRegistrationFields.phoneNumber.replace(/\D/g,'')).catch(() => {
                    this.finishRegistrationForm.phone.$error.smsEligible = true;
                    this.finishRegistrationForm.$invalid = true;
                    this.finishRegistrationForm.phone.$invalid = true;
                });
            }
        },
        validatePhoneNumber() {
            this.finishRegistrationForm.phone.$invalid = false;
            this.finishRegistrationForm.phone.$error.minlength = false;
            this.finishRegistrationForm.phone.$error.smsEligible = false;
            this.finishRegistrationForm.phone.$dirty = false;
            if (this.finishRegistrationFields.phoneNumber && this.finishRegistrationFields.phoneNumber !== '') {
                let unformattedValue = this.finishRegistrationFields.phoneNumber;
                unformattedValue = unformattedValue.replace(/\D/g,'');
                if (unformattedValue.length > 3) {
                    unformattedValue = unformattedValue.slice(0, 3) + '-' + unformattedValue.slice(3);
                }
                if (unformattedValue.length > 7) {
                    unformattedValue = unformattedValue.slice(0, 7) + '-' + unformattedValue.slice(7);
                }
                this.finishRegistrationFields.phoneNumber = unformattedValue;
                this.finishRegistrationForm.phone.$dirty = true;
                if (this.finishRegistrationFields.phoneNumber.length < 12) {
                    this.finishRegistrationForm.$invalid = true;
                    this.finishRegistrationForm.phone.$error.minlength = true;
                    this.finishRegistrationForm.phone.$invalid = true;
                    return;
                }
            }
        },
        validatePassword() {
            // fresh start
            this.finishRegistrationForm.password.$valid = false;
            this.finishRegistrationForm.password.$invalid = false;
            this.finishRegistrationForm.password.$error.minCharactersRule = false;
            this.finishRegistrationForm.password.$error.upperCaseRule = false;
            this.finishRegistrationForm.password.$error.digitRule = false;
            this.finishRegistrationForm.password.$dirty = true;
            if (!this.finishRegistrationFields.password || this.finishRegistrationFields.password === '') {
                // if there's no value no need to run the rest of the assertions, all rules are broken
                this.finishRegistrationForm.password.$dirty = false;
                this.finishRegistrationForm.password.$error.minCharactersRule = true;
                this.finishRegistrationForm.password.$error.upperCaseRule = true;
                this.finishRegistrationForm.password.$error.digitRule = true;
                this.finishRegistrationForm.password.$invalid = true;
                this.finishRegistrationForm.$invalid = true;
                return;
            }
            if (this.finishRegistrationFields.password.length < 8) {
                this.finishRegistrationForm.password.$error.minCharactersRule = true;
                this.finishRegistrationForm.password.$invalid = true;
                this.finishRegistrationForm.$invalid = true;
            }
            if (!/[A-Z]+/.test(_.trim(this.finishRegistrationFields.password))) {
                this.finishRegistrationForm.password.$error.upperCaseRule = true;
                this.finishRegistrationForm.password.$invalid = true;
                this.finishRegistrationForm.$invalid = true;
            }
            if (!/\d+/.test(_.trim(this.finishRegistrationFields.password))) {
                this.finishRegistrationForm.password.$error.digitRule = true;
                this.finishRegistrationForm.password.$invalid = true;
                this.finishRegistrationForm.$invalid = true;
            }
            if (!this.finishRegistrationForm.password.$invalid) {
                this.finishRegistrationForm.password.$valid = true;
                this.validateConfirmPassword(); // in case this becoming valid makes the validation pass
            }
        },
        validateConfirmPassword() {
            this.finishRegistrationForm.confirmPassword.$invalid = false;
            this.finishRegistrationForm.confirmPassword.$dirty = true;
            this.finishRegistrationForm.confirmPassword.$error.mismatch = false;
            this.finishRegistrationForm.confirmPassword.$error.required = false;
            if (!this.finishRegistrationFields.confirmPassword || this.finishRegistrationFields.confirmPassword === '') {
                this.finishRegistrationForm.confirmPassword.$error.required = true;
                this.finishRegistrationForm.confirmPassword.$invalid = true;
                this.finishRegistrationForm.$invalid = true;
                this.finishRegistrationForm.confirmPassword.$dirty = false;
                return;
            }
            if (this.finishRegistrationFields.confirmPassword !== this.finishRegistrationFields.password) {
                this.finishRegistrationForm.confirmPassword.$error.mismatch = true;
                this.finishRegistrationForm.confirmPassword.$invalid = true;
                this.finishRegistrationForm.$invalid = true;
                return;
            }
        },
        checkForPatientWalletAccount() {
            this.validateEmail();
            if (this.searching || this.forms.myChartForm.$invalid) {
                return;
            }
            this.searching = true;
            this.$store.dispatch('emailAvailable', {email: this.fields.email})
            .then(() => {
                return this.sendVerification()
                    .then((resp) => {
                        if (resp.data && resp.data.error) {
                            throw resp;
                        }
                    })
            })
            .then(() => {
                this.searching = false;
                this.verifyEmailPrompt = this.$translate('myChartSSO.verifyEmail.noMatch.prompt', { email: this.fields.email });
                this.foundMatch = false;
                this.showingEmailVerify = true;
                this.showingCheckEmail = false;
            }).catch((resp) => {
                if (resp.data && resp.data.error) {
                    // we encountered a known error
                    this.sendVerificationError(resp.data.error);
                } else if (!resp.errorCode && resp.data) {
                    // there is a match, so continue to "confirm" step
                    return this.sendVerification().then((resp) => {
                        if (resp.data && resp.data.error) {
                            throw resp;
                        }
                        this.verifyEmailPrompt = this.$translate('myChartSSO.verifyEmail.foundMatch.prompt', { email: this.fields.email });
                        this.foundMatch = true;
                        this.showingCheckEmail = false;
                        this.showingEmailVerify = true;
                    })
                    .catch((resp) => {
                        if (resp.data) {
                            // we encountered a known error
                            this.sendVerificationError(resp.data.error);
                        } else {
                            // we got some other error we don't know how to handle
                            this.handleErrorCode();
                        }
                    });
                } else {
                    // we got some other error we don't know how to handle
                    this.handleErrorCode();
                }
            }).finally(() => {
                //in case there is a send verification error that is improperly handled
                this.searching = false;
            });
        },

        sendVerificationError(error) {
            if (error === 'LOGIN_BLOCKED') {
                // User entered an email already associated with another mychart sso user
                this.searching = false;
                this.emailInput.$invalid = true;
                this.emailInput.$error.alreadyLinked = true;
            } else if (error === 'INVALID_TOKEN') {
                // Token expired
                this.handleInvalidTokenError();
            }
        },

        handleInvalidTokenError() {
            this.verifying = false;
            this.showingCheckEmail = false;
            this.searching = false;
            this.showingEmailVerify = false;

            this.emitter.emit('simpleModal:showPrompt', {
                header: this.$translate('myChartSSO.error.invalidToken'),
                subcontent: this.$translate('myChartSSO.error.body'),
                intent: 'error',
                actions: [{
                    label: this.$translate('myChartSSO.error.backToMyChart'),
                    clickHandler: () => {
                        this.returnToReferrer();
                    }
                }]
            });
        },

        handleErrorCode() {
            // we got some other error we don't know how to handle
            this.verifying = false;
            this.showingCheckEmail = false;
            this.searching = false;
            this.emitter.emit('simpleModal:showPrompt', {
                header: this.$translate('myChartSSO.error.header'),
                subcontent: this.$translate('myChartSSO.error.body'),
                intent: 'error',
                actions: [{
                    label: this.$translate('myChartSSO.error.backToMyChart'),
                    clickHandler: () => {
                        this.returnToReferrer();
                    }
                }]
            });
        },

        verifyEmail() {
            if (this.verifying) {
                return;
            }
            this.badVerificationAttempt = false;
            if (this.foundMatch) {
                this.verifying = true;
                return this.$store.dispatch('confirmSSOVerifyCode', { email: this.fields.email, verificationCode: this.fields.verificationCode, source: this.source, token: this.token })
                    .then((result) => {
                        this.verifying = false;
                        if (result.data.success === false) {
                            if (result.data.error && result.data.error === 'INVALID_TOKEN') {
                                this.handleInvalidTokenError();
                            } else {
                                this.source = result.data.source;
                                this.token = result.data.token;
                                this.badVerificationAttempt = true;
                            }
                        } else {
                            return this.$store.dispatch('fetchCurrentUser')
                                .then(() => {
                                    this.showingEmailVerify = false;
                                    this.emitter.emit('simpleModal:showPrompt', {
                                        header: this.$translate('myChartSSO.verifyEmail.foundMatch.success.header'),
                                        subcontent: this.$translate('myChartSSO.verifyEmail.foundMatch.success.prompt'),
                                        intent: 'success',
                                        actions: [{
                                            label: this.$translate('actions.continue'),
                                            includeClasses: 'continue-button'
                                        }]
                                    });
                                    this.$router.push({name: 'ProvidersSummary'});
                                });
                        }
                    }).catch((err) => {
                        console.error('Error while trying to confirm MyChartSSO', err);
                        this.emitter.emit('simpleModal:showPrompt', {
                            header: this.$translate('myChartSSO.error.header'),
                            subcontent: this.$translate('myChartSSO.error.body'),
                            intent: 'error',
                            actions: [{
                                label: this.$translate('myChartSSO.error.backToMyChart'),
                                clickHandler: () => {
                                    this.returnToReferrer();
                                }
                            }]
                        });
                    });
            } else {
                if (!this.fields.acceptTerms) {
                    this.termsRequiredError = true;
                    return;
                }
                this.verifying = true;
                this.badVerificationAttempt = false;
                return this.$store.dispatch('registerSSOUser', {email: this.fields.email, verificationCode: this.fields.verificationCode, acceptTerms: this.fields.acceptTerms, source: this.source, token: this.token})
                    .then((result) => {
                        this.verifying = false;
                        if (result.errorCode) {
                            throw new Error('something went wrong during registration', result);
                        } else if (result.data && result.data.success === false) {
                            if (result.data.error && result.data.error === 'INVALID_TOKEN') {
                                this.handleInvalidTokenError();
                            } else {
                                this.source = result.data.source;
                                this.token = result.data.token;
                                this.badVerificationAttempt = true;
                            }
                        } else {
                            return this.$store.dispatch('fetchCurrentUser').then(() => {
                                this.showingEmailVerify = false;
                                this.emitter.emit('simpleModal:showPrompt', {
                                    header: this.$translate('myChartSSO.verifyEmail.noMatch.success.header'),
                                    subcontent: this.$translate('myChartSSO.verifyEmail.noMatch.success.prompt'),
                                    intent: 'success',
                                    actions: [{
                                        label: this.$translate('actions.continue'),
                                        includeClasses: 'continue-button'
                                    }]
                                });
                                this.$router.push({name: 'ProvidersSummary'});
                            });
                        }
                    }).catch((err) => {
                        console.error('Error while registering SSO user', err);
                        this.emitter.emit('simpleModal:showPrompt', {
                            header: this.$translate('myChartSSO.error.header'),
                            subcontent: this.$translate('myChartSSO.error.body'),
                            actions: [{
                                label: this.$translate('myChartSSO.error.backToMyChart'),
                                clickHandler: () => {
                                    this.returnToReferrer();
                                }
                            }]
                        });
                    });
            }
        },

        sendVerification() {
            return this.$store.dispatch('myChartSSOSendVerification', {email: this.fields.email, firstName: this.userFirstName, source: this.source, token: this.token })
                .then((resp) => {
                    this.source = resp.data.source;
                    this.token = resp.data.token;
                    return resp;
                });
        },

        resendVerification() {
            return this.$store.dispatch('myChartSSOSendVerification', {email: this.fields.email, firstName: this.userFirstName, source: this.source, token: this.token })
            .then((resp) => {
                if (resp.data && resp.data.error) {
                    throw resp;
                }
                this.source = resp.data.source;
                this.token = resp.data.token;
                this.codeSent = true;
                setTimeout(() =>{
                    this.codeSent = false;
                }, 5000);
            })
            .catch((resp) => {
                if (resp.data && resp.data.error) {
                    if (resp.data.error === 'INVALID_TOKEN') {
                        this.handleInvalidTokenError();
                    }
                }
                this.codeNotSent = true;
                setTimeout(() =>{
                    this.codeNotSent = false;
                }, 5000);
            });
        },

        useDifferentEmail() {
            this.showingEmailVerify = false;
            this.showingCheckEmail = true;
        },

        finishRegistration() {
            this.finishRegistrationForm.$submitted = true;
            this.finishRegistrationForm.$invalid = false;
            this.firstNameValidName();
            this.lastNameValidName();
            this.validatePhoneNumber();
            this.validatePassword();
            this.validateConfirmPassword();
            if (this.submittingFinishRegistration || this.finishRegistrationForm.$invalid) {
                return;
            }
            var registerInfo = {
                email: this.finishRegistrationFields.email,
                password: this.finishRegistrationFields.password,
                receivesSms: this.finishRegistrationFields.receivesSms,
                confirmPassword: this.finishRegistrationFields.confirmPassword,
                firstName: this.finishRegistrationFields.firstName,
                lastName: this.finishRegistrationFields.lastName,
                phoneNumber: this.finishRegistrationFields.phoneNumber
            };

            this.submittingFinishRegistration = true;

            this.$store.dispatch('register', registerInfo)
                .then(() => {
                    this.showingFinishRegistration = false;
                    this.callback()
                        .then(() => {
                            this.emitter.emit('simpleModal:showPrompt', {
                                header: this.$translate('myChartSSO.finishRegistration.success.header'),
                                subcontent: this.$translate('myChartSSO.finishRegistration.success.prompt'),
                                intent: 'success',
                                actions: [{
                                    label: this.$translate('actions.continue'),
                                    includeClasses: 'continue-button'
                                }]
                            });
                            this.submittingFinishRegistration = false;
                        });
                })
                .catch((resp) => {
                    if (resp === 'LOGIN_NOT_VERIFIED' || (resp.errorCode && resp.errorCode === 'LOGIN_NOT_VERIFIED')) {
                        this.showingFinishRegistration = false;
                        this.emitter.emit('verification:prompt', {
                            email: this.finishRegistrationFields.email,
                            callback: this.callback
                        });
                    }
                    this.submittingFinishRegistration = false;
                });
        },

        touchedCheckEmail() {
            this.emailInput.$touched = true;
        },

        validateEmail() {
            // run some validation now that we know it's been changed since it was last run
            if (!this.fields.email || this.fields.email === '' || !validEmail(this.fields.email)) {
                this.emailInput.$invalid = true; // email is required
                this.forms.myChartForm.$error.email = true;
                this.forms.myChartForm.$invalid = true;
            } else {
                this.emailInput.$invalid = false;
                this.forms.myChartForm.$invalid = false;
                this.forms.myChartForm.$error.email = false;
            }
        },

        returnToReferrer() {
            window.history.go(this.initialHistoryLength - window.history.length - 1);
        },
    },
    computed: {
        brandingFriendlyName() {
            return this.$store.getters.brandingConfig ? this.$store.getters.brandingConfig.friendlyName : null;
        },
        initialHistoryLength() {
            return this.$store.getters.initialHistoryLength;
        }
    },
    created() {
        // listeners
        this.emitter.on('myChartSSO:showCheckEmail', ({source, token, userFirstName, userLastName}) => {
            this.welcomeMessage = this.$translate('myChartSSO.welcomeToPatientWallet', { providerGroupName: this.brandingFriendlyName });
            this.source = source;
            this.token = token;
            this.userFirstName = userFirstName;
            this.userLastName = userLastName;
            this.showingCheckEmail = true;
        });

        this.emitter.on('myChartSSO:showFinishRegistration', ({data, callback}) => {
            this.finishRegistrationFields.firstName = data.firstName;
            this.finishRegistrationFields.lastName = data.lastName;
            this.finishRegistrationFields.email = data.email;
            this.userFirstName = this.finishRegistrationFields.firstName;
            this.callback = callback;
            this.showingFinishRegistration = true;
        });
    }
};

</script>

<style lang="scss" scope>

</style>