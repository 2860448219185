<template>
    <div class="provider-summary-card vuetify">
        <content-card :clickable="clickable">
            <provider-logo v-if="showProviderLogo && providerId" :providerId="providerId" :altText="providerName"></provider-logo>
            <div class="provider-name">
                {{ providerName }}
            </div>
            <div class="total-amount-due">
                <div class="total-amount-due-value">{{ $formatCurrency(amountDue) }}</div>
                <div class="total-amount-due-label">{{ $translate('providersDashboard.totalAmountDue') }}</div>
            </div>
            <div class="account-details">
                <div>
                    <div class="num-accounts-value"> {{ numAccounts }} </div>
                    <div class="num-accounts-label"> {{ singleAccount ? $translate('providersDashboard.account') : $translate('providersDashboard.accounts')}}</div>
                </div>
            </div>
        </content-card>
    </div>
</template>

<script>
import ContentCard from './ContentCard.vue';
import ProviderLogo from './ProviderLogo.vue';
export default {
    name: 'ProviderSummaryCard',
    components: {
        ContentCard,
        ProviderLogo,
    },
    props: {
        providerName: String,
        providerId: String,
        amountDue: Number,
        numAccounts: {
            type: Number,
            default: 0
        },
        showProviderLogo: {
            type: Boolean,
            default: true
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        singleAccount() {
            return this.numAccounts == 1;
        }
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';

.provider-summary-card {
    .v-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        padding: 2.5rem 1.5rem 2.5rem 1.5rem;

        @include wider-than-tablet {
            width: 30rem;
            max-height: 309px;
        }

        #left-column-persist-v2 & .logo-container,
        .logo-container {
            margin-bottom: 1.8rem;
            max-height: 6.9rem;
            min-height: 6.9rem;
            img {
                max-width: 100%;
                max-height: 6.2rem;
            }
        }

        .provider-name {
            font-size: 2rem;
            font-weight: 500;
            letter-spacing: 0.14px;
            line-height: 2rem;
            height: 4.1rem;
            color: $global-color-font-new;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            margin-bottom: 0.4rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .total-amount-due {
            display: flex;
            flex-direction: column;
            color: $global-color-font-new;
            margin-bottom: 2.5rem;
            
            .total-amount-due-value {
                font-size: 3.4rem;
                line-height: 3.6rem;
            }

            .total-amount-due-label {
                margin-top: 0.3rem;
                font-size: 1.6rem;
                line-height: 1.6rem;
                letter-spacing: 0.4px;
            }
        }

        .account-details {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            color: $global-color-font-new;

            .num-accounts-value {
                font-size: 2rem;
                line-height: 2.4rem;
                letter-spacing: 0.1px;
                font-weight: 400;
            }

            .num-accounts-label {
                font-size: 1.6rem;
                letter-spacing: 0.4px;
                line-height: 2.4rem;
            }
        }
    }
}
</style>