<template>
    <div class="messages zero-state" >
        <div class="center-vertically">
            <base-icon icon="mdi-email-open"></base-icon>
            <h2 class="h-style-4">{{$translate('message.zeroStateTitle')}}</h2>
            <p >{{$translate('message.zeroStateBlurb')}}</p>
        </div>
    </div>
</template>

<script>
    import BaseIcon from './BaseIcon.vue';

    export default {
        name: 'MessagesListEmpty',
        components: {
            BaseIcon,
        },
    };
</script>

