
const state = {
    acknowledgeResumeData: null,
};

const getters = {};

const mutations = {
    setFormAcknowledgeResumeData(state, val) {
        state.acknowledgeResumeData = val;
    },
};

const actions = {
    // To get around the CORS Direct POST issue with NMI
    // we will create an iframe that will proxy this process and
    // will actually perform the expected redirects inside of itself.
    // We will then be updated when the redirections are complete
    useProxyIframe({}, { formAction, nmiData, proxyTimeoutMs }) {
        return new Promise((resolve, reject) => {
            var _iframe = null,
                _proxyData = {},
                _proxy = null,
                _expectLoad = false,
                _timeoutTimer,
                _gatewayResponded = false,

                _proxyUrl = '/gatewayProxies/request.html';

            // Likely we only set this through parameters while testing
            if (undefined === proxyTimeoutMs) {
                proxyTimeoutMs = 60 * 1000;  // 60 seconds
            }

            // clean up all of the set data
            function _cleanUp() {
                window.document.body.removeChild(_iframe);
                _proxyData = {};
                _iframe = null;
                _expectLoad = false;
                _gatewayResponded = false;
                window.clearInterval(_timeoutTimer);
            }

            // callback for the iframe onload
            // initiates all of the actual communication
            function _proxyFrameReady() {
                // only sending once
                if (!_expectLoad) {
                    return;
                }
                _expectLoad = false;

                _proxy.postMessage(JSON.stringify(_proxyData), window.location.origin);

                window.patientapp = window.patientapp || {};

                window.patientapp.proxyResponse = function () {
                    window.FS.log('log', 'success payment gateway step2Proxy');
                    _gatewayResponded = true;
                    resolve();
                    _cleanUp();
                };
            }

            // if we see that the gateway did not callback within
            // our timeframe,  we need to reject our promise
            function _proxyResponseTimeoutCheck() {
                if (!_gatewayResponded) {
                    reject('NETWORK_ERROR');
                }
            }

            // Note, we require the user of window.location.origin which is not supported
            // in some legacy browsers. Specifically IE9 needs it
            // This polyfill is to help fix that lack of support
            if (!window.location.origin) {
                window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }

            // data passed to proxy that's needed to build a form
            // to post to gatway
            _proxyData = {
                formAction,
                data: nmiData
            };

            _expectLoad = true;


            if (_iframe === null) {

                _iframe = window.document.createElement('iframe');
                _iframe.src = _proxyUrl;

                // we will have the contentWindow after load happens so make it go back through the cycle onload triggered 
                _iframe.onload = _proxyFrameReady;
                _iframe.style.height = '0';
                _iframe.style.width = '0';
                _iframe.style.display = 'none';

                window.document.body.appendChild(_iframe);

                _proxy = _iframe.contentWindow;
            } else {
                // always make sure we start from the initial url
                _iframe.src = _proxyUrl;
            }

            _timeoutTimer = window.setInterval(_proxyResponseTimeoutCheck, proxyTimeoutMs, 1);
        });
    },

    // postToGateway encapsulates the logic of this specific
    // gateway's posting procedures. We use the gatewayDetails
    // to build the different posting pieces and the payment is used
    // to populate other important bits of the form request
    postToGateway({ dispatch }, { gatewayDetails, payment }) {
        var nmiData = {
            'billing-cc-number': '',
            'billing-cc-exp': '',
            'billing-account-name': '',
            'billing-account-number': '',
            'billing-routing-number': '',
            'billing-cvv': '',
            'billing-first-name': '',
            'billing-last-name': '',
            'billing-address1': payment.billing.address || payment.billing.address1,
            'billing-address2': payment.billing.address2 || '',
            'billing-city': payment.billing.city,
            'billing-state': payment.billing.state,
            'billing-postal': payment.billing.zip,
            'billing-phone': payment.billing.phone || '',
            'billing-email': payment.billing.email || '',
            'billing-micr': ''
        },

            // when we resolve this post, we need
            // to pass these details along with any scaffolding
            // that is already passed to the confirmation endpoint
            acknowledgeDetails = {
                tokenId: gatewayDetails.formAction.split('/').pop()
            };

        if (payment.method.card) {
            nmiData['billing-first-name'] = payment.method.card.firstName;
            nmiData['billing-last-name'] = payment.method.card.lastName;
            nmiData['billing-cc-number'] = payment.method.card.number;
            nmiData['billing-cc-exp'] = payment.method.card.expiration;
            nmiData['billing-cvv'] = payment.method.card.cvv;
        } else {
            nmiData['billing-first-name'] = payment.method.eCheck.firstName;
            nmiData['billing-last-name'] = payment.method.eCheck.lastName;
            nmiData['billing-account-name'] = payment.method.eCheck.firstName + ' ' + payment.method.eCheck.lastName;
            nmiData['billing-account-number'] = payment.method.eCheck.accountNumber;
            nmiData['billing-routing-number'] = payment.method.eCheck.routing;
        }

        window.FS.event('payment gateway-fields field-given', {
            billingFirstName: Boolean(nmiData['billing-first-name'] && nmiData['billing-first-name'].toString().trim()),
            billingLastName: Boolean(nmiData['billing-last-name'] && nmiData['billing-last-name'].toString().trim()),
            billingAccountName: Boolean(nmiData['billing-account-name'] && nmiData['billing-account-name'].toString().trim()),
            billingAccountNumber: Boolean(nmiData['billing-account-number'] && nmiData['billing-account-number'].toString().trim()),
            billingRoutingNumber: Boolean(nmiData['billing-routing-number'] && nmiData['billing-routing-number'].toString().trim()),
            billingCCNumber: Boolean(nmiData['billing-cc-number'] && nmiData['billing-cc-number'].toString().trim()),
            billingCCExp: Boolean(nmiData['billing-cc-exp'] && nmiData['billing-cc-exp'].toString().trim()),
            billingMethodCard: Boolean(payment.method.card)
        });

        return dispatch('useProxyIframe', { formAction: gatewayDetails.formAction, nmiData })
            .then(function () {
                return acknowledgeDetails;
            });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
