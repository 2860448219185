import _ from 'lodash';

const state = {
    eCheckProcessingAvailable: true,
    cardProcessingAvailable: true,
    financingProcessingAvailable: true,
};

const getters = {
    getPaymentStatus: (state) => (paymentType) => {
        switch (paymentType.trim().toLowerCase()) {
            case 'card':
                return state.cardProcessingAvailable;
            case 'echeck':
                return state.eCheckProcessingAvailable;
            case 'financing':
                return state.financingProcessingAvailable;
            default:
                console.error('Invalid payment type: ' + paymentType);
                return false;
        }
    },
    eCheckProcessingAvailable(state) {
        return state.eCheckProcessingAvailable;
    },
    cardProcessingAvailable(state) {
        return state.cardProcessingAvailable;
    },
    financingProcessingAvailable(state) {
        return state.financingProcessingAvailable;
    }
};

const mutations = {
    setECheckProcessingAvailable(state, val) {
        state.eCheckProcessingAvailable = val;
    },
    setCardProcessingAvailable(state, val) {
        state.cardProcessingAvailable = val;
    },
    setFinancingProcessingAvailable(state, val) {
        state.financingProcessingAvailable = val;
    },
};

const actions = {
    updatePaymentStatus({ commit }, availableMethods) {
        commit('setECheckProcessingAvailable', _.includes(availableMethods, 'echeck'));
        commit('setCardProcessingAvailable', _.includes(availableMethods, 'card'));
        commit('setFinancingProcessingAvailable', _.includes(availableMethods, 'financing'));
    },
    updateXsrfToken(_, xsrfToken) {
        localStorage.setItem('XSRF-TOKEN', xsrfToken);
    },
};

export default {
    state, getters, mutations, actions,
};
