/**
 * This file contains various functions that are used by the router to
 * determine how to resolve routes that require asynchronous data look-ups,
 * authorizations, or complex logic. Each function should be expected to
 * eventually redirect the user to a final or intermediate route
 * in all cases.
 * 
 * If any function requires access to the store, it should be passed in
 * to simplify unit testing and centralize the source of truth of data
 * to the router itself.
 */
import { translate, generateDeferredPromise } from "../utils/utils";
import vueEventTransmitter  from '../utils/vueEventTransmitter';

const NUM_MILLISECONDS_BEFORE_EBILL_OPENED = 3271;  // calculated as the mean + 4 standard deviations time user agents spend on wallet

function handleSuccessfulGetAccountBill(store, route) {
  if (route.query.goto === 'plans' ||
    route.query.goto === 'financing') {
      if (store.state.BillsStore.currentBill.hasFinancing) {
        store.state.AppStore.router.push({
          name: 'AccountPaymentFlowCuraeApplicationStep',
          params: {
            accountId: store.state.BillsStore.currentBill.accountInfo.accountId,
            providerId: store.state.BillsStore.currentBill.getProviderDetails().id,
          },
          query: {
            financing: true
          }
        });
      } else {
        store.state.AppStore.router.push({
          name: 'AccountPaymentFlowAmountStep',
          params: {
            accountId: store.state.BillsStore.currentBill.accountInfo.accountId,
            providerId: store.state.BillsStore.currentBill.getProviderDetails().id,
          }
        });
      }
  } else {
    store.state.AppStore.router.push({name: 'BillSummary'});
  }
}

async function beforeEnterBackdoorLogin(store, route) {
  const bdToken = route.params.token;
  if (store.state.UsersStore.currentUser) {
    // backdoor login requested but we currently have a user
    // that is signed in
    await store.dispatch('unauthenticateUser');
  }
  return store.dispatch('backdoorLogin', bdToken)
    .then(() => {
      store.state.AppStore.router.push({name: 'ProvidersSummary'});
    }).catch(() => {
      store.state.AppStore.router.push({name: 'Landing'});
    });
}

async function beforeEnterBrandingSSO(store, route) {
  // we have to fetch the user again here,
  // because we expect that postToken will update the session
  // and we want that updated session to propagate before
  // finishing the redirect
  return store.dispatch('postBrandingSSOToken', route.params.token).then((context) => {
    return store.dispatch('fetchCurrentUser').then((user) => {
      return store.dispatch('getProviders').then((providers) => {
        if (!context.stateGo.toParams.providerId) {
          context.stateGo.toParams.providerId = providers[0].id;
        }
        if (!context.stateGo.toParams.accountId) {
          context.stateGo.toParams.accountId = providers[0].accounts[0].accountId;
        }
        return vueEventTransmitter.emit('redirect:complete', context);
      });
    });
  }).catch((error) => {
    return vueEventTransmitter.emit('redirect:error', error);
  });
}

async function beforeEnterExternalSSO(store, route) {
  const externalSSOToken = route.query.token;
  if (window.localStorage.getItem('externalSSOused') === externalSSOToken) {
    return vueEventTransmitter.emit('redirect:complete', {stateGo :{
      to: 'Landing'
    }});
  }
  window.localStorage.setItem('externalSSOused', externalSSOToken);

  return store.dispatch('postExternalSSO', externalSSOToken).then((context) => {
    return store.dispatch('changeCurrentUser', context).then(() => {
      return store.dispatch('getProviders').then(async () => {
        context.stateGo = {
          to: 'Landing'
        };
        let goToContext = JSON.parse(window.sessionStorage.getItem("externalSSO"));
        window.sessionStorage.removeItem("externalSSO");
        if (goToContext) {
          context.stateGo.to = goToContext.state ? goToContext.state : null;
          context.stateGo.toParams = goToContext.params ? goToContext.params : null;
          context.emit = goToContext.emit ? goToContext.emit : null;
          if (goToContext.bill && goToContext.bill.secureCode && goToContext.bill.amount) {
            await store.dispatch('linkBill', {sCode: goToContext.bill.secureCode, amount: goToContext.bill.amount});
          }
        }
        return vueEventTransmitter.emit('redirect:complete', context);
      });
    });
  }).catch((error) => {
    store.state.AppStore.router.push({name: 'Landing'});
    return vueEventTransmitter.emit('redirect:error', error);
  });
}

async function beforeEnterDemoSSO(store, route) {
  if (store.state.UsersStore.currentUser) {
    // backdoor login requested but we currently have a user
    // that is signed in
    await store.dispatch('unauthenticateUser');
  }
  store.commit('setShowSSOHeader', true);
  if (route.params.env === 'faetna') {
    store.commit('setFriendlySSOEnvName', 'My Aetna Partner Portal');
  } else if (route.params.env === 'fepic') {
    store.commit('setFriendlySSOEnvName', 'MyChart');
  }
  store.commit('setShowLoading', true);
  store.state.AppStore.router.push({name: 'ProvidersSummary'});
}

async function beforeEnterDfb(store, route) {
  const origin = route.query.email ? 'email' : (route.query.text ? 'text' : null);
  const firstBillToken = route.params.token;
  store.dispatch('findAuthOptionsBill', firstBillToken).then((resp) => {
    store.commit('setShowLoading', false);
    store.commit('setPaymentSource', {billType: 'bill', commType: resp?.token?.commType, notificationType: resp?.token?.notifType, origin: origin});
    setTimeout(function () {
      store.dispatch('markOpenedBill', firstBillToken);
    }, NUM_MILLISECONDS_BEFORE_EBILL_OPENED);
    if (resp?.quickPayEnabled) {
      store.commit('setAuthOptionsBill', resp);
      store.commit('setFirstBillToken', firstBillToken);
      store.state.AppStore.router.push({name: 'QuickPay'}).catch(() => {}); 
    }else{
      vueEventTransmitter.emit('firstCommVerification:prompt', {
        'type': 'bill',
        'options': resp,
        'token': firstBillToken,
      });
      store.state.AppStore.router.push({name: 'Landing'}).catch(() => {});
    }
  }).catch(() => {
    window.FS.log('error', 'first-bill token ' + firstBillToken);
    store.state.AppStore.router.push({name: 'Landing'}).catch(() => {});
  });
}

async function beforeEnterDfe(store, route) {
  const origin = route.query.email ? 'email' : (route.query.text ? 'text' : null);
  const firstEstimateToken = route.params.token;
  store.dispatch('findAuthOptionsEstimate', firstEstimateToken).then((resp) => {
    store.commit('setShowLoading', false);
    store.commit('setPaymentSource', {billType: 'estimate', commType: resp?.token?.commType, notificationType: resp?.token?.notifType, origin: origin});
    vueEventTransmitter.emit('firstCommVerification:prompt', {
      'type': 'estimate',
      'options': resp.data,
      'token': firstEstimateToken,
    });
    setTimeout(function () {
      store.dispatch('markOpenedEstimate', firstEstimateToken);
    }, NUM_MILLISECONDS_BEFORE_EBILL_OPENED);
    store.state.AppStore.router.push({name: 'Landing'}).catch(() => {});
  }).catch(() => {
    window.FS.log('error', 'first-bill token ' + firstEstimateToken);
    store.state.AppStore.router.push({name: 'Landing'}).catch(() => {});
  });
}

async function beforeEnterEbill(store, route) {
  const origin = route.query.email ? 'email' : (route.query.text ? 'text' : null);
  const commType = route.query.new ? 'new' : 'reminder';
  let token = route.params.shc;
  let ebillShc = token;
  store.commit('setShowLoading', true);
  const completeEbillFlow = async () => {
    store.commit('setShowLoading', false);
    const startedLoggedIn = Boolean(store.state.UsersStore.currentUser);
    if (!startedLoggedIn) {
      store.state.AppStore.router.push({name: 'Landing'});
      try {
        await store.dispatch('requireAuthedUser', {
          openSection: 'login'
        });
      } catch {
        console.error('Could not log in to get ebill');
        return;
      }
    }
    // Attempt to find the bill associated with this user
    return store.dispatch('getAccountBill', ebillShc).then((bill) => {
      store.commit('setPaymentSource', {billType: 'bill', commType: bill?.token?.commType ?? commType, notificationType: bill?.token?.notifType ?? 'standard', origin: origin});
      if (token) {
        setTimeout(function () {
          store.dispatch('markOpenedBill', token);
        }, NUM_MILLISECONDS_BEFORE_EBILL_OPENED);
      }

      handleSuccessfulGetAccountBill(store, route);
    }).catch((resp) => {
      // So we have attempted to load this ebill from the
      // currently logged in users account. We are going to make
      // the assumption that the securecode not found error is because
      // it is not with the user, and not that it is invalid. So when
      // this happens, we will log the user out and attempt to log in the
      // user who the link is for.
      // The compromise here is that, if a user is logged in and changes
      // the shc to an invalid one, it will log them out.

      if (startedLoggedIn && 'SECURECODE_NOT_FOUND' === resp.errorCode) {
        // log user out and ask for a new user
        return store.dispatch('unauthenticateUser').then(() => {
          return store.dispatch('requireAuthedUser', {
            openSection: 'login',
          });
        }).then(() => {
          // try one last time to get the bill for the
          // user
          return store.dispatch('getAccountBill', ebillShc);
        }).then((bill) => {
          store.commit('setPaymentSource', {billType: 'bill', commType: bill?.token?.commType ?? commType, notificationType: bill?.token?.notifType ?? 'standard', origin: origin});
          handleSuccessfulGetAccountBill(store, route);
        });
      }

      // we failed to do a good bill lookup and can't mitigate
      throw resp;

    }).catch((resp) => {
      console.error('Unable to get the ebill', resp);
      store.state.AppStore.router.push({ name: 'ProvidersSummary' });
    });
  };

  if (route.query.meta) {
    store.dispatch('retrieveMetaInformation', route.query.meta)
      .then((resp) => {
        if (resp.data && resp.data.isMyChartSSOOnlyUser) {
          store.state.AppStore.router.push({name: 'ProvidersSummary'});
          vueEventTransmitter.emit('myChartSSO:showFinishRegistration', {data:resp.data.data, callback:completeEbillFlow});
        } else {
          completeEbillFlow();
        }
      }).catch(function () {
        completeEbillFlow();
      });
  } else {
    store.dispatch('checkPatientUserStatus', { token: route.params.shc, context: 'BILL' })
      .then((resp) => {
        if (resp.data && resp.data.isMyChartSSOOnlyUser) {
          store.state.AppStore.router.push({name: 'ProvidersSummary'});
          vueEventTransmitter.emit('myChartSSO:showFinishRegistration', {data:resp.data.data, callback:completeEbillFlow});
        } else {
          completeEbillFlow();
        }
      }).catch(() => {
        completeEbillFlow();
      });
  }
}

async function beforeEnterEstimate(store, route) {
  const origin = route.query.email ? 'email' : (route.query.text ? 'text' : null);
  const commType = route.query.new ? 'new' : 'reminder';
  const token = route.params.token;
  async function completeEstimateFlow() {
    const startedLoggedIn = Boolean(store.state.UsersStore.currentUser);
    if (!startedLoggedIn) {
      store.commit('setShowLoading', false);
      try {
        await store.dispatch('requireAuthedUser', {
          openSection: 'login'
        });
      } catch {
        console.error('Could not log in to get estimate');
        store.state.AppStore.router.push({name: 'Landing'});
        return;
      }
    }
    store.dispatch('fetchEstimate', token).then((estimate) => {
      store.commit('setPaymentSource', {billType: 'estimate', commType: estimate?.token?.commType ?? commType, notificationType: estimate?.token?.notifType ?? 'standard', origin: origin});
      setTimeout(() => {
        store.dispatch('markOpenedEstimate', token);
      }, NUM_MILLISECONDS_BEFORE_EBILL_OPENED);
      store.state.AppStore.router.push({
        name: 'EstimateSummary',
        params: {
          providerId: estimate.providerDetails.providerId,
          estimateId: estimate.estimateId
        },
      });
    }).catch((resp) => {
      // So we have attempted to load this estimate from the
      // currently logged in users account. We are going to make
      // the assumption that the estimate not found error is because
      // it is not with the user, and not that it is invalid. So when
      // this happens, we will log the user out and attempt to log in the
      // user who the link is for.
      // The compromise here is that, if a user is logged in and changes
      // the estimate to an invalid one, it will log them out.

      if (startedLoggedIn && 'ESTIMATE_NOT_FOUND' === resp.errorCode) {
        console.error('We were unable to load the estimate for the signed in users account.');

        // log user out and ask for a new user
        return store.dispatch('unauthenticateUser').then(() => {
          return store.dispatch('requireAuthedUser', {
            openSection: 'login',
          });
        }).then(() => {
          // try one last time to get the estimate for the
          // user
          return store.dispatch('fetchEstimate', token);
        }).then((estimate) => {
          store.commit('setPaymentSource', {billType: 'estimate', commType: estimate?.token?.commType ?? commType, notificationType: estimate?.token?.notifType ?? 'standard', origin: origin});
          store.state.AppStore.router.push({
            name: 'EstimateSummary',
            params: {
              providerId: estimate.providerDetails.providerId,
              estimateId: estimate.estimateId
            },
          });
        });
      }

      // we failed to do a good estimate lookup and can't mitigate
      throw resp;
    }).catch((resp) => {
      if ('ESTIMATE_NOT_FOUND' === resp.errorCode) {

        // we tried all means by which to find estimate
        // we are adding the check and verify flow here as
        // to not undermine the above logic for why we might
        // need to log the user out to verify it was the right
        // logged in user state for the browser being used
        store.state.AppStore.router.push({name: 'ProvidersSummary'});

      } else {
        console.error('Unable to get the estimate');
        store.state.AppStore.router.push({name: 'ProvidersSummary'});
      }
    });
  }

  store.dispatch('checkPatientUserStatus', { token, context: 'ESTIMATE' })
    .then((resp) => {
      if (resp.data && resp.data.isMyChartSSOOnlyUser) {
        if (store.state.UsersStore.currentUser) {
          store.state.AppStore.router.push({name: 'ProvidersSummary'});
        } else {
          store.state.AppStore.router.push({name: 'Landing'});
        }
        vueEventTransmitter.emit('myChartSSO:showFinishRegistration', {data:resp.data.data, callback:completeEstimateFlow});
      } else {
        completeEstimateFlow();
      }
    }).catch(() => {
      completeEstimateFlow();
    });
}

async function beforeEnterForgotPassword(store, route) {
  const token = route.params.token;
  if (token) {
    var deferred = generateDeferredPromise();
    vueEventTransmitter.emit('login:showPrompt', {defer:deferred, options:{ openSection: 'resetpassword', token }});
  }
  store.state.AppStore.router.push({name: 'Landing'});
}

async function beforeEnterFeedback(store, route) {
  const token = route.params.token;
  let score = route.query.score;
  let nps = route.query.nps;
  if (token && nps) {
    let provider = token.split(".")[2];
    vueEventTransmitter.emit('feedbackModal:showPrompt', {
      token: token,
      receipt: nps,
      score: score,
      providerName: provider
    });
  }
  store.state.AppStore.router.push({name: 'Landing'}).catch(() => {});
}

async function beforeEnterMyChartSSO(store, route) {
  const params = {};
  if ('MyChartSSOEHRGateway' === route.name) {
    params.source = 'ehrgw';
    params.token = route.query.session;
  } else {
    params.source = 'valet';
    params.token = route.params.token;
  }

  // sign out any signed in user and then mychart sso them
  if (store.state.UsersStore.currentUser) {
    await store.dispatch('unauthenticateUser');
  }
  store.dispatch('myChartSSO', params)
    .then((data) => {
      if (data.error) {
        if ('INVALID_TOKEN' === data.error) {
          console.error('Unable to initiate MyChartSSO with token ' + route.params.token);
          vueEventTransmitter.emit('simpleModal:showPrompt', {
            header: translate('myChartSSO.error.invalidToken'),
            subcontent: translate('myChartSSO.error.body'),
            intent: 'error',
            actions: [
              {
                label: translate('myChartSSO.error.backToMyChart'),
                clickHandler: () => {
                  window.history.go(store.state.AppStore.initialHistoryLength - window.history.length - 1);
                }
              }
            ]
          });
          store.state.AppStore.router.push({name: 'Landing'});
        }
      } else {
        if (data.redirectTo) {
          window.location.href = data.redirectTo;
        }

        if (store.state.UsersStore.currentUser) {
          // if user returned, complete SSO
          if (store.state.AppStore.initialHistoryLength > 1) {
            store.commit('setShowSSOHeader', true);
            store.commit('setFriendlySSOEnvName', 'MyChart');
          }
          store.commit('setShowLoading', true);
          store.state.AppStore.router.replace({name: 'ProvidersSummary'});
        } else {
          // if no user returned, enter initial MyChart SSO entry flow
          if (data.createNewFlow) {
            store.state.AppStore.router.push({name: 'Landing'});
            vueEventTransmitter.emit('myChartSSO:showCheckEmail', {source:data.source, token:data.token, userFirstName:data.metadata.firstName, userLastName:data.metadata.lastName});
          } else {
            store.state.AppStore.router.push({name: 'Landing'});
          }
        }
      }
    }).catch(function (err) {
      console.error('Unable to initiate MyChartSSO with token ' + params.token + ' from source ' + params.source, err);
      vueEventTransmitter.emit('simpleModal:showPrompt', {
        header: translate('myChartSSO.error.header'),
        subcontent: translate('myChartSSO.error.body'),
        intent: 'error',
        actions: [
          {
            label: translate('myChartSSO.error.backToMyChart'),
            clickHandler: () => {
              window.history.go(store.state.AppStore.initialHistoryLength - window.history.length - 1);
            }
          }
        ]
      });
      store.state.AppStore.router.push({name: 'Landing'});
    });
}

async function beforeEnterVerifyEmail(store, route) {
  const verifyToken = route.params.token;
  const verifyPatientUserId = parseInt(route.params.patientUserId, 10);
  const verifyEmail = route.query.email;

  function finishVerificationWithLogin() {
    const patientUser = store.state.UsersStore.currentUser;
    // User logged in as the correct user being verified
    if (patientUser.patientUserId === verifyPatientUserId) {
      // Verification was successful or not
      if (patientUser.emailVerified) {
        store.state.AppStore.router.push({name: 'EmailVerified'});
      } else {
        store.state.AppStore.router.push({name: 'ProvidersSummary'});
      }
    } else {
      // User logged in as different user :facepalm:
      store.state.AppStore.router.push({name: 'ProvidersSummary'});
    }
  }

  if (store.state.UsersStore.currentUser) {
    const patientUser = store.state.UsersStore.currentUser;
    if (patientUser.patientUserId !== verifyPatientUserId) {
      // User is trying to verify an account that differs from the logged in user
      // Let's log the user out and try again on login
      return store.dispatch('unauthenticateUser').then(() => {
        store.commit('setShowLoading', false);
        return store.dispatch('requireAuthedUser', {
          openSection: 'login',
          prefillEmail: verifyEmail,
          verifyToken: verifyToken,
          verifyPatientUserId: verifyPatientUserId
        });
      }).then(finishVerificationWithLogin);
    } else {
      // User is already logged in as the user being verified so call verifyEmail endpoint
      store.dispatch('verifyEmail', { token: verifyToken, patientUserId: verifyPatientUserId })
        .then(() => {
          store.state.AppStore.router.push({name: 'EmailVerified'});
        }).catch(() => {
          store.state.AppStore.router.push({name: 'ProvidersSummary'});
        });
    }
  } else {
    // User is not logged in, let's try to verify the email on login
    return store.dispatch('requireAuthedUser', {
      openSection: 'login',
      prefillEmail: verifyEmail,
      verifyToken: verifyToken,
      verifyPatientUserId: verifyPatientUserId
    })
      .then(finishVerificationWithLogin)
      .catch(() => {
        store.state.AppStore.router.push({name: 'Landing'});
      });
  }
}

async function beforeEnterVerifyPhone(store, route) {
  const verifyCode = route.params.code;
  const verifyPatientUserId = parseInt(route.params.patientUserId, 10);
  const verifyPhone = route.params.phoneNumber;
  store.dispatch('verifyPhone', { code: verifyCode, patientUserId: verifyPatientUserId, phoneNumber: verifyPhone })
    .then(() => {
      store.state.AppStore.router.push({name: 'PhoneVerified'});
    }).catch(() => {
      store.state.AppStore.router.push({name: 'ProvidersSummary'});
    });
}

async function beforeEnterViewNotifications(store, route) {
  store.dispatch('requireAuthedUser', {
    openSection: 'login'
  }).then(() => {
    const notificationId = route.params.notificationId;
    store.state.AppStore.router.push({name: 'ProvidersSummary'});
    vueEventTransmitter.emit('notifications:show', notificationId);
  });
}

export {
  beforeEnterBackdoorLogin,
  beforeEnterBrandingSSO,
  beforeEnterExternalSSO,
  beforeEnterDemoSSO,
  beforeEnterDfb,
  beforeEnterDfe,
  beforeEnterEbill,
  beforeEnterEstimate,
  beforeEnterForgotPassword,
  beforeEnterFeedback,
  beforeEnterMyChartSSO,
  beforeEnterViewNotifications,
  beforeEnterVerifyEmail,
  beforeEnterVerifyPhone,
};