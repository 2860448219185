<template>
    <div>
        <div class="block-segment">
            <h1 class="call-out settings-title">
                {{ calloutText }}
            </h1>
        </div>
        <base-divider></base-divider>
    </div>
</template>
<script>
import BaseDivider from './BaseDivider.vue';

export default {
    name: 'SettingsLeftColumnPersist',
    components: {
        BaseDivider
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        calloutText() {
            let usersKey;
            const userFirstName = this.currentUser ? this.currentUser.firstName : null;
            if (userFirstName) {
                usersKey = userFirstName.length <= 12 ? userFirstName.slice(-1) !== 's' ? 'setting.argsSettings' : 'setting.argsSettingsEndsWithS' : 'setting.plural';
            } else {
                usersKey = 'setting.plural';
            }
            return this.$translate(usersKey, { name: userFirstName });
        }
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.settings-title {
    padding-bottom: 2.5rem !important;
}
</style>