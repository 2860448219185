<template>
    <div class="vuetify documents">
        <h1 class="call-out" id="docHeader"> {{ $translate('documents.documents') }} </h1>
        <base-tabs v-model="activeTab" :tabs="['documents.payments']">
            <template #documents.payments>
                <div class="separator"></div>
                <documents-payment-history></documents-payment-history>
            </template>
        </base-tabs>
    </div>
</template>

<script>
import BaseTabs from './BaseTabs.vue';
import BaseDivider from './BaseTabs.vue';
import DocumentsPaymentHistory from './DocumentsPaymentHistory.vue';
export default {
    name: 'Documents',
    components: {
        BaseTabs,
        BaseDivider,
        DocumentsPaymentHistory,
    },
    data() {
        return {
            activeTab: null,
        };
    }
}

</script>
<style lang="scss">
@import '../styles/variables.scss';

@media print {
    #docHeader, .v-slide-group {
        display:none !important;
    } 
}
</style>
