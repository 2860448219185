<template>
    <div v-if="showComponent" class='account-details-left-column'>
        <account-info-card :accountInfo="getAccountInfo"></account-info-card>
        <AppointmentInfoCard v-if="$vuetify.display.lgAndUp && currentEstimate" :appointmentInfo="currentEstimate.appointmentInfo" mode="estimate"></AppointmentInfoCard>
        <need-help-card @composemessage="composeMessage" :provider="provider"></need-help-card>
    </div>
</template>

<script>

import AccountInfoCard from './AccountInfoCard.vue';
import AppointmentInfoCard from './AppointmentInfoCard.vue';
import NeedHelpCard from './NeedHelpCard.vue';

export default {
	name: 'AccountDetailsLeftColumn',
	components: {
        AccountInfoCard,
        AppointmentInfoCard,
        NeedHelpCard,
	},
    data: () => ({
        messageProviderData: {},
    }),
    watch: {
        async account() {
            if (this.account) {
                this.messageProviderData = { account: this.account };
            }
        },
    },
	computed: {
        account() {
            return this.$store.getters.currentAccount;
        },
        provider() {
            return this.$store.getters.currentProvider;
        },
        currentEstimate() {
            return this.$store.getters.currentEstimate;
        },
        showComponent() {
        	return this.account && this.$vuetify.display.lgAndUp;
        },
        getAccountInfo() {
            if (this.account.bills && this.account.bills.length) {
                var latestBill = this.account.bills[(this.account.bills.length)-1];
                return latestBill.accountInfo;
            } else {
                return {
                    accountId: this.account.accountId,
                    accountNumber: this.account.accountNumber,
                    guarantorName: this.account.guarantorNames && this.account.guarantorNames.length ? this.account.guarantorNames[0] : null,
                    patientName: null,  // Patient name is not reliable at an account level in a world where sub-accounts exist
                };
            }
        }
        
    },
    methods: {
        composeMessage() {
            if (this.provider.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                    provider: this.provider,  
                    context: {
                        stateGo: {
                            to: 'AccountDetails',
                            toParams: {
                                accountId: this.account.accountId,
                                providerId: this.provider.id
                            },
                        },
                        emit: [
                            'message:compose', { secureCode: this.messageProviderData.account.secureCode }
                                ],
                        }
                });
            } else {
                this.emitter.emit('message:compose', this.messageProviderData);
            } 
        },
    },
};
</script>