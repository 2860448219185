<template>
    <div v-if="thread">
        <div class="listing block-segment">
            <h2 class="listing-title">{{ $translate('labels.messageDetails') }}</h2>
            <ul class="listing-items list-items-fluid">
                <li sensitive class="listing-item" v-if="thread.originalBillAmount">
                    <span class="listing-item-icon"><base-icon icon="mdi-file-document"></base-icon></span>
                    {{ $formatCurrency(thread.originalBillAmount) }}
                    <div class="listing-item-secondary-desc">{{ $translate('labels.billAmount') }}</div>
                </li>
                <li sensitive class="listing-item">
                    <span class="listing-item-icon"><base-icon icon="mdi-folder"></base-icon></span>
                    {{ thread.accountNumber }}
                    <div class="listing-item-secondary-desc">{{ $translate('labels.accountNumber') }}</div>
                </li>
                <li class="listing-item" v-if="thread.statementSentDate">
                    <span class="listing-item-icon"><base-icon icon="mdi-arrow-top-right"></base-icon></span>
                    {{ $formatDate(thread.statementSentDate, 'MMMM D, YYYY') }}
                    <div class="listing-item-secondary-desc">{{ $translate('labels.statementSent') }}</div>
                </li>
                <li sensitive class="listing-item" v-if="thread.estimateAmount">
                    <span class="listing-item-icon"><base-icon icon="mdi-file-document"></base-icon></span>
                    {{ $formatCurrency(thread.estimateAmount) }}
                    <div class="listing-item-secondary-desc">{{ $translate('labels.estimateAmount') }}</div>
                </li>
                <li sensitive class="listing-item" v-if="thread.serviceDate">
                    <span class="listing-item-icon"><base-icon icon="mdi-file-document"></base-icon></span>
                    {{ $formatDate(thread.serviceDate, 'MMMM D, YYYY') }}
                    <div class="listing-item-secondary-desc">{{ $translate('labels.appointmentDate') }}</div>
                </li>
                <li sensitive class="listing-item" v-if="thread.serviceDate">
                    <span class="listing-item-icon"><base-icon icon="mdi-file-document"></base-icon></span>
                    {{ $formatDate(thread.serviceDate, 'h:mm A') }}
                    <div class="listing-item-secondary-desc">{{ $translate('labels.appointmentTime') }}</div>
                </li>
            </ul>

            <button v-show="thread.estimateId" @click="viewEstimate()">{{ $translate('actions.viewEstimate') }}</button>
            <button v-show="thread.secureCode" @click="viewBill()">{{ $translate('actions.viewMyBill') }}</button>
        </div>
    </div>
</template>

<script>


import BaseIcon from './BaseIcon.vue';

export default {
    name: 'MessageDetailsLeftColumn',
    components: {
        BaseIcon
    },
    computed: {
        thread() {
            return this.$store.getters.thread;
        }
    },
    methods: {
        viewBill() {
            this.$store.dispatch('openPdf', this.thread.secureCode);
        },
        viewEstimate() {
            this.emitter.emit('messageDetails:viewEstimate', this.thread.estimateId);
        },
    },
};
</script>
