<template>
<modal :canClose="true" :show="isVisible" @close="close" class="confirm-phone-modal-container">
    <div class="confirm-phone-modal">
        <div class='confirm-phone-form'>
            <notification class="notification-inline failure" v-show="codeNotSent">
                <base-icon icon="mdi-close-circle"></base-icon>
                {{ $translate('confirmPhone.errors.codeNotSent') }}
            </notification>
            <notification class="notification-inline failure" v-show="codeInvalid">
                <base-icon icon="mdi-close-circle"></base-icon>
                {{ $translate('confirmPhone.errors.codeInvalid') }}
            </notification>
            <form v-show="step === 'number'" name="forms.confirmPhoneForm" @submit.prevent="confirmPhoneNumber()" novalidate >
                <h2 class="modal-header h-style-2" >{{ $translate('confirmPhone.header') }}</h2>
                <h3 class="modal-subheader h-style-4">{{ $translate('confirmPhone.numberSubheader') }}</h3>

                <div class="input-area-wrapper" :class="{errored: (forms.confirmPhoneForm.errors.required || forms.confirmPhoneForm.errors.minlength || forms.confirmPhoneForm.errors.smsEligible) &&  forms.confirmPhoneForm.submitted, 'input-focused': forms.confirmPhoneForm.focused}">

                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.confirmPhoneForm.dirty }">
                            <base-icon icon="mdi-phone"></base-icon>
                        </span>

                        <input name="phoneNumber" type="text" required
                                :placeholder="$translate('labels.phoneNumber')"
                                class="input-with-icon fs-block"
                                @input="onInputPhoneNumber"
                                @focus="forms.confirmPhoneForm.focused=true;$forceUpdate();"
                                @blur="forms.confirmPhoneForm.focused=false;validatePhoneNumber()"
                                v-model="phoneNumber" tabindex="0" maxlength="12" />
                    </div>

                    <div class="input-area-feedback">
                        <div v-if="hasPhoneError" class="vue-messages">
                            <div v-if="forms.confirmPhoneForm.errors.required" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                            <div v-else-if="forms.confirmPhoneForm.errors.minlength" class="vue-message">
                                {{ $translate('errors.phoneFormat') }}
                            </div>
                            <div v-else-if="forms.confirmPhoneForm.errors.smsEligible" class="vue-message">
                                {{ $translate('errors.smsEligible') }}
                            </div>
                        </div>
                    </div>

                    <span> <div v-html="$translate((subdomain ? 'whitelabel.' : '') + 'confirmPhone.explanation')"></div></span>
                </div>
            </form>

            <form v-show="step == 'code'" name="forms.verifyCodeForm" @submit.prevent="verifyCode()" novalidate >
                <h2 class="modal-header h-style-2" >{{ $translate('confirmPhone.header') }}</h2>
                <h3 class="modal-subheader h-style-4">{{ $translate('confirmPhone.codeSubheader') }}</h3>

                <div class="input-area-wrapper" :class="{errored: hasCodeError && forms.verifyCodeForm.submitted, 'input-focused': forms.verifyCodeForm.focused}">

                    <div class="input-area-field-wrapper">
                        <input name="code" type="text" required
                                @input="onInputCode"
                                :placeholder="$translate('labels.verificationCode')"
                                @blur="forms.verifyCodeForm.focused=false;validateCode()"
                                @focus="forms.verifyCodeForm.focused=true;$forceUpdate();"
                                v-model="code" tabindex="0" maxlength="6" autocomplete="off" />
                    </div>

                    <div class="input-area-feedback">
                        <div v-if="hasCodeError" class="vue-messages">
                            <div v-if="forms.verifyCodeForm.errors.required" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div v-show="showMoreInfo" class="more-info"> 
                <div v-html="$translate((subdomain ? 'whitelabel.' : '') + 'confirmPhone.additionalInfo')"></div>
            </div>

            <div class="modal-actions">
                <button v-show="step === 'number'" @click="showMoreInfo = !showMoreInfo" class="learn-more-button">{{ $translate(showMoreInfo ? 'confirmPhone.hide' : 'confirmPhone.learnMore') }}</button>
                <button v-show="step === 'code'" @click="step = 'number'" class="send-again-button">{{ $translate('confirmPhone.sendAgainButtonText') }}</button>
                <button v-if="step === 'number'" class="button-primary submit-button" @click="confirmPhoneNumber()">
                    {{ forms.confirmPhoneForm.submitting ? $translate('confirmPhone.submittingButtonText') : $translate('confirmPhone.submitButtonText') }}
                </button>
                <button v-else-if="step === 'code'" class="button-primary submit-button" @click="verifyCode()">
                    {{ forms.verifyCodeForm.submitting ? $translate('confirmPhone.verifyingButtonText') : $translate('confirmPhone.verifyButtonText') }}
                </button>
            </div>
        </div>
    </div>
</modal>
</template>

<script>

import Modal from './Modal.vue';
import Notification from './Notification.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'ConfirmPhoneNumberModal',
    components: {
        Modal,
        Notification,
        BaseIcon
    },
    data: () => ({
        isVisible: false,
        
        step: 'number',
        showMoreInfo: false,

        phoneNumber: '',
        code: '',

        codeInvalid: false,
        codeNotSent: false,

        mostRecentEligibilityCheck: null,
        
        forms: {
            confirmPhoneForm: {
                submitted: false,
                submitting: false,
                dirty: false,
                focused: false,
                errors: {
                    required: false,
                    minlength: false,
                    smsEligible: false,
                },
            },
            verifyCodeForm: {
                submitted: false,
                submitting: false,
                focused: false,
                errors: {
                    required: false,
                },
            },
        },
    }),
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        subdomain() {
            return this.$store.getters.subdomain;
        },
        hasPhoneError() {
            return this.forms.confirmPhoneForm.errors.required || this.forms.confirmPhoneForm.errors.minlength || this.forms.confirmPhoneForm.errors.smsEligible;
        },
        hasCodeError() {
            return this.forms.verifyCodeForm.errors.required;
        },
    },
    methods: {
        reset() {
            this.step = 'number';
            this.phoneNumber = this.currentUser.phoneNumber;
            this.code = '';
            this.showMoreInfo = false;

            this.forms.confirmPhoneForm = {
                submitted: false,
                submitting: false,
                dirty: false,
                errors: {
                    required: false,
                    minlength: false,
                    smsEligible: false,
                },
            };
            this.forms.verifyCodeForm = {
                submitted: false,
                submitting: false,
                errors: {
                    required: false,
                },
            };
        },
        clearErrors() {
            this.forms.confirmPhoneForm.errors = {
                required: false,
                minlength: false,
                smsEligible: false,
            };
            this.forms.verifyCodeForm.errors = {
                required: false,
            };
        },
        resetNotifications() {
            this.codeInvalid = false;
            this.codeNotSent = false;
        },
        close() {
            this.isVisible = false;
        },
        onInputPhoneNumber(e) {
            this.forms.confirmPhoneForm.dirty = true;
            if (e.inputType ===  'deleteContentBackward') {
                return;
            }
            const nums = (e.target.value || '').toString().replace(/(\s|\D)/g, '');
            if (nums.length >= 6) {
                this.phoneNumber = nums.slice(0, 3) + '-' + nums.slice(3, 6) + '-' + nums.slice(6);
            } else if (nums.length >= 3) {
                this.phoneNumber = nums.slice(0, 3) + '-' + nums.slice(3);
            } else {
                this.phoneNumber = nums;
            }
        },
        async confirmPhoneNumber() {
            this.resetNotifications();
            this.forms.confirmPhoneForm.submitted = true;

            await this.validatePhoneNumber();

            if (this.hasPhoneError) {
                this.forms.confirmPhoneForm.submitting = false;
                return;
            }
            const completePhoneVerification = async (currentPassword) => {
                this.isVisible = true;
                this.forms.confirmPhoneForm.submitting = true;
                try {
                    await this.$store.dispatch('confirmPhoneGetCode', { phone: this.phoneNumber, currentPassword });
                    this.step = 'code';
                } catch {
                    this.codeNotSent = true;
                    window.setTimeout(() => {
                        this.codeNotSent = false;
                    }, 5000);
                } finally {
                    this.forms.confirmPhoneForm.submitting = false;
                    this.showMoreInfo = false;
                }
            };
            if (this.phoneNumber !== this.currentUser.phoneNumber) {
                this.emitter.emit('confirmPassword:prompt', {
                    callback: completePhoneVerification,
                });
                this.close();
            } else {
                completePhoneVerification();
            }
        },
        async validatePhoneNumber() {
            this.clearErrors();
            if (!this.phoneNumber) {
                this.forms.confirmPhoneForm.errors.required = true;
                return;
            }
            if (this.phoneNumber.length < 12) {
                this.forms.confirmPhoneForm.errors.minlength = true;
                return;
            }
            if (this.phoneNumber !== this.mostRecentEligibilityCheck) {
                try {
                    await this.$store.dispatch('getSmsEligibility', this.phoneNumber.replace(/(\s|\D)/g, ''));
                    // Getting eligibility is an expensive operation, so don't rerun eligibility checks for the same number multiple times
                    this.mostRecentEligibilityCheck = this.phoneNumber;
                } catch {
                    this.forms.confirmPhoneForm.errors.smsEligible = true;
                    return;
                }
            }
        },
        onInputCode(e) {
            this.code = (e.target.value || '').toString().replace(/(\s|\D)/g, '');
        },
        async verifyCode() {
            this.resetNotifications();
            this.forms.verifyCodeForm.submitted = true;

            await this.validateCode();

            if (this.hasCodeError) {
                this.forms.verifyCodeForm.submitting = false;
                return;
            }

            try {
                await this.$store.dispatch('confirmPhoneVerifyCode', this.code);
                this.emitter.emit('confirmPhone:confirmed');
                this.emitter.emit('simpleModal:showPrompt', {
                    header: this.$translate('confirmPhone.successHeader'),
                    intent: 'success',
                    autoExpire: true,
                });
                this.close();
            } catch (e) {
                console.error('There was a problem verifying the phone code:', e);
                this.codeInvalid = true;
                window.setTimeout(() => {
                    this.codeInvalid = false;
                }, 5000);
                this.code = '';
            } finally {
                this.step = 'number';
                this.forms.verifyCodeForm.submitting = false;
            }
        },
        async validateCode() {
            this.clearErrors();
            if (!this.code) {
                this.forms.verifyCodeForm.errors.required = true;
                return;
            }
        },
    },
    created() {
        this.emitter.on('confirmPhone:prompt', () => {
            this.reset();
            this.isVisible = true;
        });
    },
};
</script>

<style lang="scss">
@import '../styles/variables.scss';
.confirm-phone-modal-container {

	.modal-header {
        font-size: 3.0rem;
		margin-bottom: 10px;
	}

    .modal-subheader {
        font-size: 2.0rem;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .more-info {
        font-size: 1.3rem;
        text-align: left;
        margin-right: auto;
        margin-left: auto;
        margin-top: 20px;
        max-width: 80%;
    }

    .send-again-button {
        margin-bottom: 1rem;
        @include wider-than-tablet {
            margin-bottom: 0;
        }
    }

    .modal-actions {
        margin-bottom: 2rem;
        @include wider-than-tablet {
            margin-bottom: 0;
        }
    }
}
</style>
