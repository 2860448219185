import http from '../../utils/http';

const state = {};

const getters = {
    getFinancingDownloadUrl: () => ({ vendor, fileId, fileName }) => {
        return '/api/services/v1/financing/downloadFinancingDoc/' + vendor + '/' + fileId + '/' + fileName;
    },
};

const mutations = {};

const actions = {
    getFinancingRedirectUrl({}, { secureCode, vendor }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { secureCode, vendor },
            url: 'financing/getRedirectUrl',
        });
    },

    manageFinancingAccount({}, secureCode) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { secureCode },
            url: 'financing/manageAccount',
            successFlag: 'success',
        });
    },

    chooseFinancingOption({dispatch}, { secureCode, vendor, option }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { secureCode, vendor, option },
            url: 'financing/chooseOption',
            successFlag: 'success',
        }).then((r)=>{
            dispatch('syncProviders')
            return r;
        });
    },

    chargeFinancingPaymentFormFirstTime({dispatch}, body) {
        return http.getDataOrThrow({
            method: 'POST',
            data: body,
            url: 'financing/chargeFinancingPaymentFormFirstTime',
            successFlag: 'success',
        }).then((r)=>{
            dispatch('syncProviders')
            return r;
        });
    },

    chargeExistingFinancingAccount({dispatch}, body) {
        return http.getDataOrThrow({
            method: 'POST',
            data: body,
            url: 'financing/chargeExistingFinancingAccount',
            successFlag: 'success',
        }).then((r)=>{
            dispatch('syncProviders')
            return r;
        });
    },

    getFinancingStatus({}, { vendor, secureCode }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { vendor, secureCode },
            url: 'financing/getStatus',
        }).then(resp => resp.status);
    },

    getFinancingOptions({ dispatch }, application) {
        // Income must be an int
        if(typeof application.income !== 'undefined'){
            application.income = parseInt(application.income);
        }
        return http.getDataOrThrow({
            method: 'POST',
            data: application,
            url: 'financing/getOptions',
        });
    }
};

export default {
    state, getters, mutations, actions,
};
