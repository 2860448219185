<template>
<div v-if="currentProvider && $vuetify.display.lgAndUp" class="accounts-summary-left-column vuetify">
    <need-help-card
        @composemessage="composeMessage"
        :provider="currentProvider"
    ></need-help-card>
</div>
</template>

<script>
import _ from 'lodash';
import NeedHelpCard from './NeedHelpCard.vue';

export default {
    name: 'AccountsSummaryLeftColumn',
    components: {
        NeedHelpCard,
    },
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        }
    },
    methods: {
        composeMessage() {
            this.emitter.emit('message:compose');
        },
    },
};
</script>

<style lang="scss">
@import './../styles/variables.scss';
.vuetify.accounts-summary-left-column {
    .content-card-container {
        padding-right: 0.5rem;
    }
}
</style>