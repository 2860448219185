<template>
<div class="notification" :class="{closable: closable, 'has-icon': showInformationIcon}" @click="$emit('close')">
    <div class="primary-icon-container u-center-vertical">
        <span class="icon-wrap"><base-icon size="30" icon="mdi-information-variant-circle-outline" color="white"></base-icon></span>
    </div>
    <div class="notification-content-container u-center-vertical">
        <div class="notification-content">
            <slot></slot>
        </div>
    </div>
</div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'Notification',
    components: {
        BaseIcon,
    },
    props: {
        showInformationIcon: {
            type: Boolean,
            default: false,
        },
        closable: {
            type: Boolean,
            default: false,
        },
    },
};

</script>

<style lang="scss" scoped>
.primary-icon-container {
    max-width: 12%;
    display: none;

    .icon-wrap {
        float:left;
        padding: 16px 12px 12px 12px;
    }
}
</style>