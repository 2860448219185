<template><v-text-field v-bind="$attrs" :hide-details="hideDetails" :variant="variant" class="base-text-field vuetify"><slot></slot></v-text-field></template>


<script>
export default {
    name: 'BaseTextField',
    props: {
        outlined: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: String,
            default: "auto"
        }
    },
	computed: {
        variant() {
            if (this.outlined) {
                return 'outlined';
            }
            return 'filled';
        },
    },
}
</script>
<style lang="scss">
@import '../styles/variables.scss';
.v-text-field {
	&.v-input {
		margin: 1rem 0px;
	}
	.v-field {
        background: white;
		padding-bottom: 1px !important;
        max-height: 4rem;
        height: 4rem;

        &:has(input:is(:-webkit-autofill, :autofill)) {
            background: rgb(232, 240, 254);
        }

		label {
			margin-left: 1.6rem;
			transform-origin: center left;
		}

		fieldset {
			margin-top: 3px;

			legend {
				margin-left: 13px;
			}
		}
		.v-field__input {
			padding-top: 0px;
            padding-bottom: 0px;
            margin-top: -0.75rem;
            input {
                border: none;
                margin-top: 0.7rem;
                margin-bottom: 0.75rem;
                height: 3.5rem;
                margin-left: -0.75rem;
                color: $global-color-font-new;
            }
        }
        .v-text-field__prefix {
            margin-top: -0.75rem;
        }
        .v-field__field {
            label {
                font-size: 1.4rem;
                margin-top: -0.5rem;
            }
        }
	}

	.v-field.v-field--variant-outlined {
        fieldset {
            border-width: 1px;
        }
        &.v-field—active {
            fieldset {
                border-width: 2px;
            }
        }
    }
}
</style>