<template>
    <div class="sub-account-details-sheet force-mobile-font-size">
        <sub-account-cost-summary
            @pay-sub-account="$emit('pay-sub-account')"
            @viewbill="$emit('viewbill')"
            :subAccount="subAccount"
            :dueDate="bill ? bill.dueDate : null"
            :showViewBill="Boolean(bill)"
            :patientName="accountInfo.patientName"
            :subAccountPaymentsEnabled="subAccountPaymentsEnabled"
            class="sub-account-details-sheet-item"
        ></sub-account-cost-summary>
        <card-header
            v-if="showServices"
            :title="$translate('labels.services')"
            icon="mdi-stethoscope"
            class="sheet-card-header"
        ></card-header>
        <sub-account-services
            v-if="showServices"
            :services="services"
            class="sub-account-details-sheet-item"
        ></sub-account-services>
        <base-divider
            v-if="showAppointmentInfo"
            class="sub-account-details-sheet-divider"
            data-testid="sub-account-details-sheet-appointmentinfo-divider"
        ></base-divider>
        <card-header
            v-if="showAppointmentInfo"
            :title="$translate('labels.visitDetails')"
            icon="mdi-calendar-clock-outline"
            class="sheet-card-header"
        ></card-header>
        <appointment-info
            v-if="showAppointmentInfo"
            :appointmentInfo="subAccount.appointmentInfo"
            class="sub-account-details-sheet-item"
        ></appointment-info>
    </div>
</template>

<script>
import AppointmentInfo from './AppointmentInfo.vue';
import CardHeader from './CardHeader.vue';
import SubAccountCostSummary from './SubAccountCostSummary.vue';
import SubAccountServices from './SubAccountServices.vue';
import BaseDivider from './BaseDivider.vue';

export default {
    name: 'SubAccountDetailsSheet',
    components: {
        AppointmentInfo,
        CardHeader,
        SubAccountCostSummary,
        SubAccountServices,
        BaseDivider
    },
    props: {
        subAccount: Object,
        accountInfo: Object,
        bill: Object,
        services: Array,
        subAccountPaymentsEnabled: Boolean,
    },
    computed: {
        showAppointmentInfo() {
            return this.subAccount.appointmentInfo 
                && (this.subAccount.appointmentInfo.appointmentDate
                || this.subAccount.appointmentInfo.appointmentTime
                || this.subAccount.appointmentInfo.inNetworkProvider);
        },
        showServices() {
            return this.services && this.services.length > 0;
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.sub-account-details-sheet {
    .sub-account-details-sheet-item {
        padding: 0;
    }
    .card-header.self-contained {
        padding: 1.6rem 0;
    }
}
</style>