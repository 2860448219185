<template>
<modal :show="isVisible" @close="close()" class="change-pass-modal-container">
    <h2 class="modal-header h-style-2">{{ $translate('actions.changePassword') }}</h2>
    <div class="change-pass-content">
        <form name="forms.changePasswordForm" @submit.prevent novalidate>

            <div v-if="passwordIncorrect || unableToChangePassword" class="vue-messages interaction-feedback">
                <div v-if="passwordIncorrect" class="vue-message">
                    {{ $translate('errors.invalidPassword') }}
                </div>
                <div v-else-if="unableToChangePassword" class="vue-message">
                    {{  $translate('errors.unableToChangePassword') }}
                </div>
            </div>

            <!-- EXISTING -->
            <div class="input-area-wrapper" :class="{errored: hasPasswordError && submitted, 'input-focused': forms.password.focused}">
                
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.password.dirty }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>

                    <input required name="currentPassword" type="password"
                        @focus="forms.password.focused = true; $forceUpdate()"
                        @blur="forms.password.focused = false; $forceUpdate()"
                        @change="forms.password.dirty = true"
                        maxlength="255" id="change-current-pass"
                        :placeholder="$translate('labels.currentPassword')"
                        class="fs-block input-with-icon"
                        v-model="password" tabindex="1"/>
                </div>

                <div class="input-area-feedback">
                    <div v-if="hasPasswordError" class="vue-messages">
                        <div v-if="forms.password.errors.required" class="vue-message">
                            {{  $translate('errors.genericRequired') }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- NEW -->
            <div class="input-area-wrapper login-password-input-area"
                :class="{
                        errored: hasNewPasswordError && submitted,
                        'login-password-incorect-blurred': forms.newPassword.dirty && hasNewPasswordError,
                        'input-focused': forms.newPassword.focused
                    }">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.newPassword.dirty }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>
                    <input required name="password" type="password"
                        @focus="forms.newPassword.focused = true; $forceUpdate()"
                        @blur="forms.newPassword.focused = false; $forceUpdate()"
                        @change="forms.newPassword.dirty = true; $forceUpdate()"
                        @input="validatePasswordRules(); $forceUpdate()"
                        maxlength="255" id="new-pass"
                        :placeholder="$translate('labels.newPassword')"
                        class="fs-block input-with-icon"
                        v-model="newPassword" tabindex="2"/>
                </div>

                <div class="input-area-rules">
                    <ul>
                        <li :class="{'rule-met': !forms.newPassword.errors.minCharactersRule}">
                            <span v-if="!forms.newPassword.errors.minCharactersRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-close"></base-icon>
                            </span>
                            {{  $translate('login.minCharactersRule') }}
                        </li>
                        <li :class="{'rule-met': !forms.newPassword.errors.upperCaseRule}">
                            <span v-if="!forms.newPassword.errors.upperCaseRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-close"></base-icon>
                            </span>
                            {{  $translate('login.upperCaseRule') }}
                        </li>
                        <li :class="{'rule-met': !forms.newPassword.errors.digitRule}">
                            <span v-if="!forms.newPassword.errors.digitRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-close"></base-icon>
                            </span>
                            {{ $translate('login.digitRule') }}
                        </li>
                    </ul>
                </div>
            </div>

            <!-- CONFIRM -->
            <div class="input-area-wrapper"	
                :class="{errored: !hasPasswordError && hasConfirmNewPasswordError && submitted, 'input-focused': forms.confirmNewPassword.focused}">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.confirmNewPassword.dirty }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>
                    <input required name="confirmPassword" type="password" id="confirm-new-pass"
                        @focus="forms.confirmNewPassword.focused = true; $forceUpdate()"
                        @blur="forms.confirmNewPassword.focused = false; $forceUpdate()"
                        @change="forms.confirmNewPassword.dirty = true"
                        @input="validateFormIfSubmitted()"
                        match="fields.newPassword"
                        :placeholder=" $translate('labels.passwordConfirm')"
                        class="fs-block input-with-icon"
                        v-model="confirmNewPassword" tabindex="3"/>
                </div>

                <div class="input-area-feedback">
                    <div v-if="hasConfirmNewPasswordError" class="vue-messages">
                        <div v-if="forms.confirmNewPassword.errors.required" class="vue-message">
                            {{  $translate('errors.genericRequired') }}
                        </div>
                        <div v-else-if="forms.confirmNewPassword.errors.mismatch" class="vue-message mismatch">
                            {{  $translate('errors.confirmPassword') }}
                        </div>
                    </div>
                </div>
            </div>
                    
        </form>
    </div>

    <div class="modal-actions">
        <button class="button-primary modal-single-button" @click="save()" tabindex="4">
            {{ $translate('actions.saveChanges') }}
        </button>
    </div>
</modal>
</template>
<script>

import Modal from './Modal.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'ChangePasswordModal',
    components: {
        Modal,
        BaseIcon
    },
    data: () => ({
        isVisible: false,
        password: '',
        newPassword: '',
        confirmNewPassword: '',
        submitted: false,
        passwordIncorrect: false,
        unableToChangePassword: false,
        forms: {
            password: {
                dirty: false,
                errors: {
                    required: false,
                },
            },
            newPassword: {
                dirty: false,
                errors: {
                    required: false,
                    minCharactersRule: false,
                    upperCaseRule: false,
                    digitRule: false,
                },
            },
            confirmNewPassword: {
                dirty: false,
                errors: {
                    required: false,
                    mismatch: false,
                },
            },
        },
    }),
    computed: {
        hasPasswordError() {
            return this.forms.password.errors.required;
        },
        hasNewPasswordError() {
            return this.forms.newPassword.errors.required || this.forms.newPassword.errors.minCharactersRule || this.forms.newPassword.errors.upperCaseRule
                || this.forms.newPassword.errors.digitRule;
        },
        hasConfirmNewPasswordError() {
            return this.forms.confirmNewPassword.errors.mismatch || this.forms.confirmNewPassword.errors.required;
        },
    },
    methods: {
        confirm() {
            this.submitted = true;
            this.validateForm();
            if (!this.invalid) {
                this.callback(this.password);
                this.close();
            }
        },
        close() {
            this.isVisible = false;
            this.clearInputs();
        },
        clearInputs() {
            this.password = '';
            this.newPassword = '';
            this.confirmNewPassword = '';
            this.submitted = false;
        },
        validatePasswordRules() {
            this.forms.newPassword.errors.minCharactersRule = this.newPassword.trim().length < 8;
            this.forms.newPassword.errors.upperCaseRule = !/[A-Z]+/.test(this.newPassword.trim());
            this.forms.newPassword.errors.digitRule = !/\d+/.test(this.newPassword.trim());
        },
        validateForm() {
            this.forms.password.errors.required = !Boolean(this.password);
            this.forms.newPassword.errors.required = !Boolean(this.newPassword);
            this.forms.confirmNewPassword.errors.required = !Boolean(this.confirmNewPassword);
            this.forms.confirmNewPassword.errors.mismatch = this.confirmNewPassword !== this.newPassword;
            this.validatePasswordRules();
        },
        validateFormIfSubmitted() {
            if (this.submitted) {
                this.validateForm();
            }
        },
        save() {
            this.submitted = true;
            this.validateForm();
            if (!this.hasPasswordError && !this.hasNewPasswordError && !this.hasConfirmNewPasswordError) {
                this.$store.dispatch('updateUser', {
                    oldPassword: this.password,
                    newPassword: this.newPassword,
                }).then(() => {
                    this.close();
                    this.emitter.emit('simpleModal:showPrompt', {
                        header: this.$translate('setting.passwordChangedDialog'),
                        intent: 'success',
                        autoExpire: true,
                    });
                }).catch((resp) => {
                    if ('INVALID_PASSWORD' === resp.errorCode) {
                        this.passwordIncorrect = true;
                    } else {
                        this.unableToChangePassword = true;
                    }
                });
            }
        },
    },
    created() {
        this.emitter.on('changePassword:prompt', () => {
            this.isVisible = true;
        });
    },
};
</script>
<style lang="scss">
</style>