<template>
<!-- Generator: Adobe Illustrator 17.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg aria-label="bill-amount-icon" version="1.1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 36.5 20.5" enable-background="new 0 0 36.5 20.5" xml:space="preserve">
<path fill="#10356B" d="M0.6,20.1c-0.2,0.1-0.3-0.1-0.3-0.2V7.5c0-0.1,0.1-0.2,0.2-0.2c1.7-0.7,15.9-6.3,17.7-7c0.1,0,0.2,0,0.3,0
	c1.7,0.7,16,6.3,17.7,7c0.1,0,0.2,0.1,0.2,0.2v12.4c0,0.2-0.2,0.3-0.3,0.2l-17.5-6.8c-0.1,0-0.2,0-0.4,0L0.6,20.1z"/>
</svg>
</template>

<script>
export default {
    name: 'BillAmountSVG'
};
</script>