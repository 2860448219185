<template>
<modal :show="isVisible" :can-close="false" class="verification-modal-container">
    <h2 class="modal-header h-style-2 verify-email" >{{ $translate('verification.headerText') }}</h2>

    <!-- envelope icon -->
    <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="max-width: 100px;margin-bottom: -30px;fill:#616161;"><g transform="translate(0,-952.36218)"><path style="text-indent:0;text-transform:none;direction:ltr;block-progression:tb;baseline-shift:baseline;color:#000000;enable-background:accumulate;" d="m 6.0000002,973.36217 a 2.0002,2.0002 0 0 0 -2,2 l 0,54.00003 a 2.0002,2.0002 0 0 0 2,2 l 87.9999998,0 a 2.0002,2.0002 0 0 0 2,-2 l 0,-54.00003 a 2.0002,2.0002 0 0 0 -2,-2 l -87.9999998,0 z m 5.4687498,4 77.0625,0 L 50,1009.7684 11.46875,977.36217 z m -3.4687498,2.28125 25.9687498,21.84378 -25.9687498,23.375 0,-45.21878 z m 83.9999998,0 0,45.21878 -25.96875,-23.375 L 92,979.64342 z m -54.9375,24.43748 11.65625,9.8125 a 2.0002,2.0002 0 0 0 2.5625,0 l 11.65625,-9.8125 25.875,23.2813 -77.625,0 25.875,-23.2813 z" fill-opacity="1" stroke="none" marker="none" visibility="visible" display="inline" overflow="visible"/></g></svg><br>

    <notification class="notification-inline failure" v-show="failure">
        <base-icon icon="mdi-close-circle"></base-icon>
        {{ $translate('verification.emailNotSent') }}
    </notification>

    <notification class="notification-inline success" v-show="emailSent">
        <base-icon icon="mdi-close-circle"></base-icon>
        {{ $translate('verification.emailSent') }}
    </notification>

    <div class="content" v-html="explanation"></div>

    <form name="forms.verifyEmailCodeForm" @submit.prevent="submitEmailCode()" novalidate >
        <div class="interaction-feedback vue-messages">
            <div v-if="verifyNotifications.bruteForce" class="vue-message">
                <div v-html="$translate('verification.bruteForce')"></div>
            </div>
            <div v-else-if="verifyNotifications.genericError" class="vue-message">
                <div v-html="$translate('verification.codeInvalid')"></div>
            </div>
        </div>

        <div class="input-area-wrapper" :class="{errored: forms.verifyEmailCodeForm.$invalid && forms.verifyEmailCodeForm.$submitted}">

            <div class="input-area-field-wrapper">
                <input name="emailVerificationCode" class="email-verification-code" type="text" inputmode="decimal" required
                        :placeholder="$translate('labels.verificationCode')"
                        @blur="validateEmailCode()"
                        v-model="fields.emailVerificationCode" tabindex="0" maxlength="6" />
                <a class="inline resend-code" @click="resendVerificationInternal();">{{ $translate('actions.resend') }}</a>
            </div>

            <div class="input-area-feedback">
                <div class="vue-messages">
                    <div v-show="forms.verifyEmailCodeForm.emailVerificationCode.$error.required" class="vue-message">
                        {{ $translate('errors.genericRequired') }}
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="modal-actions">
        <button class="button-primary submit-button verification" @click="submitEmailCode()">{{ submitButtonText }}</button>
    </div>

</modal>
</template>
<script>


import Modal from './Modal.vue';
import Notification from './Notification.vue';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'VerificationModal',
    components: {
        Modal,
        Notification,
        BaseIcon
    },
    data: () => ({
        callback: null,
        deferWantingLogin: null,
        email: null,
        emailSent: false,
        explanation: "Explanation",
        failure: false,
        fields: {
            emailVerificationCode: null,
        },
        forms: {
            verifyEmailCodeForm: {
                $invalid: false,
                $submitted: false,
                emailVerificationCode: {
                    $error: {},
                }
            },
        },
        isVisible: false,
        resending: false,
        submitButtonText: null,
        submitting: false,
        verifyNotifications: {
            bruteForce: false,
            genericError: false,
        },
    }),
    computed: {
        currentEstimate() {
            return this.$store.getters.currentEstimate;
        },
        FS() {
            return this.$store.getters.getFullstory;
        }
    },
    methods: {
        resendVerificationInternal() {
            if (this.resending) {
                return;
            }

            this.resending = true;

            this.$store.dispatch('resendVerification')
            .then(() => {
                this.emailSent = true;
                setTimeout(() => {
                    this.emailSent = false;
                }, 3000);
            })
            .catch(() => {
                this.failure = true;
                setTimeout(() => {
                    this.failure = false;
                }, 3000);
            })
            .finally(() => {
                this.resending = false;
            });
        },
        reset() {
            this.submitButtonText = this.$translate('verification.submitButtonText');
            this.isVisible = false;
            this.failed = false;
            this.submitting = false;
            this.fields = {};
            this.fields.emailVerificationCode = '';
        },
        resolveWithUser(user){
            if (this.deferWantingLogin){
                this.deferWantingLogin.resolve(user);
            }
        },
        submitEmailCode() {
            this.forms.verifyEmailCodeForm.$submitted = true;
            this.validateEmailCode();

            this.forms.verifyEmailCodeForm.$submitted = true;

            if (this.forms.verifyEmailCodeForm.$invalid || this.submitting || !this.fields.emailVerificationCode) {
                return;
            }

            this.submitting = true;
            this.submitButtonText = this.$translate('verification.submittingButtonText');

            this.verifyNotifications = {};

            this.FS.event('verify email started');

            this.$store.dispatch('confirmEmailVerifyCode', this.fields.emailVerificationCode)
                .then((user) => {
                    this.FS.log('log', 'success verify email');

                    this.reset();
                    
                    //bill has been set as currentBill within billsService.  tell user about the successful link and
                    //them point them toward the verify/payment flow.
                    this.emitter.emit('simpleModal:showPrompt', {
                        header: this.$translate('verification.successHeader'),
                        subcontent: this.$translate('verification.successSubheader'),
                        intent: 'success',
                        actions: [{
                            label: this.$translate('actions.continue'),
                            includeClasses: 'continue-button',
                            clickHandler: () => {
                                var estimate = this.currentEstimate;
                                
                                if (estimate) {
                                    this.$router.replace({
                                        name: 'SubAccountDetails',
                                        params: {providerId: estimate.providerDetails.id, estimateId: estimate.id},
                                    });
                                } else if (this.callback != null) {
                                    this.callback();
                                }
                                this.resolveWithUser(user);
                            }
                        }]
                    });
                })
                .catch((resp) => {
                    this.FS.log('error', 'verify email');

                    if ('RATE_LIMIT_EXCEEDED' === resp.errorCode) {
                        this.verifyNotifications.bruteForce = true;
                    } else {
                        this.verifyNotifications.genericError = true;
                    }
                })
                .finally(() => {
                    this.submitButtonText = this.$translate('verification.submitButtonText');
                    this.submitting = false;
                });
        },
        validateEmailCode() {
            this.forms.verifyEmailCodeForm.emailVerificationCode.$error = {};
            if (!this.fields.emailVerificationCode || this.fields.emailVerificationCode === '') {
                this.forms.verifyEmailCodeForm.emailVerificationCode.$error.required = true;
                this.forms.verifyEmailCodeForm.$invalid = true;
            } else {
                this.forms.verifyEmailCodeForm.$invalid = false;
            }
        },
    },
    created() {
        this.emitter.on('verification:prompt', (promptProperties) => {
            if ('ForgotPassword' === this.$route.name) {
                return; // skip this step because they clicked into wallet from forgot password email
            }

            this.email = promptProperties.email;
            this.isVisible = true;
            this.explanation = this.$translate('verification.explanation', { email: this.email });
            if (promptProperties.defer != undefined) {
                this.deferWantingLogin = promptProperties.defer;
            }
            if (promptProperties.callback != undefined) {
                this.callback = promptProperties.callback;
            }
        });
        this.reset();
    },
};
</script>
<style lang="scss" scoped>
    @import '../styles/variables.scss';

    .modal-actions {
        margin-bottom: 10%;
        @include wider-than-tablet {
            margin-bottom: 0%;
        }
    }
</style>