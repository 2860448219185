<template>
  <div class="payment-flow-progress-bar vuetify" :class="{ vertical }">
    <div v-for="(step, i) in steps" :key="i" class="step-container">
      <div @click="$emit('step', step)" class="step" :class="{ filled: currentStep > i }">
        <base-tooltip location="bottom" :tip="step.name">
          <div class="circle" :class="{ 'circle-colored': currentStep > i }" />
        </base-tooltip>
        <span v-if="vertical" class="step-name">{{ step.name }}</span>
      </div>
      <div v-if="i < steps.length - 1" class="divider" />
    </div>
  </div>
</template>
<script>
import BaseTooltip from './BaseTooltip.vue';

export default {
  name: "PaymentFlowProgressBar",
  components: {
    BaseTooltip
  },
  props: {
    currentStep: Number,
    steps: Array,
    vertical: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style lang="scss">
@import '../styles/variables.scss';
.payment-flow-progress-bar {
  color: #979797;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  padding: 0 1.6rem;
  width: 100%;

  .step-container {
    align-items: center;
    display: flex;
    flex: 1;
    .step {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @include wider-than-tablet {
        height: 2rem;
      }
      .circle {
        margin-top: 7px;
        background-color: $global-color-divider;
        border-radius: 50%;
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        @include wider-than-tablet {
          height: 2rem;
          width: 2rem;
        }
      }
      .circle-colored {
        background-color: $global-color-secondary;
      }
      .step-name {
        font-size: 2rem;
        letter-spacing: 0.015rem;
        line-height: 2.4rem;;
        padding-left: 4rem;
      }
      &.filled {
        color: $global-color-font-new;
        font-weight: 600;
      }
    }
    .divider {
      background-color: $global-color-divider;
      flex: 1;
      height: 0.1rem;
    }
    &:last-child {
      flex: 0;
    }
  }

  &.vertical {
    padding: 0;
    flex-direction: column;
    .step-container {
      display: inline-block;
      .step {
        justify-content: flex-start;
      }
      .divider {
        margin-bottom: 0;
        margin-left: 0.95rem;
        min-height: 10.9rem;
        width: 0.1rem;
      } 
    }
  }
}
</style>