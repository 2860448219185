<template>
    <div class="vuetify">
        <div class="financing-status">
            <div v-if="'AccessOne' === accountFinanced" class="financing-logo-container">
                {{ $translate('financingDetails.financedBy') }} <img class="financing-logo accessone" src="assets/images/accessOneLogo.png" alt="Access One"/>
            </div>
            <div v-else-if="'ClearBalance' === accountFinanced" class="financing-logo-container">
                {{ $translate('financingDetails.financedBy') }} <img class="financing-logo clearbalance" src="assets/images/clearBalanceLogo.png" alt="Clear Balance"/>
            </div>
            <div class="financing-note">
                <base-icon class="financing-note-icon" size="16" icon="mdi-information-outline"></base-icon>
                <div class="financing-note-text">
                    {{ $translate('financingDetails.contactFinancingVendor', { financedBy: accountFinanced, phone: financerPhoneNumber}) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Account from '../models/Account';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'FinancingDetails',
    props: {
        account: {
            type: Account,
            default: null,
        },
    },
    components: {
        BaseIcon
    },
    computed: {
        accountFinanced() {
                return this.account.financedBy || null;
        },
        financerPhoneNumber() {
            if (this.account && this.account.financedByPhone) {
                return this.account.financedByPhone.replace(/-/g, '‑');
            }
            return null;
        }
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';

.vuetify {
    .financing-logo-container {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 0;
        .financing-logo {
            margin-left: 0.5em;
            max-width: 20rem;
            @include wider-than-tablet {
                max-width: 27rem;
            }
        }
        .accessone{
            max-height: 1em;
        }
        .clearbalance{
            max-height: 2em;
        }
    }
    .financing-note {
        display: flex;
        margin: 0 auto;
        width: 75%;
        .financing-note-icon {
            padding-top: 8px;
        }
    }
}
</style>