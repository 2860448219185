<template>
    <v-dialog :modelValue="modelValue" v-bind="$attrs" :scrollable="scrollable" :width="width" :content-class="contentClasses" :attach="true">
        <v-card :modelValue="modelValue" v-bind="$attrs">
            <div v-if="this.showCloseButton" class="close-button" @click="onClose"><base-icon icon="mdi-close"></base-icon></div>
            <div class="default"><slot></slot></div>
            <v-card-title><slot name="title"></slot></v-card-title>
            <v-card-text><slot name="text"></slot></v-card-text>
            <v-card-actions><slot name="actions"></slot></v-card-actions>
        </v-card>
    </v-dialog>
</template>

<!--
    The dialog component uses the card component, which has 3 main sections that we use: title, text, and actions.
    There is no need to add in the close button since that is built-in to the template.
    You can pass in a function to run on close if you need to do more than close the dialog. To use:
    <base-dialog @on-close="customFunctionForOnClose">
        <template #title>Title</template>
        <template #text>Text</template>
        <template #actions>Buttons</template>
    </base-dialog>
    Alteratively, you can just pass in content without a template and it would work fine. It just won't use the defined sub-components of card. To use:
    <base-dialog>
        Text here
    </base-dialog>
-->

<script>
import BaseIcon from './BaseIcon.vue';
import BaseCard from './BaseIcon.vue';

export default {
    name: 'BaseDialog',
    props: {
        scrollable: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '600px'
        },
        modelValue: {
            default: null
        },
        showCloseButton: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        BaseIcon,
        BaseCard
    },
    computed: {
        contentClasses() {
            if (this.$attrs['content-class']) {
                return this.$attrs['content-class']  + ' base-dialog vuetify';
            }
            return 'base-dialog vuetify';
        }
    },
    methods: {
        onClose() {
            this.$emit('update:modelValue', false);
            this.$emit('on-close');
        }
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.v-overlay[role="dialog"] {
    font-family: Roboto;
    .v-overlay__content {
        .base-expansion-panels .v-expansion-panel-header .radio-wrapper .v-input--selection-controls {
            margin-top: 16px;
            padding-top: 4px;
        }
        .v-card-title:empty, .v-card-text:empty, .v-card-actions:empty, div.default:empty {
            display:none
        }
        .v-card.v-sheet {
            padding-top: 0.9rem;
        }
        @include wider-than-tablet {
			max-height: 92%;
		}
        .v-card-title {
			font-size: 2rem;
			line-height: 2.4rem;
			color: $global-color-font-new;
			padding: 2.5rem 2.5rem 2rem 2.5rem;
			@include wider-than-tablet {
				line-height: 2.6rem;
				letter-spacing: 0.24px;
			}
		}
        .v-card-text {
            justify-content: left;
			font-size: 1.6rem;
			line-height: 2.4rem;
			color: $global-color-font-new;
            padding: 0.25rem 2.5rem;
            .v-expansion-panel:not(:last-child) {
                margin-bottom: 1.5rem;
            }
            .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
                flex: none;
            }
            .v-card.v-sheet.theme--light {
                .v-card-title {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    color: $global-color-font-new;
                    padding: 2.5rem 1.5rem;
                    display: block;
                    text-align: left;
                }
            }
		}
        .v-card-actions {
            flex-direction: column-reverse;
			@include wider-than-tablet {
				flex-direction: row;
			}
			justify-content: right;
            padding: 1.5rem 2.5rem;

			button, a.v-btn {
				font-size: 1.4rem;
				line-height: 1.9rem;
				@include wider-than-tablet {
					font-size: 1.3rem;
					line-height: 1.5rem;
				}
				.v-btn__content {
					padding: 0 2.1rem;
				}
			}
		}
    }
}
</style>