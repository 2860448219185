<template>
<div v-if="showComponent" class="vuetify">
    <div class="sub-account-details-cost-breakdown">
        <content-card :headerTitle="$translate('labels.costBreakdown')" headerIcon="mdi-cash-multiple" padded>
            <div>
                <div data-testid="sub-account-details-breakdown" class="data-grid">                  
                    <!-- service amount -->
                    <div v-show="estimate.serviceAmount != null" class="row">
                        <div class="col-left" data-testid="sub-account-details-breakdown-label">{{ $translate('labels.estimateSummaryServiceAmount') }}</div>
                        <div class="col-right" data-testid="sub-account-details-breakdown-value">{{ $formatCurrency(estimate.serviceAmount) }}</div>
                    </div>
                    <!-- insurance coverage -->
                    <div v-show="insuranceCoverage" class="row">
                        <div class="col-left" data-testid="sub-account-details-breakdown-label">{{ $translate('labels.estimateSummaryInsuranceCoverage') }}</div>
                        <div class="col-right" data-testid="sub-account-details-breakdown-value">{{ insuranceCoverage }}</div>
                    </div>
                    <!-- patient payments -->
                    <div v-show="showAmountPaid" class="row patient-payments">
                        <div class="col-left" data-testid="sub-account-details-breakdown-label">{{ $translate('labels.patientPayments') }}</div>
                        <div class="col-right" data-testid="sub-account-details-breakdown-value">{{ $formatCurrency(estimate.amountPaid) }}</div>
                    </div>
                    <!-- divider -->
                    <div class="divider"></div>
                    <!-- estimated amount -->
                    <div class="row last">
                        <div class="col-left u-medium" data-testid="sub-account-details-breakdown-label">{{ $translate('labels.estimateSummaryEstimatedCost') }}</div>
                        <div class="col-right u-medium" data-testid="sub-account-details-breakdown-value">{{ $formatCurrency(estimate.estimatedAmount) }}</div>
                    </div>
                    <div v-if="isVoid" class="void" data-testid="sub-account-details-breakdown-void">{{ $translate('statusChips.void') }}</div>
                </div>
                <div class="disclaimer">
                    <div>
                        <span class="disclaimer-header-estimate">
                            <span data-testid="estimate-disclaimer-header" class="u-bold">{{ $translate('labels.estimateSummaryDisclaimerHeader') }}</span>
                            <span @click="disclaimerBottomSheet = true" data-testid="disclaimer-alert-icon"><base-icon icon="mdi-help-circle"></base-icon></span>
                        </span>
                        <span data-testid="estimate-disclaimer-content">{{ $translate('labels.estimateSummaryDisclaimer') }}</span>                
                    </div>
                </div>
            </div>
        </content-card>
    </div>
    <base-bottom-sheet v-model="disclaimerBottomSheet" scrollable>
        <div class="disclaimer-details" v-html="$translate('labels.estimateSummaryDisclaimerDetails')"></div>
    </base-bottom-sheet>
</div>
</template>
<script>
import ContentCard from './ContentCard.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'SubAccountDetailsCostBreakdown',
    components: {
        ContentCard,
        BaseBottomSheet,
        BaseIcon
    },
    props: {
        estimate: Object,
    },
    data: () => ({
        disclaimerBottomSheet: false,
    }),
    computed: {
        showComponent() {
            return this.estimate && Boolean(this.estimate.estimatedAmount) && Boolean(this.estimate.serviceAmount) && this.estimate.estimatedAmount <= this.estimate.serviceAmount;
        },
        paymentRequired() {
            // don't show if payment plan
            let isPaymentPlan = this.estimate.subAccountStatus == "PAYMENT_PLAN";
            // don't show if the estimate is paid in full
            let isPaidInFull = this.estimate.amountPaid >= this.estimate.requestedPaymentAmount;
            return !isPaymentPlan && !isPaidInFull && parseFloat(this.estimate.requestedPaymentAmount) > 0;
        },
        showAmountPaid() {
            return this.estimate.amountPaid > 0;
        },
        insuranceCoverage() {
            let insuranceCoverage = this.estimate.serviceAmount != null ? parseFloat(this.estimate.serviceAmount) - parseFloat(this.estimate.estimatedAmount) : 0;
            return this.$formatCurrency(insuranceCoverage);
        },
        isVoid() {
            return this.estimate.cancelled;
        }
    }
};
</script>

<style lang="scss">
@import '../styles/variables.scss';
.vuetify {
    .sub-account-details-cost-breakdown {
        .card-header {
            .v-icon {
                padding: 0.7rem 0.7rem 0.9rem 0.9rem;
            }
        }

        @include wider-than-tablet {
            .content-card.padded .content-card-content {
                padding-left: 3.6rem;
                padding-right: 3.6rem;
                padding-bottom: 1.8rem;
            }
        }

        .data-grid .row {
            margin-bottom: 1.6rem !important;
            margin-top: 0;
        }
        
        .data-grid .row.last {
            margin-bottom: 0 !important;
        }
    }
}
</style>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.notice {
    font-size: 1.2rem;
    padding-top: 0.5rem;
    color: $global-color-warning;
    @include wider-than-tablet {
        font-size: 1.4rem;
    }
}

.disclaimer {
    margin-top: 2rem;
    background-color: #f7f7f7;
    font-size: 1.4rem;
    padding: 1rem;
    color: dimgrey;
    border-radius: .5rem;

    .disclaimer-header-estimate {
        display: flex;
        justify-content: space-between;
    }

    .v-icon {
        display: inline;
        float: right;
    }
}

.disclaimer-details {
    padding-top: 2.5rem;
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: $global-color-font-new;
}

.data-grid {
    .col-left {
        flex: 70%;
    }
    .col-right {
        flex: 30%;
    }
}

.divider {
    margin: 1.6rem 0rem;
}

.disclaimer-bottom-sheet-card {
    overflow: auto;
}

.void {
    text-transform: uppercase;
    color: #CE1818;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.4px;
    font-weight: 500;
    text-align: right;
    @include wider-than-tablet {
        font-size: 1.6rem;
        line-height: 2rem;
    }
}
</style>