import { h } from 'vue';

import PatientcoWaystarSVG from './../components/icons/PatientcoWaystarSVG.vue';
import PatientcoSVG from './../components/icons/PatientcoSVG.vue';
import AmexSVG from './../components/icons/AmexSVG.vue';
import DiscoverSVG from './../components/icons/DiscoverSVG.vue';
import MastercardSVG from './../components/icons/MastercardSVG.vue';
import VisaSVG from './../components/icons/VisaSVG.vue';
import ApplePaySVG from './../components/icons/ApplePaySVG.vue';
import BillAmountSVG from './../components/icons/BillAmountSVG.vue';
import BillScodeSVG from './../components/icons/BillScodeSVG.vue';
import SpinnerBlueSVG from './../components/icons/SpinnerBlueSVG.vue';
import SpinnerWhiteSVG from './../components/icons/SpinnerWhiteSVG.vue';
import CuraeSVG from './../components/icons/CuraeSVG.vue';
import AccessOneSVG from './../components/icons/AccessOneSVG.vue';
import ClearBalanceSVG from './../components/icons/ClearBalanceSVG.vue';


const customSvgNameToComponent = {
    PatientcoWaystarSVG,
    PatientcoSVG,
    AmexSVG,
    DiscoverSVG,
    MastercardSVG,
    VisaSVG,
    ApplePaySVG,
    BillAmountSVG,
    BillScodeSVG,
    SpinnerBlueSVG,
    SpinnerWhiteSVG,
    CuraeSVG,
    AccessOneSVG,
    ClearBalanceSVG
};

const customSVGs = {
component: (props) => h(customSvgNameToComponent[props.icon]),
};

export { customSVGs /* aliases */ };