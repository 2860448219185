<template>
    <base-form ref="form" @submit.prevent>
        <base-dialog v-model="isVisible" :fullscreen="!$vuetify.display.lgAndUp" scrollable content-class="add-payment-method-dialog" @on-close="close">
            <template #title>{{ $translate('newPaymentFormFlow.title') }}</template>
            <template #text>
                <base-expansion-panels v-model="methodTypeIndex">
                    <payment-flow-method-step-new-method-card
                        v-model="newCard"
                        :selected="0 === methodTypeIndex"
                        :isPaymentPlan="false"
                        hide-save-option
                        data-testid="add-payment-method-dialog-new-method-card"
                        ref="paymentFlowMethodStepNewMethodCard"
                    ></payment-flow-method-step-new-method-card>
                    <payment-flow-method-step-new-e-check-card
                        v-model="newECheck"
                        :isPaymentPlan="false"
                        :selected="1 === methodTypeIndex"
                        hide-save-option
                        data-testid="add-payment-method-dialog-new-e-check-card"
                        ref="paymentFlowMethodStepNewECheckCard"
                    ></payment-flow-method-step-new-e-check-card>
                </base-expansion-panels>
                <div v-if="error" class="add-payment-method-dialog-error">
                    <base-icon :size="$vuetify.display.lgAndUp ? 24 : 20" icon="mdi-alert-circle"></base-icon>
                    <div class="add-payment-method-dialog-error-message">{{ error }}</div>
                </div>
            </template>
            <template #actions>
                <base-btn @click="close(true)" variant="text" class="vuetify cancel-button">{{ $translate('actions.cancel') }}</base-btn>
                <div data-testid="save-method-wrapper" @click="forceValidation()" class="save-method-wrapper">
                    <base-btn :disabled="contButtonDisabled" @click="saveMethod" class="v-button-primary add-payment-method-dialog vuetify">{{ submitting ? $translate('labels.submittingPaymentForm') : $translate('newPaymentFormFlow.saveMethod') }}</base-btn>
                </div>
            </template>
        </base-dialog>
    </base-form>
</template>

<script>
import BaseForm from './BaseForm.vue';
import BaseBtn from './BaseBtn.vue';
import BaseDialog from './BaseDialog.vue';
import PaymentFlowMethodStepNewECheckCard from './PaymentFlowMethodStepNewECheckCard.vue';
import PaymentFlowMethodStepNewMethodCard from './PaymentFlowMethodStepNewMethodCard.vue';
import BaseIcon from './BaseIcon.vue';
import BaseExpansionPanels from './BaseExpansionPanels.vue';

export default {
    name: 'AddPaymentMethodDialog',
    components: {
        BaseForm,
        BaseBtn,
        BaseDialog,
        PaymentFlowMethodStepNewECheckCard,
        PaymentFlowMethodStepNewMethodCard,
        BaseIcon,
        BaseExpansionPanels
    },
    props: {
        error: String,
        submitting: Boolean,
    },
    data: () => ({
        isVisible: false,
        methodTypeIndex: null,
        newCard: {},
        newECheck: {},
    }),
    computed: {
        isDataSelected() {
            if (this.methodTypeIndex == null && this.methodTypeIndex == undefined) {
                return false;
            } else if (!Object.keys(this.newCard).length && this.methodTypeIndex == 0) {
                return false;
            } else if (!Object.keys(this.newECheck).length && this.methodTypeIndex == 1) {
                return false;
            }
            return true;
        },
        contButtonDisabled() {
            let valid = false;
            if (this.methodTypeIndex == 0 || this.methodTypeIndex == 1) {
                let data = (this.methodTypeIndex == 1) ? this.newECheck : this.newCard;
                valid = data.valid;
            }
            return (!this.isDataSelected || this.submitting || !valid);
        }
    },
    methods: {
        close() {
            this.isVisible = false;
            this.methodTypeIndex = null;
            this.newCard = {};
            this.newECheck = {};
        },
        open() {
            this.isVisible = true;
        },
        saveMethod() {
            if (0 === this.methodTypeIndex) {
                this.$emit('submit', { newCard: this.newCard });
            } else if (1 === this.methodTypeIndex) {
                this.$emit('submit', { newECheck: this.newECheck });
            }
        },
        forceValidation() {
            if (this.contButtonDisabled) {
                if (this.$refs.paymentFlowMethodStepNewMethodCard.selected) {
                    this.$refs.paymentFlowMethodStepNewMethodCard.validateFields();
                } else if (this.$refs.paymentFlowMethodStepNewECheckCard.selected) {
                    this.$refs.paymentFlowMethodStepNewECheckCard.validateFields();
                }
            }
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.add-payment-method-dialog {
    .payment-flow-method-step-billing {
        .locations {
            .unitedstates {
                label {
                    .title {
                        margin-top: 0.7rem;
                    }
                }
            }
            .international {
                label {
                    .title {
                        margin-top: 1.4rem;
                    }
                }
            }
        }
    }
}
.add-payment-method-dialog-error {
    align-items: center;
    color: $global-color-warning;
    display: flex;
    font-size: 1.6rem;
    margin-top: 1.2rem;
    padding-left: 2rem;
    .add-payment-method-dialog-error-message {
        display: flex;
        align-items: center;
        min-height: 2rem;
        @include wider-than-tablet {
            min-height: 2.4rem;;
        }
    }
    .v-icon {
        color: $global-color-warning;
        margin-right: 1.2rem;
        svg {
            padding-top: 0;
        }
    }
}
</style>