<template>
<div id="app-wrap" >
  <header class="header">
    <div class="container">
      <div class="header-persistent-content">
        <!-- negative tabindex to prevent accidental navigation home inside paymentflow -->
        <a tabindex="-1" class="header-home-link">
          <patientco-logo color="white" id="header-logo"></patientco-logo>
          <patientco-text color="white" id="header-text"></patientco-text>
        </a>
      </div>
    </div>
  </header>
  <!-- CONTEXT HELPER -->
	<div class="context-helper" v-show="isBreadCrumbVisible">
		<div class="bread-crumbs" @click="click()">
      <base-icon icon="mdi-arrow-left" class="bc-arrow iconic"></base-icon>
			{{ currentBreadCrumbLabel }}
		</div>
	</div>

	<div id="main-content">
    <div class="loading-indicator" :class="{ 'js-visible': isLoadingIndicatorVisible }">
        <div class="loading-indicator-dialog">{{ $translate('labels.loading') }}</div>
    </div>
		<div id="one-column" view-scroll-top="true" class="content view-transition">
      <div class="content full-page-notification-content center-content-block">
        <div class="center-content-block-container">
          <div class="heading">{{ $translate('fullPageNotifications.header.thankYou') }}</div>
          <div class="subheading">{{ subHeader }}</div>
          <button class="button-primary button-lrg main-btn" @click="mainButtonAction()">{{ $translate('actions.continue') }}</button>
          <div class="message">{{ message }}</div>
          <button v-for="(subButton, index) in subButtons" :key="index" @click="subButton.action()">{{ subButton.text}}</button>
        </div>
      </div>
    </div>
	</div>
</div>
</template>

<script>
import PatientcoLogo from './../components/PatientcoLogo.vue';
import PatientcoText from './../components/PatientcoText.vue';
import BaseIcon from './../components/BaseIcon.vue';

export default {
  name: 'FullPageNotification',
  components: {
    PatientcoLogo,
    PatientcoText,
    BaseIcon
  },
  data: () => ({
    subHeader: null,
  }),
  computed: {
    brandingConfig() {
      return this.$store.getters.brandingConfig;
    },
    currentBreadCrumbLabel() {
      return this.$store.getters.currentBreadCrumbLabel;
    },
    isBreadCrumbVisible() {
      return this.$store.getters.isBreadCrumbVisible;
    },
    isLoadingIndicatorVisible() {
      return this.$store.getters.isLoadingIndicatorVisible;
    },
    message() {
      if (this.brandingConfig) {
        return this.$translate('fullPageNotifications.message.welcomeBranded');
      }
      return this.$translate('fullPageNotifications.message.welcome');
    },
    subButtons() {
      if (this.brandingConfig) {
        return [];
      }
      return [{
          text: this.$translate('links.termsOfService'),
          action: () => {
            window.open('https://www.waystar.com/patientco-terms-of-use/', '_blank');
          },
      }, {
          text: this.$translate('actions.contactUs'),
          action: () => {
            this.emitter.emit('contact:show');
          },
      }];
    },
  },
  methods: {
    mainButtonAction() {
      this.$router.push({name: 'ProvidersSummary'});
    },
    click() {
      this.$store.dispatch('breadCrumbClick');
    }
  },
  async mounted() {
    await this.$nextTick();
    if (window.location.hash.includes('emailVerified')) {
      this.subHeader = this.$translate('fullPageNotifications.subheader.emailVerified');
    } else if (window.location.hash.includes('phoneVerified')) {
      this.subHeader = this.$translate('fullPageNotifications.subheader.phoneVerified');
    }
  }
};
</script>
<style lang="scss" scoped>
</style>