<template>
<modal can-close :show="isVisible" @close="hidePrompt()" class="new-bill-modal-container">
    <div class="new-bill-modal">

        <div class='choose-bill-section' v-show="!showNewBillForm">
            <h2 class="modal-header h-style-2">{{ $translate('newbill.whichAccount') }}</h2>

            <div class="bill-listing">
                <button class="u-truncate existing-bill" v-for="(account, i) in accounts" :key="i" :class="{'button-warn-white-out': account.isPastDue}"
                    v-html="account.buttonText" @click="selectAccount(account)">
                </button>

                <button class="new-bill" @click="payNewBill()">
                    {{ $translate('actions.payNewBill') }}
                </button>
            </div>
        </div>

        <div class='new-bill-form' v-show="showNewBillForm">
            <h2 class="modal-header h-style-2">{{ $translate('labels.enterUniqueDetails') }}</h2>
            <find-bill-container ref="findBillContainer" @found="hidePrompt()" link-function="linkBill"></find-bill-container>
        </div>
    </div>
</modal>
</template>
<script>
import _ from 'lodash';

import FindBillContainer from './FindBillContainer.vue';
import Modal from './Modal.vue';

export default {
    name: 'NewBillModal',
    components: {
        FindBillContainer,
        Modal,
    },
    data: () => ({
        isVisible: false,
        accounts: [],
        showNewBillForm: false,
        submitting: false,
        payBillDialog: null,
    }),
    methods: {
        reset() {
            if (this.$refs.findBillContainer) {
                this.$refs.findBillContainer.reset();
            }
        },
        selectAccount(account) {
            // This flow should never trigger post deprecation of AngularJS payment flow. However, account for routing just in case.
            this.$router.push({
                name: 'AccountPaymentFlowAmountStep',
                params: { accountId: account.accountId, providerId: this.selectedProvider.id },
            });
        },
        hidePrompt() {
            this.isVisible = false;
        },
        payNewBill() {
            this.showNewBillForm = true;
        },
    },
    created() {
        this.payBillDialog = this.$translate('actions.payBill');
        this.emitter.on('newBill:showPrompt', (promptProperties) => {
            this.reset();

            var config = _.assign({
                showAcctsWithBalances: false
            }, promptProperties);

            if (config.showAcctsWithBalances) {
                this.$store.dispatch('syncProviders').then((providers) => {
                    this.accounts = _.chain(providers)
                        .map((provider) => {
                            //apply some additional info to the models to populate everything the view needs
                            var accounts = provider.accounts;
                            _.forEach(accounts, (acct) => {
                                acct.providerName = provider.name;
                                acct.isPastDueVal = acct.isPastDue ? 0 : 1; //hack to get boolean sorting to work.  lodash sort by order doesn't seem to work - so, 
                                                                            //setting past-due accts to zero will put them at the top
                                acct.buttonText = this.$translate('newbill.accountDue' , { providerName: provider.name,
                                    accountNumber: acct.accountNumber,
                                    amountDue: this.$formatCurrency(acct.amountDue)
                                });
                            });
                            return accounts;
                        })
                        .flatten()
                        .filter((acct) => {
                            return acct.amountDue > 0 && !acct.financedBy;
                        })
                        .sortByAll(['isPastDueVal', 'providerName', 'dueDate'])
                        .value();

                    this.showNewBillForm = _.isEmpty(this.accounts); //skip to the new-bill form if there are no accts w/ balances
                    this.promptVisible = true;
                }).catch(() => {
                    console.error('Could not sync providers to show accounts with balance');
                });
            } else {
                this.showNewBillForm = true;
                this.isVisible = true;
            }
        });

        this.emitter.on('newBill:hidePrompt', this.hidePrompt);
    },
    destroyed() {
        this.emitter.off('newBill:hidePrompt', this.hidePrompt);
    },
};
</script>
<style lang="scss">
.new-bill-modal {

    .bill-listing {
        max-height: 425px;
        overflow: auto;
    }

    .choose-bill-section button {
        line-height: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
            
        &.existing-bill {
            font-weight: normal;
        }
    }

	.modal-header {
		margin-top: 5%;
		margin-bottom: 5%;
	}

	.submitFindBill {
		margin-top: 10%;
	}

    .find-bill-container {
        min-width: 80%;
        max-width: 80%;
        margin: 0 auto;
    }
}
</style>