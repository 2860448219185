<template>
<div class="payment-flow-confirmation-step vuetify">
    <div class="payment-flow-step-title">{{ $translate('paymentFlow.steps.confirmation') }}</div>
    <div v-if="genericError" class="payment-flow-confirmation-step-error generic">
        <base-icon :size="$vuetify.display.lgAndUp ? 24 : 20" icon="mdi-alert-circle"></base-icon>
        <div class="payment-flow-confirmation-step-error-message">{{ genericError }}</div>
    </div>
    <div class="payment-flow-step-amount">
        <span>
            <div class="confirmation-sub-title">{{ $translate('paymentFlow.backButtons.amount') }}</div>
            <div v-if="!(installmentCount && installmentCount > 1)" class="confirmation-sub-text">{{ $translate('paymentFlow.amount.subAccountBalanceTitle', { amount: $formatCurrency(amount) }) }}</div>
            <div v-else class="confirmation-sub-text">
                <div v-if="!updatePlanData">{{ $translate('paymentFlow.amount.paymentPlan.title') }}</div>
                <div v-if="updatePlanData">{{ $translate('paymentFlow.amount.paymentPlan.updateTitle') }}</div>
                <div>
                    {{ $translate('paymentFlow.amount.paymentPlan.installmentOptionHeader', { formattedAmount: this.$formatCurrency(amount / installmentCount) }) }}
                </div>
                <div class="confirmation-extra-info-text">
                    {{ $translate('paymentFlow.amount.paymentPlan.installmentOptionSubheader', { numberOfMonths: installmentCount }) }}
                </div>
            </div>
        </span>
        <a @click="$emit('step', amountStep)" class="confirmation-edit">{{ $translate('actions.edit') }}</a> 
    </div>
    <base-divider></base-divider>
    <div class="payment-flow-step-date">
        <span>
            <div class="confirmation-sub-title">{{ $translate('paymentFlow.backButtons.date') }}</div>
            <div v-if="!(installmentCount && installmentCount > 1)" class="confirmation-sub-text">{{ $formatDate(date, 'MMMM D, YYYY') }}</div>
            <div v-else class="confirmation-sub-text">
                <div>
                    {{ $translate('paymentFlow.confirmation.paymentPlanMonthlyDate', { formattedDate: $formatDate(date, 'Do') }) }}
                </div>
                <div class="confirmation-extra-info-text">
                    {{ $translate('paymentFlow.confirmation.paymentPlanFirstPaymentDate', { formattedDate: $formatDate(date, 'MMMM D, YYYY') }) }}
                </div>
            </div>
        </span>
        <a v-if="editableDate" @click="$emit('step', dateStep)" class="confirmation-edit">{{ $translate('actions.edit') }}</a> 
    </div>
    <base-divider></base-divider>
    <div class="payment-flow-step-method">
        <span>
            <div class="confirmation-sub-title">{{ $translate('paymentFlow.backButtons.method') }}</div>
            <div v-if="method" class="confirmation-sub-text">
                <!--new echeck-->
                <span v-if="method.eCheck" class="confirm-new-echeck">
                    eCheck <br>
                    {{ titleCase(method.eCheck.bankName) }} ****{{ method.eCheck.routing.slice(-4) }}
                </span>

                <!--new card-->
                <span v-else-if="method.card" class="confirm-new-card">
                    <base-icon v-if="'Americanexpress' === cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                    <base-icon v-else-if="'Discover' === cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                    <base-icon v-else-if="'Mastercard' === cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                    <base-icon v-else-if="'Visa' === cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                    ****{{ method.card.number.slice(-4) }}
                </span>

                <!--saved echeck-->
                <span v-else-if="method.savedMethod && (method.savedMethod.formType === 'echeck')" class="confirm-saved-echeck">
                    eCheck <br> 
                    {{ titleCase(method.savedMethod.bankName) }} ****{{ method.savedMethod.last4Digits }}
                </span>

                <!--saved card-->
                <span v-else-if="method.savedMethod && (method.savedMethod.formType === 'card')" class="confirm-saved-card">
                    <base-icon v-if="'Americanexpress' === method.savedMethod.cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                    <base-icon v-else-if="'Discover' === method.savedMethod.cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                    <base-icon v-else-if="'Mastercard' === method.savedMethod.cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                    <base-icon v-else-if="'Visa' === method.savedMethod.cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                    ****{{ method.savedMethod.last4Digits }}
                </span>

                <!--saved financing-->
                <span v-else-if="method.savedMethod && (method.savedMethod.formType === 'financing')" class="confirm-saved-financing">
                    {{ getMethodDescriptor(method.savedMethod) }}
                </span>
            </div>
            <div v-if="billing" class="billing-address fs-block">
                <span v-if="parsedMethod.firstName && parsedMethod.lastName" class="names">{{ parsedMethod.firstName }} {{ parsedMethod.lastName }} <br></span>
                    <span class="billing-address1">{{ billing.address1 }} <br></span>
                    <span v-if="billing.address2" class="billing-address2">{{ billing.address2 }} <br></span>
                    <span class="billing-city-state-zip">{{ billing.city }}, {{ billing.isInternational ? billing.province : billing.state }} {{ billing.isInternational ? billing.postalCode : billing.zip }}</span>
            </div>
        </span>
        <a @click="$emit('step', methodStep)" class="confirmation-edit">{{ $translate('actions.edit') }}</a> 
    </div>
    <div v-if="methodError" class="payment-flow-confirmation-step-error method">
        <base-icon :size="$vuetify.display.lgAndUp ? 24 : 20" icon="mdi-alert-circle"></base-icon>
        <div class="payment-flow-confirmation-step-error-message">{{ methodError }}</div>
    </div>
    <!--echeck authorization for new or saved echeck-->
    <div class="eCheck-authorization-new" v-if="(method && method.eCheck) || (method && (method.savedMethod && (method.savedMethod.formType === 'echeck')))">
        <!--payment plan-->
        <p v-if="installmentCount > 1">{{ $translate('payment.confirm.eCheckAuthorizationPaymentPlan', { providerName: providerName, providerPhoneNumber: providerPhoneNumber }) }}</p>
        <!--single payment-->
        <p v-else>{{ $translate('payment.confirm.eCheckAuthorizationSingle', { providerName: providerName, amount: $formatCurrency(amount), date: $formatDate(date, 'MMMM D, YYYY'), providerPhoneNumber: providerPhoneNumber }) }}</p>
    </div>
    <base-divider></base-divider>
    <div v-if="isGuestPay" class="payment-flow-guest-pay">
        <span>
            <div class="confirmation-sub-title">{{ $translate('paymentFlow.steps.contact') }}</div>
            <div class="confirmation-sub-text">
                <base-text-field
                    :label="$translate('labels.email')"
                    dense
                    type="text"
                    required
                    filled
                    class="email-text-field fs-block"
                    name="email"
                    ref="input"
                    v-model="email"
                    :error-messages="v$.email.$errors.map(e => e.$message)"
                    @blur="v$.email.$touch"
                ></base-text-field>
                <base-checkbox 
                    v-model="acceptanceOfTerms"
                    hide-details 
                    id="acceptanceOfTerms"
                    :error-messages="v$.acceptanceOfTerms.$errors.map(e => e.$message)"
                    @action="v$.acceptanceOfTerms.$touch"
                    @click="() => { acceptanceOfTermsDirty = true }"
                ></base-checkbox>
                <label
                    for="acceptanceOfTerms"
                    v-html="$translate('labels.acceptanceOfTermsShort')"
                ></label>
                <div v-if="!acceptanceOfTerms && acceptanceOfTermsDirty" class="error-message">
                    <span class="error-message">{{  $translate('errors.fieldRequired') }}</span>
                </div>
            </div>
        </span>
        <base-divider></base-divider>
    </div>
    <div class="navigation-buttons">
        <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <div data-testid="payment-btn-wrapper" @click="forceValidation()" class="payment-btn-wrapper">
            <base-btn v-if="scheduledToday" @click="processPayment()" :disabled="disableSubmitButton" class="vuetify continue-btn v-button-primary">
                {{ isProcessing ? $translate('paymentFlow.actions.processing') : $translate('paymentFlow.actions.processPayment') }}
            </base-btn>
            <base-btn v-else @click="processPayment()" :disabled="disableSubmitButton" class="continue-btn v-button-primary">
                {{ isProcessing ? $translate('paymentFlow.actions.processing') : $translate('paymentFlow.actions.processPaymentFuture') }}
            </base-btn>
        </div>
    </div>
</div>
</template>
<script>
import BaseBtn from './BaseBtn.vue';
import { CardUtils, titleCase, formatDate, formatCurrency, getMethodDescriptor, validEmail } from '../utils/utils';
import BaseIcon from './BaseIcon.vue';
import BaseDivider from './BaseDivider.vue';
import BaseSpacer from './BaseSpacer.vue';
import BaseTextField from './BaseTextField.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import useValidate from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'

export default {
    name: 'PaymentFlowConfirmationStep',
    components: {
        BaseIcon,
        BaseBtn,
        BaseDivider,
        BaseSpacer,
        BaseTextField,
        BaseCheckbox,
    },
    props: {
        amount: String,
        date: String,
        editableDate: Boolean,
        installmentCount: Number,
        isProcessing: Boolean,
        method: Object,
        genericError: String,
        methodError: String,
        billing: Object,
        subAccountId: String,
        estimateId: String,
        updatePlanData: Object,
        providerName: String,
        providerPhoneNumber: String
    },
    data: () => ({
        v$: useValidate(),
        dialog: false,
        email: null,
        acceptanceOfTerms: false,
        acceptanceOfTermsDirty: false
    }),
    validations() {
        return {
            email: {
                required: helpers.withMessage(this.$translate('errors.fieldRequired'), required),
                email: helpers.withMessage(
                    this.$translate('errors.email'),
                    email
                )
            },
            acceptanceOfTerms: {
                required: helpers.withMessage(this.$translate('errors.fieldRequired'), required)
            }
        }
    },
    computed: {
        parsedMethod() {
            return Object.values(this.method)[0];
        },
        cardType() {
            const issuer = CardUtils.getIssuer(this.method.card.number);
            switch (issuer) {
                case 'Visa':
                    return 'Visa';
                case 'Discover':
                    return 'Discover';
                case 'Mastercard':
                    return 'Mastercard';
                case 'American Express':
                    return 'American Express';
                default:
                    return null;
            }
            
        },
        scheduledToday() {
            const today = new Date();
            return formatDate(today, 'MMMM D, YYYY') === formatDate(this.date, 'MMMM D, YYYY');
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
        guestPayFormData() {
            return this.$store.getters.guestPayFormData;
        },
        disableSubmitButton() {
            return this.isProcessing || (this.isGuestPay && (!this.acceptanceOfTerms || !validEmail(this.email)));
        },
        amountStep() {
            this.saveData();
            if (this.subAccountId) {
                return {
                    name: this.$translate('paymentFlow.steps.amount'),
                    path: this.isGuestPay ? 'GuestSubAccountPaymentFlowAmountStep' : 'SubAccountPaymentFlowAmountStep',
                };
            } else if (this.estimateId) {
                return {
                    name: this.$translate('paymentFlow.steps.amount'),
                    path: 'EstimatePaymentFlowAmountStep',
                };
            } else {
                return {
                    name: this.$translate('paymentFlow.steps.amount'),
                    path: this.isGuestPay ? 'GuestAccountPaymentFlowAmountStep' : 'AccountPaymentFlowAmountStep',
                };
            }
        },
        dateStep() {
            this.saveData();
           if (this.subAccountId) {
                return {
                    name: this.$translate('paymentFlow.steps.date'),
                    path: this.isGuestPay ? 'GuestSubAccountPaymentFlowDateStep' : 'SubAccountPaymentFlowDateStep',
                };
           } else if (this.estimateId) {
                return {
                    name: this.$translate('paymentFlow.steps.date'),
                    path: 'EstimatePaymentFlowDateStep',
                }; 
           } else {
                return {
                    name: this.$translate('paymentFlow.steps.date'),
                    path: this.isGuestPay ? 'GuestAccountPaymentFlowDateStep' : 'AccountPaymentFlowDateStep',
                };
            }
        },
        methodStep() {
            this.saveData();
            if (this.subAccountId) {
                return {
                    name: this.$translate('paymentFlow.steps.method'),
                    path: this.isGuestPay ? 'GuestSubAccountPaymentFlowMethodStep' : 'SubAccountPaymentFlowMethodStep',
                };
            } else if (this.estimateId) {
                return {
                    name: this.$translate('paymentFlow.steps.method'),
                    path: 'EstimatePaymentFlowMethodStep',
                };
            } else {
                return {
                    name: this.$translate('paymentFlow.steps.method'),
                    path: this.isGuestPay ? 'GuestAccountPaymentFlowMethodStep' : 'AccountPaymentFlowMethodStep',
                };
            }
        }
    },
    methods: {
        processPayment() {
            this.$emit('process-payment', this.email);
        },
        cancel() {
            this.$emit('cancel');
        },
        back() {
            this.saveData();
            this.$emit('back');
        },
        titleCase,
        formatDate,
        formatCurrency,
        getMethodDescriptor,
        forceValidation() {
            this.acceptanceOfTermsDirty = true;
            this.v$.$validate();
        },
        saveData() {
            if (this.isGuestPay) {
                this.$store.commit('setGuestPayFormData', {
                    email: this.email,
                    acceptanceOfTerms: this.acceptanceOfTerms
                });
            }
        }
    },
    created() {
        this.$emit('scroll-to-top');
        if (this.isGuestPay) {
            this.email = this.guestPayFormData.email;
            this.acceptanceOfTerms = this.guestPayFormData.acceptanceOfTerms;
            this.$store.commit('setGuestPayFormData', {});
        }
    },
    mounted() {
        this.$emit('loaded');
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.payment-flow-confirmation-step.vuetify {
    .confirmation-sub-title {
        color: $global-color-font-new;
        font-size: 1.8rem;
        padding-bottom: 1.5rem;
        font-weight: 500;
    }

    .confirmation-sub-text {
        color: $global-color-font-new;
        font-size: 1.6rem;
    }

    .confirmation-extra-info-text {
        font-size: 1.2rem;
    }

    .card-svg {
        max-width: 3rem;
        max-height: 1.9rem;
        margin: 0.25rem;
        vertical-align: middle
    }

    .v-divider {
        margin: 2.5rem 0;
    }

    .billing-address {
        color: $global-color-font-new;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    .navigation-buttons {
        margin-top: 4rem;
    }

    .payment-flow-step-date {
        display: flex;
        justify-content: space-between;
    }

    .payment-flow-step-amount {
        display: flex;
        justify-content: space-between;
    }

    .payment-flow-step-method {
        display: flex;
        justify-content: space-between;
    }

    .confirmation-edit {
        font-size: 1.4rem;
        color: $global-color-link-new;
        text-decoration: underline;
        font-weight: 500;
        @include wider-than-tablet {
            font-size: 1.8rem;
        }
    }

    .payment-flow-confirmation-step-error {
        align-items: flex-start;
        color: $global-color-warning;
        display: flex;
        font-size: 1.2rem;
        &.generic {
            margin-top: -0.8rem;
            @include wider-than-tablet {
                margin-bottom: 2rem;
            }
        }
        &.method {
            margin-top: 1.2rem;
        }
        .payment-flow-confirmation-step-error-message {
            display: flex;
            align-items: center;
            min-height: 2rem;
            @include wider-than-tablet {
                min-height: 2.4rem;;
            }
        }
        .v-icon {
            color: $global-color-warning;
            margin-right: 1.2rem;
        }
    }

    .eCheck-authorization-new {
        color: darken($global-color-light-grey, 25%);
        text-align: left;
        display: inline-block;
        line-height: 1.2em;
        margin-top: 1rem;
        p {
            margin-bottom: 0px !important;
        }
    }

    .payment-flow-guest-pay {
        input[type="checkbox"] {
            left: -10px;
            top: -5px;
        }

        label[for="acceptanceOfTerms"] {
            margin-left: -10px;
        }
    }

    .error-message span.error-message  {
        color: $global-color-warning;
        display: flex;
        padding-left: 6%;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
}
</style>
