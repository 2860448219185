import _ from 'lodash';

window['_fs_ready'] = function() {
    if (window.FS && window.FS.getCurrentSessionURL) {
        var sessionUrlAtTime = window.FS.getCurrentSessionURL(true);
        if (sessionUrlAtTime) {
            cookieStore.put('FullStorySessionUrl', sessionUrlAtTime);
            console.info('FullStorySessionUrl: ', sessionUrlAtTime);
        }
    } else {
        console.error('Failed to get FullStory current session url');
    }
};

const state = {};

const getters = {
    getFullstory: () => {
        if (!window._fs_namespace) {
            return false;
        }
        return window[window._fs_namespace];
    }
};

const mutations = {};

const actions = {
    /**
     * Log to the Fullstory console
     * @param {String} optionalLogLevel the log level passed to Fullstory; one of 'log', 'warn', 'error', 'info'; defaults to 'log'
     * @param {String} logMessage the log message sent to the Fullstory console
     */
    logFullstory({getters}, { optionalLogLevel, logMessage }) {
        if (typeof logMessage === 'undefined') {
            logMessage = optionalLogLevel;
            optionalLogLevel = 'log';
        }
        var FS = getters.getFullstory;
        if (!FS) {
            console.warn('FullstoryStore: Fullstory is not initialized; cannot log to FullStory console properly. Log output appears below');
            switch(optionalLogLevel) {
                case 'warn':
                    console.warn(logMessage);
                case 'error':
                    console.error(logMessage);
                case 'info':
                    console.info(logMessage);
                default:
                    console.log(logMessage);
            }
        } else {
            FS.log(optionalLogLevel, logMessage);
        }
    },
    /**
     * Identify the current patient-user to Fullstory
     * @param {Object} currentUser the current patient-user
     * @returns {Boolean} indicates whether user was identified to Fullstory
     */
    identifyFullStory({ getters }, currentUser) {
        var FS = getters.getFullstory;
        if (!FS) {
            console.warn('FullstoryStore: Fullstory is not initialized; cannot identify user to Fullstory');
            return false;
        }
        if (!(currentUser && currentUser.patientUserId)) {
            console.info('FullstoryStore: currentUser is null; disassociating from Fullstory');
            FS.identify(null);
        } else {
            var displayName = 'Patient User ' + currentUser.patientUserId;
            FS.identify(currentUser.patientUserId, {
                displayName: displayName,
                newUser: currentUser.newUser,
                isMyChartSSOOnlyUser: currentUser.isMyChartSSOOnlyUser,
                isMyChartSSOVerifiedUser: currentUser.isMyChartSSOVerifiedUser,
                browserLanguage: window.navigator.language || window.navigator.userLanguage,
            });
            console.info('FullstoryStore: identified user to Fullstory as ' + displayName);
        }
        return true;
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};