<template>
    <div class="patientco-text">
        <svg :style="svgStyle" id="patientco_text_1" data-name="Patientco Text 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 497.78 68.61" height="100%">
            <title>Patientco</title>
            <path class="cls-1" d="M0,67.66H6.48V41.07H22.39A24.8,24.8,0,0,0,33.7,38.61,17.21,17.21,0,0,0,41,31.69,21.47,21.47,0,0,0,43.64,21,21.19,21.19,0,0,0,41,10.31,17.08,17.08,0,0,0,33.7,3.42,24.8,24.8,0,0,0,22.39,1H0Zm6.48-33V7.43H22.3A18.31,18.31,0,0,1,30.24,9a11.27,11.27,0,0,1,5.05,4.59A14.6,14.6,0,0,1,37.07,21a14.93,14.93,0,0,1-1.78,7.45,11.32,11.32,0,0,1-5.05,4.67,18,18,0,0,1-7.94,1.61Z"/>
            <path class="cls-1" d="M100,67.66h7L77.92.38h-6L42.86,67.66h7L59,46H90.88ZM61.63,39.74,75,8.29,88.21,39.74Z"/><polygon class="cls-1" points="149.67 0.95 104.03 0.95 104.03 7.34 123.66 7.34 123.66 67.66 130.04 67.66 130.04 7.34 149.67 7.34 149.67 0.95"/><rect class="cls-1" x="163.49" y="0.95" width="6.48" height="66.7"/><polygon class="cls-1" points="190.17 67.66 225.43 67.66 225.43 61.27 196.65 61.27 196.65 35.83 224.67 35.83 224.67 29.54 196.65 29.54 196.65 7.34 225.43 7.34 225.43 0.95 190.17 0.95 190.17 67.66"/><polygon class="cls-1" points="295.46 67.66 295.46 0.95 289.07 0.95 289.07 55.94 247.54 0.95 242.29 0.95 242.29 67.66 248.77 67.66 248.77 12.67 290.31 67.66 295.46 67.66"/><polygon class="cls-1" points="354.92 0.95 309.28 0.95 309.28 7.34 328.9 7.34 328.9 67.66 335.29 67.66 335.29 7.34 354.92 7.34 354.92 0.95"/>
            <path class="cls-1" d="M414.57,16l4.57-4.86A34.4,34.4,0,0,0,412,5.21a33.92,33.92,0,0,0-8.73-3.84,37,37,0,0,0-21.06.33,34.11,34.11,0,0,0-9.44,4.78,33,33,0,0,0-7.34,7.35,33.86,33.86,0,0,0-4.79,9.44,36.67,36.67,0,0,0,0,22.07,33.86,33.86,0,0,0,4.79,9.44,33,33,0,0,0,7.34,7.35,34.11,34.11,0,0,0,9.44,4.78,36.5,36.5,0,0,0,11,1.7A37.41,37.41,0,0,0,404.09,67a34,34,0,0,0,16.68-11.36l-5.06-4.48a25.76,25.76,0,0,1-5.87,5.73,27.71,27.71,0,0,1-7.62,3.79,30.26,30.26,0,0,1-9,1.34,29,29,0,0,1-11-2.11,26.3,26.3,0,0,1-14.61-14.61,29.75,29.75,0,0,1,0-22A26.3,26.3,0,0,1,382.22,8.69a28.6,28.6,0,0,1,11-2.11,29.32,29.32,0,0,1,12.13,2.49A25.65,25.65,0,0,1,414.57,16Z"/>
            <path class="cls-1" d="M463.28,68.61A36.39,36.39,0,0,0,477,66,32.92,32.92,0,0,0,495.15,47.9a35.78,35.78,0,0,0,2.63-13.6,35.39,35.39,0,0,0-2.63-13.59A32.65,32.65,0,0,0,488,9.83,33,33,0,0,0,477,2.61,36.08,36.08,0,0,0,463.38,0a37.09,37.09,0,0,0-11.13,1.68,34.75,34.75,0,0,0-9.53,4.77,33.13,33.13,0,0,0-12.21,16.79,36.38,36.38,0,0,0,.92,24.66A33,33,0,0,0,449.61,66a36.37,36.37,0,0,0,13.67,2.62Zm0-6.58a29.07,29.07,0,0,1-11-2.09,26.33,26.33,0,0,1-14.66-14.58,29.28,29.28,0,0,1-2.11-11.06,28.89,28.89,0,0,1,2.13-11A26.44,26.44,0,0,1,452.31,8.67a30.36,30.36,0,0,1,22.14,0,26.7,26.7,0,0,1,8.81,5.8,26.29,26.29,0,0,1,5.83,8.78,28.7,28.7,0,0,1,2.11,11,28.9,28.9,0,0,1-2.12,11.06,26.54,26.54,0,0,1-14.71,14.58A29.35,29.35,0,0,1,463.28,62Z"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'PatientcoText',
    props: {
        color: String,
    },
    data: () => ({
        fillColor: '#10356b',
    }),
    created() {
        switch(this.color) {
            case 'white':
                this.fillColor = '#fff';
                break;
            case 'grayscale':
                this.fillColor = '#616161';
                break;
            default:
                break;
        }
    },
    computed: {
        svgStyle() {
            return 'fill: #fff';
        }
    }
};
</script>