<template>
    <div class="receipt-content" v-if="plan">

        <div class="block-segment">
            <ProviderLogo :provider-id="plan.provider.id" :alt-text="plan.provider.name"></ProviderLogo>

            <div class="receipt-payment-details">
                <div class="fs-block receipt-payer">{{plan.name}}</div>
                <div class="fs-block">{{$translate('labels.accountNumberArg', {number: plan.accountNumber}) }}</div>
                <div class="fs-block">{{plan.provider.name}}</div>
                <div class="fs-block">{{getMethodDescriptor(plan.paymentForm)}}</div>
            </div>
        </div>

        <div class="block-segment">

            <div class="receipt-payment-amount" v-html="callOutAmountStatus"></div>

            <receipt-line-items :isPaymentPlan="true" :lineItems="lineItems"></receipt-line-items>
		    <div class="info-subtle" v-html="$translate('planReceipt.messageProviderForQuestions')"></div>

        </div>
        <base-bottom-sheet v-model="savedPaymentMethodsBottomSheet">
            <saved-payment-methods-sheet
                :saved-methods="savedMethods"
                @toggle-saved-payment-methods="toggleSavedPaymentMethodsBottomSheet"
                @toggle-change-method-dialog="toggleChangeMethodDialog"
                @toggle-new-payment-method="toggleAddPaymentMethodDialog"
            ></saved-payment-methods-sheet>
        </base-bottom-sheet>
        <change-payment-method-dialog
            ref="changeMethodDialog"
            @confirm-update-payment-form="confirmUpdatePaymentForm"
        ></change-payment-method-dialog>
        <add-payment-method-dialog
            ref="addPaymentMethodDialog"
            @submit="submitNewMethod"
            :submitting="submittingNewMethod"
            :error="addPaymentMethodError"
        ></add-payment-method-dialog>
        <base-snackbar v-model="snackbar"><base-icon icon="mdi-check"></base-icon>{{$translate('receipt.methodChanged')}}</base-snackbar>
        <base-snackbar v-model="changePaymentMethodError" :isErrorMessage="true"><base-icon icon="mdi-alert-circle-outline"></base-icon>{{$translate('newPaymentFormFlow.genericError')}}</base-snackbar>
    </div>
</template>

<script>

import { CardUtils, getMethodDescriptor } from '../utils/utils';
import ProviderLogo from './ProviderLogo.vue';
import Notification from './Notification.vue';
import ReceiptLineItems from './ReceiptLineItems.vue';
import _ from 'lodash';
import SavedPaymentMethodsSheet from './SavedPaymentMethodsSheet.vue';
import ChangePaymentMethodDialog from './ChangePaymentMethodDialog.vue';
import AddPaymentMethodDialog from './AddPaymentMethodDialog.vue';
import BaseSnackbar from './BaseSnackbar.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'PlanReceiptContent',
    components: {
        ProviderLogo,
        Notification,
        ReceiptLineItems,
        SavedPaymentMethodsSheet,
        ChangePaymentMethodDialog,
        AddPaymentMethodDialog,
        BaseSnackbar,
        BaseBottomSheet,
        BaseIcon
    },
    computed: {
        plan() {
            return this.$store.getters.plan;
        },
        provider() {
            return this.$store.getters.planProvider;
        },
        paymentPossible() {
            return this.$store.getters.planPaymentPossible;
        },
        getPaymentStatus() {
            return this.$store.getters.getPaymentStatus;
        },
        FS() {
            return this.$store.getters.getFullstory;
        }
    },
    data() {
        return {
            lineItems: [],
            callOutAmountStatus: null,
            financingVendorInfo: null,
            showLinkableNotification: false,
            fetchPlanPromise: null,
            addPaymentMethodError: null,
            submittingNewMethod: false,
            savedMethods: [],
            savedPaymentMethodsBottomSheet: false,
            changePaymentMethodError: false,
            submittingNewMethod: false,
            snackbar: false,
        };
    },
    methods: {
        toggleChangeMethodDialog({ method }) {
            if (this.$refs && this.$refs.changeMethodDialog) {
                this.$refs.changeMethodDialog.open(method);
            }
        },
        toggleAddPaymentMethodDialog() {
            if (this.$refs && this.$refs.addPaymentMethodDialog) {
                this.$refs.addPaymentMethodDialog.open();
            }
        },
        toggleSavedPaymentMethodsBottomSheet() {
            if (this.savedPaymentMethodsBottomSheet) {
                this.savedPaymentMethodsBottomSheet = false;
            } else {
                this.savedPaymentMethodsBottomSheet = true;
            }
        },
        confirmUpdatePaymentForm({method}) {
            this.setupUpdatePaymentForm(method);
        },
        async setupUpdatePaymentForm(method) {
            this.$store.commit('setShowLoading', true);
            this.FS.event('payment-plan update-payment-form started');
            this.$store.dispatch('updatePaymentForm', {planId: this.getPlanId(), paymentFormId: method.id }).then(async () => {
                this.plan.paymentForm = method;
                this.$store.commit('setReceiptPlan', this.plan);
                this.setupUIVariables();
                await this.loadSavedMethods();
                this.$store.commit('setShowLoading', false);
                this.snackbar = true;
            }).catch((error) => {
                console.error('Error: ' + JSON.stringify(error));
                this.changePaymentMethodError = true;
                this.$store.commit('setShowLoading', false);
            });
        },
        getMethodDescriptor,
        /**
         * Load the current payment plan as specified by state parameters.
         * If the plan has already been loaded, skips reloading and uses the cached plan.
         * Otherwise, loads the plan from the payment-plans service and caches it into $scope.plan and _plan
         * Returns a promise which resolves with the payment plan.
         */
        loadPaymentPlan() {
            if (this.fetchPlanPromise) return this.fetchPlanPromise;
            return this.fetchPlanPromise = new Promise((resolve, reject) => {
                if (this.plan) {
                    resolve(this.plan);
                } else this.$store.dispatch('fetchPaymentPlanDetails', this.getPlanId()).then((plan) => {
                    this.usePlan(plan);
                    return resolve(this.plan);
                }).catch((error) => {
                    return reject(error);
                });
            });
        },
        usePlan(plan) {
            if(plan.paymentForm !== null && plan.paymentForm.formType.toLowerCase() === 'card' && !plan.paymentForm.cardType){
                plan.paymentForm.cardType = CardUtils.getIssuer(plan.paymentForm.first6Digits);
            }
            this.$store.commit('setReceiptPlan', plan);
        },
        setupUIVariables() {

            if (!this.plan.terminalState) {
                this.callOutAmountStatus = this.$translate('receipt.argRemainingCallOut', { amount: this.$formatCurrency(this.plan.remainingAmount) });
            }

            // set up lineItems for table summary

            var lineItems = [];

            if (this.plan.history) {
                lineItems.push({
                    date: this.plan.history.dateCreated,
                    status: '',
                    amountDialog: this.$formatCurrency(this.plan.history.originalTotal),
                    isUpdate: true,
                    friendlyDialog: this.$translate('planReceipt.paymentPlanCreated')
                });
                _.forEach(this.plan.history.planUpdates, (planUpdate) => {
                    lineItems.push({
                        date: planUpdate.dateUpdated,
                        status: '',
                        amountDialog: this.$formatCurrency(planUpdate.amount),
                        isUpdate: true,
                        friendlyDialog: this.$translate('planReceipt.paymentPlanUpdated')
                    });
                });
            }
            _.forEach(this.plan.payments, (payment) => {
                lineItems.push({
                    date: payment.date,
                    status: _.startCase(payment.status.toLowerCase()),
                    friendlyDialog: this.$translate('payment.friendlyType.' + payment.paymentType) 
                        + '<br /><span>' + (payment.transactionId ? this.$translate('receipt.transactionIdArg', {transactionId: payment.transactionId}) : '') + '</span>',
                    amountDialog: this.$formatCurrency(payment.amount)
                });
            });
            lineItems.sort((item1, item2) => {
                return new Date(item1.date) - new Date(item2.date);
            });
            this.lineItems = lineItems;
            
            this.$store.dispatch('getProviderById', this.plan.provider.id).then((data) => {
                var provider = data;
                this.$store.commit('setPlanReceiptProvider', provider);
            });

            this.$store.commit('setPlanReceiptPaymentPossible', this.getPaymentStatus('card') || this.getPaymentStatus('eCheck'));
        },
        getPlanId() {
            // if the planId is specified in the route, use that
            if (this.$route && this.$route.params && this.$route.params.planId) {
                return this.$route.params.planId;
            }
            // otherwise, grab it from the store
            return this.plan.id
        },
        async loadSavedMethods() {
            this.savedMethods = [];
            let allMethods = await this.$store.dispatch('fetchSavedMethods', { filterExpired: true });
            allMethods.forEach((method) => {
                if (method) {
                    // Exclude current saved payment method
                    if (this.plan.paymentForm && method.id !== this.plan.paymentForm.id) {
                        if ('card' === method.formType) {
                            if (!CardUtils.isExpired(method.expDate)) {
                                this.savedMethods.push(method);
                            }
                        } else if ('echeck' === method.formType) {
                            this.savedMethods.push(method);
                        }
                    }
                }
            });
        },
        async submitNewMethod({ newCard, newECheck }) {
            this.submittingNewMethod = true;
            this.addPaymentMethodError = null;
            try {
                let newPaymentFormId;
                if (newCard) {
                    newPaymentFormId = await this.$store.dispatch('submitPaymentForm', { paymentForm: { method: { card: newCard }, billing: newCard.billing } }).then(resp => {
                        return resp.paymentFormId;
                    });
                } else if (newECheck) {
                    newPaymentFormId = await this.$store.dispatch('submitPaymentForm', { paymentForm: { method: { eCheck: newECheck }, billing: newECheck.billing } }).then(resp => {
                        return resp.paymentFormId;
                    });
                }
                await this.loadSavedMethods();
                let method = this.savedMethods.find(method => {
                    return method.id == newPaymentFormId;
                });
                this.setupUpdatePaymentForm(method);
                if (this.$refs && this.$refs.addPaymentMethodDialog) {
                    // close the dialog after a success and force it to clear out its data
                    this.$refs.addPaymentMethodDialog.close(true);
                }
            } catch {
                this.addPaymentMethodError = this.$translate('newPaymentFormFlow.genericError');
            } finally {
                this.submittingNewMethod = false;
            }
        },
    },
    async mounted() {
        await this.$nextTick();
        // clear out store variables
        this.$store.commit('setReceiptPlan', null);
        this.$store.commit('setPlanReceiptProvider', null);
        this.$store.commit('setPlanReceiptPaymentPossible', false);
        // Use this once we're on vue-router, and move it to live inside of data instead of props
        // const planId = this.$route.params.planId;

        this.loadPaymentPlan().then(async (plan) => {
            if (!plan) {
                this.$router.push({name: 'ProvidersSummary'});
                return;
            }
            this.usePlan(plan);
            this.setupUIVariables();
            await this.loadSavedMethods();
        }).catch((err) => {
            // user is probably not allowed to see payment
            console.error(err);
            console.warn('Could not load plan with id ' + this.getPlanId());
            // retreat!
            this.$router.push({name: 'Home'});
        });

        this.emitter.on('planReceipt:updatePaymentForm', async () => {
            if (this.plan && !this.plan.terminalState && this.plan.remainingAmount > 0 && this.paymentPossible) {
                await this.loadSavedMethods();
                if (this.savedMethods.length) {
                    this.toggleSavedPaymentMethodsBottomSheet();
                } else {
                    this.toggleAddPaymentMethodDialog();
                }
            }
        });
    },
    destroyed() {
        // clear out store variables
        this.$store.commit('setReceiptPlan', null);
        this.$store.commit('setPlanReceiptProvider', null);
        this.$store.commit('setPlanReceiptPaymentPossible', false);
    },
};
</script>