<template>
    <div class="vuetify estimate-sub-account-card">
        <content-card clickable padded>
            <div class="data-grid">
                <div class="row">
                    <div class="col-left" v-if="appointmentDate">
                        <div class="appointment-date fs-block" :class="{ 'void-date': isVoid }">{{ $translate('labels.upcoming') }} {{ $formatDate(appointmentDate, 'MM/DD/YYYY') }}</div>
                    </div>
                    <div class="col-right">
                        <div v-if="isPaymentPlan && !isVoid" class="payment-plan-icon">
                            <base-icon color="#1E8914" icon="mdi-cached"></base-icon>
                        </div>
                        <div v-if="isVoid" class="void">
                            {{ $translate('statusChips.void') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-grid">
                <div class="row">
                    <div class="col-left">
                        <div v-if="patientName" class="patient-name fs-block">{{ patientName }}</div>
                        <div class="provider-location">{{ providerLocation }}</div>
                    </div>
                    <div class="col-right">
                        <div class="amount-due">
                            {{ $formatCurrency(amountDue) }}
                        </div>
                        <div class="estimated-remaining"> {{ $translate('labels.estimatedRemaining') }} </div>
                    </div>
                </div>
            </div>
        </content-card>
    </div>
</template>

<script>
import SubAccount from './../models/SubAccount';
import ContentCard from './ContentCard.vue';
import BaseIcon from './BaseIcon.vue';

export default ({
    name: 'EstimateSubAccountCard',
    components: {
        ContentCard,
        BaseIcon
    },
    props: {
        subAccount: SubAccount,
        status: String,
        void: Boolean
    },
    computed: {
        appointmentDate() {
            if (this.subAccount) {
                return this.subAccount.serviceDate;
            }
            return null;
        },
        patientName() {
            if (this.subAccount) {
                return this.subAccount.patientName;
            }
            return null;
        },
        providerLocation() {
            if (this.subAccount) {
                return this.subAccount.facilityName;
            }
            return null;
        },
        amountDue() {
            if (this.subAccount) {
                return this.subAccount.amountDue;
            }
            return null;
        },
        isPaymentPlan() {
            return this.status == "PAYMENT_PLAN";
        },
        isVoid() {
            if (this.void) {
                return this.void;
            }
            return null;
        }
    },
});
</script>
<style lang="scss">
@import './../styles/variables.scss';
.estimate-sub-account-card {
    
    .content-card {
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
    }

    .patient-name {
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.05rem;
        color: $global-color-font-new;
        font-weight: 500;

        @extend .two-line-then-ellipsis;

        @include wider-than-tablet {
            font-size: 2rem;
            line-height: 2.4rem;
            padding-left: 2.2rem;
        }
    }

    .col-right {
        display: flex;
        flex-direction: column;

        @include wider-than-tablet {
            padding-right: 2.2rem;
        }
    }

    .amount-due {
        font-size: 1.6rem; // mobile font
        line-height: 1.6rem;
        letter-spacing: 0.05rem;
        font-weight: 500;
        color: $global-color-font-new;

        @include wider-than-tablet {
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }

    .provider-location {
        font-size: 1.2rem; // mobile font
        line-height: 1.6rem;
        letter-spacing: 0.25px;
        color: $global-color-font-new;
        padding-top: 0.5rem;

        @extend .two-line-then-ellipsis;

        @include wider-than-tablet {
            font-size: 1.6rem;
            line-height: 2rem;
            padding-top: 0.5rem;
            padding-left: 2.2rem;
        }
    }

    .appointment-date {
        font-size: 1.2rem;
        line-height: 1.6rem;
        padding-bottom: 0.5rem;
        @include wider-than-tablet {
            margin-left: 2.2rem;
            padding-bottom: 0.5rem;
        }
    }
    
    .two-line-then-ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: unset;
    }
    .payment-plan-icon {
        svg {
            width: 1.8rem;
            @include wider-than-tablet {
                width: 2.4rem;
            }
        }
    }

    .estimated-remaining {
        font-size: 1rem;
        @include wider-than-tablet {
            font-size: 1.2rem;
        }
    }

    .void {
        text-transform: uppercase;
        color: #CE1818;
        font-size: 1rem;
        line-height: 1.6rem;
        letter-spacing: 0.33px;
        font-weight: 500;
        padding-top: 0.5rem;
        @include wider-than-tablet {
            font-size: 1.2rem;
            line-height: 2rem;
        }
    }

    .void-date {
        text-decoration: line-through;
    }
}
</style>
