<template>
    <base-expansion-panel :selected="selected" class="payment-flow-amount-step-installment-option-card vuetify">
        <template #title>
            <div v-if="currentPlan">{{ $translate('paymentFlow.amount.updatePlan.title') }}</div>
            <div v-else>{{ $translate('paymentFlow.amount.paymentPlan.title') }}</div>
        </template>
        <template #subtitle>
            <div v-if="currentPlan"> {{ $translate('paymentFlow.amount.updatePlan.subtitle') }}</div>
            <div v-else>{{ $translate('paymentFlow.amount.paymentPlan.subtitle') }}</div>
        </template>
        <template v-slot:expanded>
            <div  v-if="currentPlan" class="update-plan-card-header">
                <div>{{ $translate('paymentFlow.amount.updatePlan.yourCurrentPlan') }}</div>
                <div class="update-plan-block">
                    <div class="update-plan-block-header">{{ $translate('paymentFlow.amount.updatePlan.amountPerMonth', { formattedAmount: $formatCurrency(currentPlan.totalAmount / currentPlan.totalNumberOfPayments) }) }}</div>
                    <div class="update-plan-block-subheader">{{ $translate('paymentFlow.amount.updatePlan.numberOfPaymentsMade', { amountCompleted: currentPlan.totalNumberOfPayments - currentPlan.numberOfPaymentsRemaining, totalAmount: currentPlan.totalNumberOfPayments }) }}</div>
                </div>
                <div class="update-plan-card-header-prompt">{{ $translate('paymentFlow.amount.updatePlan.prompt') }}</div>
            </div>
             <div class="payment-flow-amount-step-installment-options">
                <base-radio-group v-model="chosenOption" :options="options"></base-radio-group>
            </div>
        </template>
    </base-expansion-panel>
</template>
<script>
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseRadioGroup from './BaseRadioGroup.vue';

export default {
    name: 'PaymentFlowAmountStepPaymentPlan',
    components: {
        BaseRadioGroup,
        BaseExpansionPanel,
    },
    data: () => ({
        chosenOption: null
    }),
    props: {
        selected: Boolean,
        options: Array,
        currentPlan: Object,
        modelValue: Number,
    },
    watch: {
        chosenOption() {
            this.$emit('update:modelValue', this.chosenOption);
        }
    },
    mounted() {
        this.chosenOption = this.modelValue || '';
    }
};
</script>