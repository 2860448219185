import http from './../../utils/http';
import _ from 'lodash';

function step1(formType) {
    function tryPrep(){
        return http.post(`paymentForms/prepGateway?formType=${formType}`);
    }

    window.FS.event('paymentForm gateway step1Start');

    return tryPrep().catch(function(resp){

        // if we did not have a network error,
        // pass through the error
        const isNetworkError = resp.status && !(resp.status >= 200 && resp.status < 400)
        if (!isNetworkError) {
            window.FS.log('error', 'paymentForm gateway step1Ended');
            throw resp;
        }

        // if we have a network error
        // we will retry it once
        console.error('Network error, could not prepGatway, trying again...');

        window.FS.event('paymentForm gateway step1Retry');

        return tryPrep().catch((resp) => {
            window.FS.log('error', 'paymentForm gateway step1Ended');
            throw resp;
        });
    });
};

function step3(ackDetails) {
    window.FS.event('paymentForm gateway step3Start');

    return http.post('paymentForms/confirm', ackDetails).catch(function(resp) {

        // if we did not have a network error,
        // or this is attempt 2+
        // pass through the error
        const isNetworkError = resp.status && !(resp.status >= 200 && resp.status < 400)
        if (!isNetworkError || state.acknowledgeResumeData !== null) {
            if (state.acknowledgeResumeData) {
                window.FS.log('error', 'payment gateway step3Resume');
            }
            window.FS.log('error', 'payment gateway step3Ended');
            throw resp;
        }

        console.error('Network error, could not send payment acknowledgement. Request for retry');

        window.FS.event('paymentForm gateway step3RetryRequested');

        state.acknowledgeResumeData = ackDetails;

        throw 'GATEWAY_RETRY_REQUESTED';
    });
};

const state = {
    acknowledgeResumeData: null,
};

const getters = {
    paymentFormsStep1Function() {
        return step1;
    },
    paymentFormsStep3Function() {
        return step3;
    },
};

const mutations = {
    setFormAcknowledgeResumeData(state, val) {
        state.acknowledgeResumeData = val;
    },
};

const actions = {
    paymentFormThreeStepRedirect({ commit, dispatch, state }, { paymentForm, resuming }) {
        const acknowledgeResumeData = state.acknowledgeResumeData;

        // if we are resuming we are saying that we want
        // to skip the step1 and step2 parts and
        // only complete step3
        if (resuming && acknowledgeResumeData !== null) {
            window.FS.event('paymentForm gateway step3ResumeStart');
            return step3(acknowledgeResumeData).then(() => {
                window.FS.log('log', 'success paymentForm gateway step3Resume');
            });
        }

        var formType = paymentForm.method.card ? 'card' : 'echeck';

        return step1(formType).then((resp) => {

            if (!resp || !resp.hasData()) {
                throw new Error('PrepGateway responded with no data');
            }

            window.FS.log('log', 'success paymentForm gateway step1Ended');
    
            return dispatch('paymentFormStep2', { prepResp: resp.getData(), paymentForm });
        }).then((gatewayRequestProperties) => {
            window.FS.log('log', 'success paymentForm gateway step2Ended');

            // the ack details can contain
            // gateway specific requirements
            // those should be returned from the gateway's
            // postToGateway promise resolver
            const ackDetails = _.assign({
                paymentForm: {}
            }, (gatewayRequestProperties || {}));

            if (paymentForm.method.card){
                ackDetails.paymentForm.method = 'card';
                ackDetails.paymentForm.first6Digits = paymentForm.method.card.number.toString().slice(0, 6);
                ackDetails.paymentForm.last4Digits = paymentForm.method.card.number.toString().slice(-4);
                ackDetails.paymentForm.exp = paymentForm.method.card.expiration;
            } else {
                ackDetails.paymentForm.method = 'echeck';
                // backend needs routing number so they don't have to parse
                // the gateway response
                ackDetails.paymentForm.routing = paymentForm.method.eCheck.routing;
            }

            return step3(ackDetails);
        }).then((resp) => {
            window.FS.log('log', 'success paymentForm gateway step3Ended');
            return resp.getData();
        });
    },
    paymentFormStep2({ dispatch }, { prepResp, paymentForm }) {

        function tryPostToGateway() {
            return dispatch('postToGateway', { gatewayDetails: prepResp, payment: paymentForm });
        }
    
        window.FS.event('paymentForm gateway step2Start');
    
        return tryPostToGateway().catch(function(resp){
    
            // if we did not have a network error,
            // pass through the error
            const isNetworkError = resp.status && !(resp.status >= 200 && resp.status < 400)
            if (!isNetworkError) {
                window.FS.log('error', 'paymentForm gateway step2Ended');
                throw resp;
            }
    
            console.error('Network error, could not post to gateway, trying again...');
    
            window.FS.event('paymentForm gateway step2Retry');
    
            return tryPostToGateway().catch((resp) => {
                window.FS.log('error', 'paymentForm gateway step2Ended');
                throw resp;
            });
        });
    },
    submitPaymentForm({ commit, dispatch }, { paymentForm, resuming }) {
        if (!paymentForm && !resuming) {
            throw new Error('Invalid paymentForm object');
        } 
        if (!resuming && (!paymentForm.method.card && !paymentForm.method.eCheck)){
            throw new Error('Unable to submit without a paymentForm.method');
        }

        // clear the previous attempt if it existed
        if (!resuming){
            commit('setFormAcknowledgeResumeData', null);
        }

        if (resuming){
            window.FS.event('paymentForm gateway resuming3Step');
        } else {
            window.FS.event('paymentForm gateway starting3Step');
        }

        // regarding acknowledge
        return dispatch('paymentFormThreeStepRedirect', { paymentForm, resuming });
    },
    resumePreviousPaymentFormAttempt({ dispatch, state }){
        if (!state.acknowledgeResumeData){
            throw new Error('UNKNOWN_ERROR');
        }
        return dispatch('submitPaymentForm', { paymentForm: null, resuming: true });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
