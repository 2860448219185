<template>
    <!-- If these aren't true, don't include the modal at all -->
    <div v-if="brandingConfig && brandingConfig.isChatEnabled && brandingConfig.chatClientId"> 
        <Modal :show="showModal" @close="resetModal()" class="composition-modal-container">
            <div class="compose-content">

                <div v-show="noProviders">
                    <h2 class="question" v-html="$translate('chat.paymentPlans.noProviders')"></h2>
                </div>

                <div v-show="askForProvider">
                    <h2 class="question">{{$translate('chat.paymentPlans.selectProvider')}}</h2>
                    <div class="compose-options-listing">
                        <button class="u-truncate" v-for="provider in providers" :key="provider.id" @click="selectProvider(provider.id)">
                            {{provider.name}}
                        </button>
                    </div>
                </div>

                <div v-show="askForAccount">
                    <h2 class="question">{{$translate('chat.paymentPlans.selectAccount')}}</h2>
                    <div class="compose-options-listing">
                        <button class="u-truncate fs-block" v-for="account in accounts" :key="account.accountId" @click="selectAccount(account.accountId)">
                            {{account.friendlyAccountNumber}} {{ account.friendlyAccountBalance }}
                        </button>
                    </div>
                </div>

            </div>
        </Modal>
        <div id="loyal-spacer"></div>
    </div>
</template>

<script>
import Modal from './Modal.vue';

import { generateDeferredPromise } from '../utils/utils';
import _ from 'lodash'

export default ({
    name: 'LoyalModal',
    components: {
        Modal,
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        currentBill() {
            return this.$store.getters.currentBill;
        },
        brandingConfig() {
            return this.$store.getters.brandingConfig;
        },
        clientId() {
            return this.brandingConfig ? this.brandingConfig.chatClientId : null;
        },
        reasonByCode() {
            return this.$store.getters.reasonByCode;
        },
        isMobile() {
            return /(phone|mobile)/i.test(navigator.userAgent);
        },
    },
    data() {
        return {
            minimizeButton: null,
            noProviders: false,
            askForProvider: false,
            askForAccount: false,
            showModal: false,
            selectedProvider: null,
            providerId: null,
            providers: [],
            accounts: [],
            secureCode: null,
        }
    },
    watch: {
        clientId() {
            this.resetModal();
            if (window.document.getElementById('guide-loader')) {
                this.resetWidget();
            } else {
                this.init();
            }
        },
    },
    methods: {
        updateUserInfo() {
            var user = this.currentUser;

            if(user) {
                window.GuideSDK.bot.setCustomVariables({
                    custom_first_name: user.firstName,
                    custom_last_name: user.lastName,
                    custom_phone_number: user.phoneNumberConfirmed ? user.phoneNumber : null,
                    custom_email_address: user.emailVerified ? user.email : null,
                    custom_patient_id: '' + user.patientUserId
                });
            }
        },
        init() {
            var guide = window.document.createElement('script');
            guide.id = 'guide-loader';
            guide.type = 'text/javascript';
            guide.async = true;
            guide.src = 'https://guide.loyalhealth.com/client/client.bundle.js';
            guide.dataset.id = 'guide-client-id';
            guide.dataset.value = this.clientId;
            var body = window.document.getElementsByTagName('body')[0];
            body.appendChild(guide);
        },
        resetWidget() {
            //remove elements added by Loyal
            var guide = window.document.getElementById('guide-loader');
            guide.parentNode.removeChild(guide);
            guide = window.document.getElementById('guide');
            guide.parentNode.removeChild(guide);

            //remove loaded SDK
            window.GuideSDK = undefined;

            //remove session variables storing chat info
            window.sessionStorage.removeItem('persist:root');
            window.sessionStorage.removeItem('conversationId');

            //reinitialize the widget
            this.init();
        },
        loadProviders() {
            this.$store.dispatch('getProviders').then((providers) => {
                this.noProviders = false;

                if (providers.length === 0) {
                    this.noProviders = true;
                    this.showModal = true;
                } else if (providers.length === 1) {
                    this.selectedProvider = providers[0];
                    this.providerId = providers[0].id;
                    this.getAccounts();
                } else {
                    this.providers = providers;
                    this.askForProvider = true;
                    this.showModal = true;
                }
            }).catch(() => {
                this.noProviders = true;
                this.showModal = true;
                console.error('Failed to get provider information');
            });
        },
        getAccounts() {
            if(this.selectedProvider === null){
                console.error('No providers to fetch payment plan options');
                return;
            }

            var accountsLen = this.selectedProvider.accounts.length;

            if(accountsLen === 1){
                this.secureCode = this.selectedProvider.accounts[0].secureCode;
                this.goToPaymentPlans();
            }else {
                _.forEach(this.selectedProvider.accounts, (acc) => {
                    acc.friendlyAccountBalance = this.$translate( 'labels.argBalance', {balance: this.$formatCurrency(acc.amountDue)} );
                });

                this.askForAccount = true;
                this.accounts = this.selectedProvider.accounts;
                this.showModal = true;
            }
        },
        goToPaymentPlans() {
            this.resetModal();
            //have to trigger a reload here in case they were already in the payment flow and chose a different account
            this.$router.go({
                name: 'AccountPaymentFlowAmountStep',
                params: { accountId: this.account.accountId, providerId: this.provider.id },
            });
        },
        assignMinimizeButton() {
            var guide = document.getElementById('guide');
            if(guide) {
                var shadowRoot = guide.shadowRoot;
                if(shadowRoot) {
                    var minimize = shadowRoot.querySelector('button[data-testref=header-right-button]');
                    if(minimize) {
                        this.minimizeButton = minimize;
                    }
                }
            }

            return null;
        },
        minimizeMobile() {
            if(this.isMobile) {
                if(!this.minimizeButton) {
                    this.assignMinimizeButton();
                }

                if(this.minimizeButton) {
                    this.minimizeButton.click();
                }
            }
        },

        selectProvider(id) {
            this.selectedProvider = _.find(this.providers, (provider) => {
                return provider.id === id;
            });
            this.askForProvider = false;
            this.getAccounts();
        },

        selectAccount(id){
            var selectedAccount = _.first(_.filter(this.selectedProvider.accounts || [], (account) => {
                return account.accountId === id;
            }));
            this.secureCode = selectedAccount.secureCode;

            this.goToPaymentPlans();
        },
        resetModal() {
            this.showModal = false;
            this.providers = [];
            this.selectedProvider = null;
            this.askForProvider = false;
            this.askForAccount = false;
        },
    },
    created() {
        this.resetModal();
        window.GuideSDKInit = () => {
            window.GuideSDK.on('conversationStart', (conversation) => {
                this.$store.commit('setIsChatSessionActive', true);
                this.assignMinimizeButton();

                this.$store.dispatch('postConversation', conversation.conversationId);
            });

            this.updateUserInfo();

            this.emitter.on('user:changed', () => {
                this.updateUserInfo();
            });

            this.emitter.on('data:clear', () => {
                this.resetWidget();
            });
            var guide = window.document.getElementById('guide');
            if (guide) {
                guide.classList.add('fs-exclude');
            }
        };

        this.emitter.on('loyalActions:logIn', () => {
            this.emitter.emit('contact:show', {subject: 'Logging In', state: 'zendesk'});
            this.minimizeMobile();
            return false;
        });

        this.emitter.on('loyalActions:forgotPassword', () => {
            var deferred = generateDeferredPromise();
            this.emitter.emit('login:showPrompt', {defer:deferred, options:{ openSection: 'forgotpassword' }});
            this.minimizeMobile();
            return false;
        });

        this.emitter.on('loyalActions:changePassword', () => {
            this.$router.push({ name: 'SettingsAccountManagement'});
            this.minimizeMobile();
            return false;
        });

        this.emitter.on('loyalActions:technicalIssue', () => {
            this.emitter.emit('contact:show', {subject: 'Live Chat Technical Issue', state: 'zendesk'});
            this.minimizeMobile();
            return false;
        });

        this.emitter.on('loyalActions:itemizedReceipt', () => {
            var data = {
                reason: this.reasonByCode('itemizedBill').id
            };

            var currentBill = this.currentBill;
            if (currentBill && Object.keys(currentBill).length) {
                data.useCurrentBill = true;
            }

            this.emitter.emit('message:compose', data);
            this.minimizeMobile();
            return false;
        });

        this.emitter.on('loyalActions:updateInformation', () => {
            var currentUser = this.currentUser;
            var data = {
                reason: this.reasonByCode('updateInsurance').id
            };

            if (currentUser !== null) {
                var currentBill = this.currentBill;
                if (currentBill !== null) {
                    data.useCurrentBill = true;
                }
                this.emitter.emit('message:compose', data);
            } else {
                data.state = 'bill';
                this.emitter.emit('contact:show', data);
            }
            this.minimizeMobile();
            return false;
        });

        this.emitter.on('loyalActions:paymentForms', () => {
            this.$router.push({ name: 'SettingsSavedPaymentMethods'});
            this.minimizeMobile();
            return false;
        });

        this.emitter.on('loyalActions:paymentPlans', () => {
            this.loadProviders();
            this.minimizeMobile();
            return false;
        });
    }
});
</script>
<style lang="scss">
@import '../styles/variables.scss';
#loyal-spacer {
    height: 8rem;
    background-color: $global-color-dark-grey;
}
</style>