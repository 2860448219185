<template>
    <base-expansion-panel :selected="selected" class="payment-flow-amount-step-amount-card vuetify">
        <template #title>
            {{ $translate('paymentFlow.amount.partialPaymentTitle') }}
        </template>
        <template #subtitle>
            {{ $translate('paymentFlow.amount.partialPaymentSubtitle') }}
        </template>
        <template v-slot:expanded>
            <div>
                <base-text-field 
                    @input="onInput"
                    @blur="onBlur"
                    @focus="blurred = false"
                    :rules="rules"
                    dense
                    prefix="$"
                    type="text"
                    required
                    filled
                    class="partial-amount-text-field"
                    min="0"
                    maxlength="20"
                    name="partialAmount"
                    ref="input"
                    inputmode="decimal"
                    v-model="partialAmount"
                > 
                </base-text-field>
                <div v-if="errorMessage" class="error-message">
                    <base-icon size="18" icon="mdi-alert-circle"></base-icon>
                    <span>{{ errorMessage }}</span>
                </div>
            </div>
        </template>
    </base-expansion-panel>
</template>
<script>
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseIcon from './BaseIcon.vue';
import BaseTextField from './BaseTextField.vue';
import { MoneyUtils } from './../utils/utils.js';

export default {
    name: 'PaymentFlowAmountStepAmountCard',
    components: {
        BaseTextField,
        BaseExpansionPanel,
        BaseIcon
    },
    props: {
        maxAmount: Number,
        minAmount: Number,
        selected: Boolean,
        modelValue: String,
    },
    data: () => ({
        blurred: false,
        partialAmount: null
    }),
    computed: {
        errorMessage() {
            // Styles for this error message don't play nice with normal vuetify error styling, so handle it separately instead of hacking
            if (this.blurred) {
                if (!Boolean(this.modelValue)) {
                    return this.$translate('errors.fieldRequired');
                }
                if (!(parseFloat(this.modelValue.replace(/[^\d\.]+/g, '')) >= this.minAmount)) {
                    return this.$translate('paymentFlow.amount.errors.amountTooLow', { minAmount: this.$formatCurrency(this.minAmount) });
                }
                if (!(parseFloat(this.modelValue.replace(/[^\d\.]+/g, '')) > 0)) {
                    return this.$translate('paymentFlow.amount.errors.amountNotPositive');
                }
                if (!(parseFloat(this.modelValue.replace(/[^\d\.]+/g, '')) <= this.maxAmount)) {
                    return this.$translate('paymentFlow.amount.errors.amountTooHigh');
                }
            }
            return null;
        },
        rules() {
            if (!this.selected) {
                // Return no rules while not selected to allow this component to be embedded in a v-form without additional overhead
                return [];
            }
            return [
                v => Boolean(v),
                v => Boolean(v) && parseFloat(v.replace(/[^\d\.]+/g, '')) >= this.minAmount,
                v => Boolean(v) && parseFloat(v.replace(/[^\d\.]+/g, '')) > 0,
                v => Boolean(v) && parseFloat(v.replace(/[^\d\.]+/g, '')) <= this.maxAmount,
            ];
        },
    },
    mounted() {
        this.partialAmount = this.modelValue || '';
    },
    methods: {
        onBlur() {
            this.blurred = true;
            this.partialAmount = MoneyUtils.onBlur(this.partialAmount);
            this.$emit('update:modelValue', this.partialAmount);
        },
        onInput() {
            this.partialAmount = MoneyUtils.onInput(this.partialAmount);
            this.$emit('update:modelValue', this.partialAmount);
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.vuetify.payment-flow-amount-step-amount-card {
    .v-text-field .v-field__field {
        max-height: 3.5rem;
        height: 3.5rem;
        min-height: unset !important;
        fieldset {
            margin: 0;
        }
    }
    fieldset {
        border: 0.01rem solid #70707066;
    }
    .partial-amount-text-field {
        max-height: 4rem;
        margin-left: 4rem;
        width: 21rem;
        input {
            border: none;
            background-color: transparent;
        }
    }
    .v-text-field__prefix {
        color: #707070;
    }
    .error-message {
        color: $global-color-warning;
        display: flex;
        font-size: 1.2rem;
        letter-spacing: 0.04rem;
        line-height: 1.6rem;
        margin-left: 4rem;
        max-width: 36rem;
        i.v-icon.base-icon {
            color: $global-color-warning !important;
            margin-right: 0.8rem;
        }
    }
    .error--text {
        fieldset {
            border: 0.15rem solid $global-color-warning;
        }
    }
}
</style>