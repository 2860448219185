<template>
    <div v-show="providers && providers.length && !messagingDisabled" class="block-segment" id="create-new-message">
        <button class="button-primary" @click="createNewMessage">
            {{ $translate('actions.createMessage') }}
        </button>
    </div>
</template>

<script>
    
    export default {
        name: 'MessagesLeftColumn',
        computed: {
            providers() {
                return this.$store.getters.providers;
            },
            messagingDisabled() {
                return this.$store.getters.messagingDisabled;
            }
        },
        methods: {
            createNewMessage() {
                this.emitter.emit('message:compose');
            },
        },
    };
</script>
