<template>
    <v-expansion-panel v-bind="$attrs" data-testid="radio-expansion-card" class="radio-expansion-card vuetify" :class="{ expanded: hasExpandedSlot && selected }">
        <v-card>
            <v-expansion-panel-title class="vuetify">
                <div class="radio-wrapper">
                    <v-checkbox v-model="isSelected" false-icon="mdi-radiobox-blank" true-icon="mdi-radiobox-marked" :color="$vuetify.theme.themes.light.colors.primary" :readonly="isSelected" role="radio" ref="radio"></v-checkbox>
                </div>
                <div>
                    <v-card-title><slot name="title"></slot></v-card-title>
                    <v-card-subtitle><slot name="subtitle"></slot></v-card-subtitle>
                    <v-card-text><slot name="text"></slot></v-card-text>
                </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text data-testid="radio-expansion-card-expanded-content" v-if="hasExpandedSlot" eager>
                <slot name="expanded"></slot>
            </v-expansion-panel-text>
        </v-card>
    </v-expansion-panel>
</template>

<script>
/**
 * Usage:
 * 
 * <base-expansion-panels v-model="panel">
 *      <BaseExpansionPanel
 *          v-for="(item,i) in items"
 *          :key="i"
 *          :selected="panel==i"
 *      >
 *          <h1>Expansion Card Header</h1>
 *          <template v-slot:expanded>
 *              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
 *              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim 
 *              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea 
 *              commodo consequat.
 *          </template>
 *      </BaseExpansionPanel>
 * </base-expansion-panels>
 * 
 * Description:
 * 
 * This special expansion card wraps the vuetify expansion panel component and adds a 
 * radio button to the card. The default slot is loaded into the expansion panel header 
 * and the "expanded" slot is loaded into the expansion panel content. This component 
 * accpets one prop "selected" that, when true, marks the radio button selected. The 
 * radio button is actually a checkbox disguised as a radio button bc an actual radio 
 * button require a radio button group to work properly. 
 */
export default {
    name: 'BaseExpansionPanel',
    props: {
        selected: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
            return {
                checked: false,
                isSelected: this.selected,
            }
        }
    ,
    watch: {
        selected() {
            this.isSelected = this.selected;
        },
    },
    computed: {
        hasExpandedSlot () {
            return Boolean(this.$slots['expanded']);
        },
    },
};
</script>

<style lang="scss">
@import '../styles/variables.scss';
.v-card-title:empty, .v-card-subtitle:empty, .v-card-text:empty, .v-card-actions:empty, div.default:empty {
    display:none
}
.vuetify {
     .radio-expansion-card.v-expansion-panel {
        margin-bottom: 1.5rem;
        margin-top: 0;
        padding: 0;
        background-color: transparent !important;
        color: $global-color-font-new;
        opacity: 1;

        .v-card-title {
            color: $global-color-font-new;
            padding-left: 0;
            font-size: 1.4rem;
            letter-spacing: 0.046rem;
            line-height: 1.6rem;
            padding-right: 0;
            opacity: 1;
            display: inline-block;
            @include wider-than-tablet {
                font-size: 1.6rem;
                letter-spacing: 0.014rem;
                line-height: 2.4rem;
            }
        }

        .v-card-subtitle {
            color: $global-color-font-new;
            padding-left: 0;
            font-size: 1.2rem;
            letter-spacing: 0.011rem;
            opacity: 1;
            line-height: 1.6rem;
            @include wider-than-tablet {
                font-size: 1.4rem;
                letter-spacing: 0.01rem;
                line-height: 2.4rem;
            }
        }

        .v-card {
            margin: 0 !important;
            box-shadow: 0px 2px 10px #00000029 !important;
            padding: 1.6rem 0;
        }

        &::before {
            box-shadow: none !important;
        }

        &:not(:first-child)::after {
            border-top: none !important;
        }

        .v-expansion-panel-text {
            .v-expansion-panel-text__wrapper {
                padding: 0.8rem 2.4rem;
            }
            .v-label {
                opacity: 1;
            }
        }

        button.v-expansion-panel-title {
            text-align: left;
            padding: 0 2.4rem;

            .radio-wrapper {
                flex: 0 0 4rem;

                .v-icon {
                    font-size: 2.4rem;
                }

                .v-selection-control__input {
                    margin-top: 1.7rem;
                    margin-left: -1.3rem;
                }

                .v-input--selection-controls {
                    margin-top: 12px;
                    padding-top: 0px;
                }

                .v-input--selection-controls__input {
                    height: 22px;
                    width: 22px;

                    @include desktop {
                        height: 24px;
                        width: 24px;
                    }
                }

                .v-input--selection-controls__ripple {
                    height: 32px;
                    width: 32px;
                    top: calc(50% - 23px);

                    @include desktop {
                        height: 34px;
                        width: 34px;
                        top: calc(50% - 24px);
                    }
                }
            }

            &.v-expansion-panel-title--active {
                cursor: default;
            }
            .v-expansion-panel-title__overlay {
                background: none;
            }
            .v-expansion-panel-title__icon i {
                display: none;
            }
        }

        .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
            margin-top: 0px;
        }

        &.expanded {
            .v-expansion-panel-title--active {
                padding-bottom: 0;
            }
        }
    }
}
</style>