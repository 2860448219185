<template>
<modal can-close :show="isVisible" @close="isVisible = false" class="notification-modal">
    <!-- LOADING -->
    <div class="loading-indicator hide-on-print" :class="{'js-visible': isLoadingIndicatorVisible}">
        <div class="loading-indicator-dialog">{{ $translate('labels.loading') }}</div>
    </div>
    <!-- ALL NOTIFICATIONS	 -->
    <div v-if="!currentNotification && !notificationUnavailable" class="modal-subcontent">
        <h2 class="modal-header h-style-2">{{ $translate('notification.plural') }}</h2>
        <div class="notifications" v-if="notifications.length">
            <div class="block-segment" v-for="(notification, i) in notifications" :key="i">
                <div class="block-segment-minimal-content" @click="viewDetails(i)">
                    <div>
                        <span v-if="notification.isRead" class="notification-read-indicator read"><base-icon icon="mdi-email-open"></base-icon></span>
                        <span v-else class="notification-read-indicator unread"><base-icon icon="mdi-email"></base-icon></span>
                        <span class="notification-subheader truncate">{{ notification.providerName }}</span>
                    </div>
                    <h3 class="notification-header truncate fs-block">{{ notification.subject }}</h3>
                    <div class="notification-subheader truncate">{{ notification.friendlyCreatedTime }}</div>
                </div>
                <div class="separator" v-if="notifications.indexOf(notification) != notifications.length - 1"></div>
            </div>
        </div>
        <div v-if="notifications.length == 0">
            <div class="block-segment-minimal-content">
            <div class="notification-header fs-block">{{ $translate('notification.zeroStateTitle') }}</div>
            </div>
        </div>
    </div>
    <!-- VIEW DETAILS -->
    <div v-if="currentNotification && !notificationUnavailable" class="notification-details">
        <div class="modal-subcontent">
            <div class="center-content-block">
                <provider-logo v-if="currentNotification.providerId" :provider-id="currentNotification.providerId" :alt-text="currentNotification.providerName" class="provider-logo"></provider-logo>
                <div v-if="currentNotification.providerId" class="modal-subheader">{{ currentNotification.providerName }}</div>
            </div>
            <div v-if="currentNotification.providerId" class="u-separator"></div>
            <div class="block-segment text-block">
                <div class="modal-text">
                    <div class="notification-subject fs-block">{{ currentNotification.subject }}</div>
                    <div class="notification-date">{{ currentNotification.friendlyCreatedTime }}</div>
                    <div class="notification-body fs-block">{{ currentNotification.body }}</div>
                </div>
            </div>
            <div class="block-segment">
                <button v-for="(link, i ) in currentNotification.links" tabindex="1" class="button" :key="i"
                @click="clickLink(link)">{{ link.text }}</button>
                <button v-if="currentNotification.providerId && canMessage" tabindex="2" class="button" @click="messageProvider()">
                    {{ $translate('actions.messageProvider') }}
                </button>
                <button v-if="!isJustOneNotification" tabindex="3" type="button" class="button button-primary" @click="viewAll()">
                    {{ $translate('actions.back') }}
                </button>
            </div>
        </div>
    </div>
    <!-- NOTIFICATION UNAVAILBLE -->
    <div v-if="notificationUnavailable" class="notification-details">
        <div class="modal-subcontent">
            <div class="block-segment text-block">
                <div class="notification-header fs-block">
                    {{ $translate('notification.noLongerAvailable') }}
                </div>
            </div>
            <div class="block-segment">
                <button tabindex="3" type="button" class="button button-primary" @click="viewAll()" data-testid="notifications-unavailable-back">
                    {{ $translate('actions.back') }}
                </button>
            </div>
        </div>
    </div>
</modal>
</template>
<script>
import _ from 'lodash';

import Modal from './Modal.vue';
import ProviderLogo from './ProviderLogo.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'NotificationsModal',
    components: {
        Modal,
        ProviderLogo,
        BaseIcon
    },
    data: () => ({
        isVisible: false,
        currentNotification: null,
        notificationUnavailable: false,
        notifications: [],
        isJustOneNotification: false,
        canMessage: false,
        loadingKeyPromise: null,
    }),
    computed: {
        isLoadingIndicatorVisible() {
            return this.$store.getters.isLoadingIndicatorVisible;
        },
        providers() {
            return this.$store.getters.providers;
        }
    },
    methods: {
        viewDetails(id) {
            this.currentNotification = this.notifications[id];
            this.checkCanMessage(this.currentNotification.providerId);
            
            if (!this.currentNotification.isRead) {
                this.currentNotification.isRead = true;
                this.$store.dispatch('setNotificationsSeen');
                this.$store.dispatch('updateNotificationReadLog', { notificationId: this.currentNotification.id, isRead: true });
            }
        },
        clickLink(link) {
            this.$store.dispatch('updateNotificationClickLog', {notificationId:link.id});
            if (link.relativeUrl) {
                this.$router.push({
                    path: link.relativeUrl,
                });
                this.isVisible = false;
            } else {
                window.open(link.url, '_blank');
            }
        },
        messageProvider() {
            this.$store.dispatch('getProviderById', this.currentNotification.providerId).then((resp) => {
                var secureCode = _.head(resp.accounts).secureCode;
                this.$router.push({
                    name: 'Messages',
                    query: {
                        'autofill': true,
                        'data': JSON.stringify({
                            'secureCode': secureCode,
                            'reason': 6 //the reasons are a static array. this is the "other" reason
                        }),
                    },
                });
                this.isVisible = false;
            });
        },
        viewAll() {
            this.notificationUnavailable = false;
            this.currentNotification = null;
        },
        checkCanMessage(id) {
            _.filter(this.providers, (provider) => {
                if(provider.id === id){
                    this.canMessage = provider.canMessage;
                    return;
                }  
            });
        },
        loadNotifications() {
            this.loadingKeyPromise = this.$store.dispatch('loadLoadingIndicator');
            return this.$store.dispatch('getNotifications').then((notifications) => {
                this.notifications = notifications;
                this.isJustOneNotification = notifications.length === 1;
                if (this.isJustOneNotification) {
                    this.viewDetails(0);
                } else {
                    this.viewAll();
                }
            }).finally(() => {
                // stop the loading
                this.loadingKeyPromise.then((key) => {
                    this.$store.dispatch('completeLoadingIndicator', key);
                });
            });
        },
    },
    created() {
        this.emitter.on('notifications:show', (id) => {
            this.loadNotifications().then(() => {
                this.isVisible = true;
                if (id) {
                    var relevantNotifications = this.notifications.filter((notification) => {
                        return id == notification.id;
                    });
                    if (relevantNotifications.length > 0) {
                        this.viewDetails(this.notifications.indexOf(relevantNotifications[0]));
                    } else {
                        this.notificationUnavailable = true;
                    }
                }
            });
        });
    },
};
</script>
<style lang="scss">
.notification-read-indicator {
    padding-right: 1rem;
}
</style>