<template>
    <Modal :show="visible" :canClose="canClose" @close="closeHandlerPrivate" :class="includeClasses + ' simple-modal ' + intent">
        <div>
            <div :class="iconClasses" v-html="intentIcon"></div>

            <!-- anything can be passed to these containers and
                adding fs-block markings to this for everything is
                easier than modifing bound content -->
            <h2 class="modal-header h-style-1 fs-block" v-html="header"></h2>
            <div class="modal-subcontent fs-block" v-html="subcontent"></div>

            <div class="modal-actions" v-if="actions.length > 0">
                <button v-for="(action, index) in actions" :key="index" @click="actionClick(action)" :class="(action.includeClasses ? action.includeClasses : '') + (actions.length === 1 ? ' modal-single-button' : '')">
                    {{action.label}}
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>

import Modal from './Modal.vue';
import _ from 'lodash';

export default {
    name: 'SimpleModal',
    components: {
        Modal,
    },
    data() {
        return {
            visible: false,
            header: '',
            subcontent: '',
            intent: 'default', // options: default | success | failure
            meta: '', // options: useArrowUp | useArrowDown
            includeClasses: '',
            closeHandler: function() {},
            autoExpire: false, // if true, 5 second timeout
            canClose: true,
            intentIcon: '',
            iconClass: '',
            actions: [],
        }
    },
    computed: {
        iconClasses() {
            return this.iconClass + (this.iconClass ? ' icon-padding' : '');
        },
    },
    methods: {
        closeModal() {
            this.visible = false;
            this.header = '';
            this.subcontent = '';
            this.intent = 'default', // options: default | success | failure
            this.meta = ''; // options: useArrowUp | useArrowDown
            this.includeClasses = '';
            this.closeHandler = function() {};
            this.autoExpire = false; // if true, 5 second timeout
            this.canClose = true;
            this.intentIcon = '';
            this.iconClass = '';
            this.actions = [];
        },
        setModal(promptProperties) {
            _.assign(this.$data, promptProperties);
            if (this.autoExpire) {
                setTimeout(() => {
                    this.closeModal();
                }, 5000);
            }

            if(this.intent === 'success'){

                this.iconClass = 'icon-check';

                this.intentIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="75px" height="75px">' +
                                            '<g fill="none" stroke="#fff" stroke-width="2">' +
                                            '<circle cx="36" cy="36" r="35" style=""></circle>' +
                                            '<path d="M17.417,37.778l9.93,9.909l25.444-25.393" style=""></path>' +
                                            '</g>'+
                                            '</svg>';
            } else if(this.intent === 'information') {
                if(this.meta === 'useArrowDown'){
                    this.intentIcon = '<svg version="1.1" width="75px" height="75px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 75 75" enable-background="new 0 0 75 75" xml:space="preserve"><path fill="#FFFFFF" d="M37.5,0.4C17,0.4,0.4,17,0.4,37.5C0.4,58,17,74.6,37.5,74.6C58,74.6,74.6,58,74.6,37.5   C74.6,17,58,0.4,37.5,0.4z M37.5,71.4c-18.7,0-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9c18.7,0,33.9,15.2,33.9,33.9  C71.4,56.2,56.2,71.4,37.5,71.4z"/><polygon fill="#FFFFFF" points="38.8,11.6 38.8,57.8 50.1,46.6 52.3,48.8 37.2,63.9 22.1,48.8 24.4,46.6 35.6,57.8 35.6,11.6 "/></svg>';
                } else if(this.meta === 'useArrowUp') {
                    this.intentIcon = '<svg version="1.1" width="75px" height="75px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 75 75" enable-background="new 0 0 75 75" xml:space="preserve"><path fill="#FFFFFF" d="M37.5,0.4C17,0.4,0.4,17,0.4,37.5C0.4,58,17,74.6,37.5,74.6C58,74.6,74.6,58,74.6,37.5   C74.6,17,58,0.4,37.5,0.4z M37.5,71.4c-18.7,0-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9c18.7,0,33.9,15.2,33.9,33.9  C71.4,56.2,56.2,71.4,37.5,71.4z"/><polygon fill="#FFFFFF" points="35.6,63.9 35.6,17.7 24.4,29 22.1,26.7 37.2,11.6 52.3,26.7 50.1,29 38.8,17.7 38.8,63.9 "/></svg>';
                } else if(this.meta === 'warning') {
                    this.intentIcon = '<svg data-icon="warning" data-containerTransform="translate(0 5)" data-width="null" data-height="null" xmlns="http://www.w3.org/2000/svg" version="1.1" width="128" height="128" viewBox="0 0 128 128">  <path d="M64 .781c-.259 0-.521.167-.719.531l-62.563 115.375c-.395.729-.047 1.313.781 1.313h125c.829 0 1.176-.585.781-1.313l-62.563-115.375c-.198-.364-.46-.531-.719-.531zm-4 49.219h8v18.156l-2 13.844h-4l-2-13.906v-18.094zm4 40c2.209 0 4 1.791 4 4s-1.791 4-4 4-4-1.791-4-4 1.791-4 4-4z"  transform="translate(0 5) " fill="#FFFFFF" /></svg>';
                }
            }
            this.visible = true;
        },
        actionClick(action) {
            if (action.clickHandler) {
                action.clickHandler();
            }
            this.closeHandlerPrivate();
        },
        closeHandlerPrivate() {
            this.closeHandler();
            this.closeModal();
        },
    },
    created() {
        this.emitter.on('simpleModal:showPrompt', this.setModal);

        this.emitter.on('simpleModal:hidePrompt', this.closeModal);
    },
};
</script>
<style lang="scss">

</style>