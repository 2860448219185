<template>
    <base-card>
        <div class="nodata-icon-container">
            <base-icon :size="iconSize" icon="mdi-file-document-outline"></base-icon>
        </div>
        <div class="data-title">
            {{ title }}
        </div>
        <div class="data-description">
            {{ description }}
        </div>
    </base-card>
</template>

<script>
import BaseIcon from './BaseIcon.vue';
import BaseCard from './BaseCard.vue';

export default {
    name: 'NoData',
    props: {
        title: String,
        description: String,
    },
    components: {
        BaseIcon,
        BaseCard
    },
    computed: {
        iconSize() {
            return this.$vuetify.display.lgAndUp ? '16rem' : '12.5rem';
        },
    }
};

</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
.v-card {
    margin: 0 1.6rem 1rem 1.6rem;
}

.data-title {
    color: $global-color-font-new;
    text-align: center;
    padding-bottom: 1.6rem;
    padding-top: 2.5rem;
    font-size: 2rem;
    @include wider-than-tablet {
        padding-bottom: 2.2rem;
        padding-top: 3.2rem;
        font-size: 2.5rem;
    }
}

.data-description {
    color: $global-color-darkish-grey;
    text-align: center;
    font-size: 1.6rem;
    padding-bottom: 7rem;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    letter-spacing: 0.05rem;
    @include wider-than-tablet {
        font-size: 1.6rem;
        padding-bottom: 13.6rem;
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

.nodata-icon-container {
    display: flex;
    justify-content: center;
    padding-top: 2.5rem;
    .v-icon {
        background: #E0E0E0;
        padding: 3.2rem;
        border-radius: 50%;
        color: #c2c2c2 !important;
    }
    @include wider-than-tablet {
        padding-top: 7.8rem;
        .v-icon {
            padding: 4rem;
        }
    }
}

</style>