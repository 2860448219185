<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="300" height="200" viewBox="0 0 300 200">
        <path d="M0 0 C106.92 0 213.84 0 324 0 C324 38.94 324 77.88 324 118 C217.08 118 110.16 118 0 118 C0 79.06 0 40.12 0 0 Z " fill="#FDFEFE" transform="translate(0,0)"/>
        <path d="M0 0 C2.74346178 2.02680237 4.41909458 3.76452036 5.42578125 7.0625 C6.0355765 14.78657315 1.55906344 19.73123595 -3.19140625 25.296875 C-10.38717015 33.03002746 -20.52607987 40.25319837 -31.2109375 41.9765625 C-33.4609375 41.5390625 -33.4609375 41.5390625 -35.2109375 40.9765625 C-34.16035156 40.491875 -33.10976563 40.0071875 -32.02734375 39.5078125 C-21.56610523 34.56127642 -12.04489829 29.42907559 -6.2109375 18.9765625 C-5.34987081 16.39336244 -4.99440131 14.64334626 -4.8984375 11.9765625 C-4.86105469 11.2959375 -4.82367188 10.6153125 -4.78515625 9.9140625 C-5.06124442 7.82167771 -5.06124442 7.82167771 -6.90234375 6.30078125 C-9.46227858 4.83239223 -11.55966771 4.28533233 -14.4609375 3.7890625 C-15.35296875 3.63050781 -16.245 3.47195312 -17.1640625 3.30859375 C-17.83953125 3.19902344 -18.515 3.08945312 -19.2109375 2.9765625 C-13.67754004 -1.41199411 -6.66804833 -3.08662308 0 0 Z " fill="#C0CA43" transform="translate(59.2109375,45.0234375)"/>
        <path d="M0 0 C-1.07378906 0.50789063 -2.14757813 1.01578125 -3.25390625 1.5390625 C-14.66077395 7.02030202 -25.26822157 12.42978494 -29.76953125 25.1796875 C-30.51911944 28.89260094 -30.19002547 30.70862761 -28.125 33.875 C-24.20239724 36.54236988 -20.64035853 37.32264919 -16 38 C-19.37550481 41.37550481 -22.79488643 41.23874948 -27.375 41.375 C-31.60511709 41.3184098 -33.58564726 41.23453928 -37.3125 39.125 C-39.75371297 34.60423524 -40.02813415 31.02059671 -39 26 C-33.78194163 13.87867375 -20.59463391 5.01355422 -8.82421875 0.08984375 C-5.49814532 -0.9856104 -3.29926076 -0.9670247 0 0 Z " fill="#BFC93F" transform="translate(55,36)"/>
        <path d="M0 0 C11 0 11 0 15 3 C15.5 5.9375 15.5 5.9375 15 9 C13 11.375 13 11.375 11 13 C12.45044271 17.10958767 14.51110752 20.43258745 17 24 C14.3125 24.4375 14.3125 24.4375 11 24 C8.95224699 21.88875077 7.46519774 19.54140493 6 17 C5.67 19.31 5.34 21.62 5 24 C3.35 24 1.7 24 0 24 C0 16.08 0 8.16 0 0 Z " fill="#14487B" transform="translate(148,45)"/>
        <path d="M0 0 C10 0 10 0 13 2 C13.45892595 5.51843231 13.41140736 7.34860502 11.5 10.375 C11.005 10.91125 10.51 11.4475 10 12 C11.32 12.66 12.64 13.32 14 14 C13.63768116 20.40096618 13.63768116 20.40096618 10.9375 22.9375 C8 24 8 24 0 24 C0 16.08 0 8.16 0 0 Z " fill="#2E5C8A" transform="translate(169,45)"/>
        <path d="M0 0 C1.98 0 3.96 0 6 0 C8.15537041 5.2852434 10.25359981 10.58999949 12.25 15.9375 C12.75273437 17.28134766 12.75273437 17.28134766 13.265625 18.65234375 C14 21 14 21 14 24 C12.35 24 10.7 24 9 24 C8.67 22.35 8.34 20.7 8 19 C5.03 19.33 2.06 19.66 -1 20 C-1.33 21.32 -1.66 22.64 -2 24 C-3.65 24 -5.3 24 -7 24 C-6.23672799 15.60400792 -3.1696329 7.76062585 0 0 Z " fill="#184B7D" transform="translate(131,45)"/>
        <path d="M0 0 C4.95 0 9.9 0 15 0 C15 1.32 15 2.64 15 4 C11.7 4 8.4 4 5 4 C5 5.98 5 7.96 5 10 C7.97 10 10.94 10 14 10 C14 11.32 14 12.64 14 14 C11.36 14 8.72 14 6 14 C6 15.65 6 17.3 6 19 C8.97 19 11.94 19 15 19 C15 20.65 15 22.3 15 24 C10.05 24 5.1 24 0 24 C0 16.08 0 8.16 0 0 Z " fill="#275685" transform="translate(107,45)"/>
        <path d="M0 0 C0.90105469 0.01804688 1.80210938 0.03609375 2.73046875 0.0546875 C3.41753906 0.07789063 4.10460937 0.10109375 4.8125 0.125 C4.8125 1.445 4.8125 2.765 4.8125 4.125 C3.53375 4.24875 2.255 4.3725 0.9375 4.5 C-2.53540936 4.81542399 -2.53540936 4.81542399 -5.1875 7.125 C-5.46840287 9.52991422 -5.46840287 9.52991422 -5.375 12.1875 C-5.36855469 13.08597656 -5.36210938 13.98445313 -5.35546875 14.91015625 C-5.43144743 17.09825599 -5.43144743 17.09825599 -4.1875 18.125 C-2.67280552 18.35692865 -1.14995438 18.53715238 0.375 18.6875 C1.20128906 18.77128906 2.02757813 18.85507812 2.87890625 18.94140625 C3.83603516 19.03228516 3.83603516 19.03228516 4.8125 19.125 C4.4825 20.775 4.1525 22.425 3.8125 24.125 C-5.32190731 24.7525547 -5.32190731 24.7525547 -8.6875 22.3125 C-12.1698049 17.23413869 -13.05307573 14.23617326 -12.1875 8.125 C-9.15730833 2.37808476 -6.56279644 -0.17046225 0 0 Z " fill="#1B4D7F" transform="translate(83.1875,44.875)"/>
        <path d="M0 0 C3 1 3 1 4.07495117 3.00952148 C4.39439697 3.87134033 4.71384277 4.73315918 5.04296875 5.62109375 C5.39423828 6.55630859 5.74550781 7.49152344 6.10742188 8.45507812 C6.64206055 9.93137695 6.64206055 9.93137695 7.1875 11.4375 C7.73954102 12.90799805 7.73954102 12.90799805 8.30273438 14.40820312 C11 21.67931064 11 21.67931064 11 24 C10.01 23.67 9.02 23.34 8 23 C7.505 20.525 7.505 20.525 7 18 C3.04 18 -0.92 18 -5 18 C-5.33 19.98 -5.66 21.96 -6 24 C-6.99 24 -7.98 24 -9 24 C-3.234375 3.234375 -3.234375 3.234375 0 0 Z " fill="#14477B" transform="translate(194,45)"/>
        <path d="M0 0 C3 1 3 1 4.07495117 3.00952148 C4.39439697 3.87134033 4.71384277 4.73315918 5.04296875 5.62109375 C5.39423828 6.55630859 5.74550781 7.49152344 6.10742188 8.45507812 C6.64206055 9.93137695 6.64206055 9.93137695 7.1875 11.4375 C7.73954102 12.90799805 7.73954102 12.90799805 8.30273438 14.40820312 C11 21.67931064 11 21.67931064 11 24 C10.01 23.67 9.02 23.34 8 23 C7.505 20.525 7.505 20.525 7 18 C3.04 18 -0.92 18 -5 18 C-5.66 19.98 -6.32 21.96 -7 24 C-7.66 24 -8.32 24 -9 24 C-3.234375 3.234375 -3.234375 3.234375 0 0 Z " fill="#35618D" transform="translate(231,45)"/>
        <path d="M0 0 C4 1 4 1 5.35620117 2.56640625 C5.74783447 3.22511719 6.13946777 3.88382813 6.54296875 4.5625 C6.97673828 5.27664062 7.41050781 5.99078125 7.85742188 6.7265625 C8.29634766 7.47679687 8.73527344 8.22703125 9.1875 9 C10.07604364 10.47963491 10.96532797 11.95882537 11.85546875 13.4375 C12.24371826 14.09621094 12.63196777 14.75492188 13.03198242 15.43359375 C13.90690018 16.98349568 13.90690018 16.98349568 15 18 C15.495 9.09 15.495 9.09 16 0 C16.66 0 17.32 0 18 0 C18 7.92 18 15.84 18 24 C17.01 24 16.02 24 15 24 C13.55786133 22.25585938 13.55786133 22.25585938 12.08203125 19.84375 C11.55029297 18.98652344 11.01855469 18.12929688 10.47070312 17.24609375 C9.92349609 16.33988281 9.37628906 15.43367188 8.8125 14.5 C8.25369141 13.59378906 7.69488281 12.68757813 7.11914062 11.75390625 C5.73645133 9.50851339 4.36698171 7.25498998 3 5 C2.67 11.27 2.34 17.54 2 24 C1.34 24 0.68 24 0 24 C0 16.08 0 8.16 0 0 Z " fill="#315E8B" transform="translate(246,45)"/>
        <path d="M0 0 C1.32 0 2.64 0 4 0 C4 5.94 4 11.88 4 18 C6.97 18 9.94 18 13 18 C13 19.65 13 21.3 13 23 C8.71 23 4.42 23 0 23 C0 15.41 0 7.82 0 0 Z " fill="#0D4277" transform="translate(92,46)"/>
        <path d="M0 0 C2.9375 0.75 2.9375 0.75 4.9375 2.75 C5.26765665 5.85347254 5.44281294 7.90781176 3.8125 10.625 C1.02643958 12.29663625 -0.88238862 12.21376624 -4.0625 11.75 C-6 10.125 -6 10.125 -7.0625 7.75 C-6.9375 4.625 -6.9375 4.625 -6.0625 1.75 C-3.0625 -0.25 -3.0625 -0.25 0 0 Z " fill="#C0CA41" transform="translate(68.0625,27.25)"/>
        <path d="M0 0 C4.29 0 8.58 0 13 0 C13 0.66 13 1.32 13 2 C9.37 2 5.74 2 2 2 C2 4.97 2 7.94 2 11 C5.3 11 8.6 11 12 11 C12 11.33 12 11.66 12 12 C8.7 12 5.4 12 2 12 C2 15.3 2 18.6 2 22 C5.63 22 9.26 22 13 22 C13 22.66 13 23.32 13 24 C8.71 24 4.42 24 0 24 C0 16.08 0 8.16 0 0 Z " fill="#36628E" transform="translate(292,45)"/>
        <path d="M0 0 C0.91007812 0.00902344 1.82015625 0.01804688 2.7578125 0.02734375 C3.45648438 0.03894531 4.15515625 0.05054688 4.875 0.0625 C5.205 0.7225 5.535 1.3825 5.875 2.0625 C-0.56 2.5575 -0.56 2.5575 -7.125 3.0625 C-8.13602038 6.09556113 -8.3232535 8.32190239 -8.375 11.5 C-8.40078125 12.44746094 -8.4265625 13.39492188 -8.453125 14.37109375 C-8.06295548 17.5714128 -7.51524482 18.89554769 -5.125 21.0625 C-2.3863775 21.54590432 -2.3863775 21.54590432 0.5625 21.3125 C1.55636719 21.27640625 2.55023438 21.2403125 3.57421875 21.203125 C4.33347656 21.15671875 5.09273438 21.1103125 5.875 21.0625 C5.875 21.7225 5.875 22.3825 5.875 23.0625 C2.16485899 24.29921367 -1.26938758 24.62618603 -5.125 24.0625 C-7.9350346 22.26886089 -9.63440468 21.04369065 -11.125 18.0625 C-12.06262008 7.65645414 -12.06262008 7.65645414 -9.25 2.875 C-5.99063142 0.09495033 -4.20797746 -0.05394843 0 0 Z " fill="#497199" transform="translate(281.125,44.9375)"/>
        <path d="M0 0 C5.41538462 -0.36923077 5.41538462 -0.36923077 8.375 1.5 C8.91125 1.995 9.4475 2.49 10 3 C9.25 7.75 9.25 7.75 7 10 C3.375 10.125 3.375 10.125 0 10 C0 6.7 0 3.4 0 0 Z " fill="#F3F5F8" transform="translate(171,57)"/>
        <path d="M0 0 C0.66 0 1.32 0 2 0 C2.6708712 1.6232687 3.33657029 3.24867578 4 4.875 C4.556875 6.23238281 4.556875 6.23238281 5.125 7.6171875 C6 10 6 10 6 12 C2.7 12 -0.6 12 -4 12 C-2.87204037 7.89100419 -1.66489182 3.92438786 0 0 Z " fill="#E7ECF1" transform="translate(231,49)"/>
        <path d="M0 0 C0.66 0 1.32 0 2 0 C2 7.26 2 14.52 2 22 C5.3 22 8.6 22 12 22 C12 22.66 12 23.32 12 24 C8.04 24 4.08 24 0 24 C0 16.08 0 8.16 0 0 Z " fill="#37628E" transform="translate(209,45)"/>
        <path d="M0 0 C6.15234375 -0.09765625 6.15234375 -0.09765625 8 0 C9 1 9 1 9.1875 3.4375 C9 6 9 6 7 8 C3.375 8.125 3.375 8.125 0 8 C0 5.36 0 2.72 0 0 Z " fill="#F7F9FA" transform="translate(171,47)"/>
        <path d="M0 0 C2.73370045 2.36092311 3.91097645 4.56538728 5 8 C5 9.32 5 10.64 5 12 C2.03 12 -0.94 12 -4 12 C-3.52309796 10.37433394 -3.04340334 8.74948689 -2.5625 7.125 C-2.29566406 6.22007813 -2.02882812 5.31515625 -1.75390625 4.3828125 C-1 2 -1 2 0 0 Z " fill="#E8EDF2" transform="translate(194,49)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C2 1.99 2 2.98 2 4 C-10.48519362 9.55580866 -10.48519362 9.55580866 -17 10 C-14.36363828 7.36363828 -11.25314824 6.04077956 -7.9375 4.4375 C-3.68646924 2.6219508 -3.68646924 2.6219508 0 0 Z " fill="#C6CF54" transform="translate(41,77)"/>
        <path d="M0 0 C2.38125464 2.38125464 3.00186241 4.33857567 4.125 7.5 C4.65480469 8.96953125 4.65480469 8.96953125 5.1953125 10.46875 C6 13 6 13 6 15 C5.01 14.67 4.02 14.34 3 14 C2.67 12.35 2.34 10.7 2 9 C-1.96 9 -5.92 9 -10 9 C-10 8.67 -10 8.34 -10 8 C-6.37 8 -2.74 8 1 8 C0.34 5.69 -0.32 3.38 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z " fill="#4D749B" transform="translate(236,54)"/>
        <path d="M0 0 C-2.28929053 1.17154293 -4.58118362 2.33738074 -6.875 3.5 C-7.52597656 3.83386719 -8.17695312 4.16773438 -8.84765625 4.51171875 C-13.7734375 7 -13.7734375 7 -16 7 C-16.33 6.01 -16.66 5.02 -17 4 C-15.58521865 3.35004703 -14.16808513 2.70521278 -12.75 2.0625 C-11.96109375 1.70285156 -11.1721875 1.34320313 -10.359375 0.97265625 C-6.46237429 -0.63388875 -4.08933174 -1.31442806 0 0 Z " fill="#C5CE52" transform="translate(55,36)"/>
        <path d="M0 0 C0.99 0.33 1.98 0.66 3 1 C2.71382812 1.56203125 2.42765625 2.1240625 2.1328125 2.703125 C0.98584069 5.02870923 -0.07839794 7.37770201 -1.125 9.75 C-1.47820312 10.54921875 -1.83140625 11.3484375 -2.1953125 12.171875 C-2.46085938 12.77515625 -2.72640625 13.3784375 -3 14 C-3.66 13.67 -4.32 13.34 -5 13 C-4.54554036 11.58208592 -4.08625056 10.16571954 -3.625 8.75 C-3.24214844 7.56664062 -3.24214844 7.56664062 -2.8515625 6.359375 C-2.04826333 4.13372042 -1.10288338 2.09068328 0 0 Z " fill="#4A7299" transform="translate(194,45)"/>
        <path d="M0 0 C3 1 3 1 4.25 3.3125 C5.0275526 6.09873015 4.98726527 7.33438378 4 10 C3.34 8.02 2.68 6.04 2 4 C1.34 4 0.68 4 0 4 C-0.33 5.32 -0.66 6.64 -1 8 C-1.625 5.6875 -1.625 5.6875 -2 3 C-1.34 2.01 -0.68 1.02 0 0 Z " fill="#3D6792" transform="translate(231,45)"/>
        <path d="M0 0 C2.95183101 2.95183101 3.08663988 5.95511947 4 10 C3.01 9.67 2.02 9.34 1 9 C0.67 7.35 0.34 5.7 0 4 C-3.96 4 -7.92 4 -12 4 C-12 3.67 -12 3.34 -12 3 C-8.04 3 -4.08 3 0 3 C0 2.01 0 1.02 0 0 Z " fill="#557A9F" transform="translate(201,59)"/>
        <path d="M0 0 C2.475 0.495 2.475 0.495 5 1 C4.67 2.65 4.34 4.3 4 6 C2.68 6.33 1.36 6.66 0 7 C0 4.69 0 2.38 0 0 Z " fill="#E7ECF1" transform="translate(153,49)"/>
        <path d="M0 0 C1.67542976 0.28604898 3.34385343 0.61781233 5 1 C4.67 2.65 4.34 4.3 4 6 C2.68 5.67 1.36 5.34 0 5 C-0.5625 3.0625 -0.5625 3.0625 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z " fill="#87A1BB" transform="translate(309,45)"/>
        <path d="M0 0 C0.99 0 1.98 0 3 0 C3 0.99 3 1.98 3 3 C2.34 3 1.68 3 1 3 C1 9.6 1 16.2 1 23 C0.67 23 0.34 23 0 23 C0 15.41 0 7.82 0 0 Z " fill="#295787" transform="translate(246,45)"/>
        <path d="M0 0 C0.66 0 1.32 0 2 0 C2.33 2.64 2.66 5.28 3 8 C1.35 8 -0.3 8 -2 8 C-1.34 5.36 -0.68 2.72 0 0 Z " fill="#CFD9E4" transform="translate(133,52)"/>
        <path d="M0 0 C0.66 0.99 1.32 1.98 2 3 C1.01 3.495 1.01 3.495 0 4 C-0.33 10.27 -0.66 16.54 -1 23 C-1.66 22.67 -2.32 22.34 -3 22 C-2.67 15.4 -2.34 8.8 -2 2 C-1.34 2 -0.68 2 0 2 C0 1.34 0 0.68 0 0 Z " fill="#3D6791" transform="translate(249,46)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-1.96 4.96 -5.92 8.92 -10 13 C-10 8.97698923 -8.67577112 7.95743123 -6 5 C-4.05056443 3.25501743 -2.04941287 1.63021478 0 0 Z " fill="#F5F6E3" transform="translate(39,43)"/>
        <path d="M0 0 C2.7330836 2.36039038 4.00684499 4.52395748 5 8 C4.625 10.8125 4.625 10.8125 4 13 C1.68917959 8.54341778 0 5.09639729 0 0 Z " fill="#38648F" transform="translate(196,48)"/>
        <path d="M0 0 C0.66 0.66 1.32 1.32 2 2 C-2.48074195 5.36055646 -5.60550707 5.27429625 -11 5 C-8.24433749 2.24433749 -5.78460367 2.54065767 -2 2 C-1.34 1.34 -0.68 0.68 0 0 Z " fill="#497198" transform="translate(180,64)"/>
        <path d="M0 0 C0.56203125 0.36867188 1.1240625 0.73734375 1.703125 1.1171875 C0.2913467 1.65112929 -1.12657744 2.16883854 -2.546875 2.6796875 C-3.33578125 2.96972656 -4.1246875 3.25976562 -4.9375 3.55859375 C-7.48790952 4.16241587 -8.85009729 3.9645807 -11.296875 3.1171875 C-9.88995893 2.38967962 -8.47081478 1.68578397 -7.046875 0.9921875 C-6.25796875 0.59773438 -5.4690625 0.20328125 -4.65625 -0.203125 C-2.296875 -0.8828125 -2.296875 -0.8828125 0 0 Z " fill="#CDD46A" transform="translate(35.296875,82.8828125)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.2884375 1.99 1.2884375 1.99 0.5625 3 C-1.41317854 6.7933028 -1.64027463 9.75524068 -2 14 C-2.33 14 -2.66 14 -3 14 C-3.08153933 12.41753293 -3.13943557 10.83383727 -3.1875 9.25 C-3.22230469 8.36828125 -3.25710938 7.4865625 -3.29296875 6.578125 C-2.94923684 3.55328417 -1.99772541 2.24416398 0 0 Z " fill="#235383" transform="translate(273,47)"/>
        <path d="M0 0 C-0.33 1.65 -0.66 3.3 -1 5 C-1.165 4.360625 -1.33 3.72125 -1.5 3.0625 C-2.781786 0.83880759 -2.781786 0.83880759 -4.9140625 0.51171875 C-6.93575155 0.2724022 -8.96841939 0.13106972 -11 0 C-11 -0.33 -11 -0.66 -11 -1 C-6.58937864 -2.0257259 -4.07369424 -2.03684712 0 0 Z " fill="#4A7199" transform="translate(182,47)"/>
        <path d="M0 0 C4.875 0.875 4.875 0.875 6 2 C6.04063832 3.66617115 6.042721 5.33388095 6 7 C5.01 7.495 5.01 7.495 4 8 C3.566875 6.576875 3.566875 6.576875 3.125 5.125 C2.23174891 1.94788422 2.23174891 1.94788422 0 0 Z " fill="#39648F" transform="translate(177,57)"/>
        <path d="M0 0 C-4.455 1.485 -4.455 1.485 -9 3 C-9.66 2.34 -10.32 1.68 -11 1 C-4.5 -1.125 -4.5 -1.125 0 0 Z " fill="#CED56C" transform="translate(55,36)"/>
        <path d="M0 0 C1.42944945 2.35438733 2.08661261 3.48032433 1.625 6.25 C1.41875 6.8275 1.2125 7.405 1 8 C0.01 8 -0.98 8 -2 8 C-1.34 5.36 -0.68 2.72 0 0 Z " fill="#476F97" transform="translate(187,61)"/>
        <path d="M0 0 C0.598125 0.20625 1.19625 0.4125 1.8125 0.625 C1.049375 1.0375 0.28625 1.45 -0.5 1.875 C-3.1241642 3.58375809 -4.49071976 5.03517753 -6.1875 7.625 C-5.875 4.75 -5.875 4.75 -5.1875 1.625 C-2.1875 -0.375 -2.1875 -0.375 0 0 Z " fill="#CBD365" transform="translate(67.1875,27.375)"/>
    </svg>
</template>


<script>
export default {
    name: 'ClearBalanceSVG'
};
</script>