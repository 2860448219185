<template>
<!-- Generator: Adobe Illustrator 17.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg aria-label="bill-secure-code-icon" version="1.1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 36.5 36.5" enable-background="new 0 0 36.5 36.5" xml:space="preserve">
<circle fill="#08A650" cx="18.2" cy="18.3" r="18"/>
<g>
	<path fill="#FFFFFF" d="M23.5,17.2l-11.7,0c0,1.8-0.1,4.1-0.2,6c-0.2,4.4,3.5,6.7,7.1,6.5c4.2-0.2,5-4.7,5-4.7S23.5,20.6,23.5,17.2
		"/>
	<path fill="#FFFFFF" d="M17.9,11.3l-5.1-0.1c-0.9,0.3-1,2-1,2s0,1.1,0,2.8l6.2,0L17.9,11.3z"/>
	<path fill="#FFFFFF" d="M23.5,16c0-1,0-1.8,0.1-2.2c0.2-1.9-1.4-2.4-1.4-2.4l-3,0c0,1.7,0.1,4,0.1,4.7L23.5,16z"/>
	<path fill="none" stroke="#FFFFFF" stroke-width="1.4236" stroke-miterlimit="10" d="M17.8,10.7C17.6,6,23.1,6,23.1,6"/>
</g>
</svg>
</template>

<script>
export default {
    name: 'BillScodeSVG'
};
</script>