<template>
<div class="vuetify">
    <div class="content-card-container">
        <base-card class="content-card" :class="{ clickable, padded }" :ripple="clickable">
            <template #title><card-header v-if="headerTitle" :icon="headerIcon" :title="headerTitle"></card-header></template>
            <template #text>
                <div class="content-card-content">
                    <slot></slot>
                </div>
            </template>
        </base-card>
    </div>
</div>
</template>
<script>
import CardHeader from './CardHeader.vue';
import BaseCard from './BaseCard.vue';

export default {
    name: 'ContentCard',
    components: {
        CardHeader,
        BaseCard
    },
    props: {
        headerIcon: String,
        headerTitle: String,
        clickable: {
            type: Boolean,
            default: false,
        },
        // Setting `padded` to true will apply a standardized amount of padding on the content (not the header) part of the card
        // designed to work in tandem with other ContentCards
        padded: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.vuetify {
    .content-card-container {
        padding: 0 1.6rem;
		@include wider-than-tablet {
			padding: 0 1rem;
		}
        .hide-ripple {
            display: none;
        }
    }
    .content-card {
        margin-bottom: 1rem;
        padding: 0;
        .v-card-title {
            padding: 0;
        }
        .v-card-text {
            padding: 0;
            font-size: inherit;
            line-height: inherit;
        }
        &.v-card {
            &.clickable {
                cursor: pointer;
                box-shadow: 0 4px 8px $global-color-main-grey;
                &:hover {
                    box-shadow: 0 6px 12px $global-color-main-grey;
                }
            }
            &:not(.clickable) {
                box-shadow: 0 1px 2px $global-color-main-grey;
            }
        }
        &.padded .content-card-content {
            padding: 0 1.6rem 1rem 1.6rem;
        }
        .content-card-content {            
            color: $global-color-font-new;

            // needed for IE11
            overflow: hidden;

            .data-grid .row {
                margin: 0;
            }
        }
    }
}

</style>