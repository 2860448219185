<template>
    <div>
        <div class="block-segment">
            <h1 class="call-out ">
                {{ calloutText }}
            </h1>
        </div>
        <div class="separator"></div>
    </div>
</template>
<script>
export default {
    name: 'MessagesLeftColumnPersist',
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        calloutText() {
            let usersKey;
            const userFirstName = this.currentUser ? this.currentUser.firstName : null;
            if (userFirstName) {
                usersKey = userFirstName.length <= 12 ? userFirstName.slice(-1) !== 's' ? 'message.argsMessages' : 'message.argsMessagesEndsWithS' : 'message.plural';
            } else {
                usersKey = 'message.plural';
            }
            return this.$translate(usersKey, { name: userFirstName });
        }
    },
};
</script>