<template>
    <div class="accounts-summary-account-card vuetify">
        <content-card clickable>
            <div class="card-content">
                <div>
                    <div v-if="patientName" class="patient-name fs-block">
                        {{ patientName }}
                    </div>
                    <div v-if="account.accountNumber" class="account-number"> 
                        {{ $translate('accountsSummaryAccountCard.accountNumber') }}{{ account.accountNumber.slice(-4) }}
                    </div>
                    <status-chip :account="account.getStatus() != 'ZERO_BALANCE' ? account : null"></status-chip>
                </div>
                <div class="amount-due" :class="{ 'grayed-out': shouldGrayOut }">
                    {{ account.hasPendingBalance ? '$-.--' : $formatCurrency(account.totalAmountDue) }}
                </div>
            </div>
        </content-card>
    </div>
</template>

<script>
import Account from './../models/Account';
import StatusChip from './StatusChip.vue';
import ContentCard from './ContentCard.vue';
export default {
    name: 'AccountsSummaryAccountCard',
    components: {
        StatusChip,
        ContentCard,
    },
    props: {
        account: Account,
    },
    computed: {
        singleBill() {
            return this.account.bills.length == 1;
        },
        patientName() {
            if (this.account.patientName) {
                return this.account.patientName;
            }
            if (this.account.guarantorNames && this.account.guarantorNames.length) {
                return this.account.guarantorNames[0];
            }
            return null;
        },
        shouldGrayOut() {
            return this.account.hasPendingBalance || !this.account.totalAmountDue || this.account.isBadDebt || !this.account.provider.active || this.account.totalAmountDue < 0;
        },
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';

.vuetify.accounts-summary-account-card {
    color: $global-color-font-new;
    .card-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.3rem;
        padding-bottom: 2rem;
        @include wider-than-tablet {
            padding-left: 3.8rem;
            padding-right: 3.8rem;
            padding-top: 2.3rem;
            padding-bottom: 2.5rem;
        }
    }
    .patient-name {
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 500;
        letter-spacing: 0.016rem;
        margin-bottom: 0.5rem;
        @include wider-than-tablet {
            font-size: 2.4rem;
            line-height: 3.2rem;
            letter-spacing: 0.018rem;
        }
    }
    .account-number {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.025rem;
        @include wider-than-tablet {
            font-size: 1.6rem;
            line-height: 2.4rem;
            letter-spacing: 0.014rem;
        }
    }
    .amount-due {
        font-size: 2.4rem;
        line-height: 2.4rem;
        font-weight: 500;
        letter-spacing: 0.018rem;
        @include wider-than-tablet {
            font-size: 3.4rem;
            line-height: 3.6rem;
            letter-spacing: 0;
        }
    }
    .v-chip {
        cursor: pointer;
        margin-top: 1rem;
    }
    .grayed-out {
        color: $global-color-greyed-out;
    }
}
</style>