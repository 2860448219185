<template><v-select v-bind="$attrs" :variant="variant" :hide-details="hideDetails" :return-object="returnObject" :item-title="title" class="base-select vuetify fs-block" :menu-props="{contentClass: 'base-select-overlay'}" :attach="true"></v-select></template>

<script>
export default {
    name: 'BaseSelect',
    props: {
        outlined: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: String,
            default: 'auto',
        },
        returnObject: {
            type: Boolean,
            default: true
        },
        itemText: String,
        itemTitle: String
    },
    computed: {
        variant() {
            if (this.outlined) {
                return 'outlined'
            }
            return 'filled';
        },
        title() {
            if (this.itemTitle) {
                return this.itemTitle;
            } else if (this.itemText) {
                return this.itemText;
            }
        }
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.base-select.v-select {
    .v-field {
        background: white;
    }
    .v-field__field {
        .v-field__input {
            .v-select__selection {
                height: 5rem;
                input {
                    display: inline;
                }
            }
        }
    }
    .v-field__append-inner {
        i {
            margin-top: -0.5rem;
        }

    }
}
.v-overlay__content.base-select-overlay .v-field, .v-list-item-title {
  font-size: 1.6rem !important;
}
</style>