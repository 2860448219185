<template>
    <div v-if="thread">
        <div class="block-segment">
            <div class="message-details-logo u-center">
                <ProviderLogo :provider-id="thread.providerId" :alt-text="thread.providerName"></ProviderLogo>
            </div>
        </div>
        <div class="separator"></div>
    </div>
</template>
<script>
import ProviderLogo from './ProviderLogo.vue'

export default {
    name: 'MessageDetailsLeftColumnPersist',
    components: {
        ProviderLogo,
    },
    computed: {
        thread() {
            return this.$store.getters.thread;
        }
    },
};
</script>