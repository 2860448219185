<template><v-textarea v-bind="$attrs" :hide-details="hideDetails" :width="width" :variant="variant" class="base-textarea vuetify fs-block"><slot></slot></v-textarea></template>

<script>
export default {
    name: 'BaseTextarea',
    props: {
        hideDetails: {
            type: String,
            default: 'auto',
        },
        width: {
            type: String,
            default: '500px'
        },
        outlined: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        variant() {
            if (this.outlined) {
                return 'outlined';
            }
            return 'filled';
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.v-text-field.v-textarea {
	.v-field {
		padding-bottom: 1px !important;
        max-height: 100rem !important;
        height: 13.5rem;
        .v-field__input {
            padding: 1.6rem;
            margin-top: 0;
        }
    }

    textarea {
        background: #FFFFFF;
        color: $global-color-font-new;
    }
}
</style>