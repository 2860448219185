import http from '../../utils/http';
import _ from 'lodash';

import vueEventTransmitter  from '../../utils/vueEventTransmitter';
import { formatDate } from './../../utils/utils.js';

const state = {
    fetched: false,
    numNew: null,
    notifications: [],
};

const getters = {
    numNew(state) {
        return state.numNew;
    }
};

const mutations = {
    setNotifications(state, val) {
        state.notifications = val;
    },
    setNotificationsFetched(state, val) {
        state.fetched = val;
    },
    setNotificationsNumNew(state, val) {
        state.numNew = val;
    }
};

const actions = {
    setNotificationsSeen({ commit, state }) {
        const newNotifications = state.notifications.filter(n => !n.isRead);
        commit('setNotificationsNumNew', newNotifications.length);
        vueEventTransmitter.emit('notifications:seen');
    },
    updateNotificationClickLog({}, { notificationId }) {
        const url = 'notifications/updateNotificationLinkClickLog';
        return http.post(url, {
            id: notificationId,
        });
    },
    updateNotificationReadLog({}, { notificationId, isRead }) {
        const url = 'notifications/updateNotificationReadLog';
        return http.post(url, {
            id: notificationId,
            isRead,
        });
    },
    fetchNotifications({ commit, state }) {
        commit('setNotificationsFetched', false);
        const url = 'notifications';
        return http.get(url).then((resp) => {
            if (resp.hasData()) {
                const notifData = resp.getData();
                const oldNotifications = state.notifications;
                commit('setNotifications', notifData.notifications);
                _.forEach(state.notifications, (notification) => {
                    notification.friendlyCreatedTime = formatDate(notification.timeCreated, 'MMM DD, YYYY - hh:mm A');
                });

                if (!_.isEmpty(state.notifications) && oldNotifications.length !== notifData.notifications.length) {
                    // only update this when we think that something has actually changed.  here's a situation that we'd like to avoid: 
                    //   - new acct + no notifications
                    //   - add a bill.  notifications get updated and the 'new' indicator gets set
                    //   - user loads notifications and indicator goes away
                    //   - user adds a bill from either a new provider (w/ no relevant notifications) or from the same provider.
                    //   - new indicator should NOT be set.  i.e. indicator status should be whatever is set within the controller.
                    commit('setNotificationsNumNew', notifData.numNew);
                }
            }

            return state.notifications;
        }).finally(() => {
            commit('setNotificationsFetched', true);
        });
    },
    getNotifications({ state, dispatch }, forceFetch) {
        if (!state.fetched || forceFetch) {
            return dispatch('fetchNotifications').then(() => {
                return state.notifications;
            });
        } else {
            return Promise.resolve(state.notifications);
        }
    },
};

export default {
    state, getters, mutations, actions,
};
