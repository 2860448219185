<template>
<v-data-table v-bind="$attrs" :headers="headers" :items="items" :items-per-page="100" :show-expand="showExpand" mobile-breakpoint="0" class="base-data-table">
    <!--TODO: figure out how to name the template slots so that they can be passed in-->
	<template #bottom></template>
</v-data-table>
</template>
<script>
export default {
    name: 'BaseDataTable',
    props: {
        headers: Array,
        items: Array,
        showExpand: Boolean,
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.vuetify {
	.v-table {
		background: transparent;
		font-size: 1.2rem;
		.v-table__wrapper {
			thead {
				font-size: 1.4rem;
				background: rgba(224, 224, 224, 0.7);
			}
			.v-data-table-header__content {
				justify-content: center;
			}
			.v-data-table__td {
				text-align: center;
				i {
					font-size: 3em;
				}
			}
		}
	}
}
.v-menu__content {
	z-index: 999 !important;
}
.v-input label.v-label:not(.v-label--active) {
	z-index: 998;
}
</style>
