<template>
    <div class="activity-list" v-if="computedList">
        <div v-for="activity in computedList" :key="activity.id">
            <ActivityListItem :activity="activity" :divider="true" :account="account"></ActivityListItem>
        </div>
        <base-btn v-if="limit" class="white activity-list-show-more" data-testid="activity-list-show-more" block @click="showMore" x-large>
            {{ $translate('labels.seeAll').toUpperCase() }}<base-icon icon="mdi-chevron-down"></base-icon>
        </base-btn>
    </div>
</template>

<script>
import ActivityListItem from './ActivityListItem.vue';

import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';

export default {
    name: 'ActivityList',
    components: {
        ActivityListItem,
        BaseIcon,
        BaseBtn
    },
    props: {
        activityList: Array,
        account: Object,
    },
    data() {
        return {
            limit: this.activityList.length > 5 ? 5 : null,
        }
    },
    computed: {
        computedList() {
            return this.limit ? this.activityList.slice(0, this.limit) : this.activityList;
        }
    },
    created() {
        this.emitter.on('accountDetailsActivity:collapseActivityList', () => {
            this.limit = this.activityList.length > 5 ? 5 : null;
        });
    },
    methods: {
        showMore() {
            this.limit = null;
        },
    }
};
</script>
<style lang="scss">
button.vuetify.white.activity-list-show-more.v-btn {
    background-color: white;
    .v-btn__content {
        font-size: 1.4rem ;
    }
}

</style>
