<template>
    <base-dialog class="delete-payment-method-dialog" v-model="isVisible" scrollable>
        <template #title>{{ $translate('dialogs.deletePaymentMethod') }}</template>
        <template #text>
            <div class="method-to-delete">
                <base-icon v-if="'Americanexpress' === method.cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                <base-icon v-else-if="'Discover' === method.cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                <base-icon v-else-if="'Mastercard' === method.cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                <base-icon v-else-if="'Visa' === method.cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                {{ getMethodDescriptor(method) }}
            </div>
            <div class="delete-method-disclaimer">
                {{ $translate('dialogs.removeCardContent') }}
            </div>
        </template>
        <template #actions>
            <base-btn class="expired-buttons cancel-btn" variant="text" @click="close()">{{ $translate('actions.cancel') }}</base-btn>
            <base-btn class="expired-buttons remove-btn v-button-primary" variant="text" @click="deleteMethod()">{{ $translate('linkedAccounts.disassociate') }}</base-btn>
        </template>
    </base-dialog>
</template>

<script>
import { getMethodDescriptor } from '../utils/utils';
import BaseBtn from './BaseBtn.vue';
import BaseDialog from './BaseDialog.vue';
import BaseIcon from './BaseIcon.vue';
export default {
    name: 'AddPaymentMethodDialog',
    components: {
        BaseBtn,
        BaseDialog,
        BaseIcon
    },
    data: () => ({
        isVisible: false,
        method: {}
    }),
    methods: {
        getMethodDescriptor,
        close() {
            this.isVisible = false;
        },
        open(method) {
            this.method = method;
            this.isVisible = true;
        },
        deleteMethod() {
            this.$store.dispatch('deleteSavedMethod', this.method.id).then(() => {
                this.$emit('remove-from-list', this.method);
                this.close();
            });
        }
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.delete-payment-method-dialog {
    .method-to-delete {
        display: flex;
        justify-content: center;
        font-weight: bold;
        padding-bottom: 1rem;

        .card-svg {
            margin-right: 1rem;
        }
    }
}
</style>