<template>
<div v-if="provider" class="vuetify need-help" data-testid="need-help">
    <div>
        <icon-data-list-item labelFirst
                v-if="provider.canMessage == true && !isGuestPay"
                icon="mdi-email-outline"
                :dataLink="$translate('actions.sendMessage')"
                @dataLinkClick="$emit('composemessage')"
                class="fs-block need-help-message-provider"
            ></icon-data-list-item>
        <icon-data-list-item labelFirst
                v-if="provider.billingPhoneNumber != null"
                icon="mdi-phone-outline"
                :label="additionalPhones.length?$translate('labels.primaryPhone'):null"
                :dataLink="provider.billingPhoneNumber"
                @dataLinkClick="openInBlank('tel:'+provider.billingPhoneNumber)"
                class="fs-block need-help-billing-phone-number"
            ></icon-data-list-item>
        <icon-data-list-item labelFirst
                v-for="(additionalPhone, key) in additionalPhones" :key="additionalPhone.label"
                icon="mdi-phone-outline"
                :label="additionalPhone.label"
                :dataLink="additionalPhone.number"
                @dataLinkClick="openInBlank('tel:'+additionalPhone.number)"
                :class="'fs-block need-help-additional-phone need-help-additional-phone-'+key"
            ></icon-data-list-item>
        <icon-data-list-item labelFirst
                v-for="website in provider.websites" :key="website.name" :class="'fs-block need-help-' + website.type"
                :icon="mapIcon(website.type)"
                :dataLink="website.name"
                @dataLinkClick="openInBlank(website.url)"
            ></icon-data-list-item>
    </div>
</div>
</template>

<script>
import IconDataListItem from  './IconDataListItem.vue';

export default {
    name: 'NeedHelp',
    props: {
        provider: Object,
    },
    components: {
        IconDataListItem,
    },
    computed: {
        additionalPhones() {
            if (this.provider && this.provider.additionalPhones) {
                return this.provider.additionalPhones.sort((a,b) => a.order-b.order);
            }
            return [];
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
    },
    methods: {
        mapIcon(type) {
            var icons = {
                'wire-globe': 'mdi-web',
                'calendar': 'mdi-calendar-month-outline',
                'home': 'mdi-web',
                'clipboard': 'mdi-clipboard-text-outline',
                'document': 'mdi-file-document-outline',
                'dollar': 'mdi-charity',
                'heart': 'mdi-heart',
                'home': 'mdi-home',
                'people': 'mdi-account-group',
                'pill': 'mdiM-medical-bag',
                'pulse': 'mdi-pulse',
            }
            return icons[type];
        },
        openInBlank(url) {
            window.open(url, '_blank')
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.need-help-list-item {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-bottom: 1.6rem;
    .v-icon {
        margin-right: 2rem;
        margin-left: 0.8rem;
    }
    .need-help-list-item-value > a {
        font-size: 1.6rem;
        line-height: 1.8rem;
        letter-spacing: 0.5px;
        text-decoration: underline;
    }
}
</style>