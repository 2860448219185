<template>
<div class="vuetify">
    <div class="card-header" :class="{ 'self-contained': !inExpansionPanel }">
        <div class="filled-icon"><base-icon :icon="icon" size="x-large"></base-icon></div>
        <div class="card-title">{{ title }}</div>
    </div>
</div>
</template>
<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'CardHeader',
    props: {
        icon: String, // raw svg string
        title: String,
        inExpansionPanel: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BaseIcon
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.vuetify {
    .card-header {
        display: flex;
        align-items: center;
    }
    .filled-icon {
        background: #10356B;
        border-radius: 50%;
        padding: 0.9rem;
        margin-right: 1.6rem;
        width: 4rem;
        height: 4rem;
        i {
            color: white !important;
            font-size: 2.2rem;
            padding-bottom: 6px;
        }
    }

    .card-header.self-contained {
        padding: 1.6rem;
        text-align: left;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.15px;
        color: $global-color-font-new;
    }
}


</style>