<template>
    <div class="saved-payment-methods-sheet">
        <div class="saved-payment-methods-header">
            <div class="title">{{ $translate('actions.updatePaymentForm') }}</div>
            <div class="subtitle">{{ $translate('dialogs.chooseDifferentMethod') }}</div>
        </div>
        <base-card v-for="(method, index) in savedMethods" :key="index" @click="openConfirmDialog(method); close();" class="saved-payment-methods-card">
            <template #title>
                <div v-if="method.formType=='card'" class="saved-svg">
                    <base-icon v-if="'Americanexpress' === method.cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                    <base-icon v-else-if="'Discover' === method.cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                    <base-icon v-else-if="'Mastercard' === method.cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                    <base-icon v-else-if="'Visa' === method.cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                </div>
                <div>
                    <h1 class="saved-title">{{ getMethodDescriptor(method) }}</h1>
                    <h2 v-if="method.formType=='card'" class="exp-date">
                        {{ $translate('labels.expDate', { date: $formatDate(method.expDate, 'MM/YY', 'MMYY') }) }}
                    </h2>
                </div>
            </template>
        </base-card>
        <div class="saved-payment-methods-button">
            <base-btn class="cancel-btn add-payment-method" variant="text" @click="openNewPaymentMethod(); close();"><base-icon class="vuetify" icon="mdi-plus"></base-icon>{{ $translate('actions.addPaymentMethod') }}</base-btn>
        </div>
    </div>
</template>

<script>
import { getMethodDescriptor } from '../utils/utils';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseCard from './BaseCard.vue';

export default {
    name: 'SavedPaymentMethodsSheet',
    components: {
        BaseIcon,
        BaseBtn,
        BaseCard
    },
    props: {
        savedMethods: Array,
    },
    methods: {
        getMethodDescriptor,
        close() {
            this.$emit('toggle-saved-payment-methods');
        },
        openConfirmDialog(method = null) {
            this.$emit('toggle-change-method-dialog', { method: method });
        },
        openNewPaymentMethod() {
            this.$emit('toggle-new-payment-method');
        }
    }
};
</script>

<style lang="scss">
@import '../styles/variables.scss';
.saved-payment-methods-sheet {
    height: 100%;
    overflow-y: auto;
    padding-top: 0.9rem;
    padding-bottom: 1.8rem;
    .saved-payment-methods-header {
        .title {
            font-size: 2rem;
            line-height: 2.4rem;
            padding-bottom: 2rem;
            text-align: center;
            font-weight: 500;
        }
        .subtitle {
            font-size: 1.4rem;
            line-height: 2rem;
            padding-left: 1rem;
            padding-bottom: 1rem;
        }
    }
    .saved-payment-methods-button {
        padding: 0.5rem 0 9rem 0;
        .v-btn.v-btn {
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: $global-color-secondary;
        }
    }
    .saved-payment-methods-card {
        padding: 1.5rem 0;
        margin: 1rem 0;
        .saved-title {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.7rem;
        }
        .exp-date {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
        .saved-svg {
            padding-top: 5.75px;
            padding-right: 1rem;
        }
        .card-svg {
            max-width: 3rem;
            max-height: 1.9rem;
            margin: 0.25rem;
        }
    }
}
</style>