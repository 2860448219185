<template>
    <div v-if="showComponent" class="vuetify account-info" data-testid="account-info">
        <div>
            <icon-data-list-item 
                icon="mdi-account-outline"
                :label="$translate('linkedAccounts.billingName')"
                :data="accountInfo.patientName"
                v-if="accountInfo.patientName"
                class="fs-block"
            ></icon-data-list-item>
            <icon-data-list-item 
                icon="mdi-account-circle-outline"
                :label="$translate('labels.guarantorName')"
                :data="accountInfo.guarantorName"
                v-if="accountInfo.guarantorName"
                class="fs-block"
            ></icon-data-list-item>
            <icon-data-list-item 
                icon="mdi-folder-plus-outline"
                :label="$translate('labels.accountNumber')"
                :data="accountInfo.accountNumber"
                v-if="accountInfo.accountNumber"
            ></icon-data-list-item>
            <icon-data-list-item 
                icon="mdi-shield-check-outline"
                :label="$translate('labels.primaryInsuranceNoColon')"
                :data="accountInfo.primaryInsurance"
                v-if="accountInfo.primaryInsurance"
            ></icon-data-list-item>
        </div>
    </div>
</template>

<script>
    import IconDataListItem from  './IconDataListItem.vue';
    export default {
        name: 'AccountInfo',
        components: {
            IconDataListItem,
        },
        props: {
            accountInfo: Object,
        },
        computed: {
            showComponent() {
                return this.accountInfo && (this.accountInfo.patientName || this.accountInfo.guarantorName || this.accountInfo.accountNumber || this.accountInfo.primaryInsurance);
            }
        }
    };
</script>
<style lang="scss" scoped>
</style>