<template>
    <base-dialog v-model="isVisible" :fullscreen="!$vuetify.display.lgAndUp" scrollable content-class="carc-descriptions-dialog" @on-close="close">
        <template #title>{{ $translate('labels.claimReasonCodes') }}</template>
        <template #text>
            <div v-for="code in carcCodes" class="carc">
                <span class="carc-code">{{ code }}</span>
                <span class="carc-description">{{ $translate('carc.' + code) }}</span>
            </div>
        </template>
    </base-dialog>
</template>

<script>
import BaseDialog from './BaseDialog.vue';

export default {
    name: 'AddPaymentMethodDialog',
    components: {
        BaseDialog,
    },
    props: {
        carcCodes: Array,
    },
    data: () => ({
        isVisible: false,
    }),
    methods: {
        close() {
            this.isVisible = false;
        },
        open() {
            if (this.carcCodes?.length) {
                this.isVisible = true;
            }
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.carc-code {
    display: block;
    font-weight: bold;
}
.carc-description {
    display: block;
    margin-bottom: 3em;
}
</style>