<template>
<div class="settings-content">
    <div class="settings-saved-methods-section vuetify">
        <div class="saved-methods-title">{{ $translate('labels.savedMethods') }}</div>
        <div v-if="methodsFetched && paymentMethods().length">
            <base-divider></base-divider>
            <div class="saved-method" v-for="(method, index) in paymentMethods()" :key="'saved' + index">
                <div class="saved-payment-form">
                    <div class="saved-svg">
                        <base-icon v-if="'Americanexpress' === method.cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                        <base-icon v-else-if="'Discover' === method.cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                        <base-icon v-else-if="'Mastercard' === method.cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                        <base-icon v-else-if="'Visa' === method.cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                    </div>
                    <div class="saved-details">
                        <h1 class="saved-title">{{ getMethodDescriptor(method) }}</h1>
                        <h2 v-if="method.formType=='card' && !isExpired(method.expDate)" class="helper-text exp-date">
                            {{ $translate('labels.expDate', { date: $formatDate(method.expDate, 'MM/YY', 'MMYY') }) }}
                        </h2>
                        <h2 class="helper-text expired-card" v-if="method.formType=='card' && isExpired(method.expDate)">
                            <base-icon class="vuetify" size="16" icon="mdi-alert-circle-outline"></base-icon>
                            {{ $translate('labels.expired', { date: $formatDate(method.expDate, 'MM/YY', 'MMYY') }) }}
                        </h2>
                        <h2 class="helper-text scheduled-payment" v-if="method.hasScheduledPayment">
                            <base-icon class="vuetify" size="16" icon="mdi-alert-circle-outline"></base-icon>
                            <div v-html="$translate('setting.unremovableMethodHelp')"></div>
                        </h2>
                    </div>
                    <div class="saved-delete">
                        <base-btn class="delete-payment-method" variant="text" @click="deleteMethod(method)" :disabled="method.hasScheduledPayment">
                            {{ $translate('linkedAccounts.disassociate') }}
                        </base-btn>
                    </div>
                </div>
                <base-divider></base-divider>
            </div>
        </div>
        <div class="no-saved-methods" v-else>{{ $translate('labels.noSavedMethods') }}</div>
         <div class="saved-payment-methods-button">
            <base-btn class="add-payment-method" variant="text" @click="addNewMethod()"><base-icon class="vuetify" icon="mdi-plus"></base-icon>{{ $translate('actions.addPaymentMethod') }}</base-btn>
        </div>
    </div>

    <div class="settings-financing-methods-section vuetify" v-if="methodsFetched && financingMethods().length">
        <div class="financing-methods-title">{{ $translate('labels.financingMethods') }}</div>
        <base-divider></base-divider>
        <div class="financing-account" v-for="(method, index) in financingMethods()" :key="'financing' + index">
            <div class="saved-payment-form">
                <div class="saved-svg">
                    <base-icon v-if="method.vendor == 'Curae'" class="financing-svg" icon="custom:CuraeSVG"></base-icon>
                    <base-icon v-else-if="method.vendor == 'AccessOne'" class="financing-svg" icon="custom:AccessOneSVG"></base-icon>
                    <base-icon v-else-if="method.vendor == 'ClearBalance'" class="financing-svg" icon="custom:ClearBalanceSVG"></base-icon>
                </div>
                <div class="saved-details">
                    <h1 class="saved-title">{{ getMethodDescriptor(method, 'supershort') }}</h1>
                    <h2 class="helper-text account-number">
                        {{ $translate('labels.accountNumberArg', {number: method.accountNumber}) }}
                    </h2>
                    <div class="helper-text saved-financing-disclaimer">
                        <base-icon class="vuetify" size="16" icon="mdi-alert-circle-outline"></base-icon>
                        {{ $translate('payment.financing.paymentForm.disclaimer', {provider: method.provider}) }}
                    </div>
                </div>
                <div class="saved-delete">
                    <base-btn class="details-payment-method" variant="text" @click="moreInfo(method)">
                        {{ $translate('labels.details') }}
                    </base-btn>
                </div>
            </div>
            <base-divider></base-divider>
        </div>
    </div>

    <add-payment-method-dialog ref="addPaymentMethodDialog" @submit="submitNewMethod" :submitting="submittingNewMethod" :error="addPaymentMethodError"></add-payment-method-dialog>
    <delete-payment-method-dialog
        ref="deletePaymentMethodDialog"
        @removeFromList="removeFromList"
    ></delete-payment-method-dialog>
    <base-dialog v-model="detailsDialog" class="settings-more-info">
        <template #title>{{ this.$translate('payment.financing.paymentForm.infoHeader', detailsMethod) }}</template>
        <template #text><div v-html="$translate('payment.financing.paymentForm.infoContent', detailsMethod)"></div></template>
        <template #actions><base-btn class="close-btn v-button-primary" variant="text" @click="detailsDialog = false">{{ $translate('actions.close') }}</base-btn></template>
    </base-dialog>
</div>
</template>
<script>

import { getMethodDescriptor, CardUtils } from '../utils/utils';
import _ from 'lodash';
import AddPaymentMethodDialog from './AddPaymentMethodDialog.vue';
import DeletePaymentMethodDialog from './DeletePaymentMethodDialog.vue';
import BaseDivider from './BaseDivider.vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseDialog from './BaseDialog.vue';

export default {
    name: 'SettingsSavedPaymentMethodsContent',
    components: {
        AddPaymentMethodDialog,
        DeletePaymentMethodDialog,
        BaseDivider,
        BaseIcon,
        BaseBtn,
        BaseDialog
    },
    data: () => ({
        addPaymentMethodError: null,
        methodsFetched: false,
        submittingNewMethod: false,
        detailsMethod: {},
        detailsDialog: false,
    }),
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },
    methods: {
        getMethodDescriptor,
        isExpired: CardUtils.isExpired,
        deleteMethod(method) {
            if (!method.hasScheduledPayment && this.$refs && this.$refs.deletePaymentMethodDialog) {
                this.$refs.deletePaymentMethodDialog.open(method);
            }
        },
        removeFromList(method) {
            this.emitter.emit('simpleModal:showPrompt', {
                header: this.$translate('setting.argMethodDeleted', {method: getMethodDescriptor(method, 'long', '&nbsp;')}),
                intent: 'success',
                autoExpire: true,
            });
        },
        addNewMethod() {
            if (this.$refs && this.$refs.addPaymentMethodDialog) {
                this.$refs.addPaymentMethodDialog.open();
            }
        },
        async loadMethods() {
            this.methodsFetched = false;
            try {
                await this.$store.dispatch('fetchSavedMethods', {
                    filterExpired: false,
                    filterAcceptedMethods: false,
                });
            } finally {
                this.methodsFetched = true;
            }
        },
        moreInfo(method) {
            this.detailsMethod = method;
            this.detailsMethod.formattedAvailableCredit = this.$formatCurrency(method.availableCredit);
            this.detailsDialog = true;
        },
        paymentMethods() {
            const methods = this.currentUser.savedMethods;
            const types = ['card','echeck'];
            if(_.isUndefined(methods) || !_.isArray(methods)) {
                return [];
            }
            return _.filter(methods, function (method) {
                return types.indexOf(method.formType) > -1;
            });
        },
        financingMethods() {
            const methods = this.currentUser.savedMethods;
            const types = ['financing'];
            if(_.isUndefined(methods) || !_.isArray(methods)) {
                return [];
            }
            return _.filter(methods, function (method) {
                return types.indexOf(method.formType) > -1;
            });
        },
        async submitNewMethod({ newCard, newECheck }) {
            this.submittingNewMethod = true;
            this.addPaymentMethodError = null;
            try {
                if (newCard) {
                    await this.$store.dispatch('submitPaymentForm', { paymentForm: { method: { card: newCard }, billing: newCard.billing } });
                } else if (newECheck) {
                    await this.$store.dispatch('submitPaymentForm', { paymentForm: { method: { eCheck: newECheck }, billing: newECheck.billing } });
                }
                if (this.$refs && this.$refs.addPaymentMethodDialog) {
                    // close the dialog after a success and force it to clear out its data
                    this.$refs.addPaymentMethodDialog.close(true);
                }
                this.loadMethods();
            } catch {
                this.addPaymentMethodError = this.$translate('newPaymentFormFlow.genericError');
            } finally {
                this.submittingNewMethod = false;
            }
        }
    },
    mounted() {
        this.loadMethods();
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.settings-content {
    padding: 0 1.4rem;
    @include wider-than-tablet {
        padding: 0 2.4rem;
    }

    .saved-methods-title, .financing-methods-title {
        color: $global-color-font-new;
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.4rem;
        padding: 2.5rem 0;
        @include wider-than-tablet {
            font-size: 2.4rem;
        }
    }

    .saved-payment-form {
        padding: 2rem 0 2rem 1rem;
        @include wider-than-tablet {
            padding: 2rem 0 2rem 2rem;
        }
        display: flex;
        align-items: center;
        .saved-svg {
            flex: 1;
            padding: 1rem;
            @include wider-than-tablet {
                padding: 0;
                font-size: 2.4rem;
            }
        }
        .saved-details {
            flex: 10;
            .saved-title {
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 2.4rem;
            }
            .helper-text {
                font-size: 1.2rem;
                line-height: 1.6rem;
                display: flex;
                .base-icon {
                    margin-right: 0.8rem;
                    color: $global-color-warning !important;
                }
            }
        }
        .saved-delete {
            flex: 1;
            justify-content: center;
            .delete-payment-method {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $global-color-warning;
            }
            .details-payment-method {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $global-color-secondary;
            }
        }
    }

    .saved-payment-methods-button {
        padding: 2.5rem 0;
        .v-btn.v-btn {
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: $global-color-secondary;
        }
    }

    .settings-financing-methods-section {
        padding-bottom: 3rem;
    }
}
</style>