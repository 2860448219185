<template>
<div class="settings-content">

    <div v-if="canManageAccount" class="block-segment">
        <h2 class="call-out  settings-details-header settings-account-management-header">{{ $translate('labels.accountManagement') }}</h2>

        <notification class="settings-save-note notification-inline failure" v-show="invalidPassword">
            <base-icon icon="mdi-close-circle"></base-icon>
            {{ $translate('labels.invalidPassword') }}
        </notification>

        <div class="separator"></div>

        <div class="acct-mgmt-item">
            <span>{{ $translate('labels.changePassword') }}</span>
            <button class="button-sml settings-change-password" @click="changePassword()">{{ $translate('actions.changePassword') }}</button>

            <div class="separator"></div>
        </div>

        <div class="acct-mgmt-item">
            <span>{{ $translate('labels.deactivateAccount') }}</span>
            <button class="button-sml"
                @click="deactivate()" :disabled="hasScheduledPayment" data-testid="settings-deactivatebutton">{{ $translate('actions.deactivateAccount') }}</button>
            <div class="help-text" v-show="hasScheduledPayment"
                 v-html="$translate('setting.nonDeactivateableUserHelp')"></div>
            <div class="separator"></div>
        </div>
    </div>

    <div class="block-segment">
        <h2 class="call-out  settings-details-header settings-active-accounts-header">{{ $translate('linkedAccounts.activeAccounts') }}</h2>
        <div class="separator" id="clear"></div>
        <table id="linkedAccountsTable" v-if="linkedAccounts && linkedAccounts.length">
            <thead>
                <tr>
                    <th class="linkedAccDataTitle">{{ $translate('linkedAccounts.billingName') }}</th>
                    <th class="linkedAccDataTitle">{{ $translate('linkedAccounts.providerName') }}</th>
                    <th class="linkedAccDataTitle">{{ $translate('linkedAccounts.accountNum') }}</th>
                    <th></th>
                </tr>
            </thead>

            <tr v-for="(account, i) in linkedAccounts" class="eachAccount" :key="i">
                <td class="linkedAccData guarantor fs-block">{{account.guarantorName}}</td>
                <td class="linkedAccData providerName fs-block">{{account.providerName}}</td>
                <td class="linkedAccData accountNumber fs-block">{{account.accountNumber}}</td>
                
                <td>
                    <button
                        class="button-sml button-warn disassociateBtn"
                        :disabled="account.disassociateBtnIsActive === false"
                        :key="removeBtnKey"
                        :class="{disassociateBtn_failed: account.disassociateFailed}"
                        @click="submitDisassociate(account)">
                        {{ $translate('linkedAccounts.disassociate') }}
                    </button>
                    <div v-if="account.disassociateFailed">
                        <br/>
                        <div class="disassociateFailed">
                            {{ $translate('linkedAccounts.disassociateError') }} 
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <div v-if="linkedAccounts && !linkedAccounts.length" class="no-accounts h-style-6">{{ $translate('linkedAccounts.noAssociatedAcc') }}</div>
        
        <div class="h-style-6" v-if="!linkedAccounts">{{ $translate('labels.loading') }}</div>
    </div>

</div>
</template>
<script>

import Notification from './Notification.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'SettingsAccountManagementContent',
    components: {
        Notification,
        BaseIcon
    },
    data() {
      return {
        invalidPassword: false,
        linkedAccounts: null,
        removeBtnKey: 0,
        hasScheduledPayment: false
      };
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        brandingConfig() {
            return this.$store.getters.brandingConfig;
        },
        canManageAccount() {
            if (this.brandingConfig && this.brandingConfig.externalAccountManagement) {
                return false;
            }
            return true;
        }
    },
    methods: {
        changePassword() {
            this.emitter.emit('changePassword:prompt');
        },
        deactivate() {
            this.emitter.emit('confirmPassword:prompt', {
                'header': this.$translate('dialogs.deactivateAccountHeader'),            
                'subheader': this.$translate('dialogs.deactivateAccountWarning'),
                'additionalInfo': this.$translate('dialogs.deactivateAccountSubheader'),
                'callback': (currentPassword) => {
                    this.$store.dispatch('deactivateAccount', currentPassword)
                        .then(() => {
                            this.emitter.emit('simpleModal:showPrompt', {
                                header: this.$translate('dialogs.deactivateAccountSuccessHeader'),
                                subcontent: this.$translate('dialogs.deactivateAccountSuccessSubheader'),
                                intent: 'success',
                                closeHandler: () => {
                                    //wait until the user has seen feedback before logging them out forever
                                    this.$store.dispatch('unauthenticateUser', /*showModal*/ false);
                                }
                            });
                        })
                        .catch(() => {
                            this.invalidPassword = true;

                            window.setTimeout(() => {
                                this.invalidPassword = false;
                            }, 3000);
                        });
                }
            });
        },
        submitDisassociate(account) {
            this.emitter.emit('simpleModal:showPrompt', {
                header: this.$translate('linkedAccounts.confirmDisassociation'),
                subcontent: this.$translate('linkedAccounts.accountInfoLabel', account),
                includeClasses: 'settings-disassociate-method',
                actions: [{
                    label: this.$translate('linkedAccounts.disassociate'),
                    includeClasses: 'button-warn',
                    clickHandler: () => {
                        // when disabling the remove button, somehow the page does not reload to reflect that change
                        // so the solution is to use a key so that when the key changes the buttons all reload to 
                        // their correct enabled/disabled format
                        account.disassociateBtnIsActive = false;
                        this.removeBtnKey +=1;

                        // submit the request to server
                        this.$store.dispatch('disassociateAccount', account.accountId).then((resp) => {
                            const index = this.linkedAccounts.indexOf(account);
                            if(resp.success === true){
                                // show thank you
                                this.emitter.emit('simpleModal:showPrompt', {
                                    header: this.$translate('linkedAccounts.accountRemoved', account),
                                    subcontent: this.$translate('linkedAccounts.argMethodDisassociated',  account),
                                    intent: 'success',
                                    includeClasses: 'disassociate-success',
                                    autoExpire: true
                                });

                                // remove account
                                this.linkedAccounts.splice(index, 1);

                                // trigger new call to get account data and reset accounts and messages pulled in
                                this.$store.dispatch('syncProviders').catch(function() {
                                    console.error('failed to refresh providers on the settings page after successfully dissoaciating account');
                                });
                            } else {
                                account.disassociateBtnIsActive = true;
                                // resp.data.success === false, show error message
                                this.linkedAccounts[index].disassociateFailed = true;
                                this.$forceUpdate();
                            
                                window.setTimeout(() => {
                                    this.linkedAccounts[index].disassociateFailed  = false;
                                    this.$forceUpdate();
                                }, 6000);
                            }  
                       });
                    }
                }]
            });
        }
    },
    watch: {
        async currentUser() {
            if(this.currentUser) {
                this.hasScheduledPayment = this.currentUser.hasScheduledPayment;
            }
        },
    },
    mounted() {
        this.$store.dispatch('getProviders').then((providers) => {
            this.linkedAccounts = [];
            providers.forEach((provider) => {
                var accounts = provider.accounts;
                accounts.forEach((account) => {
                    account.providerName = provider.name;
                    account.guarantorName = (account.guarantorNames && account.guarantorNames.length) ? account.guarantorNames[0] : '';
                    account.disassociateBtnIsActive = true;
                });
                this.linkedAccounts = this.linkedAccounts.concat(accounts);
            });
            
        }).catch(function() {
            console.error('Failed to get providers from the settings page');
        });
    },
};
</script>