<template>
    <div class="icon-info-list-item">
        <base-icon :icon="icon"></base-icon>
        <div v-if="labelFirst">
            <div v-if="label" class="icon-info-list-item-label labelFirst">{{ label }}</div>
            <div v-if="data" class="icon-info-list-item-value">{{ data }}</div>
            <div v-if="dataLink" class="icon-info-list-item-value" @click="$emit('dataLinkClick')"><a>{{ dataLink }}</a></div>
        </div>
        <div v-else>
            <div v-if="data" class="icon-info-list-item-value">{{ data }}</div>
            <div v-if="dataLink" class="icon-info-list-item-value" @click="$emit('dataLinkClick')"><a>{{ dataLink }}</a></div>
            <div v-if="label" class="icon-info-list-item-label">{{ label }}</div>
        </div>
    </div>
</template>
<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'IconDataListItem',
    props: {
        icon: String, // raw svg string
        label: String,
        data: String,
        dataLink: String,
        labelFirst: { type: Boolean, default: false }
    },
    components: {
        BaseIcon
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.icon-info-list-item {
    display: flex;
    align-items: flex-start;
    line-height: 1;
    margin-bottom: 1.5rem;
    .v-icon {
        margin-right: 2rem;
        margin-left: 0.8rem;
        padding-top: 0.5rem;
    }
    .icon-info-list-item-value {
        font-size: 1.6rem;
        line-height: 1.8rem;
        letter-spacing: 0.5px;
        color: $global-color-font-new;
    }
    .icon-info-list-item-label {
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.25px;
        padding-top: 0.2rem;
        color: #707070;
    }
    .icon-info-list-item-label.labelFirst {
        font-weight: bold;
        color: $global-color-font-new;
    }
    a {
        font-size: 1.6rem;
        line-height: 1.8rem;
        letter-spacing: 0.5px;
        text-decoration: underline;
    }
}
</style>