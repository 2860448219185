import { createVuetify } from 'vuetify';
// Styles
import 'vuetify/styles';
import './../styles/app.scss';
// Fonts/Icons
import '@mdi/font/css/materialdesignicons.css';
import { mdi } from "vuetify/iconsets/mdi";
import { customSVGs } from './customSVGs';
// Directives
import * as directives from 'vuetify/directives';
// Components
import * as components from 'vuetify/components';


const light = {
    colors: {
        primary: '#10356B',
        secondary: '#10356B',
        accent: '#ffffff',
        anchor: '#1867C0',
    }
};

const dark = {
    colors: {
        primary: '#10356B',
        secondary: '#10356B',
        accent: '#ffffff',
        anchor: '#1867C0',
    }
};

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
      custom: customSVGs
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
        light,
        dark
    }
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 400,
      md: 550,
      lg: 875,
      xl: 1280,
    },
  }
});

export default vuetify;