<template>
    <base-dialog v-model="isVisible" content-class="change-payment-method-dialog">
        <template #title>{{ $translate('dialogs.updatePaymentFormConfirmHeader') }}</template>
        <template #text>
            <div class="confirm-method-content vuetify">
                <div v-if="method.cardType" class="saved-svg">
                    <base-icon v-if="'Americanexpress' === method.cardType" class="card-svg" icon="custom:AmexSVG"></base-icon>
                    <base-icon v-else-if="'Discover' === method.cardType" class="card-svg" icon="custom:MastercardSVG"></base-icon>
                    <base-icon v-else-if="'Mastercard' === method.cardType" class="card-svg" icon="custom:DiscoverSVG"></base-icon>
                    <base-icon v-else-if="'Visa' === method.cardType" class="card-svg" icon="custom:VisaSVG"></base-icon>
                </div>
                <div>
                    <h1 :class="{ 'saved-title': true, 'type-card': method.cardType}">{{ title }}</h1>
                </div>
            </div>
            <div class="confirm-text">{{ $translate('dialogs.confirmChangePaymentMethod') }}</div>
        </template>
        <template #actions>
            <base-btn class="cancel-btn" variant="text" @click="close()">{{ $translate('actions.cancel') }}</base-btn>
            <base-btn class="continue-btn v-button-primary" variant="text" @click="confirmUpdatePaymentForm();">{{ $translate('actions.change') }}</base-btn>
        </template>
    </base-dialog>
</template>

<script>
import { getMethodDescriptor } from '../utils/utils';
import BaseDialog from './BaseDialog.vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';

export default {
    name: 'ChangePaymentMethodDialog',
    components: {
        BaseBtn,
        BaseDialog,
        BaseIcon
    },
    data: () => ({
        isVisible: false,
        method: {},
        title: ""
    }),
    methods: {
        getMethodDescriptor,
        close() {
            this.isVisible = false;
        },
        open(method) {
            this.method = method;
            this.title = this.getMethodDescriptor(method);
            this.isVisible = true;
        },
        confirmUpdatePaymentForm() {
            this.isVisible = false;
            this.$emit('confirm-update-payment-form', { method: this.method });
        },
    }
};
</script>