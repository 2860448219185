<template>
    <div class="block-segment" v-if="payment">
        <button class="button-primary" @click="print();">
            {{$translate('actions.printReceipt')}}
        </button>
        <button class="button-primary" v-show="payment.billViewable && !isGuestPay" @click="viewBill();">
            {{$translate('actions.viewBill')}}
        </button>

        <button v-show="payment.cancelable && !isGuestPay" @click="cancelReceiptPayment();">
            {{$translate('actions.cancelPayment')}}
        </button>
    </div>
</template>

<script>


export default {
    name: 'ReceiptLeftColumn',
    computed: {
        payment() {
            return this.$store.getters.payment;
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        }
    },
    methods: {
        print() {
            try {
                window.print();
            } catch (error) {
                this.emitter.emit('simpleModal:showPrompt', {
                    header: this.$translate('receipt.printingNotSupportedHeader'),
                    subcontent: this.$translate('receipt.printingNotSupportedContent', {
                        viewBill: this.$translate('actions.viewBill')
                    }),
                    intent: 'information',
                    meta: 'warning'
                });
            }
        },
        viewBill() {
            if (this.payment) {
                this.$store.dispatch('openPdf', this.payment.secureCode);
            }
        },
        cancelReceiptPayment() {
            this.emitter.emit('receipt:cancelPayment');
        },
    },
};

</script>

<style lang="scss">

</style>