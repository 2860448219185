<template>
<div class="settings-left-column">
    <div>
        <h3 :class="{ current: 'SettingsContact' === route }" @click="goToState('SettingsContact')" data-testid="settings-contact" class="flow-link">
            <a>
                {{ $translate('setting.menu.contact') }}
            </a>
        </h3>
        <h3 :class="{ current: 'SettingsSavedPaymentMethods' === route }" @click="goToState('SettingsSavedPaymentMethods')" class="flow-link" data-testid="settings-savedpaymentmethods">
            <a>
                {{ $translate('setting.menu.savedPaymentMethods') }}
            </a>
        </h3>
        <h3 :class="{ current: 'SettingsAccountManagement' === route }" @click="goToState('SettingsAccountManagement')" class="flow-link" data-testid="settings-accountmanagement">
            <a>
                {{ $translate('setting.menu.accountManagement') }}
            </a>
        </h3>
    </div>
</div>
</template>
<script>

export default {
    name: 'SettingsLeftColumn',
    computed: {
        route() {
            return this.$route.name;
        }
    },
    methods: {
        goToState(state) {
            this.$router.push({name: state});
		},
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.settings-left-column {
    padding: 2rem 2.5rem;
    h3 {
        font-size: 1.8rem;
        font-weight: 500;
        padding: 1.5rem;
        &.current, &:hover {
            font-weight: 600;
            background: $global-color-light-grey;
        }
        a {
            color: $global-color-font-new;
        }
    }
}
</style>