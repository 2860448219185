<template>
<div class="payment-flow-access-one-step vuetify">
    <div class="financing-logo-container">
        <img class="financing-logo" src="assets/images/accessOneLogo.png" />
    </div>
    <div class="offer-title">
        <h1 v-if="accessOneFinancingOptions.length > 1">{{ $translate('paymentFlow.financing.accessOne.congratulationsMultiple') }}</h1>
        <h1 v-else-if="accessOneFinancingOptions.length == 1">{{ $translate('paymentFlow.financing.accessOne.congratulations') }}</h1>
    </div>
    <div v-if="financingError" class="financing-error">
        <base-icon :size="$vuetify.display.lgAndUp ? 24 : 20" icon="mdi-alert-circle"></base-icon>
        <div class="financing-error-message">{{ financingError }}</div>
    </div>
    <base-expansion-panels v-model="optionIndex" mandatory>
        <base-expansion-panel v-for="(option, index) in accessOneFinancingOptions" :selected="index === optionIndex" :key="index">
            <template #title>
                <div v-if="option.hasInterest">
                    {{ $translate('paymentFlow.financing.accessOne.amountPerMonthWithInterest', { formattedAmount: $formatCurrency(option.amount), interestRate: option.rate }) }}
                </div>
                <div v-else>
                    {{ $translate('paymentFlow.financing.accessOne.amountPerMonth', { formattedAmount: $formatCurrency(option.amount) }) }}
                </div>
            </template>
            <template #subtitle>
                {{ $translate('paymentFlow.financing.accessOne.length', { numberOfMonths: option.months }) }}
            </template>
        </base-expansion-panel>
    </base-expansion-panels>
    <div v-if="financingDisclaimers && financingDisclaimers.financingOptions" class="financing-disclaimer financing-options">
        {{ financingDisclaimers.financingOptions }}
    </div>
    <div v-if="financingDisclaimers && financingDisclaimers.interestBearing" class="financing-disclaimer interest-bearing">
        {{ financingDisclaimers.interestBearing }}
    </div>
    <div v-if="financingDisclaimers && financingDisclaimers.interestFree" class="financing-disclaimer interest-free">
        {{ financingDisclaimers.interestFree }}
    </div>
    <div class="navigation-buttons">
        <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn v-if="!$vuetify.display.lgAndUp" @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <base-btn @click="continueWithAccessOne()" :disabled="isGettingFinancingOptions" class="continue-btn v-button-primary">
            {{ $vuetify.display.lgAndUp ? $translate('paymentFlow.financing.accessOne.continue') : $translate('paymentFlow.actions.continue') }}
        </base-btn>
    </div>
</div>
</template>
<script>
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseSpacer from './BaseSpacer.vue';
import BaseExpansionPanels from './BaseExpansionPanels.vue';

export default {
    name: 'PaymentFlowAccessOneStep',
    components: {
        BaseExpansionPanel,
        BaseIcon,
        BaseBtn,
        BaseExpansionPanels,
        BaseSpacer
    },
    props: {
        accessOneFinancingOptions: Array,
        financingDisclaimers: Object,
        financingError: String,
        isGettingFinancingOptions: Boolean,
    },
    data: () => ({
        optionIndex: 0,
    }),
    methods: {
        continueWithAccessOne() {
            this.$emit('continue-with-access-one', this.accessOneFinancingOptions[this.optionIndex]);
        },
        cancel() {
            this.$emit('cancel');
        },
        back() {
            this.$emit('back');
        },
    },
    created() {
        this.$emit('scroll-to-top');
    },
    mounted() {
        this.$emit('loaded');
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.financing-disclaimer {
    font-size: 1.2rem;
    letter-spacing: 0.025rem;
    line-height: 1.6rem;
    @include wider-than-tablet {
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 0 1.5rem;
    }
}
.financing-options, .interest-bearing {
    padding-bottom: 1rem;
}
.financing-logo-container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    .financing-logo {
        max-width: 20rem;
        @include wider-than-tablet {
            max-width: 27rem;
        }
    }
}
.financing-error {
    align-items: flex-start;
    color: $global-color-warning;
    display: flex;
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    margin-bottom: 2rem;
    .financing-error-message {
        display: flex;
        align-items: center;
        min-height: 2rem;
        @include wider-than-tablet {
            font-size: 1.4rem;
            min-height: 2.4rem;;
        }
    }
    .v-icon {
        color: $global-color-warning;
        margin-right: 1.2rem;
    }
}
</style>
