<template>
    <div>
        <h2 class="header-hidden">{{ $translate('hiddenHeaders.messages') }}</h2>
        <div v-if="messages && messages.length" class="messages">
            <MessagesList :messages="messages"></MessagesList>
        </div>
        <div v-else-if="fetched">
            <MessagesListEmpty></MessagesListEmpty>
        </div>
        <modal v-if="messagingDisabled" :show="messagingDisabled" @close="messagingDisabled = false" class="composition-modal-container">
            <messaging-disabled :provider="messagingDisabledProvider"></messaging-disabled>
        </modal>
    </div>
</template>

<script>

import MessagesListEmpty from './MessagesListEmpty.vue';
import MessagesList from './MessagesList.vue';
import MessagingDisabled from './MessagingDisabled.vue';
import Modal from './Modal.vue';

export default {
    name: 'MessagesContent',
    components: {
        MessagesListEmpty,
        MessagesList,
        MessagingDisabled,
        Modal,
    },
    data: () => ({
        loadingIndicator: null,
        messagingDisabled: false,
        messagingDisabledProvider: {},
        showMessagingDisabled: false,
    }),
    computed: {
        messages() {
            return this.$store.getters.messages;
        },
        fetched() {
            return this.$store.getters.fetched;
        }
    },
    methods: {
        openMessageThread(message) {
            return this.$store.dispatch('getProviderById', message.providerId).then((provider) => {
                this.$store.commit('setThreadId', message.id);
                if (provider.requiresRedirectToBranded) {
                    this.emitter.emit('redirect:tab', {
                        provider: provider,
                        context: {
                            stateGo: {
                                to: 'MessageDetails',
                                toParams: {
                                    threadId: message.id
                                }
                            }
                        }
                    });
                } else {
                    this.$router.push({
                        name: 'MessageDetails',
                        params: { threadId: message.id },
                    });
                }
            });
        },
        checkMessagingDisabled(providers){
            // check if providers have messaging enabled
            var messagingDisabled = true;
            var i = 0;
            while((i < providers.length) && (messagingDisabled === true)){
                if(providers[i].canMessage){
                    messagingDisabled = false;
                    this.messagingDisabled = messagingDisabled;
                    this.$store.commit('setMessagingDisabled', messagingDisabled);
                    return;
                } else {
                    this.messagingDisabledProvider = providers[i];
                    i++;
                }
            }
            this.messagingDisabled = messagingDisabled;
            this.$store.commit('setMessagingDisabled', messagingDisabled);
        },
        loadMessages() {
            this.$store.dispatch('getMessages', { forceFetch: true }).finally(() => {
                this.loadingIndicator.then((key) => {
                    this.$store.dispatch('completeLoadingIndicator', key);
                });
            });
        },
    },
    mounted() {
        // vuex setup for entire "Messages" page
        this.$store.dispatch('getProviders').then((providers) => {
            this.checkMessagingDisabled(providers)
        }).catch(() => {
            console.error('Unable to check if provider has messaging disabled');
        });
        this.loadingIndicator = this.$store.dispatch('loadLoadingIndicator');
        this.loadMessages();

        // set up listeners
        this.emitter.on('message:newThreadCreated', () => {
            this.loadMessages();
        });

        this.emitter.on('message:openThread', this.openMessageThread);

        this.emitter.on('data:clear', () => {
            this.$store.dispatch('clearMessagesData');
        });

        if (this.$route.query && this.$route.query.autofill) {
            let data = JSON.parse(this.$route.query.data);
            if (this.$store.getters.currentBill) {
                data.useCurrentBill = true;
            }
            this.emitter.emit('message:compose', data);
        }
    },
    destroyed() {
        // turn off listener so we don't call logic on component that doesn't exist
        this.emitter.off('message:newThreadCreated');
    },
};
</script>