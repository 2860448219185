<template>
    <div class="logo-container">
        <img :src="srcPath" :alt="altText"/>
    </div>
</template>

<script>
export default ({
    name: 'ProviderLogo',
    props: {
        providerId: String,
        altText: String,
    },
    computed: {
        backendless() {
            return this.$store.getters.config.backendless;
        },
        srcPath() {
            if (!this.backendless) {
                return '/api/services/v1/providers/logo/' + this.providerId;
            }
            //for backendless
            switch(this.providerId){
                case 789: // american
                    return 'assets/americanhealth.png';
                case 888: // power phys
                    return 'assets/powerphy.png';
                case 999: // gen card
                    return 'assets/gencardiology.png';
                default:
                    return 'assets/nautilus.png';
            }
        },
    },
});
</script>