<template>
    <div class="message-details-message">
        <div size="sm" :class="'type-indicator type-' + message.messageType">
            <base-icon icon="mdi-circle"></base-icon>
        </div>
        <div :class="'message-content type-' + message.messageType">
            <div sensitive class="message-header"> {{message.messageBy}} {{$translate('misc.on')}} {{ message.friendlyMessageTime }}</div>
            <div sensitive class="message-contents fs-block" v-html="message.message"></div>
            <div sensitive class="message-attachments">
                <div :class="attachment.virusScanStatus" v-for="attachment in message.attachments" :key="attachment.id">
                     <MessageDetailsAttachment :attachment="attachment"></MessageDetailsAttachment>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import MessageDetailsAttachment from './MessageDetailsAttachment.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'MessageDetailsMessage',
    components: {
        MessageDetailsAttachment,
        BaseIcon
    },
    props: {
        message: Object,
    },

};
</script>
