<template>
    <div class="message-create-area">
        <div size="sm" class="type-indicator">
            <base-icon icon="mdi-circle"></base-icon>
        </div>
		<div class="message-content">
			<b>{{ $translate('message.messageClosedByProviderDialog') }}</b>
			<div v-if="messagingEnabled" class="message-closed-start-new-text">
                {{ $translate('message.startNewMessageDialog') }}
                <span>
                    <a :tabindex="0" class="message-closed-start-new-link" @click="startNewMessageThread">
                        {{ $translate('message.clickHereWithPeriod') }}
                    </a>
                </span>
            </div>
            <div v-else class="message-closed-provider-disabled">
                {{ $translate('message.messagingDisabled') }} 
                <span v-if="providerPhoneNumber" class="message-closed-provider-phone-number"> {{ $translate('message.messagingDisabledPhoneNumber') }} {{providerPhoneNumber}}. </span>
            </div>
		</div>
	</div>
</template>



<script>

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'MessageDetailsThreadClosed',
    props: {
        messagingEnabled: Boolean,
        providerPhoneNumber: String,
    },
    components: {
        BaseIcon
    },
    methods: {
        startNewMessageThread() {
            this.emitter.emit('message:compose');
        }
    }

};
</script>
