<template>
<modal :show="isVisible" @close="hidePrompt()" class="change-pass-modal-container">
		
    <h2 class="modal-header h-style-2">Browser Info</h2>

    <div class="text-left">
        <ul>
            <li><strong>Browser:</strong> {{browserDetails.browser_name}}</li>
            <li><strong>Version:</strong> {{browserDetails.full_version}}</li>
            <li><strong>User Agent:</strong> {{browserDetails.user_agent}}</li>
        </ul>
        <p ng-hide="ipDetailsLoaded">Loading IP Details...</p>
        <ul ng-show="ipDetailsLoaded">
            <li><strong>IP:</strong> {{ipDetails.ip}}</li>
            <li><strong>Location:</strong> {{ipDetails.location.city}}, {{ipDetails.location.region_name}}, {{ipDetails.location.country_name}} {{ipDetails.location.location.country_flag_emoji}}</li>
        </ul>
    </div>

    <h2 class="modal-header h-style-2">Application Info</h2>

    <div class="text-left">
        <ul>
            <li><strong>Root URL:</strong> {{config.rootUrl}}</li>
            <li><strong>Base URL:</strong> {{config.baseUrl}}</li>
            <li><strong>API URL:</strong> {{config.apiUrl}}</li>
            <li><strong>Backendless:</strong> {{config.backendless}}</li>
            <li><strong>Environment:</strong> {{config.env}}</li>
            <li><strong>Languages:</strong> {{config.languages}}</li>
            <li><strong>Pendo Enabled:</strong> {{config.pendoEnabled}}</li>
            <li><strong>Version:</strong> {{config.version}}</li>
        </ul>
    </div>

    <div>
        <a @click="copyBrowserInfoToClipboard()"><p>{{copyText}}</p></a>
    </div>
</modal>
</template>
<script>

import Modal from './Modal.vue';

export default {
    name: 'BrowserInfoModal',
    components: {
        Modal,
    },
    data: () => ({
        browserDetails: {},
        copyText: 'Copy to Clipboard',
        ipDetails: { location: { location: {} } },
        ipDetailsLoaded: false,
        isVisible: false,
    }),
    computed: {
        config() {
            return this.$store.getters.config;
        }
    },
    methods: {
        copyBrowserInfoToClipboard() {
            // Get all of our data in one place
            var data = {
                'Browser': this.browserDetails.browser_name,
                'Browser Version': this.browserDetails.full_version,
                'User Agent': this.browserDetails.user_agent,
                'IP': this.ipDetails.ip,
                'Location': this.ipDetails.location.city+', '+this.ipDetails.location.region_name+', '+this.ipDetails.location.country_name,
                'API URL': this.config.apiUrl,
                'Backendless': this.config.backendless,
                'Base URL': this.config.baseUrl,
                'Environment': this.config.env,
                'Languages': this.config.languages,
                'Pendo Enabled': this.config.pendoEnabled,
                'Root URL': this.config.rootUrl,
                'Version': this.config.version,
            };

            // Stringify it
            var str = JSON.stringify(data);
            
            // Create a textarea.  This is nessecary for executing the "copy to clipboard" functionality
            var el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            // Copy the text
            document.execCommand('copy');
            // Remove the textarea
            document.body.removeChild(el);

            // Save the initial copy text, update the copy text to "copied!" then, after 3 seconds, reset the text.
            var og = this.copyText;
            this.copyText = 'Copied!';
            setTimeout(function(){
                this.copyText = og;
            }, 3000);
        },
        getBrowser() {
            // From http://www.javascripter.net/faq/browsern.htm
            var nAgt = navigator.userAgent;
            var browserName  = navigator.appName;
            var fullVersion  = ''+parseFloat(navigator.appVersion); 
            var majorVersion = parseInt(navigator.appVersion,10);
            var nameOffset,verOffset,ix;

            // In Opera 15+, the true version is after "OPR/" 
            if ((verOffset=nAgt.indexOf('OPR/'))!=-1) {
                browserName = 'Opera';
                fullVersion = nAgt.substring(verOffset+4);
            }
            // In older Opera, the true version is after "Opera" or after "Version"
            else if ((verOffset=nAgt.indexOf('Opera'))!=-1) {
                browserName = 'Opera';
                fullVersion = nAgt.substring(verOffset+6);
                if ((verOffset=nAgt.indexOf('Version'))!=-1){
                    fullVersion = nAgt.substring(verOffset+8);
                }
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset=nAgt.indexOf('MSIE'))!=-1) {
                browserName = 'Microsoft Internet Explorer';
                fullVersion = nAgt.substring(verOffset+5);
            }
            // In Chrome, the true version is after "Chrome" 
            else if ((verOffset=nAgt.indexOf('Chrome'))!=-1) {
                browserName = 'Chrome';
                fullVersion = nAgt.substring(verOffset+7);
            }
            // In Safari, the true version is after "Safari" or after "Version" 
            else if ((verOffset=nAgt.indexOf('Safari'))!=-1) {
                browserName = 'Safari';
                fullVersion = nAgt.substring(verOffset+7);
                if ((verOffset=nAgt.indexOf('Version'))!=-1){
                    fullVersion = nAgt.substring(verOffset+8);
                }
            }
            // In Firefox, the true version is after "Firefox" 
            else if ((verOffset=nAgt.indexOf('Firefox'))!=-1) {
                browserName = 'Firefox';
                fullVersion = nAgt.substring(verOffset+8);
            }
            // In most other browsers, "name/version" is at the end of userAgent 
            else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
                    (verOffset=nAgt.lastIndexOf('/')) ) 
            {
                browserName = nAgt.substring(nameOffset,verOffset);
                fullVersion = nAgt.substring(verOffset+1);
                if (browserName.toLowerCase()==browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            // trim the fullVersion string at semicolon/space if present
            if ((ix=fullVersion.indexOf(';'))!=-1){
                fullVersion=fullVersion.substring(0,ix);
            }
            if ((ix=fullVersion.indexOf(' '))!=-1){
                fullVersion=fullVersion.substring(0,ix);
            }

            majorVersion = parseInt(''+fullVersion,10);
            if (isNaN(majorVersion)) {
                fullVersion  = ''+parseFloat(navigator.appVersion); 
                majorVersion = parseInt(navigator.appVersion,10);
            }

            return {
                'browser_name': browserName,
                'full_version': fullVersion,
                'major_version': majorVersion,
                'app_name': navigator.appName,
                'user_agent': navigator.userAgent
            };
        },
        hidePrompt() {
            this.isVisible = false;
        },
    },
    created() {
        this.emitter.on('show-browser-info', () => {
            this.isVisible = true;
            this.browserDetails = this.getBrowser();
            this.$store.dispatch('getIPData', {
                successCallback: (response) => {
                    this.ipDetails = response.data;
                    this.ipDetailsLoaded = true;
                }, errorCallback: () => {
                    this.ipDetailsLoaded = true;
                }
            });
        });
    },
};
</script>
<style lang="scss">
</style>