<template>
<div class="payment-flow-method-step-billing">
    <h2>{{ $translate('payment.billing.billingAddressSection') }}</h2>
    <div>
        <base-radio-group class="locations" inline v-model="location" :options="locations"></base-radio-group>
    </div>
    <base-text-field
        v-model="billing.address1"
        :label="$translate('labels.address')"
        :error-messages="v$.billing.address1.$errors.map(e => e.$message)"
        class="fs-block"
        :name="'address1-' + type"
        required
        @blur="v$.billing.address1.$touch"
    ></base-text-field>
    <base-text-field
        v-model="billing.address2"
        :label="$translate('labels.address2')"
        :rules="[]"
        class="fs-block"
        :name="'address2-' + type"
    ></base-text-field>
    <base-text-field
        v-model="billing.city"
        :label="$translate('labels.city')"
        :error-messages="v$.billing.city.$errors.map(e => e.$message)"
        class="fs-block"
        :name="'city-' + type"
        required
        @blur="v$.billing.city.$touch"
    ></base-text-field>
    <div class="state-zip-fields vuetify">
        <base-select
            v-model="billing.state"
            :items="states"
            item-title="text"
            item-value="value"
            :label="$translate('labels.state')"
            :error-messages="v$.billing.state.$errors.map(e => e.$message)"
            class="state"
            :name="'state-' + type"
            id="card-select"
            required
            @blur="v$.billing.state.$touch"
            v-if="location == 1"
        ></base-select>
        <base-text-field
            v-model="billing.province"
            :label="$translate('labels.province')"
            :error-messages="v$.billing.province.$errors.map(e => e.$message)"
            class="province"
            :name="'province-' + type"
            required
            @blur="v$.billing.province.$touch"
            v-if="location == 2"
        ></base-text-field>
        <base-text-field
            v-model="billing.zip"
            :label="$translate('labels.zip')"
            :error-messages="v$.billing.zip.$errors.map(e => e.$message)"
            class="fs-block zip"
            :name="'zip-' + type"
            ref="zip"
            required
            maxlength="5"
            @keypress="onlyNumbers($event)"
            inputmode="decimal"
            @blur="v$.billing.zip.$touch"
            v-if="location == 1"
        ></base-text-field>
        <base-text-field
            v-model="billing.postalCode"
            :label="$translate('labels.postalCode')"
            :error-messages="v$.billing.postalCode.$errors.map(e => e.$message)"
            class="fs-block postalCode"
            :name="'postalCode-' + type"
            ref="postalCode"
            required
            maxlength="10"
            @blur="v$.billing.postalCode.$touch"
            v-if="location == 2"
        ></base-text-field>
    </div>
</div>
</template>

<script>
import _ from 'lodash';
import BaseSelect from './BaseSelect.vue';
import BaseTextField from './BaseTextField.vue';
import BaseIcon from './BaseIcon.vue';
import BaseRadioGroup from './BaseRadioGroup.vue';
import useValidate from '@vuelidate/core';
import { onlyNumbers, states } from '../utils/utils.js';
import { required, minLength, helpers, requiredIf } from '@vuelidate/validators';

export default {
    name: 'PaymentFlowBillingAddress',
    props: {
        modelValue: Object,
        type: String
    },
    components: {
        BaseSelect,
        BaseTextField,
        BaseIcon,
        BaseRadioGroup
    },
    data: () => ({
        v$: useValidate(),
        billing: {
            address1: '',
            address2: '',
            city: '',
            state: null,
            zip: '',
            isInternational: false,
            province: '',
            postalCode: ''
        },
        states: states,
        location: 1
    }),
    validations() {
        return {
            billing: {
                address1: { required: this.requiredMsg },
                city: { required: this.requiredMsg },
                state: { required: this.requiredIfMsg(1) },
                zip: { 
                    required: this.requiredIfMsg(1),
                    minLength: helpers.withMessage(
                        this.$translate('errors.minZipCode'),
                        minLength(5)
                    )
                },
                province: {
                    required: this.requiredIfMsg(2)
                },
                postalCode: {
                    required: this.requiredIfMsg(2)
                }
            }
        }
    },
    computed: {
        requiredMsg() {
            return helpers.withMessage(this.$translate('errors.fieldRequired'), required);
        },
        locations() {
            return [
                {
                    key: 1,
                    title: this.$translate('labels.unitedStates')
                },
                {
                    key: 2,
                    title: this.$translate('labels.international')
                }
            ];
        }
    },
    watch: {
        modelValue: {
            handler() {
                if (this.modelValue) {
                    Object.keys(this.modelValue).forEach((key) => {
                        if (this.modelValue[key]) {
                            this.billing[key] = this.modelValue[key];
                        }
                    });
                    if (this.modelValue.isInternational == true) {
                        this.location = 2;
                    }
                    this.billing.valid = !this.v$.billing.$invalid;
                }
            },
            deep: true
        },
        billing: {
            handler() {
                this.updateModelValue();
            },
            deep: true
        },
        "billing.state": {
            handler(value) {
                if (typeof value == 'string') {
                    let state = this.states.find(state => {
                        return state.value == value;
                    });
                    this.billing.state = state;
                }
            }
        },
        location(value) {
            if (value == 1) {
                this.billing.isInternational = false;
            } else if (value == 2) {
                this.billing.isInternational = true;
            }
        }
    },
    methods: {
        onlyNumbers: onlyNumbers,
        updateModelValue() {
            let newBilling = _.cloneDeep(this.billing);
            newBilling.state = (this.billing && this.billing.state) ? this.billing.state.value : null;
            newBilling.valid = !this.v$.billing.$invalid;
            this.$emit('update:modelValue', newBilling);
        },
        validateFields() {
            this.v$.$validate();
        },
        requiredIfMsg(location) {
            let matches = false;
            if (location == this.location) {
                matches = true;
            }
            return helpers.withMessage(this.$translate('errors.fieldRequired'), requiredIf(matches));
        }
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';

.payment-flow-method-step-billing {
    h2 {
        color: $global-color-font-new;
        font-size: 1.4rem;
        line-height: 2rem;
        padding-left: 0.4rem;
        padding-bottom: 0.4rem;
    }

    .locations {
        height: 3.25rem;
        padding-top: .25rem;
        margin-left: -1rem;
        .v-icon {
            margin-right: 0.6rem;
            margin-top: 0.2rem;
        }
        .v-selection-control__input {
            bottom: 0.5rem;
        }
        label {
            padding: 0;
            margin: 0;
            .title {
                font-size: 1.4rem;
                line-height: 2rem;
                color: $global-color-font-new;
                font-weight: 400;
                margin-top: -0.7rem;
                margin-left: -1rem;
            }
        }

        .base-radio:last-of-type {
            margin-top: -0.7rem;
        }

        .v-radio-group .v-input__details {
            display: none;
        }
    }

    .state-zip-fields {
        display: flex;
        flex-flow: row wrap;
        & > .state, & > .province {
            margin-right: 0.5rem;
            margin-top: 0;
            width: 65%;
        }
        & > .zip, & > .postalCode {
            min-width: 8rem;
            margin-top: 0;
            width: 25%;
        }
    }
}
</style>