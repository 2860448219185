<template>
    <div v-if="showComponent" class="vuetify account-details-activity">
        <content-card :headerTitle="this.headerTitle" headerIcon="mdi-format-list-bulleted">
            <div>
                <ActivityList :activityList="activityList" :account="account"></ActivityList>
            </div>
        </content-card>
    </div>
</template>

<script>
import ContentCard from './ContentCard.vue';
import ActivityList from './ActivityList.vue';

export default {
    name: 'AccountDetailsActivity',
    components: {
        ContentCard,
        ActivityList,
    },
    props: {
        activityList: Array,
        account: Object,
    },
    data() {
        return {
            headerTitle: this.$translate('labels.accountDetailsActivityHeader'),
        }
    },
    computed: {
        showComponent() {
            return this.activityList && this.activityList.length;
        },
    }
};
</script>
<style lang="scss">
</style>
