<template>
<div data-testid="account-details-content" class="vuetify account-details-content" data-app>
    <base-tabs v-model="activeTab" :tabs="tabNames">
        <template #labels.bills>
            <div v-if="bill || (account && account.hasPendingBalance)">
                <bill-summary-cost-summary
                    @viewpaymentoptions="viewPaymentOptions()"
                    @viewaccountinfo="accountInfoBottomSheet = true"
                    @viewbill="viewBill(secureCode)"
                    @watchstatementoverview="watchStatementOverview()"
                    @redirecttofinancing="redirectToFinancing()"
                    :account="account"
                    :amountDue="account.amountDue"
                    :dueDate="bill ? bill.dueDate : null"
                    :providerLocation="provider.name"
                    :patientName="account.patientName"
                    :accountNumber="account.accountNumber"
                    :disablePaymentButton="!account.amountDue || Boolean(account.financedBy) || !account.provider.active || account.amountDue < 0"
                    :showFinancingRedirectButton="account.canManageFinancedAccount"
                    :financedBy="account.financedBy"
                    :loadingFinancingRedirect="account.loadingVendor"
                    :sunday-sky-program-id="sundaySkyProgramId"
                    data-testid="account-details-cost-summary"
                ></bill-summary-cost-summary>
                <div v-if="shouldShowAccountDetailsLabel" class="account-details-mobile">
                    <span class="vuetify label">{{ $translate('labels.accountDetails') }}</span>
                </div>
                <div v-if="billServices && bill && billSubaccountsCount <= 1 && !account.hasPendingBalance">
                    <sub-account-services-card :services="billServices"></sub-account-services-card>
                    <appointment-info-card :appointmentInfo="appointmentInfo"></appointment-info-card>
                </div>
                <div v-if="billSubaccountsCount>1">
                    <div v-for="(subAccount) in billSubAccounts" :key="subAccount.id" @click="viewSubAccount(subAccount)">
                        <bill-summary-sub-account-card
                            :subAccount="subAccount">
                        </bill-summary-sub-account-card>
                    </div>
                </div>
            </div>
            <div v-else>
                <no-data :title="$translate('labels.noBill')" :description="$translate('labels.noBillDetails')" data-testid="bills-no-data"></no-data>
                <account-info-card v-show="!$vuetify.display.lgAndUp" :accountInfo="accountInfo"></account-info-card>
            </div>
        </template>
        <template #labels.estimates>
            <div v-if="showEstimateDetails">
                <div>
                    <back-button class="back-to-all-estimates" :text="$translate('labels.allEstimates')" @backbuttonclick="backLink"></back-button>
                    <SubAccountDetailsBalance :estimate="currentEstimate" @pay-estimate="payEstimate()"></SubAccountDetailsBalance>
                    <AppointmentInfoCard v-if="!$vuetify.display.lgAndUp" :appointmentInfo="currentEstimate.appointmentInfo" mode="estimate"></AppointmentInfoCard>
                    <sub-account-services-card :services="estimateServices"></sub-account-services-card>
                    <SubAccountDetailsCostBreakdown :estimate="currentEstimate"></SubAccountDetailsCostBreakdown>
                </div>
            </div>
            <div v-else-if="estimateSubaccountsCount >= 1">
                <div v-for="estimate in estimates" :key="estimate.subAccount.id" @click="openEstimateDetails(estimate)">
                    <estimate-sub-account-card
                        :subAccount="estimate.subAccount"
                        :status="estimate.subAccountStatus"
                        :void="estimate.cancelled"
                    >
                    </estimate-sub-account-card>
                </div>
            </div>
            <div v-else>
                <no-data :title="$translate('labels.noEstimate')" :description="$translate('labels.noEstimateDetails')" data-testid="estimates-no-data"></no-data>
                <account-info-card v-show="!$vuetify.display.lgAndUp" :accountInfo="accountInfo"></account-info-card>
            </div>
        </template>
        <template #labels.accountDetailsActivityHeader>
            <div v-if="activityList && activityList.length">
                <account-details-activity :activityList="activityList" :account="account"></account-details-activity>
            </div>
            <div v-else>
                <no-data :title="$translate('labels.noActivity')" :description="$translate('labels.noActivityDetails')" data-testid="activity-no-data"></no-data>
            </div>
        </template>
    </base-tabs>

    <base-bottom-sheet v-model="accountInfoBottomSheet">
        <card-header
            :title="$translate('labels.accountInfo')"
            icon="mdi-folder-plus-outline"
            class="sheet-card-header"
        ></card-header>
        <account-info
            :accountInfo="accountInfo"
            data-testid="account-details-account-info-mobile"
        ></account-info>
    </base-bottom-sheet>
    <base-bottom-sheet v-model="subAccountDetailsSheet">
        <sub-account-details-sheet
            @close="subAccountDetailsSheet = false"
            @pay-sub-account="paySubAccount()"
            @viewbill="viewBill(secureCode)"
            :subAccount="visibleSubAccount"
            :accountInfo="accountInfo"
            :bill="bill"
            :services="subAccountServices"
            :subAccountPaymentsEnabled="provider.subAccountPaymentsEnabled"
        ></sub-account-details-sheet>
    </base-bottom-sheet>
    <base-dialog v-model="clearBalanceSuccessDialog" content-class="clear-balance-approved">
        <template #title>{{ $translate('paymentFlow.amount.clearBalanceSuccessTitle') }}</template>
        <template #text>{{ $translate('paymentFlow.amount.clearBalanceSuccessText') }}</template>
        <template #actions>
            <base-btn class="continue-btn v-button-primary" variant="text" @click="clearBalanceSuccessDialog = false">{{ $translate('actions.close') }}</base-btn>
        </template>
    </base-dialog>

    <base-dialog v-model="videoBillModal" :scrollable="false" :showCloseButton="false" width="auto" content-class="sunday-sky-dialog">
        <sunday-sky-player :program-id="sundaySkyProgramId" :poster-url="sundaySkyPosterUrl" type="bill" @exit="closeVideo" @play="playVideo"></sunday-sky-player>
    </base-dialog>

    <video-bill-feedback-dialog v-model="videoBillFeedback" @on-close="closeVideoFeedback()" @on-close-cancel="closeVideoFeedbackNoBanner()"></video-bill-feedback-dialog>
    <base-snackbar v-model="videoBillFeedbackBanner" class="video-feedback-snackbar"><base-icon icon="mdi-check"></base-icon>{{$translate('videoBill.thanks')}}</base-snackbar>
    
</div>
</template>

<script>
import AccountDetailsActivity from './AccountDetailsActivity.vue';
import AccountInfo from './AccountInfo.vue';
import AccountInfoCard from './AccountInfoCard.vue';
import AppointmentInfoCard from './AppointmentInfoCard.vue';
import BackButton from './BackButton.vue';
import BillSummaryCostSummary from './BillSummaryCostSummary.vue';
import BillSummarySubAccountCard from './BillSummarySubAccountCard.vue';
import CardHeader from './CardHeader.vue';
import EstimateSubAccountCard from './EstimateSubAccountCard.vue';
import NeedHelp from './NeedHelp.vue';
import NeedHelpCard from './NeedHelpCard.vue';
import NoData from './NoData.vue';
import ProviderLogo from './ProviderLogo.vue';
import SubAccountDetailsBalance from './SubAccountDetailsBalance.vue';
import SubAccountDetailsCostBreakdown from './SubAccountDetailsCostBreakdown.vue';
import SubAccountDetailsSheet from './SubAccountDetailsSheet.vue';
import SubAccountServicesCard from './SubAccountServicesCard.vue';
import SundaySkyPlayer from './SundaySkyPlayer.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseDialog from './BaseDialog.vue';
import BaseBtn from './BaseBtn.vue';
import BaseTabs from './BaseTabs.vue';
import VideoBillFeedbackDialog from './VideoBillFeedbackDialog.vue';
import BaseSnackbar from './BaseSnackbar.vue';
import BaseIcon from './BaseIcon.vue';
import { squashDescriptionlessDetailLines } from '../utils/utils';

export default {
    name: 'AccountDetailsContent',
    components: {
        AccountDetailsActivity,
        AccountInfo,
        AccountInfoCard,
        AppointmentInfoCard,
        BackButton,
        BillSummaryCostSummary,
        BillSummarySubAccountCard,
        CardHeader,
        EstimateSubAccountCard,
        NeedHelp,
        NeedHelpCard,
        NoData,
        ProviderLogo,
        SubAccountDetailsBalance,
        SubAccountDetailsCostBreakdown,
        SubAccountDetailsSheet,
        SubAccountServicesCard,
        SundaySkyPlayer,
        BaseBottomSheet,
        BaseDialog,
        BaseBtn,
        BaseTabs,
        VideoBillFeedbackDialog,
        BaseSnackbar,
        BaseIcon
    },
    data() {
        return {
            provider: {},
            messageProviderData: {}, 
            account: {},
            secureCode: null,
            amountDue: null,
            isPastDue: false,
            dueDate: null,
            billSubaccountsCount: -1,
            estimateSubaccountsCount: -1,
            activityList: [], 
            billSubAccounts: [],
            estimate: null,
            estimates: [], // Is equivalent to billSubAccounts
            bill: null,
            appointmentInfo: {},
            estimateAppointmentInfo: {},
            accountInfo: {},
            activeTab: null,
            tabNames: [],

            visibleSubAccount: null,
            subAccountServices: null,
            showEstimateDetails: false,

            accountInfoBottomSheet: false,
            subAccountDetailsSheet: false,
            clearBalanceSuccessDialog: false,
            
            videoBillModal: false,
            videoBillFeedback: false,
            videoBillFeedbackBanner: false,
            videoBillPlayed: false,
        };
    },
    computed: {
        shouldShowEstimatesTab() {
            return this.provider && this.provider.features && this.provider.features.indexOf('estimates') >= 0;
        },
        billServices() {
            return this.$store.getters.billServices;
        },
        shouldShowAccountDetailsLabel() {
            const hasBillDetails = this.bill && this.billSubaccountsCount <= 1 && !this.account.hasPendingBalance;
            return hasBillDetails && ((this.billServices && this.billServices.length) || (this.appointmentInfo && Object.keys(this.appointmentInfo).length)) || this.billSubaccountsCount > 1;
        },
        sundaySkyPosterUrl() {
            if (this.bill) {
                return this.bill.sundaySkyPosterUrl;
            }
            return null;
        },
        sundaySkyProgramId() {
            if (this.bill && this.bill.providerDetails) {
                return this.bill.providerDetails.features.indexOf('showSundaySkyInBillSummary') > -1 ? this.bill.sundaySkyProgramId : null
            }
            return null;
        },
        currentEstimate() {
            return this.$store.getters.currentEstimate;
        },
        estimateServices() {
            return this.$store.getters.estimateServices;
        },
    },
    created() {
        this.$store.commit('setShowLoading', true);
        this.$nextTick(() => {
            var pathname = window.location.hash || window.location.pathname;
            var providerId = this._getProviderIdFromRoute(pathname);
            var accountId = this._getAccountIdFromRoute(pathname);
            this.$store.dispatch('getProviderById', providerId).then((provider) => {
                this.provider = provider;
                this.$store.commit('setCurrentProvider', provider);
                this.$store.dispatch('getAccountById', accountId).then((account) => {
                    this.account = account;
                    this.account.providerId = providerId;
                    this.$store.commit('setCurrentAccount', this.account);
                    this.messageProviderData = { account: this.account };
                    this.secureCode = this.account.secureCode;
                    this.amountDue = this.account.amountDue;
                    this.isPastDue = this.account.isPastDue;
                    this.dueDate = this.account.dueDate;
                    if (this.account.estimates && this.account.estimates.length) {
                        this.estimates = this.account.estimates;
                        this.accountInfo = {
                            accountId: this.account.accountId,
                            accountNumber: this.account.accountNumber,
                            guarantorName: this.account.guarantorNames && this.account.guarantorNames.length ? this.account.guarantorNames[0] : null,
                            patientName: null,  // Patient name is not reliable at an account level in a world where sub-accounts exist
                        };
                        this.estimateSubaccountsCount = this.estimates.length;
                        if (this.estimateSubaccountsCount==1) {
                            // only define if only one estimate
                            this.estimate = this.account.estimates[0];
                            this.estimateAppointmentInfo = this.estimate.appointmentInfo;
                        }
                    }
                    if (this.account.bills && this.account.bills.length) {
                        //find latest bill
                        this.bill = this.account.bills[(this.account.bills.length)-1];
                        this.$store.commit('setCurrentBill', this.bill);
                        this.accountInfo = this.bill.accountInfo;
                        if (this.bill.subAccountsEnabled) { //check subAccounts at the bill level
                            this.billSubAccounts = this.bill.subAccounts;
                            this.billSubaccountsCount = this.billSubAccounts.length;
                            if (1 === this.billSubaccountsCount) {
                                this.appointmentInfo = this.bill.subAccounts[0].appointmentInfo;
                            }
                            // If serviceDate empty, set it using the main statement date
                            for (let index in this.billSubAccounts) {
                                let subAccount = this.billSubAccounts[index];
                                if (!subAccount.serviceDate) {
                                    subAccount.serviceDate = this.bill.statementSentDate;
                                }
                            }
                        }
                    }
                    this.$store.dispatch('fetchAccountHistory', {id: accountId}).then((history) => {
                        this.activityList = history;
                    });

                    this.getTabNames();
                    if(this.provider && this.provider.features && this.provider.features.indexOf('estimates') >= 0 && !this.bill && this.estimateSubaccountsCount > 0) {
                        this.activeTab = 1; // start at estimates tab
                    }
                    else {
                        this.activeTab = 0; // start at bills tab
                    }

                    this.$store.commit('setShowLoading', false);

                    if (this.$route && this.$route.query && this.$route.query.clearBalanceSuccess) {
                        this.clearBalanceSuccessDialog = true;
                    }
                }).catch((err) => {
                    console.error('Error: ' + err);
                    this.$store.commit('setShowLoading', false);
                    this.$router.push({name: 'ProvidersSummary'});
                });
            }).catch((err) => {
                console.error('Error: ' + err);
                this.$store.commit('setShowLoading', false);
                this.$router.push({name: 'ProvidersSummary'});
            });
        });
    },
    watch: {
        activeTab() {
            this.backLink();
        },
    },
    methods: {
        // [root_url]/#/dashboard/provider/:ProviderId/account/:accountId
        _getProviderIdFromRoute(pathname) {
            return this.$route.params.providerId ?? null;
        },
        _getAccountIdFromRoute(pathname) {
            return this.$route.params.accountId ?? null;
        },
        getTabNames() {
            let names = [];
            names.push('labels.bills');
            if (this.shouldShowEstimatesTab) {
                names.push('labels.estimates');
            }
            names.push('labels.accountDetailsActivityHeader');
            this.tabNames =  names;
        },
        viewPaymentOptions() {
            if (this.provider.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                        provider: this.provider,
                        context: {
                            stateGo: {
                                to: 'AccountPaymentFlowAmountStep',
                                toParams: {
                                    accountId: this.account.accountId,
                                    providerId: this.provider.id
                                },
                            },
                        },
                    });
            } else {
                this.$router.push({
                    name: 'AccountPaymentFlowAmountStep',
                    params: {
                        accountId: this.account.accountId,
                        providerId: this.provider.id
                    },
                });
            }
        },
        composeMessage() {
            this.closeBottomSheets();
            if (this.provider.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                    provider: this.provider,
                    context: {
                        stateGo: {
                            to: 'AccountDetails',
                            toParams: {
                                accountId: this.account.accountId,
                                providerId: this.provider.id
                            },
                        },
                        emit: [
                            'message:compose',{ secureCode:this.messageProviderData.account.secureCode }
                        ],
                    },
                });
            } else {
                this.emitter.emit('message:compose', this.messageProviderData);
            }
        },
        viewSubAccount(subAccount) {
            this.setSubAccountServices(subAccount);
            this.visibleSubAccount = subAccount;
            this.subAccountDetailsSheet = true;
        },
        setSubAccountServices(subAccount) {
            if (subAccount.services) {
                this.subAccountServices = subAccount.services;
            } else if (subAccount.detailLines) {
                this.subAccountServices = squashDescriptionlessDetailLines(subAccount.detailLines);
            } else if (this.bill && this.bill.details && this.bill.details.visits && this.bill.details.visits.length) {
                var visit = _.find(this.bill.details.visits, { voucherNumber: subAccount.subAccountNumber });
                if (visit) {
                    this.subAccountServices = visit.detailLines.filter(d => 'chrg' === d.type);
                } else {
                    this.subAccountServices = null;
                }
            }
        },
        addCosts(types, detailLines) {
            var total = 0;
            detailLines.forEach((details) => {
                if(types.includes(details.type) && details.cost) {
                    total = total + details.cost;
                }
            });
            return total;
        },
        closeBottomSheets() {
            this.accountInfoBottomSheet = false;
            this.subAccountDetailsSheet = false;
            this.emitter.emit('responsive-provider-logo-left-column-persist-close-bottom-sheet');
        },
        viewBill(secureCode) {
            this.closeBottomSheets();
            this.$store.dispatch('openPdf', secureCode);
        },
        watchStatementOverview() {
            if (this.sundaySkyProgramId) {
                this.videoBillModal = true;
            } else {
                console.error('Video bill clicked without program id set.');
            }
        },
        redirectToFinancing() {
            this.account.loadingVendor = true;
            this.account.failedToGoToVendor = false;
            this.$store.dispatch('manageFinancingAccount', this.account.secureCode)
                .then((data) => {
                    window.location.href = data.redirect_url;
                }).catch(() => {
                    this.account.failedToGoToVendor = true;
                }).finally(() => {
                    this.account.loadingVendor = false;
                });
        },
        payEstimate() {
            if (this.provider.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                    provider: this.provider,
                    context: {
                        stateGo: {
                            to: 'EstimatePaymentFlow',
                            toParams: {
                                accountId: this.account.accountId,
                                providerId: this.provider.id,
                                estimateId: this.estimate.id
                            },
                        },
                    },
                });
            } else {
                this.$router.push({
                    name: 'EstimatePaymentFlow',
                    params: {
                        accountId: this.account.accountId,
                        providerId: this.provider.id,
                        estimateId: this.estimate.id
                    },
                });
            }
        },
        paySubAccount() {
            if (this.provider.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                    provider: this.provider,
                    context: {
                        stateGo: {
                            to: 'SubAccountPaymentFlow',
                            toParams: {
                                accountId: this.account.accountId,
                                providerId: this.provider.id,
                                subAccountId: this.visibleSubAccount.id,
                            },
                        },
                    },
                });
            } else {
                this.$router.push({
                    name: 'SubAccountPaymentFlow',
                    params: {
                        accountId: this.account.accountId,
                        providerId: this.provider.id,
                        subAccountId: this.visibleSubAccount.id,
                    },
                });
            }
        },
        openEstimateDetails(estimate) {
            this.showEstimateDetails = true;
            this.$store.commit('setCurrentEstimate', estimate);
        },
        refreshEmailAndPhoneNotificationBanners() {
            this.$store.dispatch('refreshEmailAndPhoneNotificationBanners');
        },
        playVideo() {
            this.videoBillPlayed = true;
        },
        closeVideo() {
            this.videoBillModal = false;
            this.videoBillFeedback = this.videoBillPlayed; // only prompt for feedback if video started
            this.videoBillPlayed = false; // reset for next play
        },
        closeVideoFeedback() {
            this.videoBillFeedback = false;
            this.videoBillFeedbackBanner = true;
        },
        closeVideoFeedbackNoBanner() {
            this.videoBillFeedback = false;
        },
        backLink() {
            this.showEstimateDetails = false;
            this.$store.commit('setCurrentEstimate', null);
        },
    },
    mounted() {
        this.emitter.on('responsive-provider-logo-left-column-persist-compose-message', this.composeMessage);
        this.emitter.on('userSettings:updated', this.refreshEmailAndPhoneNotificationBanners);
        this.$store.dispatch('refreshEmailAndPhoneNotificationBanners');
    },
    destroyed() {
        this.emitter.off('responsive-provider-logo-left-column-persist-compose-message', this.composeMessage);
        this.emitter.off('userSettings:updated',this.refreshEmailAndPhoneNotificationBanners);
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.vuetify {
    .account-details-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2.5rem 0 0 0;
        span.vuetify.label {
            color:$global-color-font-new;
            width: 100%;
            margin-left: 1.4rem;
            font-size: 2.4rem;
            @include wider-than-tablet {
                margin-left: 0.9rem;
                font-size: 2.4rem;
            }
            &:hover::before, &:active::before {
                opacity: 0;
            }
        }
    }
}
.account-details-content {
    padding-bottom: 1.6rem;
}


</style>