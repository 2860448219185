<template>
<div class="input-area-field-wrapper">
    <span :class="{ 'icon-active': dirty }">
        <base-icon icon="mdi-phone"></base-icon>
    </span>

    <input
        @input="onInput"
        @change="onChange"
        @focus="$emit('inputfocus')"
        @blur="$emit('inputblur')"
        :value="modelValue"
        :required="required"
        :placeholder="$translate('labels.phoneNumber')"
        :disabled="disableAccountMangement"
        id="details-phone"
        name="phoneNumber"
        type="text"
        class="fs-block input-with-icon"
        data-testid="settings-contact-form-phone-number"
        maxlength="12" />
</div>
</template>
<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'LegacyPhoneNumberInput',
    components: {
        BaseIcon
    },
    props: {
        modelValue: String,
        required: {
            type: Boolean,
            default: false,
        },
        disableAccountMangement: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        dirty: false,
        mostRecentEligibilityCheck: null,
    }),
    methods: {
        onInput(e) {
            this.dirty = true;
            const nums = (e.target.value || '').toString().replace(/(\s|\D)/g, '');
            let cleaned = '';
            if (nums.length >= 6) {
                cleaned = nums.slice(0, 3) + '-' + nums.slice(3, 6) + '-' + nums.slice(6);
            } else if (nums.length >= 3) {
                cleaned = nums.slice(0, 3) + '-' + nums.slice(3);
            } else {
                cleaned = nums;
            }
            this.$emit('update:modelValue', cleaned);
        },
        onChange(e) {
            this.$emit('change', e.target.value);
        },
        async validate() {
            if (!this.modelValue) {
                if (!this.required) {
                    return null;
                }
                return 'required';
            }
            if (this.modelValue.length < 12) {
                return 'minlength';
            }
            if (this.modelValue !== this.mostRecentEligibilityCheck) {
                try {
                    await this.$store.dispatch('getSmsEligibility', this.modelValue.replace(/(\s|\D)/g, ''));
                    // Getting eligibility is an expensive operation, so don't rerun eligibility checks for the same number multiple times
                    this.mostRecentEligibilityCheck = this.modelValue;
                } catch {
                    return 'smsEligible';
                }
            }
            return null;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.input-with-icon {
	display: inline;
}
.base-icon {
	color: $global-color-dark-grey !important;
}
</style>
