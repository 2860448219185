<template>
    <div class="block-segment" v-if="paymentAgreement">
        <button class="button-primary" @click="print();">
            {{$translate('actions.printReceipt')}}
        </button>
        <button class="button-primary" v-show="paymentAgreement.estimateId !== null" @click="viewEstimateDetails();">
            {{$translate('actions.viewEstimateDetails')}}
        </button>
    </div>
</template>

<script>


export default {
    name: 'AgreementReceiptLeftColumn',
    computed: {
        paymentAgreement() {
            return this.$store.getters.paymentAgreement;
        },
    },
    methods: {
        print() {
            try {
                window.print();
            } catch (error) {
                this.emitter.emit('simpleModal:showPrompt', {
                    header: this.$translate('receipt.printingNotSupportedHeader'),
                    subcontent: this.$translate('receipt.printingNotSupportedContent', {
                        viewBill: this.$translate('actions.viewBill')
                    }),
                    intent: 'information',
                    meta: 'warning'
                });
            }
        },
        viewEstimateDetails() {
            if (this.paymentAgreement) {
                this.$router.push({
                    name: 'SubAccountDetails',
                    params: {
                        providerId: _paymentAgreement.provider.id, 
                        estimateId: _paymentAgreement.estimateId,
                    },
                });
            }
        },
    },
};

</script>

<style lang="scss">

</style>