import { createRouter, createWebHashHistory, isNavigationFailure, NavigationFailureType } from 'vue-router';

import store from '../store';
import {
  beforeEnterBackdoorLogin,
  beforeEnterBrandingSSO,
  beforeEnterExternalSSO,
  beforeEnterDemoSSO,
  beforeEnterDfb,
  beforeEnterDfe,
  beforeEnterEbill,
  beforeEnterEstimate,
  beforeEnterForgotPassword,
  beforeEnterFeedback,
  beforeEnterMyChartSSO,
  beforeEnterViewNotifications,
  beforeEnterVerifyEmail,
  beforeEnterVerifyPhone,
} from './asyncRoutes';
import { handleQueryParams, redirectFromLegacyLinksIfNecessary, routeWithAuthenticationGuards } from './middleware';

// Views
import Home from '../views/Home.vue'
import FullPageNotification from '../views/FullPageNotification.vue';
import Landing from '../views/Landing.vue'
import QuickPay from '../views/QuickPay.vue'

// Common
import StandardFooter from './../components/StandardFooter.vue';
import ResponsiveProviderLogoLeftColumnPersist from './../components/ResponsiveProviderLogoLeftColumnPersist.vue';

// Account Details
import AccountDetailsContent from './../components/AccountDetailsContent.vue';
import AccountDetailsLeftColumn from './../components/AccountDetailsLeftColumn.vue';
import AccountDetailsLeftColumnPersist from './../components/AccountDetailsLeftColumnPersist.vue';

// Accounts Summary
import AccountsSummaryContent from './../components/AccountsSummaryContent.vue';
import AccountsSummaryLeftColumn from './../components/AccountsSummaryLeftColumn.vue';
import AccountsSummaryLeftColumnPersist from './../components/AccountsSummaryLeftColumnPersist.vue';

// Agreement Receipt
import AgreementReceiptContent from './../components/AgreementReceiptContent.vue';
import AgreementReceiptLeftColumn from './../components/AgreementReceiptLeftColumn.vue';
import AgreementReceiptLeftColumnPersist from './../components/AgreementReceiptLeftColumnPersist.vue';

// Bill Summary
import BillSummaryContent from './../components/BillSummaryContent.vue';
import BillSummaryLeftColumn from './../components/BillSummaryLeftColumn.vue';

// Message Details
import MessageDetailsContent from './../components/MessageDetailsContent.vue';
import MessageDetailsLeftColumn from './../components/MessageDetailsLeftColumn.vue';
import MessageDetailsLeftColumnPersist from './../components/MessageDetailsLeftColumnPersist.vue';

// Messages
import MessagesContent from './../components/MessagesContent.vue';
import MessagesLeftColumn from './../components/MessagesLeftColumn.vue';
import MessagesLeftColumnPersist from './../components/MessagesLeftColumnPersist.vue';

// Payment Flow
import PaymentFlowContent from './../components/PaymentFlowContent.vue';
import PaymentFlowAccessOneStep from './../components/PaymentFlowAccessOneStep.vue';
import PaymentFlowConfirmationStep from './../components/PaymentFlowConfirmationStep.vue';
import PaymentFlowCuraeAcceptStep from './../components/PaymentFlowCuraeAcceptStep';
import PaymentFlowCuraeApplicationStep from './../components/PaymentFlowCuraeApplicationStep.vue';
import PaymentFlowCuraeExistingStep from './../components/PaymentFlowCuraeExistingStep.vue';
import PaymentFlowCuraeOffersStep from './../components/PaymentFlowCuraeOffersStep.vue';
import PaymentFlowDateStep from './../components/PaymentFlowDateStep.vue';
import PaymentFlowMethodStep from './../components/PaymentFlowMethodStep.vue';
import PaymentFlowAmountStep from './../components/PaymentFlowAmountStep.vue';
import PaymentFlowLeftColumnPersist from './../components/PaymentFlowLeftColumnPersist.vue';

// Plan Receipt
import PlanReceiptContent from './../components/PlanReceiptContent.vue';
import PlanReceiptLeftColumn from './../components/PlanReceiptLeftColumn.vue';
import PlanReceiptLeftColumnPersist from './../components/PlanReceiptLeftColumnPersist.vue';

// Providers Summary
import ProvidersContent from './../components/ProvidersSummaryContent.vue';
import ProvidersSummaryLeftColumn from './../components/ProvidersSummaryLeftColumn.vue';
import ProvidersSummaryLeftColumnPersist from './../components/ProvidersSummaryLeftColumnPersist.vue';

// Receipt
import ReceiptContent from './../components/ReceiptContent.vue';
import ReceiptLeftColumn from './../components/ReceiptLeftColumn.vue';
import ReceiptLeftColumnPersist from './../components/ReceiptLeftColumnPersist.vue';

// Settings
import SettingsAccountManagementContent from './../components/SettingsAccountManagementContent.vue';
import SettingsContactContent from './../components/SettingsContactContent.vue';
import SettingsLeftColumn from './../components/SettingsLeftColumn.vue';
import SettingsLeftColumnPersist from './../components/SettingsLeftColumnPersist.vue';
import SettingsSavedPaymentMethodsContent from './../components/SettingsSavedPaymentMethodsContent.vue';

// Sub-account Details
import SubAccountDetailsContent from './../components/SubAccountDetailsContent.vue';
import SubAccountDetailsLeftColumn from './../components/SubAccountDetailsLeftColumn.vue';
import ProviderLogoLeftColumnPersist from './../components/ProviderLogoLeftColumnPersist.vue';

// Documents
import Documents from './../components/Documents.vue';

/**
 * Header type props are evaluated like this:
 * - 'standard' => Standard header always
 * - 'standardWhenLoggedIn' => Standard header while logged in, otherwise no header
 * - anything else (including undefined/null) => No header always
 * - - For clarity purposes, it can be useful to define the headerType as 'none' when we want to exclue a header
 * 
 * Importantly, header types are inherited by child routes, though they can be overridden.
 * - For example, the Home route has a 'standardWhenLoggedIn' headerType, meaning by default all child routes of Home
 * - route will have the 'standardWhenLoggedIn' headerType. However, you can redefine headerType on a child route to change it
 */

const routes = [
  {
    path: '/bd/:token',
    name: 'BackdoorLogin',
    components: {
      content: ProvidersContent,
      leftColumn: ProvidersSummaryLeftColumn,
      leftColumnPersist: ProvidersSummaryLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterBackdoorLogin(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/demoSSO/:env/:token',
    name: 'DemoSSO',
    components: {
      content: ProvidersContent,
      leftColumn: ProvidersSummaryLeftColumn,
      leftColumnPersist: ProvidersSummaryLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterDemoSSO(store, to),
  },
  {
    path: '/ebill/:shc',
    name: 'Ebill',
    components: {
      content: BillSummaryContent,
      leftColumn: BillSummaryLeftColumn,
      leftColumnPersist: ResponsiveProviderLogoLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterEbill(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/estimate/:token',
    name: 'Estimate',
    components: {
      content: SubAccountDetailsContent,
      leftColumn: SubAccountDetailsLeftColumn,
      leftColumnPersist: ResponsiveProviderLogoLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterEstimate(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/firstBill/:token',
    name: 'DigitalFirstBill',
    components: {
      default: Landing,
      footer: StandardFooter,
    },
    beforeEnter: to => beforeEnterDfb(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/firstEstimate/:token',
    name: 'DigitalFirstEstimate',
    components: {
      default: Landing,
      footer: StandardFooter,
    },
    beforeEnter: to => beforeEnterDfe(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/forgot/:token',
    name: 'ForgotPassword',
    components: {
      default: Landing,
      footer: StandardFooter,
    },
    beforeEnter: to => beforeEnterForgotPassword(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/feedback/:token',
    name: 'Feedback',
    components: {
      default: Landing,
      footer: StandardFooter,
    },
    beforeEnter: to => beforeEnterFeedback(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/MyChartSSO/:token',
    name: 'MyChartSSOValet',
    components: {
      content: ProvidersContent,
      leftColumn: ProvidersSummaryLeftColumn,
      leftColumnPersist: ProvidersSummaryLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterMyChartSSO(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/MyChartSSO',
    name: 'MyChartSSOEHRGateway',
    components: {
      content: ProvidersContent,
      leftColumn: ProvidersSummaryLeftColumn,
      leftColumnPersist: ProvidersSummaryLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterMyChartSSO(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/sso/token/:token',
    name: 'BrandingSSO',
    components: {
      default: Landing,
      footer: StandardFooter,
    },
    beforeEnter: to => beforeEnterBrandingSSO(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/sso/external',
    name: 'ExternalSSO',
    components: {
      default: Landing,
      footer: StandardFooter,
    },
    beforeEnter: to => beforeEnterExternalSSO(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/verifyEmail/:token/:patientUserId',
    name: 'VerifyEmail',
    components: {
      content: ProvidersContent,
      leftColumn: ProvidersSummaryLeftColumn,
      leftColumnPersist: ProvidersSummaryLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterVerifyEmail(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/verifyPhone/:code/:patientUserId/:phoneNumber',
    name: 'VerifyPhone',
    components: {
      content: ProvidersContent,
      leftColumn: ProvidersSummaryLeftColumn,
      leftColumnPersist: ProvidersSummaryLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterVerifyPhone(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/viewNotifications/:notificationId',
    name: 'ViewNotifications',
    components: {
      content: ProvidersContent,
      leftColumn: ProvidersSummaryLeftColumn,
      leftColumnPersist: ProvidersSummaryLeftColumnPersist,
    },
    beforeEnter: to => beforeEnterViewNotifications(store, to),
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/quickPay',
    name: 'QuickPay',
    components: {
      default: QuickPay,
      footer: StandardFooter,
    },
    meta: {
      headerType: 'none',
    },
  },
  {
    path: '/',
    name: 'Landing',
    components: {
      default: Landing,
      footer: StandardFooter,
    },
    meta: {
      headerType: 'none',
    },
  },
  {
    path: '/emailVerified',
    name: 'EmailVerified',
    components: {
      default: FullPageNotification,
      footer: StandardFooter,
    },
    meta: {
      headerType: 'standard',
    },
  },
  {
    path: '/phoneVerified',
    name: 'PhoneVerified',
    components: {
      default: FullPageNotification,
      footer: StandardFooter,
    },
    meta: {
      headerType: 'standard',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      headerType: 'standardWhenLoggedIn',
    },
    children: [
      {
        path: '/messages/:threadId',
        name: 'MessageDetails',
        components: {
          content: MessageDetailsContent,
          leftColumn: MessageDetailsLeftColumn,
          leftColumnPersist: MessageDetailsLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: false,
        },
      },
      {
        path: '/messages',
        name: 'Messages',
        components: {
          content: MessagesContent,
          leftColumn: MessagesLeftColumn,
          leftColumnPersist: MessagesLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: false,
        },
      },
      {
        path: 'dashboard/provider/:providerId/receipt/:paymentId',
        name: 'Receipt',
        components: {
          content: ReceiptContent,
          leftColumn: ReceiptLeftColumn,
          leftColumnPersist: ReceiptLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: false,
        },
      },
      {
        path: 'dashboard/provider/:providerId/paymentAgreement/:agreementId',
        name: 'PaymentAgreementReceipt',
        components: {
          content: AgreementReceiptContent,
          leftColumn: AgreementReceiptLeftColumn,
          leftColumnPersist: AgreementReceiptLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: false,
        },
      },
      {
        path: 'dashboard/provider/:providerId/plan/:planId',
        name: 'PlanReceipt',
        components: {
          content: PlanReceiptContent,
          leftColumn: PlanReceiptLeftColumn,
          leftColumnPersist: PlanReceiptLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
        }
      },
      {
        path: '/guest/receipt/:paymentId',
        name: 'GuestReceipt',
        components: {
          content: ReceiptContent,
          leftColumn: ReceiptLeftColumn,
          leftColumnPersist: ReceiptLeftColumnPersist,
        },
        meta: {
          authenticationRequired: false,
          useNewLayouts: false,
        },
      },
      {
        path: '/settings/savedPaymentMethods',
        name: 'SettingsSavedPaymentMethods',
        components: {
          content: SettingsSavedPaymentMethodsContent,
          leftColumn: SettingsLeftColumn,
          leftColumnPersist: SettingsLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
        },
      },
      {
        path: '/settings/accountManagement',
        name: 'SettingsAccountManagement',
        components: {
          content: SettingsAccountManagementContent,
          leftColumn: SettingsLeftColumn,
          leftColumnPersist: SettingsLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: false,
        },
      },
      {
        path: '/settings',
        name: 'SettingsContact',
        components: {
          content: SettingsContactContent,
          leftColumn: SettingsLeftColumn,
          leftColumnPersist: SettingsLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: false,
        },
      },
      {
        path: '/documents/paymentHistory',
        name: 'DocumentsPaymentHistory',
        components: {
         content: Documents,
        },
        meta: {
          authenticationRequired: true,
          columnlessLayout: true
        }
      },
      {
        path: '/documents/billHistory',
        name: 'DocumentsBillsHistory',
        components: {
          content: Documents,
        },
        meta: {
          authenticationRequired: true,
          columnlessLayout: true
        }
      },
      {
        path: 'dashboard/provider/:providerId/estimate/:estimateId',
        name: 'SubAccountDetails',
        components: {
          content: SubAccountDetailsContent,
          leftColumn: SubAccountDetailsLeftColumn,
          leftColumnPersist: ProviderLogoLeftColumnPersist,
        },
        meta: {
          useNewLayouts: true,
        },
      },
      {
        path: 'home/provider/:providerId/account/:accountId',
        name: 'AccountDetails',
        components: {
          content: AccountDetailsContent,
          leftColumn: AccountDetailsLeftColumn,
          leftColumnPersist: AccountDetailsLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          showNotificationBanner: true,
          useNewLayouts: true,
        },
      },
      {
        path: '/billSummary',
        name: 'BillSummary',
        components: {
          content: BillSummaryContent,
          leftColumn: BillSummaryLeftColumn,
          leftColumnPersist: ResponsiveProviderLogoLeftColumnPersist,
        },
        meta: {
          headerType: 'none',
          useNewLayouts: true,
        },
      },
      {
        path: '/estimateSummary',
        name: 'EstimateSummary',
        components: {
          content: SubAccountDetailsContent,
          leftColumn: SubAccountDetailsLeftColumn,
          leftColumnPersist: ResponsiveProviderLogoLeftColumnPersist,
        },
        meta: {
          useNewLayouts: true,
        },
      },
      {
        path: 'home',
        name: 'ProvidersSummary',
        components: {
          content: ProvidersContent,
          leftColumn: ProvidersSummaryLeftColumn,
          leftColumnPersist: ProvidersSummaryLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          showNotificationBanner: true,
          useNewLayouts: true,
        },
      },
      {
        path: 'home/provider/:providerId',
        name: 'AccountsSummary',
        components: {
          content: AccountsSummaryContent,
          leftColumn: AccountsSummaryLeftColumn,
          leftColumnPersist: AccountsSummaryLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          showNotificationBanner: true,
          useNewLayouts: true,
        },
      },
      {
        path: 'home/provider/:providerId/payment/account/:accountId/subAccount/:subAccountId',
        name: 'SubAccountPaymentFlow',
        redirect: { name: 'SubAccountPaymentFlowAmountStep' },
        components: {
          content: PaymentFlowContent,
          leftColumnPersist: PaymentFlowLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: true,
        },
        children: [{
          path: 'amount',
          component: PaymentFlowAmountStep,
          name: 'SubAccountPaymentFlowAmountStep',
        }, {
          path: 'date',
          component: PaymentFlowDateStep,
          name: 'SubAccountPaymentFlowDateStep',
        }, {
          path: 'method',
          component: PaymentFlowMethodStep,
          name: 'SubAccountPaymentFlowMethodStep',
        }, {
          path: 'confirmation',
          component: PaymentFlowConfirmationStep,
          name: 'SubAccountPaymentFlowConfirmationStep',
        }],
      },
      {
        path: 'home/provider/:providerId/payment/account/:accountId/estimate/:estimateId',
        name: 'EstimatePaymentFlow',
        redirect: { name: 'EstimatePaymentFlowAmountStep' },
        components: {
          content: PaymentFlowContent,
          leftColumnPersist: PaymentFlowLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: true,
        },
        children: [{
          path: 'amount',
          component: PaymentFlowAmountStep,
          name: 'EstimatePaymentFlowAmountStep',
        }, {
          path: 'date',
          component: PaymentFlowDateStep,
          name: 'EstimatePaymentFlowDateStep',
        }, {
          path: 'method',
          component: PaymentFlowMethodStep,
          name: 'EstimatePaymentFlowMethodStep',
        }, {
          path: 'confirmation',
          component: PaymentFlowConfirmationStep,
          name: 'EstimatePaymentFlowConfirmationStep',
        }],
      },
      {
        path: 'home/provider/:providerId/payment/account/:accountId',
        name: 'AccountPaymentFlow',
        redirect: { name: 'AccountPaymentFlowAmountStep' },
        components: {
          content: PaymentFlowContent,
          leftColumnPersist: PaymentFlowLeftColumnPersist,
        },
        meta: {
          authenticationRequired: true,
          useNewLayouts: true,
        },
        children: [{
          path: 'amount',
          component: PaymentFlowAmountStep,
          name: 'AccountPaymentFlowAmountStep',
        }, {
          path: 'accessOne',
          component: PaymentFlowAccessOneStep,
          name: 'AccountPaymentFlowAccessOneStep',
        }, {
          path: 'curae/application',
          component: PaymentFlowCuraeApplicationStep,
          name: 'AccountPaymentFlowCuraeApplicationStep',
        }, {
          path: 'curae/offers',
          component: PaymentFlowCuraeOffersStep,
          name: 'AccountPaymentFlowCuraeOffersStep',
        }, {
          path: 'curae/accept',
          component: PaymentFlowCuraeAcceptStep,
          name: 'AccountPaymentFlowCuraeAcceptStep',
        }, {
          path: 'curae/existing',
          component: PaymentFlowCuraeExistingStep,
          name: 'AccountPaymentFlowCuraeExistingStep',
        }, {
          path: 'date',
          component: PaymentFlowDateStep,
          name: 'AccountPaymentFlowDateStep',
        }, {
          path: 'method',
          component: PaymentFlowMethodStep,
          name: 'AccountPaymentFlowMethodStep',
        }, {
          path: 'confirmation',
          component: PaymentFlowConfirmationStep,
          name: 'AccountPaymentFlowConfirmationStep',
        }],
      },
      {
        path: 'home/guest/provider/:providerId/payment/account/:accountId',
        name: 'GuestAccountPaymentFlow',
        redirect: { name: 'GuestAccountPaymentFlowAmountStep' },
        components: {
          content: PaymentFlowContent,
          leftColumnPersist: PaymentFlowLeftColumnPersist,
        },
        meta: {
          authenticationRequired: false,
          useNewLayouts: true,
        },
        children: [{
          path: 'amount',
          component: PaymentFlowAmountStep,
          name: 'GuestAccountPaymentFlowAmountStep',
        }, {
          path: 'date',
          component: PaymentFlowDateStep,
          name: 'GuestAccountPaymentFlowDateStep',
        }, {
          path: 'method',
          component: PaymentFlowMethodStep,
          name: 'GuestAccountPaymentFlowMethodStep',
        }, {
          path: 'confirmation',
          component: PaymentFlowConfirmationStep,
          name: 'GuestAccountPaymentFlowConfirmationStep',
        }],
      },
      {
        path: 'home/guest/provider/:providerId/payment/account/:accountId/subAccount/:subAccountId',
        name: 'GuestSubAccountPaymentFlow',
        redirect: { name: 'GuestSubAccountPaymentFlowAmountStep' },
        components: {
          content: PaymentFlowContent,
          leftColumnPersist: PaymentFlowLeftColumnPersist,
        },
        meta: {
          authenticationRequired: false,
          useNewLayouts: true,
        },
        children: [{
          path: 'amount',
          component: PaymentFlowAmountStep,
          name: 'GuestSubAccountPaymentFlowAmountStep',
        }, {
          path: 'date',
          component: PaymentFlowDateStep,
          name: 'GuestSubAccountPaymentFlowDateStep',
        }, {
          path: 'method',
          component: PaymentFlowMethodStep,
          name: 'GuestSubAccountPaymentFlowMethodStep',
        }, {
          path: 'confirmation',
          component: PaymentFlowConfirmationStep,
          name: 'GuestSubAccountPaymentFlowConfirmationStep',
        }],
      }
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  redirectFromLegacyLinksIfNecessary();
  routeWithAuthenticationGuards(store, to, from, next);
  handleQueryParams(store, to, from, next);
  store.commit('setShowLoading', false);
  store.commit('setStateObject', {
    params: to.params || {},
    current: {
      data: {
        authNotRequired: !Boolean(to.matched[to.matched.length - 1] && to.matched[to.matched.length - 1].meta.authenticationRequired),
      },
      params: to.params,
    },
    previous: {
      name: from.name,
    },
  });
});

router.afterEach((to, from, failure) => {
  if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
    console.error("Route duplicated. Don't fail");
  }
});

store.commit('setRouter', router);

export default router;