<template>
    <div class="no-providers">
        <div class="noproviders-icon-container">
            <base-icon :size="iconSize" icon="mdi-file-document-outline"></base-icon>
        </div>
        <div class="data-title">
            {{ $translate('providersDashboard.noBills') }}
        </div>
        <div class="data-description">
            <div v-if="$vuetify.display.lgAndUp">
                {{ $translate('providersDashboard.noBillsDescriptionShort') }}
            </div>
            <div v-else v-html="$translate('providersDashboard.noBillsDescriptionLong')" @click="findBillBottomSheet = true" class="find-bill-bottom-sheet-link"></div>
        </div>
        <base-bottom-sheet v-model="findBillBottomSheet" class="no-providers-bottom-sheet">
            <find-bill-card @findbill="onFindBill" :search-errors="findBillErrors" :submitting="submittingFindBill" :insideBottomSheet="true"></find-bill-card>
        </base-bottom-sheet>
    </div>
</template>

<script>

import FindBillCard from './FindBillCard.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'NoProviders',
    components: {
        FindBillCard,
        BaseBottomSheet,
        BaseIcon
    },
    data: () => ({
        findBillBottomSheet: false,
        submittingFindBill: false,
        findBillErrors: {
            throttled: false,
            notFound: false,
        },
    }),
    methods: {
        closeBottomSheets() {
            this.findBillBottomSheet = false;
            this.emitter.emit('responsive-provider-logo-left-column-persist-close-bottom-sheet');
        },
        async onFindBill(secureCode, billAmount) {
            this.submittingFindBill = true;
            this.findBillErrors.notFound = false;
            this.findBillErrors.throttled = false;
            try {
                const bill = await this.$store.dispatch('findBill', { secureCode, billAmount });
                if (bill) {
                    this.$router.push({name: 'BillSummary'});
                } else {
                    this.findBillErrors.notFound = true;
                }
            } catch(err) {
                if (err && err.errorCode) {
                    if ('SECURECODE_NOT_FOUND' === err.errorCode) {
                        this.findBillErrors.notFound = true;
                    } else if ('SECURECODE_BLOCKED' === err.errorCode) {
                        this.findBillErrors.throttled = true;
                    }
                }
            } finally {
                this.submittingFindBill = false;
            }
        },
    },
    computed: {
        iconSize() {
            return this.$vuetify.display.lgAndUp ? '16rem' : '12.5rem';
        },
    }
};

</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.data-title {
    color: $global-color-font-new;
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 2rem;
    font-size: 2rem;
    font-weight: 500;
    @include wider-than-tablet {
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
        font-size: 2.5rem;
    }
}

.data-description {
    color: $global-color-font-new;
    text-align: center;
    font-size: 1.4rem;
    padding-bottom: 4.2rem;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    @include wider-than-tablet {
        font-size: 1.6rem;
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

.noproviders-icon-container {
    display: flex;
    justify-content: center;
    padding-top: 2.5rem;
    .v-icon {
        background: #E0E0E0;
        padding: 3.2rem;
        border-radius: 50%;
        color: #c2c2c2 !important;
    }
    @include wider-than-tablet {
        padding-top: 7.8rem;
        .v-icon {
            padding: 4rem;
        }
    }
}
</style>