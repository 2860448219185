<template>
    <div class="message-attachments fs-block" data-testid="compose-message-message-attachments">
        <div class="file" v-for="(attachment, index) in attachments" :key="index">
            <base-icon icon="mdi-paperclip"></base-icon>
                {{ attachment.name }} ({{ humanFileSize(attachment.size, true) }}) <a tabindex="0" @click="removeAttachment(index)"><base-icon icon="mdi-close"></base-icon></a>
        </div>
    </div>
</template>

<script>
    

    import BaseIcon from './BaseIcon.vue';

    export default {
        name: 'ComposeMessageMessageAttachments',
        components: {
            BaseIcon
        },
        props: {
            attachments: Array,
        },
        methods: {
            // Shamelessly stolen from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
            humanFileSize: function(bytes, si) {
                var thresh = si ? 1000 : 1024;
                if(Math.abs(bytes) < thresh) {
                    return bytes + ' B';
                }
                var units = si
                    ? ['KB','MB','GB','TB','PB','EB','ZB','YB']
                    : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
                var u = -1;
                do {
                    bytes /= thresh;
                    ++u;
                } while(Math.abs(bytes) >= thresh && u < units.length - 1);
                return bytes.toFixed(1)+' '+units[u];
            },
            removeAttachment: function(index) {
                this.emitter.emit('attachment:remove', index);
            },
        },
    };
</script>
