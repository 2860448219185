<template>
    <div v-show="visible" class="modal vue-modal" :class="{'modal-visible': visible}">
        <div class="modal-content-container">
            <div class="modal-close-icon" @click="hideModal()" v-if="canClose !== false"><base-icon icon="mdi-close"></base-icon></div>
            <div class="modal-inner-content-wrapper">
                <div class="modal-inner-content-container">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'SimpleModal',
    components: {
        BaseIcon
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        canClose: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        show() {
            if (this.show) {
                this.open();
            } else {
                if (this.visible) {
                    this.hideModal();
                }
            }
        },
    },
    data() {
        return {
            visible: this.show,
            overlayKey: null,
        }
    },
    methods: {
        overlayClicked() {
            if(this.canClose !== false) {
                this.hideModal();
            }
        },
        open() {
            this.visible = true;
            this.$store.dispatch('showOverlay', { key: this.overlayKey, clickCallback: this.overlayClicked }).then((key) => {
                this.overlayKey = key;
            });
        },
        hideModal() {
            this.visible = false;

            if(this.overlayKey){
                this.$store.dispatch('hideOverlay', this.overlayKey);
            }
            this.$emit('close');
        },
    },
    created() {
        if (this.show) {
            this.open();
        }
    }
};
</script>
<style lang="scss">

</style>