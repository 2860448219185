<template>
    <v-tabs active-class="active-tab" ref="tabs" v-bind="$attrs">
        <v-tab v-for="tab in tabs" :data-testid="`${tab}-tab`">
            {{ $translate(tab) }}
        </v-tab>
    </v-tabs>
    <v-window v-bind="$attrs">
        <v-window-item v-for="tab in tabs" :data-testid="`${tab}-tab-item`">
            <slot :name="tab"></slot>
        </v-window-item>
    </v-window>
</template>

<!--
    The tabs prop being passed in determines the number and name of the slots to be used for the tab content.
    The values passed into tabs should be labels that will be translated in this component. To use:
    <base-tabs v-model="model" :tabs="['label.one', 'label.two']">
        <template #one>ONE CONTENT</template>
        <template #two>TWO CONTENT</template>
    </base-tabs>
-->

<script>
export default {
    name: 'BaseTabs',
    props: {
        tabs: {
            type: Array
        },
        routeOnClick: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.v-slide-group {
    padding: 0 1rem;
    button {
        .v-btn__content {
            text-transform: uppercase;
            font-size: 1.4rem;
        }
    }
    .v-tab__slider {
        color: $global-color-secondary;
        bottom: 0.5rem;
        height: 3px;
    }
}
</style>