import vueEventTransmitter from "../utils/vueEventTransmitter";

function initialize(store) {
    vueEventTransmitter.on('$stateChangeSuccess', () => {
        if (store.state.AppStore.isBreadCrumbVisible) {
            store.commit('setIsBreadCrumbVisible', false);
            store.commit('setCurrentBreadCrumb', null);
        }
    });

    vueEventTransmitter.on('data:clear', () => {
        store.commit('setCurrentBill', null);
        store.commit('setNotifications', []);
        store.commit('setNotificationsFetched', false);
        store.commit('setNotificationsNumNew', null);
        store.dispatch('changeCurrentUser', null);
        store.dispatch('flushProviders');
    });

    // When the app let's us know an unauthenticated response
    // was made we need to clear out the user session
    vueEventTransmitter.on('unauthedAccessResponse', () => {
        store.dispatch('unauthenticateUser');
    }); 

    vueEventTransmitter.on('autologoutService:refresh', (autologoutStatus) => {
        autologoutStatus.enabled = autologoutStatus.autoLogout;
        store.dispatch('configureAutoLogout', autologoutStatus);
    });

    // Reload all experiments when the user changes
    vueEventTransmitter.on('user:changed', (user) => {
        store.dispatch('identifyFullStory', user);
    });
}

export default { initialize };