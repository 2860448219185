<template>
    <div class="message-create-area">
        <div size="sm" class="type-indicator type-patient">
            <base-icon icon="mdi-circle"></base-icon>
        </div>
        <div class="message-content">
			<div class="message-header"> {{ $formatDate(today, 'MMM DD, YYYY') }} </div>
			<textarea
                class="fs-block"
                :placeholder="$translate('message.createAreaPlaceholder')"
                maxlength="5000"
				v-model="message">
            </textarea>
			<input type="file" ref="attachment" class="message-attachment-input fs-block" v-show="false" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.txt" multiple @change="addAttachment($event.target.files)">
            <ComposeMessageMessageAttachments :attachments="attachments"></ComposeMessageMessageAttachments>
		</div>
		<div class="interaction-feedback" v-show="errorMessage() != ''">
            {{ errorMessage() }}
        </div>

		<button class="button-inline left attach-file" :disabled="attachments.length >= 3" @click="clickAddAttachment"> {{$translate('actions.attachFile')}} </button>
		<button class="button-inline button-primary send-reply" @click="reply()"> {{ sendDialog }}</button>
	</div>
</template>



<script>

import ComposeMessageMessageAttachments from './ComposeMessageMessageAttachments.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'MessageDetailsCreateMessageArea',
    components: {
        ComposeMessageMessageAttachments,
        BaseIcon
    },
    props: {
        sendDialog: String,
        errorNotifications: Object,
    },
    data: () => {
        return {
            message: String,
            attachments: Array,
            triedAddingTooManyAttachments: Boolean,
        }
    },
    computed: {
        today: function() {
            var date = new Date();
            //plus one on the month becauese it is 0 based for some reason
            var month = date.getMonth() + 1;
            var dateString = `${date.getFullYear()}/${month}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            return dateString;
        }
    },
    created: function() {
        this.triedAddingTooManyAttachments = false;
        this.message = "";
        this.attachments = [];
        this.emitter.on('messageDetails:replySentSuccessfully', this.clearCreateMessageData);
        this.emitter.on('attachment:remove', this.removeAttachment);
    },
    methods: {
        errorMessage: function() {
            var result = "";
            if (this.errorNotifications.tooManyFiles) {
                result = this.$translate('errors.tooManyFiles');
            } else if (this.errorNotifications.genericFileError) {
                result = this.$translate('errors.genericFileError');
            } else if (this.errorNotifications.fileTooLarge) {
                result = this.$translate('errors.fileTooLarge');
            } else if (this.errorNotifications.invalidFileType) {
                result = this.$translate('errors.invalidFileType');
            } else if (this.errorNotifications.messageRequiredForAttachments) {
                result = this.$translate('errors.messageRequiredForAttachments');
            } else if (this.errorNotifications.messageRequired) {
                result = this.$translate('errors.genericRequired');
            }
            if (result == this.$translate('errors.tooManyFiles') && this.attachments.length <= 3) {
                result = "";
            }
            if ((result == "" && this.attachments.length > 3) || this.triedAddingTooManyAttachments) {
                result = this.$translate('errors.tooManyFiles');
            }
            return result;
        },
        addAttachment: function(files) {
            this.triedAddingTooManyAttachments = false;
            for (var i = 0; i<files['length']; i++) {
                if (this.attachments.length < 3) {
                    this.attachments.splice(this.attachments.length, 1, files[i]);
                } else {
                    this.triedAddingTooManyAttachments = true;
                }
            }
            this.$forceUpdate();
        },
        clickAddAttachment: function() {
            this.$refs.attachment.click();
        },
        removeAttachment: function(index) {
            this.triedAddingTooManyAttachments = false;
            let toDelete = this.attachments[index];
            this.attachments = this.attachments.filter(a => {
                return a.name != toDelete.name;
            });
        },
        reply: function() {
            this.triedAddingTooManyAttachments = false;
            this.emitter.emit('messageDetails:sendReply', {
                message: this.message,
                attachments: this.attachments
            });
        },
        clearCreateMessageData: function() {
            this.triedAddingTooManyAttachments = false;
            this.attachments = [];
            this.message = "";
            this.$forceUpdate();
        },
    },
    

};
</script>
