<template>
<div v-if="currentProvider && currentProvider.accounts" class="account-details-left-column-persist vuetify" data-app>
    <div v-if="$vuetify.display.lgAndUp">
        <div class="block-segment">
            <provider-logo :provider-id="currentProvider.id" :alt-text="currentProvider.name"></provider-logo>
        </div>
    </div>
    <div v-else class="responsive-left-column-persist-mobile">
        <provider-logo :provider-id="currentProvider.id" :alt-text="currentProvider.name"></provider-logo>
        <base-btn
            @click="needHelpBottomSheet = true"
            class="need-help-sheet-btn"
            variant="outlined"
        >{{ $translate('labels.needHelp') }}</base-btn>
    </div>
    <base-bottom-sheet v-model="needHelpBottomSheet" content-class="need-help-sheet">
        <card-header
            :title="$translate('labels.needHelp')"
            icon="mdi-hospital-box-outline"
            class="sheet-card-header"
        ></card-header>
        <need-help
            @composemessage="composeMessage"
            :provider="currentProvider"
        ></need-help>
    </base-bottom-sheet>
</div>
</template>

<script>


import CardHeader from './CardHeader.vue';
import NeedHelp from './NeedHelp.vue';
import ProviderLogo from './ProviderLogo.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseBtn from './BaseBtn.vue';

export default {
    name: 'AccountDetailsLeftColumnPersist',
    components: {
        CardHeader,
        NeedHelp,
        ProviderLogo,
        BaseBottomSheet,
        BaseBtn,
    },
    data: () => ({
        needHelpBottomSheet: false,
    }),
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        providers() {
            return this.$store.getters.providers;
        },
        numberOfPastDueAccounts() {
            if (this.currentProvider && this.currentProvider.accounts) {
                return this.currentProvider.accounts.filter(a => a.isPastDue).length;
            }
            return 0;
        },
    },
    methods: {
        composeMessage() {
            this.emitter.emit('responsive-provider-logo-left-column-persist-compose-message');
        },
    },
};
</script>

<style lang="scss">
@import './../styles/variables.scss';
.vuetify.account-details-left-column-persist {
    .back-button, .logo-container {
        flex: 1;
    }
}
</style>