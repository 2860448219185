<template>
<div class="settings-content">
	<div v-if="showConfirmEmail" @click="confirmEmail()">
        <notification v-model="confirmEmailMessage" class="clickable confirm-email-notification" :showInformationIcon="true" >
            <div class="fs-block">{{ confirmEmailMessage }}</div>
        </notification>
	</div>

	<div v-if="showConfirmPhone && !showConfirmEmail && !currentUser.isMyChartSSOOnlyUser" @click="confirmPhone()">
		<notification class="clickable confirm-phone-notification" :showInformationIcon="true">
			<div class="fs-block">{{ $translate('actions.confirmPhoneNumber') }}</div>
		</notification>
	</div>

	<form novalidate @submit.prevent name="forms.detailsForm" data-testid="settings-contact-form">

		<div class="block-segment">

			<h2 class="call-out  settings-details-header settings-user-details-header">{{ $translate('labels.userDetails') }}</h2>

			<notification class="settings-save-note notification-inline success" v-show="changePosted">
				<base-icon icon="mdi-close-circle"></base-icon>
				{{ $translate('labels.changesSaved') }}
			</notification>

            <notification class="settings-save-note notification-inline failure" v-show="changeFailed">
                <base-icon icon="mdi-close-circle"></base-icon>
                {{ $translate('labels.changesFailed') }}
            </notification>

			<div class="separator"></div>

				<div class="split-input-areas-container">

					<!-- FIRST NAME -->
					<div class="input-area-wrapper" :class="{errored: forms.detailsForm.firstName.invalid && forms.detailsForm.firstName.dirty, 'input-focused': forms.detailsForm.firstName.focused}">
						<div class="input-area-field-wrapper">
							<span :class="{ 'icon-active': forms.detailsForm.firstName.dirty }">
								<base-icon icon="mdi-account"></base-icon>
							</span>

							<input required name="firstName" type="text" id="details-first"
									class="fs-block input-with-icon" valid-name  :placeholder="$translate('labels.firstName')"
									@focus="forms.detailsForm.firstName.focused = true; $forceUpdate()"
									@blur="validateFirstName(); forms.detailsForm.firstName.focused = false; $forceUpdate()"
									@change="forms.detailsForm.firstName.dirty = true; $forceUpdate()"
									@input="onInputFirstName"
									data-testid="settings-contact-form-first-name"
									:disabled="disableAccountMangement"
									v-model="firstName" />
						</div>

						<div class="input-area-feedback">
							<div v-if="forms.detailsForm.firstName.invalid" class="vue-messages">
								<div v-if="forms.detailsForm.firstName.errors.required" class="vue-message">
									{{ $translate('errors.genericRequired') }}
								</div>
							</div>
						</div>

					</div>


					<!-- LAST NAME -->
					<div class="input-area-wrapper" :class="{errored: forms.detailsForm.lastName.invalid && forms.detailsForm.lastName.dirty, 'input-focused': forms.detailsForm.lastName.focused}">
						<div class="input-area-field-wrapper">
							<span :class="{ 'icon-active': forms.detailsForm.lastName.dirty }">
								<base-icon icon="mdi-account"></base-icon>
							</span>

							<input required name="lastName" type="text" id="details-last"
									class="fs-block input-with-icon" valid-name  :placeholder="$translate('labels.lastName')"
									@focus="forms.detailsForm.lastName.focused = true; $forceUpdate()"
									@blur="validateLastName(); forms.detailsForm.lastName.focused = false; $forceUpdate()"
									@change="forms.detailsForm.lastName.dirty = true; $forceUpdate()"
									@input="onInputLastName"
									data-testid="settings-contact-form-last-name"
									:disabled="disableAccountMangement"
									v-model="lastName" />
						</div>

						<div class="input-area-feedback">
							<div v-if="forms.detailsForm.lastName.invalid" class="vue-messages">
								<div v-if="forms.detailsForm.lastName.errors.required" class="vue-message">
									{{ $translate('errors.genericRequired') }}
								</div>
							</div>
						</div>
					</div>

				</div>

				<!-- EMAIL -->
				<div class="input-area-wrapper" :class="{errored: forms.detailsForm.email.invalid && forms.detailsForm.email.touched, 'input-focused': forms.detailsForm.email.focused}">
					<legacy-email-input
						v-model="email"
						ref="email"
						@change="forms.detailsForm.email.touched = true"
						@inputfocus="forms.detailsForm.email.focused = true; $forceUpdate();"
						@inputblur="validateField('email'); forms.detailsForm.email.focused = false; $forceUpdate();"
						:original-email="currentUser.email"
						:disableAccountMangement="disableAccountMangement"
						required
						is-update
						id="details-email"
					></legacy-email-input>

					<div class="input-area-feedback">
						<div v-if="forms.detailsForm.email.invalid" class="vue-messages">
							<div v-if="forms.detailsForm.email.errors.required" class="vue-message">
								{{ $translate('errors.genericRequired') }}
							</div>
							<div v-else-if="forms.detailsForm.email.errors.invalid" class="vue-message">
								{{ $translate('errors.email') }}
							</div>
							<div v-else-if="forms.detailsForm.email.errors.emailAvailable" class="vue-message">
								{{ $translate('errors.emailInUse') }}
							</div>
						</div>
					</div>
				</div>


				<!-- PHONE -->
				<div class="input-area-wrapper"
					:class="{errored: forms.detailsForm.phoneNumber.invalid && (forms.detailsForm.phoneNumber.dirty), 'input-focused': forms.detailsForm.phoneNumber.focused}">
					<legacy-phone-number-input
						v-model="phoneNumber"
						ref="phoneNumber"
						@change="forms.detailsForm.phoneNumber.dirty = true"
						@inputfocus="forms.detailsForm.phoneNumber.focused = true; $forceUpdate();"
						@inputblur="validateField('phoneNumber'); forms.detailsForm.phoneNumber.focused = false; $forceUpdate();"
						:disableAccountMangement="disableAccountMangement"
					></legacy-phone-number-input>

					<div class="input-area-feedback">
						<div v-if="forms.detailsForm.phoneNumber.invalid" class="vue-messages">
							<div v-if="forms.detailsForm.phoneNumber.errors.minlength" class="vue-message">
								{{ $translate('errors.phoneFormat') }}
							</div>
							<div v-if="forms.detailsForm.phoneNumber.errors.smsEligible" class="vue-message">
								{{ $translate('errors.smsEligible') }}
							</div>
						</div>
					</div>
				</div>
		</div>

		<div class="block-segment">

			<h2 class="call-out settings-details-header settings-communication-prefs-header">{{ $translate('labels.communicationPrefs') }}</h2>

			<div class="separator"></div>

			<!-- EBILL -->

			<div class="input-area-wrapper" :class="{'input-focused': forms.detailsForm.receivesEbills.focused}">
				<div class="input-area-field-wrapper">
					<input type="checkbox" id="details-ebill" v-model="receivesEbills"
						@focus="forms.detailsForm.receivesEbills.focused = true"
						@blur="forms.detailsForm.receivesEbills.focused = false" />
					<label for="details-ebill">
						<span>{{ $translate('setting.ebillDialog') }}</span>
						<base-icon @click.prevent="triggerMethodModal('email')" icon="mdi-information" class="info-icon"></base-icon>
					</label>
				</div>
			</div>

			<!-- SMS -->
			<div class="input-area-wrapper" :class="{'input-focused': forms.detailsForm.receivesSms.focused}">
				<div class="input-area-field-wrapper">
					<input type="checkbox" id="details-sms" v-model="receivesSms"
						@focus="forms.detailsForm.receivesSms.focused = true"
						@blur="forms.detailsForm.receivesSms.focused = false" />
					<label for="details-sms">
						<span>{{ $translate('setting.smsDialog') }}</span>
						<base-icon @click.prevent="triggerMethodModal('sms')" icon="mdi-information" class="info-icon"></base-icon>
					</label>
				</div>
			</div>
		</div>

		<button class="button-primary settings-save-button" @click.prevent="saveChanges()">{{ submitting ? $translate('labels.savingChanges') : $translate('actions.saveChanges') }}</button>
	</form>
</div>
</template>
<script>
import _ from 'lodash';

import LegacyEmailInput from './LegacyEmailInput.vue';
import LegacyPhoneNumberInput from './LegacyPhoneNumberInput.vue';
import Notification from './Notification.vue';
import { getValidName } from '../utils/utils';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'SettingsContactContent',
	components: {
		LegacyEmailInput,
		LegacyPhoneNumberInput,
		Notification,
		BaseIcon
	},
	data: () => ({
		showConfirmEmail: false,
		showConfirmPhone: false,
		changePosted: false,
		changeFailed: false,

		submitting: false,

		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		receivesEbills: false,
		receivesSms: false,

		forms: {
			detailsForm: {
				invalid: false,
				firstName: {},
				lastName: {},
				email: {},
				phoneNumber: {},
				receivesEbills: {},
				receivesSms: {},
			},
		},
		disableAccountMangement: false,
	}),
    computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
		brandingConfig() {
			return this.$store.getters.brandingConfig;
		}
    },
    methods: {
		confirmEmail() {
			this.emitter.emit('confirmEmail:prompt');
		},
        confirmPhone() {
			this.emitter.emit('confirmPhone:prompt');
		},
		resetForm() {
			this.forms = {
				invalid: false,
				detailsForm: {
					firstName: {},
					lastName: {},
					email: {},
					phoneNumber: {},
					receivesEbills: {},
					receivesSms: {},
				},
			};
			this.firstName = this.currentUser.firstName;
			this.lastName = this.currentUser.lastName;
			this.email = this.currentUser.email;
			this.phoneNumber = this.currentUser.phoneNumber;
			this.receivesSms = Boolean(this.currentUser.receivesSms);
			this.receivesEbills = Boolean(this.currentUser.receivesEbills);
			this.refreshConfirmPhoneNotification();
			this.refreshConfirmEmailNotification();
		},
		async validateField(field) {
			const error = await this.$refs[field].validate();
			this.forms.detailsForm[field].errors = {};
			if (error) {
				this.forms.detailsForm.invalid = true;
				this.forms.detailsForm[field].invalid = true;
				this.forms.detailsForm[field].errors[error] = true;
			} else {
				this.forms.detailsForm[field].invalid = false;
			}

			this.$forceUpdate();
		},
		validateFirstName() {
			if (!this.firstName.match(/\S/)) {
				this.forms.detailsForm.firstName.invalid = true;
				this.forms.detailsForm.firstName.errors = {
					required: true,
				};
			} else {
				this.forms.detailsForm.firstName.invalid = false;
				this.forms.detailsForm.firstName.errors = {
					required: false,
				};
			}
		},
		validateLastName() {
			if (!this.lastName.match(/\S/)) {
				this.forms.detailsForm.lastName.invalid = true;
				this.forms.detailsForm.lastName.errors = {
					required: true,
				};
			} else {
				this.forms.detailsForm.lastName.invalid = false;
				this.forms.detailsForm.lastName.errors = {
					required: false,
				};
			}
		},
		async validateForm() {
			this.forms.detailsForm.invalid = false;
			await this.validateField('email');
			await this.validateField('phoneNumber');
			this.validateFirstName();
			this.validateLastName();
		},
		onInputFirstName() {
			this.firstName = getValidName(this.firstName);
		},
		onInputLastName() {
			this.lastName = getValidName(this.lastName);
		},
		async saveChanges() {
			await this.validateForm();
			if (!this.forms.detailsForm.invalid) {
				const completeSave = (currentPassword) => {
					this.submitting = true;
					var payload = _.assign({}, {
						firstName: this.firstName,
						lastName: this.lastName,
						email: this.email,
						phoneNumber: this.phoneNumber,
						receivesEbills: this.receivesEbills,
						receivesSms: this.receivesSms,
					}, {'currentPassword': currentPassword});

					this.$store.dispatch('updateUser', payload).then(() => {

						this.refreshConfirmPhoneNotification();
						this.refreshConfirmEmailNotification();

						this.changePosted = true;

						window.setTimeout(() => {
							this.changePosted = false;
						}, 3000);

					})
					.catch(() => {
						this.changeFailed = true;

						window.setTimeout(() => {
							this.changeFailed = false;
						}, 3000);
					})
					.finally(() => {
						this.submitting = false;
						window.scroll(0, 0);
					});
				};
				if (this.currentUser.email !== this.email || this.currentUser.phoneNumber !== this.phoneNumber) {
					this.emitter.emit('confirmPassword:prompt', {
						'callback': completeSave,
					});
				} else {
					completeSave();
				}
			}
		},
		triggerMethodModal(method) {
			this.emitter.emit('simpleModal:showPrompt', {
				header: this.$translate('setting.modals.' + method + '.header'),
				subcontent: this.$translate('setting.modals.' + method + '.content'),
				actions: [{
					label: this.$translate('actions.close'),
					includeClasses: 'button-primary',
				}],
			});
		},
		refreshConfirmPhoneNotification() {
            this.showConfirmPhone = !this.currentUser.phoneNumberConfirmed && !this.currentUser.newUser;
        },
		refreshConfirmEmailNotification() {
			this.email = this.currentUser.email;
            this.showConfirmEmail = !this.currentUser.emailVerified && !this.currentUser.newUser && !this.currentUser.newEmail;
            this.confirmEmailMessage = this.$translate('actions.confirmEmail', {email: this.currentUser.email});
        },
		onConfirmPhone() {
			this.$store.dispatch('fetchCurrentUser').then((user) => {
				this.firstName = user.firstName;
				this.lastName = user.lastName;
				this.email = user.email;
				this.phoneNumber = user.phoneNumber;
				this.receivesSms = Boolean(user.receivesSms);
				this.receivesEbills = Boolean(user.receivesEbills);
				this.refreshConfirmPhoneNotification();
				this.refreshConfirmEmailNotification();
			});
		},
    },
	mounted() {
		this.resetForm();
		if (this.brandingConfig && this.brandingConfig.externalAccountManagement) {
			this.disableAccountMangement = true;
		}
	},
	created() {
		this.emitter.on('userSettings:updated', this.refreshConfirmEmailNotification);
    	this.emitter.on('confirmPhone:confirmed', this.onConfirmPhone);
	},
	destroyed() {
		this.emitter.off('userSettings:updated', this.refreshConfirmEmailNotification);
    	this.emitter.off('confirmPhone:confirmed', this.onConfirmPhone);
	},
};
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.info-icon {
	height: 1.5rem;
	width: 1.5rem;
	margin: 0.25rem 0 0 0.5rem !important;
	color: $global-color-dark-grey !important;
}
.base-icon {
	color: $global-color-dark-grey !important;
}
.input-with-icon {
	display: inline;
}
</style>