<template>
<div v-if="$vuetify.display.lgAndUp" class="providers-summary-left-column vuetify">
    <find-bill-card @findbill="onFindBill" :search-errors="findBillErrors" :submitting="submittingFindBill" :insideBottomSheet="false"></find-bill-card>
</div>
</template>
<script>
import FindBillCard from './FindBillCard.vue';

export default {
    name: 'ProvidersSummaryLeftColumn',
    components: {
        FindBillCard,
    },
    data: () => ({
        submittingFindBill: false,
        findBillErrors: {
            throttled: false,
            notFound: false,
        },
    }),
    methods: {
        async onFindBill(secureCode, billAmount) {
            this.submittingFindBill = true;
            this.findBillErrors.notFound = false;
            this.findBillErrors.throttled = false;
            try {
                const bill = await this.$store.dispatch('findBill', { secureCode, billAmount });
                if (bill) {
                    this.$router.push({name: 'BillSummary'});
                } else {
                    this.findBillErrors.notFound = true;
                }
            } catch(err) {
                if (err && err.errorCode) {
                    if ('SECURECODE_NOT_FOUND' === err.errorCode) {
                        this.findBillErrors.notFound = true;
                    } else if ('SECURECODE_BLOCKED' === err.errorCode) {
                        this.findBillErrors.throttled = true;
                    }
                }
            } finally {
                this.submittingFindBill = false;
            }
        },
    },
};
</script>
<style lang="scss">
</style>