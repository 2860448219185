<template>
<div class="responsive-left-column-persist">
    <div v-if="isPlainLogoVisible" class="responsive-left-column-persist-desktop">
        <div class="block-segment">
            <provider-logo :provider-id="providerId" :alt-text="providerName"></provider-logo>
        </div>
    </div>
    <div v-else-if="isNeedHelpVisible" class="responsive-left-column-persist-mobile">
        <provider-logo :providerId="providerId" :altText="providerName"></provider-logo>
        <base-btn
            @click="needHelpBottomSheet = true"
            class="need-help-sheet-btn"
            variant="outlined"
        >{{ $translate('labels.needHelp') }}</base-btn>
    </div>
    <base-bottom-sheet v-model="needHelpBottomSheet" content-class="need-help-sheet">
        <card-header
            :title="$translate('labels.needHelp')"
            icon="mdi-hospital-box-outline"
            class="sheet-card-header"
        ></card-header>
        <need-help
            @composemessage="composeMessage"
            :provider="provider || currentProvider"
            data-testid="responsive-provider-logo-left-column-persist-need-help-mobile"
        ></need-help>
    </base-bottom-sheet>
</div>
</template>

<script>


import CardHeader from './CardHeader.vue';
import NeedHelp from './NeedHelp.vue';
import ProviderLogo from './ProviderLogo.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseBtn from './BaseBtn.vue';

export default {
    name: 'ResponsiveProviderLogoLeftColumnPersist',
    components: {
        BaseBtn,
        CardHeader,
        NeedHelp,
        ProviderLogo,
        BaseBottomSheet
    },
    props: {
        provider: Object,
        hideOnMobile: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        providerId: null,
        providerName: null,
        needHelpBottomSheet: false,
    }),
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        isPlainLogoVisible() {
            return this.providerId && this.$vuetify.display.lgAndUp;
        },
        isNeedHelpVisible() {
            return this.providerId && !this.$vuetify.display.lgAndUp && !this.hideOnMobile;
        },
    },
    watch: {
        provider() {
            this.setFields();
        },
        currentProvider() {
            this.setFields();
        },
    },
    methods: {
        setFields() {
            if (this.provider) {
                this.providerId = this.provider.id;
                this.providerName = this.provider.name;
            } else if (this.currentProvider) {
                this.providerId = this.currentProvider.id;
                this.providerName = this.currentProvider.name;
            }
        },
        composeMessage() {
            // Different pages will want to handle sending a message different ways,
            // so instead of handling each case individually, allow root Content components
            // to listen for these events.
            this.emitter.emit('responsive-provider-logo-left-column-persist-compose-message');
        },
        closeBottomSheet() {
            this.needHelpBottomSheet = false;
        },
    },
    mounted() {
        this.setFields();
        this.emitter.on('responsive-provider-logo-left-column-persist-close-bottom-sheet', this.closeBottomSheet);
    },
    destroyed() {
        this.emitter.off('responsive-provider-logo-left-column-persist-close-bottom-sheet', this.closeBottomSheet);
    },
};
</script>

<style lang="scss">
@import './../styles/variables.scss';
.responsive-left-column-persist {
    .logo-container {
        img {
            max-width: 17rem !important;
            max-height: 6rem !important;
            @include wider-than-tablet {
                max-width: 20rem !important;
                max-height: 12rem !important;
            }
        }
    }
    .responsive-left-column-persist-desktop {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }
    .responsive-left-column-persist-mobile {
        display: flex;
        align-items: center;
        padding: 1rem 1.6rem;
        .v-btn.vuetify.need-help-sheet-btn {
            color: $global-color-secondary;
            font-size: 1.2rem;
            padding-top: 0.8rem;
            padding-bottom: 0.6rem;
            padding-left: 1.8rem;
            padding-right: 1.8rem;
            height: unset;
        }
        .logo-container {
            text-align: start !important;
        }
    }
}
.responsive-left-column-persist-mobile {
    display: flex;
    align-items: center;
    padding: 1rem 1.6rem;
    .v-btn.vuetify.need-help-sheet-btn {
        color: $global-color-secondary;
        font-size: 1.2rem;
        padding-top: 0.8rem;
        padding-bottom: 0.6rem;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
        height: unset;
    }
    .logo-container {
        text-align: start !important;
    }
}
</style>