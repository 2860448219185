import _ from 'lodash';
import { formatCurrency, translate, MoneyUtils } from '../utils/utils';
import SubAccount from './SubAccount';

class Estimate {
    constructor(estimateServerResponse) {
        let object = estimateServerResponse;
        if (object && object.estimate) {
            object = object.estimate;
        }
        _.assign(this, object);
        this.estimateSource = object;
        this.subAccount = SubAccount.fromEstimate(this);
        this._paymentOptions = null; //needs the _ since assign will assign a value to 'paymentOptions'
        this._paymentOptionsRemainder = null;
        this.__estimate__ = this.estimateSource;
    }

    getStatus() {
        if (this.cancelled) {
            return 'VOID';
        } else if (this.subAccountStatus == "PAYMENT_PLAN") {
            return 'PAYMENT_PLAN_INPROGRESS';
        } else if (this.subAccount.serviceDate) {
            return 'DUE_DATE';
        }
        return null;
    }

    getTotalAmount() {
        return this.estimateSource.totalAmount;
    }

    // Get the payment options that are also allowed on the
    // estimate. We consider a custom amount and the estimate amount as
    // items that will always be presented to the user.
    getPaymentOptions() {

        if (this._paymentOptions !== null) {
            return this._paymentOptions;
        }
        this._paymentOptions = [];

        _.map(this.estimateSource.paymentOptions, (option) => {

            var key = '',
                translationVars = {
                    calculatedTotal: formatCurrency(option.amount),
                    discountPolicy: option.discountPolicy,
                    percentage: ((option.amount / this.estimateSource.totalAmount) * 100).toFixed(0)
                };

            // Type shortcode
            // Only show balance type payments in amount default
            switch (option.type.toUpperCase()) {
                case 'ESTIMATE_BALANCE': // Pay total now
                    key = 'payment.amount.payEstimateBal';
                    option.dialog = translate(key, translationVars);
                    this._paymentOptions.push(option);
                    break;
                case 'ESTIMATE_MIN_REQUESTED':
                    if ((translationVars.percentage != 100) && (translationVars.percentage != 0)) {
                        key = 'payment.amount.payEstimateRequestedAmount';
                        option.dialog = translate(key, translationVars);
                        this._paymentOptions.push(option);
                    }
                    break;
            }                        
        });
        return this._paymentOptions;
    }

    getPaymentOptionsRemainder() {
        if (this._paymentOptionsRemainder !== null) {
            return this._paymentOptionsRemainder;
        }
        this._paymentOptionsRemainder = [];

        _.map(this.estimateSource.paymentOptions, (option) => {

            var key = '',
                translationVars = {
                    calculatedTotal: formatCurrency(option.amount),
                    discountPolicy: option.discountPolicy
                };

            // Type shortcode
            // Only show agreement type payments in estimate-remainder page
            switch (option.type.toUpperCase()) {
                case 'ESTIMATE_AGREEMENT': // Payment Agreement
                    key = 'payment.amount.payEstimateBalAgreement';
                    option.dialog = translate(key, translationVars);
                    this._paymentOptionsRemainder.push(option);
                    break;
            }                        
        });
        return this._paymentOptionsRemainder;
    }

    // get the specific details for a given payment option
    getPaymentOption(index) {
        if (this._paymentOptions === null) {
            // hydrate the options if this
            // is called before get paymentOptions
            this.getPaymentOptions();
        }
        return this._paymentOptions[index];
    }

    // get the specific details for a given payment option
    getPaymentOptionRemainder(index) {
        if (this._paymentOptionsRemainder === null) {
            // hydrate the options if this
            // is called before get paymentOptions
            this.getPaymentOptionsRemainder();
        }
        return this._paymentOptionsRemainder[index];
    }

    // get the details for the provider associated with this estimate
    getProviderDetails() {
        return this.estimateSource.providerDetails;
    }

    // get the details for the provider associated with this estimate
    getAccountDetails() {
        return this.estimateSource.accountDetails;
    }

    // we assume there is no address because there may not be a bill
    getGuarantorAddress() {
        return null;
    }

    isProviderActive() {
        return this.estimateSource.providerDetails.active;
    }

    canMessage() {
        return this.isProviderActive() && _.includes(this.estimateSource.providerDetails.features, 'messaging');
    }

    // estimates cannot be past due
    isPastDue() {
        return false;
    }

    hasZeroBalance() {
        return parseFloat(this.estimateSource.accountBalance.amount || 0) === 0;
    }

    hasNegativeBalance() {
        return parseFloat(this.estimateSource.accountBalance.amount || 0) < 0;
    }

    // get the diff of what the estimate has vs what
    // is owed on the account
    balanceDiff() {
        return MoneyUtils.subtract(this.estimateSource.accountBalance.amount, this.estimateSource.estimatedAmount);
    }

    // get the diff of what the estimate has vs what
    // is owed on the account
    remainderAmount() {
        return MoneyUtils.subtract(this.estimateSource.accountBalance.amount, this.estimateSource.requestedPaymentAmount);
    }

    reflectsDbu() {

        if (!this.isProviderActive()) {
            return false;
        }

        return _.indexOf(['dbu', 'mbu'], _.trim(this.estimateSource.accountBalance.lastAction).toLowerCase()) >= 0 &&
            !MoneyUtils.equals(this.estimateSource.accountBalance.amount, this.estimateSource.estimatedAmount);
    }

    hasFinancingOption() {
        return this.isProviderActive() && this.estimateSource.hasFinancing;
    }
}

export default Estimate;
