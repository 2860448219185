<template><v-chip :text-color="textColor" :color="color" :size="size" class="base-chip" variant="flat"><slot></slot></v-chip></template>
<script>
export default {
    name: 'BaseChip',
    props: {
        textColor: {
            type: String,
            default: "#FFFFFF"
        },
        color: {
            type: String,
            default: "#CE1818"
        },
        small: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        size() {
            if (this.small) {
                return 'small';
            }
            return null;
        }
    }
};
</script>