<template>
	<div v-if="estimate" class="vuetify sub-account-details-content">
		<SubAccountDetailsBalance :estimate="estimate" @pay-estimate="payEstimate()"></SubAccountDetailsBalance>
		<AppointmentInfoCard v-if="!$vuetify.display.lgAndUp" :appointmentInfo="appointmentInfo" mode="estimate"></AppointmentInfoCard>
		<sub-account-services-card :services="services"></sub-account-services-card>
		<SubAccountDetailsCostBreakdown :estimate="estimate"></SubAccountDetailsCostBreakdown>
        <need-help-card v-if="!$vuetify.display.lgAndUp" @composemessage="composeMessage" :provider="provider"></need-help-card>
	</div>
</template>

<script>
import SubAccountDetailsBalance from './SubAccountDetailsBalance.vue';
import AppointmentInfoCard from './AppointmentInfoCard.vue';
import SubAccountServicesCard from './SubAccountServicesCard.vue';
import SubAccountDetailsCostBreakdown from './SubAccountDetailsCostBreakdown.vue';
import NeedHelpCard from './NeedHelpCard.vue';

export default {
	name: 'SubAccountDetailsContent',
	components: {
		SubAccountDetailsBalance,
		AppointmentInfoCard,
		SubAccountServicesCard,
		SubAccountDetailsCostBreakdown,
        NeedHelpCard,
	},
	data() {
		return {
            provider: {},
            messageProviderData: {},
            estimate: {},
            appointmentInfo: {},
		}
    },
    computed: {
        currentEstimate() {
            return this.$store.getters.currentEstimate;
        },
        services() {
            return this.$store.getters.estimateServices;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },
    created() {
        this.$store.commit('setShowLoading', true);
        this.$nextTick(() => {
            if (this.shouldUseEstimateIdFromUrl(window.location.hash || window.location.pathname)) {
                this.loadEstimateFromUrl();
            } else if (this.currentEstimate) {
                this.loadCurrentEstimate(this.currentEstimate);
            } else {
                this.$router.push({name: 'Home'});
            }
        });
    },
    methods: {
        shouldUseEstimateIdFromUrl(pathname) {
            if (pathname.includes("provider/") && pathname.includes("estimate/")) {
                return true;
            }
            return false;
        },
        // url format: #/dashboard/provider/ZZ5E3AD0/estimate/97741078
        _getEstimateIdFromRoute(pathname) {
            return this.$route.params.estimateId ?? null;
        },
        _getProviderIdFromRoute(pathname) {
            return this.$route.params.providerId ?? null;  
        },
        _getEstimateFromProvider(provider, estimateId) {
            let estimate;
            provider.accounts.forEach(function (account) {
                account.estimates.forEach(function (e) {
                    if (e.id == estimateId) {
                        estimate = e;
                    }
                })
            });
            return estimate;
        },
        loadEstimateFromUrl() {
            // we are on the estimate details page, look for ids in url to load vuex
            var pathname = window.location.hash || window.location.pathname;
            let estimateId = this._getEstimateIdFromRoute(pathname);
            let providerId = this._getProviderIdFromRoute(pathname);
            this.$store.dispatch('getProviderById', providerId).then((provider) => {
                this.provider = provider;
                this.messageProviderData = { "estimateId": estimateId, "reason": 11 };
                this.estimate = this._getEstimateFromProvider(provider, estimateId);
                this.estimate.providerDetails = provider;
                this.$store.commit('setCurrentEstimate', this.estimate);
                this.appointmentInfo = this.estimate.appointmentInfo;
                this.$store.commit('setShowLoading', false);
            }).catch((err) => {
                console.error('Error: ' + err);
                this.$store.commit('setShowLoading', false);
                this.$router.push({name: 'ProvidersSummary'});
            });
        },
        loadCurrentEstimate(estimate) {
            // we are on verify estimate page, look for the ids in vuex
            if (!estimate) {
                this.$router.push({name: 'ProvidersSummary'});
                this.$store.commit('setShowLoading', false);
            } else {
                this.estimate = estimate;
                this.$store.commit('setCurrentEstimate', estimate);
                this.provider = estimate.providerDetails;
                this.messageProviderData = { "estimateId": estimate.id, "reason": 11 };
                this.appointmentInfo = estimate.appointmentInfo;
                this.$store.commit('setShowLoading', false);
            }
        },
        payEstimate() {
            this.requireAuthedUserForLink('EstimatePaymentFlowAmountStep', {
                accountId: this.estimate.accountId,
                providerId: this.provider.id,
                estimateId: this.estimate.id,
            });
        },
        requireAuthedUserForLink(state, params) {
            const onAuthSuccess = () => {           
                this.getUserAndLink(state, params);
            };
            this.emitter.emit('require-authed-user', {params:{}, successCallback:onAuthSuccess});
        },
        getUserAndLink(state, params) {
            this.$store.dispatch('linkEstimate', this.estimate).then(() => {
                if (this.estimate.providerDetails.requiresRedirectToBranded) {
                    this.emitter.emit('redirect:tab', {
                        provider: this.provider,
                        context: {
                            stateGo: {
                                to: state,
                                toParams: params
                            }
                        }
                    });
                } else {
                    this.$router.push({
                        name: state,
                        params: params,
                    });
                }
            }).catch((message) => {
                console.error(message);
            });
        },
        composeMessage() {
            const authParams = {};
            const onAuthSuccess = () => {
                this.$store.dispatch('linkEstimate', this.estimate).then(() => {
                    if (this.estimate.providerDetails.requiresRedirectToBranded) {
                        this.emitter.emit('redirect:tab', {
                            provider: this.provider,
                            context: {
                                stateGo: {
                                    to: 'SubAccountDetails',
                                    toParams: {
                                        id: this.provider.id,
                                        estimateId: this.estimate.subAccount.id,
                                    }
                                },
                                emit: ['message:compose', this.messageProviderData],
                            },
                        });
                    } else {
                        this.emitter.emit('message:compose', this.messageProviderData);
                    }
                }).catch((message) => {
                    console.error(message);
                });
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
        },
    }
}

</script>