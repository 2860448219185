<template>
    <div v-if="estimate" class="vuetify sub-account-details-balance">
        <base-card :class="{condensed: Boolean(estimate.serviceAmount), 'balance-card': true}">
            <template #text>
                <div v-if="paymentMade">
                            <div class="original-cost-label">{{ $translate('labels.originalEstimatedCost') }} {{ $formatCurrency(Number(estimate.estimatedAmount)) }}</div>
                            <div data-testid="sub-account-details-balance-estimated-cost-label" class="balance-cost-label">{{ $translate('labels.estimatedRemainingCost') }}</div>
                        </div>
                        <div v-if="noPayments" data-testid="sub-account-details-balance-estimated-cost-label" class="balance-cost-label">{{ $translate('labels.estimatedVisitCost') }}</div>
                <div data-testid="sub-account-details-balance-estimated-cost-amount" class="balance-amount fs-block">{{ $formatCurrency(Number(amountRemaining)) }}</div>
                        <div data-testid="sub-account-details-balance-requested-payment-notice" v-if="showRequestedPaymentAmount" class="balance-notice">{{ $translate('labels.estimateRequestedAmountNotice', { amount: $formatCurrency(Number(requestedRemaining)), DOS: $formatDate(estimate.dateOfService, 'M/D/YYYY') }) }}</div>
                        <div v-if="isPaymentPlan || isVoid" class="account-status">
                            <status-chip :class="{ 'view-plan-offset': (shouldViewPlanLink) }" :estimate="estimateModel"></status-chip>
                            <div v-if="shouldViewPlanLink" @click="viewPlanLink()" class="payment-plan-link">{{ $translate('actions.view') }}</div>
                        </div>
            </template>
            <template #actions>
                <div v-if="showPaymentButton">
                    <base-btn id="sub-account-balance-button" data-testid="sub-account-details-balance-view-payment-options" class="button-bigger-text balance-btn v-button-primary" :x-large="$vuetify.display.lgAndUp" tabindex="1" @click="$emit('pay-estimate')">{{ $translate('actions.viewPaymentOptions') }}</base-btn>
                </div>
                <div v-if="isVoid" class="estimate-alert" data-testid="sub-account-details-balance-void">
                    <base-icon :size="$vuetify.display.lgAndUp ? 20 : 16" icon="mdi-alert-circle-outline"></base-icon>
                    <span> {{ $translate('labels.estimateVoid') }} </span>
                </div>
            </template>
        </base-card>
    </div>
</template>

<script>
import StatusChip from './StatusChip.vue';
import Estimate from './../models/Estimate';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseCard from './BaseCard.vue';

export default {
    name: 'SubAccountDetailsBalance',
    components: {
        StatusChip,
        BaseIcon,
        BaseBtn,
        BaseCard
    },
    props: {
        estimate: Object,
    },
    computed: {
        showRequestedPaymentAmount() {
            return this.estimate.requestedPaymentAmount > 0 && this.estimate.amountPaid < this.estimate.requestedPaymentAmount && this.isPayableStatus && !this.isPaymentPlan && !this.isVoid;
        },
        showPaymentButton() {
            return this.isPayableStatus && (this.estimate.amountDue > 0) && !this.estimate.cancelled;
        },
        isPayableStatus() {
            // not payable statuses are ADJUDICATED and INACTIVE
            let statuses = ["CREATED", "PRESERVICE_UNFULFILLED", "PRESERVICE_PARTIAL_FULFILLED", "PRESERVICE_FULFILLED", "PAYMENT_PLAN"];
            return statuses.includes(this.estimate.subAccountStatus);
        },
        isPaymentPlan() {
            return this.estimate.subAccountStatus == "PAYMENT_PLAN";
        },
        noPayments() {
            return this.estimate.amountPaid == 0 || this.estimate.amountPaid == null;
        },
        paymentMade() {
            return this.estimate.amountPaid > 0;
        },
        amountRemaining() {
            if (this.estimate.amountPaid == 0 || this.estimate.amountPaid == null) {
                return this.estimate.estimatedAmount;
            } else {
                return this.estimate.estimatedAmount - this.estimate.amountPaid;
            }
        },
        requestedRemaining() {
            if (this.estimate.amountPaid == 0 || this.estimate.amountPaid == null) {
                return this.estimate.requestedPaymentAmount;
            } else {
                return this.estimate.requestedPaymentAmount - this.estimate.amountPaid;
                // can return a negative number but won't display because of showRequestedPaymentAmount
            }
        },
        estimateModel() {
            return new Estimate(this.estimate);
        },
        isVoid() {
            return this.estimate.cancelled;
        },
        shouldViewPlanLink() {
            return this.isPaymentPlan &&
                !this.isVoid &&
                this.estimate &&
                this.estimate.providerDetails &&
                this.estimate.providerDetails.id &&
                this.estimate.currentPlan &&
                this.estimate.currentPlan.paymentPlanId;
        }
    },
    methods: {
        viewPlanLink() {
            this.$router.push({
                name: 'PlanReceipt',
                params: {
                    providerId: this.estimate.providerDetails.id,
                    planId: this.estimate.currentPlan.paymentPlanId,
                },
            });
        }
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';
.sub-account-details-balance {
    .balance-card.base-card {
        margin-bottom: 1rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding: 2.5rem;
        @include wider-than-tablet {
            margin: 1rem;
        }

        .text {
            padding: 0;
            line-height: 3.5rem;
        }

        .actions {
            padding: 0;
            display: block;
        }
    }
}
.v-chip {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    .v-icon {
        margin-right: 0.4rem;
        height: 1.6rem;
        width: 1.6rem;
    }
    @include wider-than-tablet {
        font-size: 1.2rem;
    }
}

.original-cost-label {
    font-size: 1.2rem;
    color: #707070;
    @include wider-than-tablet {
        font-size: 1.6rem;
    }
}

.estimate-alert {
    padding-top: 2rem;

    span {
        color: $global-color-font-new;
        font-size: 1.2rem;
        line-height: 1.6rem;

        @include wider-than-tablet {
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    svg {
        color: $global-color-link-new;
    }
}

.account-status {
    display: flex;
    justify-content: center;
    .view-plan-offset {
        padding-left: 5rem;
    }
    .payment-plan-link {
        color: $global-color-link-new;
        text-decoration: underline;
        cursor: pointer;
        font-size: 1.4rem;
        margin-left: 1rem;
        letter-spacing: 0.025rem;
        @include wider-than-tablet {
            font-size: 1.6rem;
            margin-left: 1.5rem;
        }
    }
}
</style>