import _ from 'lodash';
import vueEventTransmitter  from '../../utils/vueEventTransmitter';
import http from '../../utils/http';


const state = {
    // config is loaded by gulp, so for now we allow AngularJS to give us a copy
    config: {},
    brandingConfig: null,
    currentState: null,
    stateObject: {
        params: {},
        current: {},
        previous: {},
    },
    friendlySSOEnvName: null,
    initialHistoryLength: 0,
    isTransworld: false,
    isFirstBill: false,
    isFirstEstimate: false,
    isChatSessionActive: false,
    currentLanguage: 'en',
    showSwitchLanguage: false,
    isOverlayVisible: false,
    overlayStack: [],
    isNavOpen: false,
    loadingIndicatorWaitTime: 1500, // time (ms) we wait before actually making the indicator visible
    loadingIndicatorPrepping: false,
    loadingIndicatorStack: [],
    isLoadingIndicatorVisible: false,
    browserInfoCallback: null,
    currentBreadCrumb: null,
    currentBreadCrumbLabel: null,
    isBreadCrumbVisible: false,
    showLoading: false,
    showSSOHeader: false,
    visibleDialogStack: [],
    visibleNotificationBanner: null,
    router: null,
    paymentSource: {
        billType: null,
        commType: null,
        notificationType: null,
        origin: null,
    },
};

const getters = {
    subdomain(state) {
        const host = window.location.host;
        if (state.config.isCypress) {
            // On test, treat port 8888 as branded otherwise not
            return (window.location.port == "8888" || window.location.port == "8886") ? "brand" : null;
        } else if (host.indexOf('gopatientco') < 0 || (state.config.env === 'dev' && host.indexOf('gopatientco') > 0)) {
            // Other environments, if gopatientco is not in the url assume that there is a subdomain
            // On dev, if the url does not start with gopatientco assume that there is a subdomain
            const hostPieces = host.split('.');
            if (hostPieces.length > 1 && 'www' === hostPieces[0]) {
                return hostPieces[1];
            }
            return hostPieces[0];
        } else {
            return null;
        }
    },
    config(state) {
        return state.config;
    },
    paymentSource(state) {
        return state.paymentSource;
    },
    isChatSessionActive(state) {
        return state.isChatSessionActive;
    },
    currentState(state) {
        return state.currentState;
    },
    showLoading(state) {
        return state.showLoading;
    },
    brandingConfig(state) {
        return state.brandingConfig;
    },
    isSpanish(state) {
        return state.currentLanguage == 'es';
    },
    currentLanguage(state) {
        return state.currentLanguage;
    },
    isFirstBill(state) {
        return state.isFirstBill;
    },
    isFirstEstimate(state) {
        return state.isFirstEstimate;
    },
    stateObject(state) {
        return state.stateObject;
    },
    initialHistoryLength(state) {
        return state.initialHistoryLength;
    },
    isLoadingIndicatorVisible(state) {
        return state.isLoadingIndicatorVisible;
    },
    currentBreadCrumbLabel(state) {
        return state.currentBreadCrumbLabel;
    },
    isBreadCrumbVisible(state) {
        return state.isBreadCrumbVisible;
    },
    friendlySSOEnvName(state) {
        return state.friendlySSOEnvName;
    },
    showSSOHeader(state) {
        return state.showSSOHeader;
    },
    visibleNotificationBanner(state) {
        return state.visibleNotificationBanner;
    }
};

const mutations = {
    setConfig(state, val) {
        state.config = val;
    },
    setPaymentSource(state, val) {
        if (!state.paymentSource.billType) {
            state.paymentSource.billType = val.billType;
        }
        if (!state.paymentSource.commType) {
            state.paymentSource.commType = val.commType;
        }
        if (!state.paymentSource.notificationType) {
            state.paymentSource.notificationType = val.notificationType;
        }
        if (!state.paymentSource.origin) {
            state.paymentSource.origin = val.origin;
        }
    },
    setBrandingConfig(state, val) {
        state.brandingConfig = val;
    },
    setIsTransworld(state, val) {
        state.isTransworld = val;
    },
    setIsFirstBill(state, val) {
        state.isFirstBill = val;
    },
    setIsFirstEstimate(state, val) {
        state.isFirstEstimate = val;
    },
    setIsChatSessionActive(state, val) {
        state.isChatSessionActive = val;
    },
    setCurrentLanguage(state, val) {
        if (['en','es'].includes(val)) {
            state.currentLanguage = val;
            localStorage.setItem('currentLanguage', val);
        } else {
            console.error('Attempted to switch to unsupported language', val);
        }
    },
    setShowSwitchLanguage(state, val) {
        state.showSwitchLanguage = val;
    },

    setIsOverlayVisible(state, val) {
        state.isOverlayVisible = val;
    },
    setOverlayStack(state, val) {
        state.overlayStack = val;
    },
    pushToOverlayStack(state, val) {
        state.overlayStack.push(val);
    },
    removeFromOverlayStack(state, index) {
        state.overlayStack.splice(index, 1);
    },

    setIsNavOpen(state, val) {
        state.isNavOpen = val;
    },

    setLoadingIndicatorWaitTime(state, val) {
        state.loadingIndicatorWaitTime = val;
    },
    setLoadingIndicatorPrepping(state, val) {
        state.loadingIndicatorPrepping = val;
    },
    pushToLoadingIndicatorStack(state, val) {
        state.loadingIndicatorStack.push(val);
    },
    removeFromLoadingIndicatorStack(state, index) {
        state.loadingIndicatorStack.splice(index, 1);
    },
    setIsLoadingIndicatorVisible(state, val) {
        state.isLoadingIndicatorVisible = val;
    },

    setBrowserInfoCallback(state, cb) {
        state.browserInfoCallback = cb;
    },

    setCurrentBreadCrumb(state, val) {
        state.currentBreadCrumb = val;
    },
    setCurrentBreadCrumbLabel(state, val) {
        state.currentBreadCrumbLabel = val;
    },
    setIsBreadCrumbVisible(state, val) {
        state.isBreadCrumbVisible = val;
    },

    setShowLoading(state, val) {
        state.showLoading = val;
    },

    setCurrentState(state, val) {
        state.currentState = val;
    },
    setStateObject(state, val) {
        state.stateObject = val;
    },
    setVisibleDialogStack(state, val) {
        state.visibleDialogStack = val;
    },
    setVisibleNotificationBanner(state, text) {
        state.visibleNotificationBanner = text;
    },
    pushToVisibleDialogStack(state, val) {
        state.visibleDialogStack.push(val);
    },
    popFromVisibleDialogStack(state) {
        state.visibleDialogStack.pop();
    },
    removeFromVisibleDialogStackByKey(state, key) {
        const index = state.visibleDialogStack.findIndex(item => item.key === key);
        state.visibleDialogStack.splice(index, 1);
    },
    setRouter(state, val) {
        state.router = val;
    },
    setInitialHistoryLength(state, val) {
        state.initialHistoryLength = val;
    },
    setShowSSOHeader(state, val) {
        state.showSSOHeader = val;
    },
    setFriendlySSOEnvName(state, val) {
        state.friendlySSOEnvName = val;
    },
};

const actions = {
    showLoadingIfNecessary({ commit }) {
        if ('1' === localStorage.getItem('showLoadingIndicatorOnLoad')) {
            commit('setShowLoading', true);
        }
    },
    setBrandingConfig({ commit }, brandingConfig) {
        commit('setBrandingConfig', brandingConfig);
        vueEventTransmitter.emit('setBrandingConfig', brandingConfig);
    },
    closeNav({ commit, dispatch, state }) {
        if (state.isNavOpen){
            commit('setIsNavOpen', false);
            dispatch('hideOverlay', 'nav_overlay');
            /// TODO: Refactor/remove this once vue upgrade is complete
            document.body.classList.remove('js-mask-visible');
            document.body.classList.remove('js-open-nav');
        }
    },
    openNav({ commit, dispatch }) {
        commit('setIsNavOpen', true);
        dispatch('showOverlay', {
            key: 'nav_overlay',
            clickCallback: () => {
                dispatch('closeNav');
            },
        });
        /// TODO: Refactor/remove this once vue upgrade is complete
        document.body.classList.add('js-mask-visible');
        document.body.classList.add('js-open-nav');
    },
    toggleNav({ state, dispatch }) {
        return state.isNavOpen ? dispatch('closeNav') : dispatch('openNav');
    },

    // pass the click callback so that when 
    // we get a overlay click we can perform
    // whichever mutation we need for that piece
    // of ui - e.g. modal is different than nav cb
    showOverlay({ commit, state }, { key, clickCallback }) {
        const stack = state.overlayStack;
        if (_.isFunction(key)) {
            clickCallback = key;
            key = _.uniqueId('overlay_');
        }

        if (!key) {
            key = _.uniqueId('overlay_');
        }

        commit('setIsOverlayVisible', true);

        const keyIndex = _.findLastIndex(stack, (ov) => {
            return ov.key === key;
        });

        if (keyIndex === -1) {
            commit('pushToOverlayStack', { key: key, cb: clickCallback || null });
        }

        document.body.classList.add('js-mask-visible');

        return key;
    },

    // we require a key because we don't want a 
    // closer to inadvertently hide the overlay for another section 
    hideOverlay({ commit, state }, key) {
        var keyIndex = _.findLastIndex(state.overlayStack, (ov) => {
            return ov.key === key;
        });

        if(keyIndex === -1){
            return;
        }

        commit('removeFromOverlayStack', keyIndex);

        if (state.overlayStack.length === 0) {
            commit('setIsOverlayVisible', false);
            document.body.classList.remove('js-mask-visible');
        }
    },

    clickOverlay({ state }) {
        const stack = state.overlayStack;
        const item = stack[stack.length - 1];
        if (item && item.cb){
            item.cb();
        }
    },

    loadLoadingIndicator({ commit, state }) {
        const key = _.uniqueId('loader_');
        commit('pushToLoadingIndicatorStack', key);

        if (state.loadingIndicatorPrepping || state.isLoadingIndicatorVisible) {
            return;
        }

        commit('setLoadingIndicatorPrepping', true);

        function checkAndShow() {
            if (state.loadingIndicatorStack.length > 0) {
                commit('setIsLoadingIndicatorVisible', true);
            } else {
                // make sure anytime we expect
                // the ui to be closed, that it is
                if (!state.isLoadingIndicatorVisible || state.loadingIndicatorStack.length !== 0) {
                    return;
                }

                commit('setIsLoadingIndicatorVisible', false);
            }
            commit('setLoadingIndicatorPrepping', false);
        }
        if (state.loadingIndicatorWaitTime === 0){
            checkAndShow();
        } else {
            window.setInterval(checkAndShow, state.loadingIndicatorWaitTime, 1);
        }

        return key;
    },

    completeLoadingIndicator({ commit, state }, key) {
        commit('removeFromLoadingIndicatorStack', key);
        if (!state.isLoadingIndicatorVisible || state.loadingIndicatorStack.length !== 0) {
            return;
        }

        commit('setIsLoadingIndicatorVisible', false);
    },

    prepBrandingSSO({}, context) {
        return http.getDataOrThrow({
            method: 'POST',
            data: context,
            url: 'sso/prep',
        });
    },

    postBrandingSSOToken({}, token) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token },
            url: 'sso/token',
        });
    },

    postExternalSSO({}, token) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token: token },
            url: 'sso/external',
        });
    },

    postConversation({}, conversationId) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { conversationId },
            url: 'liveChat/postConversation',
        });
    },

    getBrandingConfig({ getters }) {
        if (getters.subdomain) {
            return http.getDataOrThrow({
                method: 'GET',
                url: `providers/brand/${getters.subdomain}`,
            });
        } else {
            throw new Error('No subdomain set');
        }
    },

    getIPData({}, { successCallback, errorCallback }) {
        return http.get('patientUser/ip').then(successCallback, errorCallback);
    },

    sendContactInfo({}, { email, firstName, lastName, subject, message }) {
        return http.getDataOrThrow({
            method: 'POST',
            url: 'contact',
            data: {
                patientEmail: email,
                message,
                subject,
                patientName: `${firstName} ${lastName}`
            }
        }).then(resp => resp.status);
    },

    showBreadCrumbs({ commit }, specs) {
        commit('setCurrentBreadCrumb', specs);
        commit('setCurrentBreadCrumbLabel', specs.label);
        commit('setIsBreadCrumbVisible', true);
    },

    breadCrumbClick({ commit, state }) {
        state.currentBreadCrumb.onClick();
        commit('setIsBreadCrumbVisible', false);
        commit('setCurrentBreadCrumb', null);
    },
};

export default {
    state, getters, mutations, actions,
};
