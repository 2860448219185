<template>
<div class="notification-banner vuetify">
    <base-banner>
        <div class="notification-banner-content">
            <base-icon icon="mdi-alert-outline"></base-icon>
            <div @click="onClick" class="notification-banner-content-text" v-html="notificationText"></div>
            <base-icon class="close vuetify" @click="$emit('dismiss')" icon="mdi-close"></base-icon>
        </div>
    </base-banner>
</div>
</template>

<script>
import BaseBanner from './BaseBanner.vue';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'NotificationBanner',
    components: {
        BaseBanner,
        BaseIcon
    },
    props: {
        notification: Object,
    },
    computed: {
        notificationText() {
            if (this.notification) {
                return this.notification.text;
            }
        }
    },
    methods: {
        onClick(e) {
            if ('A' === e.target.nodeName) {
                this.notification.action();
            }
        },
    },
};
</script>

<style lang="scss">
@import './../styles/variables.scss';

.notification-banner {
	position: relative;
	width: 100%;
	display: flex;
	text-align: center;
    margin-top: 1rem;

    .v-toolbar__content{
        padding-top: 12px!important;
        padding-bottom: 12px!important;
    }
}
.notification-banner-content {
    max-width: 88rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $global-color-font-new;

    .notification-banner-content-text {
        flex: 1;
        text-align: left;
        padding-left: 1.6rem;
    }

    a {
        color: $global-color-font-new;
        text-decoration: underline;
    }
    .close {
        color: $global-color-font-new;
        margin-left: 0;
        cursor: pointer;
        @include wider-than-tablet {
           margin-left: 32rem;
        }
    }
}
</style>