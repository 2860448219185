<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="300" height="200" viewBox="0 0 300 200">
        <path d="M0 0 C123.42 0 246.84 0 374 0 C374 65.67 374 131.34 374 199 C250.58 199 127.16 199 0 199 C0 133.33 0 67.66 0 0 Z " fill="#FEFEFE" transform="translate(0,0)"/>
        <path d="M0 0 C0.33 -0.99 0.66 -1.98 1 -3 C5.29 -3 9.58 -3 14 -3 C14.09936145 3.38626642 14.17170145 9.77207635 14.21972656 16.15893555 C14.23976081 18.32861171 14.26699612 20.49823443 14.30175781 22.66772461 C14.350637 25.798149 14.37305125 28.92785548 14.390625 32.05859375 C14.41127014 33.01833603 14.43191528 33.97807831 14.45318604 34.96690369 C14.45613021 43.93491546 11.68313686 50.52926042 5.85546875 57.296875 C0.13693378 62.54588815 -3.91149101 64.88929559 -11.8125 64.6875 C-13.00117676 64.66707642 -13.00117676 64.66707642 -14.21386719 64.64624023 C-20.98418413 64.34183946 -26.0177159 62.79593883 -31 58 C-38.41566834 49.26010517 -39.38330033 40.8910434 -39.4375 29.75 C-39.47037109 28.57308594 -39.50324219 27.39617187 -39.53710938 26.18359375 C-39.58453972 18.43088881 -38.57413704 12.40471174 -34 6 C-24.08848234 -3.5539487 -12.47215702 -5.64776921 0 0 Z " fill="#822158" transform="translate(199,43)"/>
        <path d="M0 0 C7.25501673 3.2893508 13.32887295 9.09528912 16.16796875 16.5625 C17.65886612 22.8062581 17.62110499 28.25779003 14.75 34 C11.45 34 8.15 34 4.75 34 C4.75 33.01 4.75 32.02 4.75 31 C4.08355469 31.28875 3.41710937 31.5775 2.73046875 31.875 C-5.46077843 35.17441145 -12.50289468 36.47860657 -21.0625 33.8125 C-27.37683009 30.22199858 -31.30203045 26.57623977 -34.25 20 C-34.82872143 15.87660979 -34.58070496 13.70454534 -32.8125 9.9375 C-29.45158149 6.08473976 -25.64282607 3.56763777 -21.25 1 C-20.62609375 0.61972656 -20.0021875 0.23945312 -19.359375 -0.15234375 C-13.70467028 -2.42469731 -5.76374866 -1.80117146 0 0 Z " fill="#E0662A" transform="translate(196.25,56)"/>
        <path d="M0 0 C3.52550252 0.71134965 3.52550252 0.71134965 10.6640625 0.4765625 C10.6640625 11.3665625 10.6640625 22.2565625 10.6640625 33.4765625 C8.0240625 33.4765625 5.3840625 33.4765625 2.6640625 33.4765625 C2.6640625 32.8165625 2.6640625 32.1565625 2.6640625 31.4765625 C2.0453125 31.9715625 1.4265625 32.4665625 0.7890625 32.9765625 C-3.65943382 35.11184073 -7.48546984 35.26531636 -12.3359375 34.4765625 C-17.4161677 32.39828651 -21.10291529 29.59937816 -23.3359375 24.4765625 C-25.0623835 18.64364136 -25.38870109 12.70842132 -22.7734375 7.1640625 C-16.01999056 -0.28204567 -10.08550921 -2.2599636 0 0 Z " fill="#535351" transform="translate(45.3359375,123.5234375)"/>
        <path d="M0 0 C4.49462872 1.71314259 8.53647717 3.85114171 11.29296875 7.8984375 C12.15767026 9.95261104 12.64333012 11.80191819 13 14 C13.11085938 14.66257813 13.22171875 15.32515625 13.3359375 16.0078125 C13.73440651 20.8463648 12.3757119 24.22826985 9.609375 28.16796875 C5.72531954 32.5893814 1.43953709 35.23585791 -4.5 35.625 C-10.40837158 35.24808664 -14.38525659 32.44338887 -18.609375 28.49609375 C-22.28152533 24.2153567 -22.69804028 20.11533632 -22.41796875 14.58203125 C-21.38116822 8.1771233 -16.87991754 4.71298074 -12 1 C-8.19948164 -0.90025918 -4.13073326 -0.59174621 0 0 Z " fill="#E36E31" transform="translate(249,123)"/>
        <path d="M0 0 C3.95685496 2.34891952 7.46273406 4.9525873 9.6484375 9.05078125 C9.6484375 12.35078125 9.6484375 15.65078125 9.6484375 19.05078125 C1.7284375 19.05078125 -6.1915625 19.05078125 -14.3515625 19.05078125 C-13.6915625 20.70078125 -13.0315625 22.35078125 -12.3515625 24.05078125 C-10.93533034 24.10525172 -9.51848059 24.14378633 -8.1015625 24.17578125 C-6.91820312 24.21058594 -6.91820312 24.21058594 -5.7109375 24.24609375 C-3.13264011 24.03265854 -1.6087023 23.25876012 0.6484375 22.05078125 C3.95964241 22.35179988 5.4993807 23.57936593 7.6484375 26.05078125 C6.25708463 30.22483986 4.04228152 31.03868965 0.2578125 33.02734375 C-4.0171181 34.70403809 -7.8520872 34.85837938 -12.3515625 34.05078125 C-17.70073522 31.5819323 -21.81317229 27.36931312 -24.3515625 22.05078125 C-25.37625536 16.96830468 -25.46109191 12.33941451 -23.16796875 7.609375 C-17.49854254 -0.38672482 -9.57807654 -3.86623355 0 0 Z " fill="#555553" transform="translate(152.3515625,123.94921875)"/>
        <path d="M0 0 C5.30247798 2.06207477 9.15571414 4.21401067 11.625 9.4375 C12 13 12 13 12 20 C4.08 20 -3.84 20 -12 20 C-12 20.99 -12 21.98 -12 23 C-8.07178453 25.61881031 -4.56556861 25.28987737 0 25 C0.99 24.34 1.98 23.68 3 23 C6.30129606 23.30949651 7.8586563 24.53745474 10 27 C8.25868376 31.16515844 6.28938617 32.40457371 2.28125 34.234375 C-2.21926991 35.74482346 -6.49909017 35.92061051 -10.984375 34.26953125 C-16.29301802 31.49043394 -19.67583335 27.43953898 -22 22 C-22.69306569 17.11630455 -22.55914129 13.13978801 -20.375 8.6875 C-14.29853828 1.81150384 -9.33047975 -1.34773596 0 0 Z " fill="#E36F33" transform="translate(323,123)"/>
        <path d="M0 0 C3.61707804 0.60807031 5.61266682 1.86650777 8.25390625 4.37890625 C10.80346696 10.73984414 10.48337118 17.04627551 10.37890625 23.81640625 C10.36988281 24.92951172 10.36085938 26.04261719 10.3515625 27.18945312 C10.32812865 29.91949648 10.29532025 32.64908635 10.25390625 35.37890625 C7.28390625 35.37890625 4.31390625 35.37890625 1.25390625 35.37890625 C1.23932373 34.72817139 1.22474121 34.07743652 1.2097168 33.40698242 C1.13526076 30.45952319 1.03859359 27.51319983 0.94140625 24.56640625 C0.91884766 23.54224609 0.89628906 22.51808594 0.87304688 21.46289062 C0.81987305 19.98852539 0.81987305 19.98852539 0.765625 18.484375 C0.73944092 17.57840576 0.71325684 16.67243652 0.6862793 15.73901367 C0.17787919 12.96391269 -0.49818371 12.04025807 -2.74609375 10.37890625 C-6.49458452 9.9103449 -7.51355579 10.22388094 -10.74609375 12.37890625 C-11.25513759 14.79461755 -11.25513759 14.79461755 -11.2578125 17.60546875 C-11.29326172 18.63671875 -11.32871094 19.66796875 -11.36523438 20.73046875 C-11.39907227 22.35082031 -11.39907227 22.35082031 -11.43359375 24.00390625 C-11.46775391 25.091875 -11.50191406 26.17984375 -11.53710938 27.30078125 C-11.61976896 29.99312192 -11.68460217 32.68599896 -11.74609375 35.37890625 C-15.04609375 35.37890625 -18.34609375 35.37890625 -21.74609375 35.37890625 C-21.74609375 24.15890625 -21.74609375 12.93890625 -21.74609375 1.37890625 C-18.44609375 1.37890625 -15.14609375 1.37890625 -11.74609375 1.37890625 C-11.41609375 2.36890625 -11.08609375 3.35890625 -10.74609375 4.37890625 C-10.03453125 3.38890625 -10.03453125 3.38890625 -9.30859375 2.37890625 C-6.05051264 -0.16398633 -4.04056263 -0.20635493 0 0 Z " fill="#E47135" transform="translate(286.74609375,122.62109375)"/>
        <path d="M0 0 C3.1617831 1.51989252 5.83658141 3.21414534 8 6 C8.2109375 8.70703125 8.2109375 8.70703125 8 11 C5.03 11 2.06 11 -1 11 C-1.33 10.34 -1.66 9.68 -2 9 C-5.02934491 8.34227572 -5.02934491 8.34227572 -8 8 C-7.99488194 9.94561536 -7.99488194 9.94561536 -7 12 C-4.10315127 13.48417909 -4.10315127 13.48417909 -0.625 14.5625 C6.94424723 17.12194613 6.94424723 17.12194613 9 20 C9.52720749 24.06702918 9.47484425 26.22617975 7.3125 29.75 C3.5764285 33.3850966 0.87537148 35.1700787 -4.375 35.4375 C-9.28329591 35.25752915 -12.06021395 33.91544167 -16 31 C-18.25709039 28.01481593 -19 26.75081172 -19 23 C-15.7 23 -12.4 23 -9 23 C-8.67 23.99 -8.34 24.98 -8 26 C-6.02 26 -4.04 26 -2 26 C-1.67 24.68 -1.34 23.36 -1 22 C-1.59167969 21.96261719 -2.18335938 21.92523438 -2.79296875 21.88671875 C-7.54423746 21.49142559 -11.19958423 21.12975416 -15 18 C-17.14935455 15.25772005 -17.93408099 13.45201608 -18.4375 10 C-17.88726132 6.22693478 -16.67599263 4.67599263 -14 2 C-9.50810881 -0.70274809 -5.10227314 -0.8757633 0 0 Z " fill="#595957" transform="translate(214,123)"/>
        <path d="M0 0 C3.23939656 1.73539102 5.42321134 3.42321134 8 6 C8.125 8.625 8.125 8.625 8 11 C5.36 11 2.72 11 0 11 C-0.33 10.34 -0.66 9.68 -1 9 C-2.98 9 -4.96 9 -7 9 C-7.33 9.99 -7.66 10.98 -8 12 C-7.26394531 12.18175781 -6.52789062 12.36351562 -5.76953125 12.55078125 C4.26329902 15.13519592 4.26329902 15.13519592 8 18 C9.61170388 21.22340777 9.59542551 24.51441919 9 28 C6.19777572 31.68292334 3.4892448 33.85158854 -1 35 C-5.84275415 35.46121468 -7.99497747 35.5309553 -12.3125 33.25 C-14.70428965 31.24757145 -16.47685922 29.70780582 -18 27 C-18 25.68 -18 24.36 -18 23 C-16.54257537 22.91903197 -15.08387193 22.86082226 -13.625 22.8125 C-12.81289062 22.77769531 -12.00078125 22.74289063 -11.1640625 22.70703125 C-10.44992188 22.80371094 -9.73578125 22.90039062 -9 23 C-8.34 23.99 -7.68 24.98 -7 26 C-3.41642759 26.25015873 -3.41642759 26.25015873 0 26 C-0.33 24.68 -0.66 23.36 -1 22 C-1.81984375 21.82984375 -2.6396875 21.6596875 -3.484375 21.484375 C-4.56203125 21.24203125 -5.6396875 20.9996875 -6.75 20.75 C-7.81734375 20.51796875 -8.8846875 20.2859375 -9.984375 20.046875 C-13.3468555 18.87958902 -14.85025451 17.81550592 -17 15 C-17.92782126 11.86931475 -17.79083141 9.15787164 -17 6 C-13.00463797 -0.26748485 -6.96389959 -1.37671821 0 0 Z " fill="#565654" transform="translate(183,123)"/>
        <path d="M0 0 C2.96912152 1.32475625 5.46853066 2.96902174 8 5 C7.44655057 7.66762624 7.1544313 8.8345379 5.25 10.875 C4.5075 11.24625 3.765 11.6175 3 12 C0.625 11.1875 0.625 11.1875 -2 10 C-5.66207884 9.55158218 -7.30472525 9.5242857 -10.375 11.625 C-12.51323431 14.75011169 -12.6829669 16.28162465 -12 20 C-10.08333333 23.08333333 -10.08333333 23.08333333 -7 25 C-3.64217508 24.88218158 -1.00557875 24.38448599 2.125 23.1875 C4 23 4 23 6.75 24.9375 C7.4925 25.618125 8.235 26.29875 9 27 C7.87627812 30.37116564 7.52837401 30.55882121 4.5625 32.125 C3.94503906 32.45757812 3.32757813 32.79015625 2.69140625 33.1328125 C-1.26627155 35.16192213 -4.57879181 35.5584684 -9 35 C-15.16185085 32.9534647 -18.80442861 29.77065521 -22.078125 24.1484375 C-23.7656907 20.21555135 -23.53126459 16.19034947 -23 12 C-18.21005633 2.23588406 -10.49209183 -1.3238344 0 0 Z " fill="#595957" transform="translate(117,123)"/>
        <path d="M0 0 C3.90660527 2.23333363 3.90660527 2.23333363 5.58203125 4.01171875 C5.58203125 8.01171875 5.58203125 8.01171875 3.89453125 9.88671875 C3.13140625 10.25796875 2.36828125 10.62921875 1.58203125 11.01171875 C-0.73046875 10.19921875 -0.73046875 10.19921875 -3.41796875 9.01171875 C-8.7601563 8.6214042 -8.7601563 8.6214042 -13.41796875 11.01171875 C-14.20542504 15.01076149 -13.92651386 18.85336601 -12.29296875 22.57421875 C-9.55136277 24.67611667 -7.80586957 24.41029532 -4.41796875 24.01171875 C-3.40734375 23.59921875 -2.39671875 23.18671875 -1.35546875 22.76171875 C-0.38609375 22.51421875 0.58328125 22.26671875 1.58203125 22.01171875 C4.45703125 23.88671875 4.45703125 23.88671875 6.58203125 26.01171875 C4.83817015 30.20403128 2.82382629 31.41593255 -1.20703125 33.2421875 C-5.62049105 34.77832103 -9.24130841 34.98088868 -13.55859375 33.05859375 C-18.66242426 30.07190775 -22.5023606 26.75854319 -24.41796875 21.01171875 C-25.20125412 14.7118664 -24.73328141 9.91783454 -20.95703125 4.70703125 C-15.41347066 -1.39932737 -7.74036008 -3.16366898 0 0 Z " fill="#565654" transform="translate(85.41796875,123.98828125)"/>
        <path d="M0 0 C8.25 0 16.5 0 25 0 C23.84615385 8.07692308 23.84615385 8.07692308 19 12 C15.26669892 13.24443369 11.8711182 13.65860234 8 13 C4.30041487 10.97615693 1.31192951 8.06698148 0 4 C0 2.68 0 1.36 0 0 Z " fill="#FDF6F2" transform="translate(173,67)"/>
        <path d="M0 0 C3.32961021 0.55493503 5.30091917 1.49496853 8 3.5 C9.42662219 6.35324437 9.31033868 8.33454549 9 11.5 C7.5625 14.375 7.5625 14.375 5 16.5 C1.85595859 17.21534153 -0.85595859 17.21534153 -4 16.5 C-7.06018871 13.96228253 -7.83450185 12.38265681 -8.5625 8.5 C-7.62376863 3.49343268 -5.04252151 0.84042025 0 0 Z " fill="#FDF7F4" transform="translate(244,131.5)"/>
        <path d="M0 0 C2.22617565 0.46623679 3.86052038 0.9923077 5.875 2.0625 C7.53783837 5.28424934 7.50048571 8.51414707 6.875 12 C4.83760881 15.05608679 3.40156037 15.8207454 -0.0625 17 C-3.125 17 -3.125 17 -5.9375 15.1875 C-8.47687354 12.64812646 -9.02825001 11.7256249 -9.5 8.1875 C-9.125 5 -9.125 5 -7.125 2.0625 C-4.02378679 -0.06958408 -3.50949164 -0.31727876 0 0 Z " fill="#F8F8F8" transform="translate(39.125,132)"/>
        <path d="M0 0 C2 2 2 2 2.125 5.625 C2.08375 6.73875 2.0425 7.8525 2 9 C-0.63390925 10.72217143 -1.92411417 11.00455315 -5.125 10.8125 C-8 10 -8 10 -10 7 C-10.35242362 4.18061103 -10.34645957 2.61139923 -8.9375 0.125 C-5.82785011 -1.68060316 -3.38260527 -0.79952488 0 0 Z " fill="#E5783F" transform="translate(350,147)"/>
        <path d="M0 0 C7.92 0 15.84 0 24 0 C24 0.33 24 0.66 24 1 C23.0925 1.103125 22.185 1.20625 21.25 1.3125 C17.93905916 2.01289133 16.5187092 2.8411064 14 5 C10.81458315 6.59270842 7.46702488 6.39055419 4 6 C1.5 4.6875 1.5 4.6875 0 3 C0 2.01 0 1.02 0 0 Z " fill="#FAE7DD" transform="translate(311,143)"/>
        <path d="M0 0 C7.92 0 15.84 0 24 0 C24 0.33 24 0.66 24 1 C23.0925 1.103125 22.185 1.20625 21.25 1.3125 C17.92070026 2.01677494 16.56280941 2.87905428 14 5 C10.40735548 6.19754817 7.77282526 6.33094958 4 6 C1.5625 4.125 1.5625 4.125 0 2 C0 1.34 0 0.68 0 0 Z " fill="#E6E6E6" transform="translate(138,143)"/>
        <path d="M0 0 C2.375 1.875 2.375 1.875 4 4 C4 4.66 4 5.32 4 6 C-0.62 6 -5.24 6 -10 6 C-8.50886201 0.03544804 -5.77616228 -0.70728518 0 0 Z " fill="#FCF3EE" transform="translate(322,131)"/>
        <path d="M0 0 C3.41366172 0.34136617 4.7640201 0.86071318 7.125 3.3125 C7.125 4.3025 7.125 5.2925 7.125 6.3125 C2.505 6.3125 -2.115 6.3125 -6.875 6.3125 C-3.93247126 0.42744253 -3.93247126 0.42744253 0 0 Z " fill="#F3F3F2" transform="translate(144.875,130.6875)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.16855469 1.64001953 1.16855469 1.64001953 0.3203125 2.29296875 C-5.67453641 7.46645936 -7.06243831 11.6243831 -7.84375 19.4375 C-7.8953125 20.283125 -7.946875 21.12875 -8 22 C-8.33 22 -8.66 22 -9 22 C-10.03307219 10.52142007 -10.03307219 10.52142007 -6.1875 5.375 C-4.20879181 3.05917856 -2.57801859 1.71867906 0 0 Z " fill="#81817F" transform="translate(137,124)"/>
        <path d="M0 0 C-0.598125 0.268125 -1.19625 0.53625 -1.8125 0.8125 C-4.07327822 2.03977961 -5.38272213 3.00917764 -7 5 C-7.50155022 7.2143507 -7.50155022 7.2143507 -7.51171875 9.66796875 C-7.54716797 10.56064453 -7.58261719 11.45332031 -7.61914062 12.37304688 C-7.64169922 13.30181641 -7.66425781 14.23058594 -7.6875 15.1875 C-7.72166016 16.12787109 -7.75582031 17.06824219 -7.79101562 18.03710938 C-7.87348218 20.35795393 -7.94263403 22.67842148 -8 25 C-8.33 25 -8.66 25 -9 25 C-9.11601004 21.39610336 -9.18718408 17.79268533 -9.25 14.1875 C-9.28351563 13.16333984 -9.31703125 12.13917969 -9.3515625 11.08398438 C-9.36445313 10.10107422 -9.37734375 9.11816406 -9.390625 8.10546875 C-9.41157227 7.19949951 -9.43251953 6.29353027 -9.45410156 5.36010742 C-9 3 -9 3 -7.14746094 1.19262695 C-4.58173547 -0.23228993 -2.89129326 -0.2409411 0 0 Z " fill="#FAE6DB" transform="translate(284,132)"/>
        <path d="M0 0 C-0.98076152 2.18785263 -1.70848526 3.75512761 -3.5625 5.3125 C-5.77817401 7.91350862 -5.6872065 10.6843889 -6 14 C-6.33 14 -6.66 14 -7 14 C-7.7833257 6.01007787 -7.7833257 6.01007787 -4.625 1.9375 C-2 0 -2 0 0 0 Z " fill="#777776" transform="translate(173,124)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C0.88121617 2.17262904 -0.24523051 3.33795138 -1.375 4.5 C-2.00148438 5.1496875 -2.62796875 5.799375 -3.2734375 6.46875 C-5 8 -5 8 -7 8 C-5.50902342 4.2047869 -3.24299151 2.38957269 0 0 Z " fill="#727270" transform="translate(30,124)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-0.97 3.97 -3.94 6.94 -7 10 C-7 7 -7 7 -4.625 4.4375 C-3.75875 3.633125 -2.8925 2.82875 -2 2 C-1.34 1.34 -0.68 0.68 0 0 Z " fill="#CD5831" transform="translate(171,59)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C0.88121617 2.17262904 -0.24523051 3.33795138 -1.375 4.5 C-2.00148437 5.1496875 -2.62796875 5.799375 -3.2734375 6.46875 C-5 8 -5 8 -7 8 C-5.50902342 4.2047869 -3.24299151 2.38957269 0 0 Z " fill="#934071" transform="translate(171,44)"/>
        <path d="M0 0 C0.66 0 1.32 0 2 0 C0.84826645 2.46800047 -0.04783565 4.04783565 -2 6 C-2.64767793 8.57061311 -2.64767793 8.57061311 -3 11 C-3.33 11 -3.66 11 -4 11 C-4.35858439 7.14521782 -4.2754818 5.42240542 -2.125 2.125 C-1.42375 1.42375 -0.7225 0.7225 0 0 Z " fill="#777775" transform="translate(200,125)"/>
        <path d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-0.31 3.31 -2.62 5.62 -5 8 C-5 5 -5 5 -2.5 2.3125 C-1.675 1.549375 -0.85 0.78625 0 0 Z " fill="#696968" transform="translate(69,125)"/>
    </svg>
</template>


<script>
export default {
    name: 'AccessOneSVG'
};
</script>