<template>
    <div class="message-headings">
		<h2 class="message-provider " id="thread-provider-name"> {{ thread.providerName }} </h2>
		<div class="fs-block" id="thread-friendly-account-number">
            {{ thread.friendlyAccountNumber }} 
        </div>
		<div class="fs-block" id="thread-reason"> {{ thread.reason }} </div>
	</div>
</template>

<script>
export default {
    name: 'MessageDetailsHeading',
    props: {
        thread: Object,
    },

};
</script>
