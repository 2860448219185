<template>
    <label>
        <input type="checkbox"
            v-bind="$attrs"
            v-model="val"
            :true-icon="onIcon"
            :false-icon="offIcon"
            @blur="action"
            @input="action"
            class="base-checkbox">
        <span class="checkbox-label">{{ $attrs['label'] }}</span>
    </label>
</template>

<script>
export default {
    name: 'BaseCheckbox',
    inheritAttrs: false,
    props: {
        offIcon: {
            default: 'mdi-checkbox-blank-outline'
        },
        onIcon: {
            default: 'mdi-checkbox-marked'
        },
    },
    data: () => ({
        val: false,
    }),
    mounted() {
        this.val = this.$attrs['modelValue'];
    },
    methods: {
        action() {
            this.$emit('action');
        }
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
label {
    color: $global-color-font-new;
    font-size: 1.4rem;
    line-height: 1.9rem;
    padding-left: 0.25rem;
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
        margin: 10px;
        height: 14px !important;
        opacity: 100% !important;
        flex: 0;
        display: inline-block;
        font-size: 2.4rem;
        accent-color: $global-color-secondary;
        vertical-align: bottom;
        position: relative;
        top: -3px;
    }

    span.checkbox-label {
        flex: 1;
        justify-content: center;
    }
}
</style>