<template>
    <div class="compose-content">
        <div v-if="provider.name" class="modal-subcontent provider-cannot-message" id="providerCantMessage">{{ $translate('message.argProviderCantMessage', { name: provider.name, billingPhoneNumber: provider.billingPhoneNumber })  }}</div>
        <ComposeMessageNoProviders v-else></ComposeMessageNoProviders>
    </div>
</template>

<script>
    import ComposeMessageNoProviders from './ComposeMessageNoProviders.vue';

    export default {
        name: 'MessagingDisabled',
        components : {
            ComposeMessageNoProviders
        },
        props: {
            provider: Object,
        },
    };
</script>
