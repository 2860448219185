<template>
<div>
  <div id="app-wrap" class="vuetify quick-pay">
    <div class="container">
      <div class="header-persistent-content left">
        <!-- negative tabindex to prevent accidental navigation home inside paymentflow -->
        <div tabindex="-1" class="header-home-link" @click="goHome()">
          <patientco-logo color="white" id="header-logo"></patientco-logo>
          <patientco-text color="white" id="header-text"></patientco-text>
        </div>
      </div>
      <div class="header-persistent-content right">
        <base-btn
            @click="needHelpBottomSheet = true"
            class="need-help-sheet-btn no-capitalization"
            variant="outlined"
        >{{ $translate('labels.needHelp') }}</base-btn>
      </div>
    </div>
    <div id="main-content-v2">
      <div id="left">
        <div class="fancy-background">
          <div class="amount-due-label">{{ $translate('quickPay.amountDue') }}</div>
          <div class="amount-due">{{  $formatCurrency(amountDue) }}</div>
          <div class="status-chip vuetify">
            <base-chip color="#1867C0">
              {{ $translate('statusChips.dueDate', { date: $formatDate(dueDate, 'MM/DD/YYYY') }) }}
            </base-chip>
          </div>
        </div>
      </div>
      <div id="right">
        <div class="salutation">{{ $translate('quickPay.salutation', {name:patientName}) }}</div>
        <div class="explanation">
          {{ $translate('quickPay.explanation') }}
          <span class="provider-name">{{ providerName }}</span>
          <a color="primary" class="view-bill-details" @click="verifyFirstCommUser">
            {{ $translate('quickPay.viewBillDetails') }}
          </a>
        </div>
        <!-- sub actions - floating contact and login links -->
        <div class="quickpay-actions">
          <base-btn
            @click="payFullAmount()"
            class="full-payment v-button-primary no-capitalization vuetify"
            variant="outlined">
            {{ $translate('quickPay.payFullAmount') }}
          </base-btn>
          <base-btn
            @click="payPartialAmount()"
            class="partial-payment no-capitalization vuetify"
            variant="outlined">
            {{ $translate('quickPay.payPartialAmount') }}
          </base-btn>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Account from './../models/Account';
import Provider from './../models/Provider';
import PatientcoLogo from './../components/PatientcoLogo.vue';
import PatientcoText from './../components/PatientcoText.vue';
import StandardFooter from './../components/StandardFooter.vue';
import BaseDialog from './../components/BaseDialog.vue';
import SundaySkyPlayer from './../components/SundaySkyPlayer.vue';
import BaseBtn from '../components/BaseBtn.vue';
import BaseChip from '../components/BaseChip.vue';

export default {
  name: 'QuickPay',
  components: {
    BaseBtn,
    PatientcoLogo,
    PatientcoText,
    StandardFooter,
    BaseDialog,
    SundaySkyPlayer,
    BaseChip,
  },
  data() {
    return {
      providerName: null,
      patientName: null,
      amountDue: 0,
      dueDate: new Date(),
      accountId: null,
      providerId: null,
    }
  },
  computed: {
    brandingConfig() {
      return this.$store.getters.brandingConfig;
    },
    currentBill() {
      return this.$store.getters.currentBill;
    },
    currentProvider() {
      return this.$store.getters.currentProvider;
    },
    currentAccount() {
      return this.$store.getters.currentAccount;
    },
    currentPaymentData() {
      return this.$store.getters.currentPaymentData;
    },
    authOptionsBill() {
      return this.$store.getters.authOptionsBill;
    },
    firstBillToken() {
      return this.$store.getters.firstBillToken;
    }
  },
  methods: {
    notImplemented() {
      alert('not implemented');
    },
    needHelp() {
      let needHelpSrc = 'assets/images/needhelp.png';
      if (this.brandingConfig && this.brandingConfig.needHelpImage) {
          needHelpSrc = this.brandingConfig.needHelpImage;
      } else if (this.brandingConfig) {
          needHelpSrc = 'assets/images/needhelpbranded.png';
      }
      this.emitter.emit('simpleModal:showPrompt',{
          header: this.$translate('landing.needHelpHeader'),
          subcontent: this.$translate('landing.needHelpContent') +
              '<br /><br /><img class="needhelp" src="' + needHelpSrc + '"/>',
          actions: [{
              label: this.$translate('actions.close'),
              includeClasses: 'button-primary'
          }]
      });
    },
    payFullAmount() {
      this.$store.commit('setIsGuestPay', true);
      this.$store.commit('setIsQuickPay', true);
      this.$store.commit('setQuickPayType', 'CB');
      this.$router.push({
          name: 'GuestAccountPaymentFlowAmountStep',
          params: {
              accountId: this.accountId,
              providerId: this.providerId
          },
      });
    },
    payPartialAmount() {
      this.$store.commit('setIsGuestPay', true);
      this.$store.commit('setIsQuickPay', true);
      this.$store.commit('setQuickPayType', 'O');
      this.$router.push({
          name: 'GuestAccountPaymentFlowAmountStep',
          params: {
              accountId: this.accountId,
              providerId: this.providerId
          },
      });
    },
    verifyFirstCommUser() {
      this.emitter.emit("firstCommVerification:prompt", {
        'type': 'bill',
        'options': this.authOptionsBill,
        'token': this.firstBillToken,
      });
    },
  },
  mounted() {
    this.$store.dispatch('showLoadingIfNecessary');
    let provider = this.currentProvider;
    let account = this.currentAccount;
    let authOptions = this.authOptionsBill;
  
    // Get minimum data required to go through payment flow
    if (!provider || !Object.keys(provider).length) {
      this.providerName = authOptions?.providerName;
      this.patientName = authOptions?.guarantorName;
      this.amountDue = authOptions?.billAmountDue;
      this.dueDate = new Date(authOptions?.billDueDate);
      this.accountId = authOptions?.id;
      this.providerId = authOptions?.providerId;
      let secureCode = authOptions?.secureCode;
      let active = authOptions?.active;
      let features = authOptions?.features;
      provider = new Provider({
        id: this.providerId,
        name: this.providerName,
        active: active,
        features: features,
      });
      let billSource = {
        hasFinancing: false,
        paymentOptions: [
          { type: 'CB', amount:  parseFloat(this.amountDue) },
          { type: 'O' }
        ],
        providerDetails: provider,
        accountInfo: {
          patientName: this.patientName
        }
      };
      account = new Account({
        accountId: this.accountId,
        dueDate: this.dueDate,
        amountDue: parseFloat(this.amountDue),
        secureCode: secureCode,
        bills: [ billSource ]
      }, provider);
      this.$store.commit('setCurrentProvider', provider);
      this.$store.commit('setCurrentAccount', account);
    } else {
      this.providerName = provider.name;
      this.patientName = account.bills[0].accountInfo.patientName;
      this.amountDue = account.amountDue;
      this.dueDate = account.dueDate;
      this.accountId = account.accountId;
      this.providerId = provider.id;
    }
    this.$store.commit('setCurrentPaymentData', {provider: provider, account: account, estimate: null, subAccount: null});
  },
};
</script>
<style lang="scss">
@import './../styles/variables.scss';
#app-wrap.quick-pay {
  width: 1000px;
  max-width: 1000px;
  margin: 0 auto;
  padding-right: 45px;
  color: #4d4d4d;
  .container {
    padding-top: 2em !important;
    padding-left: 45px !important;
    width: 100% !important;
    max-width: 100% !important;
    max-width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .header-persistent-content {
      flex-basis: content;
      font-size: 1.4rem !important;
    }
    button.need-help-sheet-btn {
      border-width: 1.5px;
      font-size: 1.4rem;
    }
    &:after {
      content: none !important;
    }
    .header-home-link {
      display: flex !important;
      flex-direction: row;
      background-color: #f00 !important;
      height: 32px;
    }
  }

  #main-content-v2 {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 0;
    &:after {
      content: none !important;
    }
    #right, #left {
      width: 432px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 2em;
    }

    #right {
      align-content: start;

      .quickpay-actions {
        display: flex;
        flex-direction: column;
        button.base-btn {
          font-size: 2.4rem;
          font-weight: 400;
          padding: 3.2rem;
          margin-bottom: 16px;
          &.partial-payment {
            border-color: $global-color-secondary;
            color: $global-color-secondary;
          }
          .v-btn__content {
            margin-top: -1.6rem;
          }
        }
      }
      .view-bill-details {
        display: block;
        margin-top: 2.4rem;
        margin-bottom: 4rem;
      }
      .salutation {
        font-size: 3.2rem;
        font-weight: regular;
      }
      .explanation {
        font-size: 2.4rem;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .provider-name {
        display: block;
      }
    }
    #left {
      padding-top: 60px;
      .fancy-background {
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 80px;
        width: 432px !important;
        height: 577px;
        background: center / contain no-repeat url("../../public/assets/images/corner-fold-page.png");

        .amount-due {
            font-size: 6rem;
            font-weight: 500;
            line-height: 8rem;
            padding-bottom: 14px;
        }

        .amount-due-label {
            font-size: 2.4rem;
        }
      }
      .vuetify.status-chip {
        .v-chip {
            font-size: 2rem !important;
            padding: 24px;
            font-weight: 500;
            letter-spacing: 0.15rem;
            text-transform: uppercase;
            .v-icon {
                margin-right: 0.4rem;
                height: 1.6rem;
                width: 1.6rem;
            }
            @include wider-than-tablet {
                font-size: 1.2rem;
            }
        }
      }
    }
  }
}
</style>