<template>
    <div class="receipt-content" v-if="paymentAgreement">

        <div class="block-segment">
            <ProviderLogo :provider-id="paymentAgreement.provider.id" :alt-text="paymentAgreement.provider.name"></ProviderLogo>

            <div class="receipt-payment-details">
                <div data-testid="patient-name" class="fs-block receipt-payer">{{paymentAgreement.name}}</div>
                <div class="fs-block">{{$translate('labels.accountNumberArg', {number: paymentAgreement.accountNumber}) }}</div>
                <div>{{paymentAgreement.provider.name}}</div>
                <div v-if="paymentAgreement.cardholderName">{{$translate('labels.cardHolderName',{name: paymentAgreement.cardholderName} )}}</div>
                <div class="fs-block" data-testid="payment-form">{{getMethodDescriptor(paymentAgreement.paymentForm)}}</div><br>
            </div>
        </div>

        <div class="block-segment">

            <div class="receipt-payment-amount" v-html="callOutAmountStatus"></div>

            <ReceiptLineItems :isPaymentPlan="false" :lineItems="lineItems" :summationTotalFormatted="summationTotalFormatted"/>

        </div>
    </div>
</template>

<script>

import { getMethodDescriptor } from '../utils/utils';
import ProviderLogo from './ProviderLogo.vue';
import Notification from './Notification.vue';
import ReceiptLineItems from './ReceiptLineItems.vue';

export default {
    name: 'AgreementReceiptContent',
    components: {
        ProviderLogo,
        Notification,
        ReceiptLineItems,
    },
    data() {
        return {
            lineItems: [],
            callOutAmountStatus: null,
            summationTotalFormatted: null,
            showLinkableNotification: false,
        };
    },
    computed: {
        paymentAgreement() {
            return this.$store.getters.paymentAgreement;
        },
    },
    methods: {
        getMethodDescriptor,
        setupUIVariables() {
             var formattedOrigAmount = this.$formatCurrency(this.paymentAgreement.amount);

            this.summationTotalFormatted = formattedOrigAmount;
            this.callOutAmountStatus = this.$translate('receipt.argAuthorizedCallOut', { amount: formattedOrigAmount });

            // set up lineItems for table summary
            this.lineItems = [];

            // balance payment
            this.lineItems.push({
                date: this.paymentAgreement.dateCreated,
                friendlyDialog: this.$translate('payment.friendlyType.agreement'),
                amountDialog: formattedOrigAmount
            });

            if (this.paymentAgreement.dateCanceled) {
                this.callOutAmountStatus = this.$translate('receipt.argCanceledCallout', { amount: formattedOrigAmount });
                this.summationTotalFormatted = this.$formatCurrency(0);
                this.lineItems.push({
                    date: this.paymentAgreement.dateCanceled,
                    friendlyDialog: this.$translate('labels.paymentCanceled'),
                    amountDialog: '<span>' + formattedOrigAmount + '</span>'
                });
            }
        },
        getIdFromRoute() {
            return this.$route.params.agreementId ?? null;
        },
    },
    async mounted() {
        await this.$nextTick();
        // Use this once we're on vue-router, and move it to live inside of data instead of props
        // const agreementId = this.$route.params.agreementId;
        this.$store.dispatch('fetchPaymentAgreementDetails', this.getIdFromRoute()).then((paymentAgreement) => {
            if (!paymentAgreement) {
                this.$router.push({name: 'ProvidersSummary'});
                return;
            }
            this.$store.commit('setReceiptPaymentAgreement', paymentAgreement);
            this.setupUIVariables();
        }, () => {
            console.warn('Could not load payment with id ' + this.getIdFromRoute()); //user is probably not allowed to see payment
            this.$router.push({name: 'Home'});
        });
    },
    destroyed() {
        // clear store when we leave the page
        this.$store.commit('setReceiptPaymentAgreement', null);
    },
};

</script>

<style lang="scss">

</style>