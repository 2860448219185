<template>
<modal :canClose="true" :show="isVisible" @close="close" class="confirm-email-modal-container">
    <div class="confirm-email-modal">
        <div class='confirm-email-form'>
            <notification class="notification-inline failure" v-show="emailNotSent">
                <base-icon icon="mdi-close-circle"></base-icon>
                {{ $translate('confirmEmail.emailNotSent') }}
            </notification>
            <form name="forms.confirmEmailForm" @submit.prevent @submit="confirmEmail" novalidate >
                <h2 class="modal-header h-style-2" >{{ $translate('confirmEmail.header') }}</h2>

                <div class="input-area-wrapper" :class="{errored: hasError && dirty, 'input-focused': focused}">

                    <legacy-email-input
						v-model="email"
						ref="email"
						@change="dirty = true"
						@inputfocus="focused = true; $forceUpdate();"
						@inputblur="validateEmail(); focused = false; $forceUpdate();"
						:original-email="currentUser ? currentUser.email : null"
						required
						is-update
					></legacy-email-input>

                    <div class="input-area-feedback">
                        <div class="vue-messages">
                            <div v-if="errors.required" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                            <div v-if="errors.invalid" class="vue-message">
                                {{ $translate('errors.email') }}
                            </div>
                            <div v-if="errors.emailAvailable" class="vue-message">
                                {{ $translate('errors.emailInUse') }}
                            </div>
                        </div>
                    </div>

                    <span class='explanation'>{{ $translate('confirmEmail.explanation') }}</span>
                </div>
            </form>

            <div class="modal-actions">
                <button class="button-primary submit-button" @click="confirmEmail">
                    {{ submitting ? $translate('confirmEmail.submittingButtonText') : $translate('confirmEmail.submitButtonText') }}
                </button>
            </div>
        </div>
    </div>
</modal>
</template>

<script>

import LegacyEmailInput from './LegacyEmailInput.vue';
import Modal from './Modal.vue';
import Notification from './Notification.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'ConfirmEmailModal',
    components: {
        LegacyEmailInput,
        Modal,
        Notification,
        BaseIcon
    },
    data: () => ({
        isVisible: false,
        submitting: false,
        submitted: false,
        emailNotSent: false,
        email: null,
        dirty: false,
        focused: false,
        errors: {
            emailAvailable: false,
            invalid: false,
            required: false,
        },
    }),
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        hasError() {
            return this.errors.emailAvailable || this.errors.invalid || this.errors.required;
        },
    },
    methods: {
        async confirmEmail() {
            this.submitted = true;
            await this.validateEmail();
            if (this.hasError) {
                this.submitting = false;
                return;
            }
            if (!this.submitting) {
                const completeEmailVerification = (currentPassword) => {
                    this.submitting = true;
                    this.isVisible = true;
                    this.$store.dispatch('confirmEmailSendVerification', { email: this.email, currentPassword })
                        .then(() => {
                            this.emitter.emit('simpleModal:showPrompt', {
                                header: this.$translate('confirmEmail.successHeader'),
                                intent: 'success',
                                autoExpire: true,
                            });
                            this.emitter.emit('userSettings:updated');
                            this.close();
                        })
                        .catch(() => {
                            this.emailNotSent = true;

                            window.setTimeout(() => {
                                this.emailNotSent = false;
                            }, 3000);
                        })
                        .finally(() => {
                            this.reset();
                        });
                };
                if (this.currentUser.email !== this.email) {
                    this.emitter.emit('confirmPassword:prompt', {
                        'callback': completeEmailVerification,
                    });
                    this.close();
                } else {
                    completeEmailVerification();
                }
            }
        },
        clearErrors() {
            this.errors.emailAvailable = false;
            this.errors.invalid = false;
            this.errors.required = false;
        },
        reset() {
            this.email = this.currentUser.email;
            this.dirty = false;
            this.submitting = false;
        },
        async validateEmail() {
            this.clearErrors();
			const emailError = await this.$refs.email.validate();
			if (emailError) {
				this.errors[emailError] = true;
			} 
			this.$forceUpdate();
        },
        close() {
            this.isVisible = false;
        },
    },
    created() {
        this.emitter.on('confirmEmail:prompt', () => {
            this.isVisible = true;
            this.reset();
        });
    },
};
</script>

<style lang="scss">
@import '../styles/variables.scss';

.confirm-email-modal-container {

	.modal-header {
        font-size: 3.0rem;
        margin-bottom: 20px;
	}

    .explanation {
    	font-size: 1.3rem;
    }

    .modal-actions {
        margin-bottom: 10%;
        @include wider-than-tablet {
            margin-bottom: 0%;
        }
    }
}
</style>
