<template>
    <div v-if="plan">
        <div class="receipt-left-col-per block-segment">
            <h1 class="call-out provider-dashboard ">
                {{$translate('labels.paymentPlanTitle')}}
            </h1>
            <div class="sub-content">
                {{plan.status}}
            </div>

            <div class="receipt-created-stamp">
                {{ $translate(plan.isUpdatedPlan ? 'labels.updatedArg' : 'labels.createdArg', { date: $formatDate(plan.dateCreated, 'MM/DD/YY') }) }}
            </div>

            <div class="receipt-created-stamp">{{$translate( 'labels.argTotal', { amount: $formatCurrency(plan.totalAmount) })}}</div>
        </div>

        <div class="separator"></div>
    </div>
</template>

<script>
export default {
    name: 'PlanReceiptLeftColumnPersist',
    computed: {
        plan() {
            return this.$store.getters.plan;
        }
    },
};

</script>

<style lang="scss">

</style>