<template>
<div v-if="account" class="payment-flow-left-column-persist vuetify">
    <div v-if="!$vuetify.display.lgAndUp">
        <div class="responsive-left-column-persist-mobile">
            <back-button :text="backText" @backbuttonclick="backLink"></back-button>
            <base-btn
                @click="needHelpBottomSheet = true"
                class="need-help-sheet-btn"
                variant="outlined"
            >{{ $translate('labels.needHelp') }}</base-btn>
        </div>
    </div>
    <payment-flow-progress-bar
        v-if="$vuetify.display.lgAndUp || progressBarSteps.length >= 3"
        @step="onClickProgressBarStep"
        :steps="progressBarSteps"
        :current-step="progressBarCurrentStep"
        :vertical="$vuetify.display.lgAndUp"
    ></payment-flow-progress-bar>
    <base-bottom-sheet v-model="needHelpBottomSheet" content-class="payment-flow-content-need-help-sheet need-help-sheet">
        <card-header
            :title="$translate('labels.needHelp')"
            icon="mdi-hospital-box-outline"
            class="sheet-card-header"
        ></card-header>
        <need-help
            @composemessage="composeMessage"
            :provider="currentProvider"
        ></need-help>
    </base-bottom-sheet>
</div>
</template>

<script>

import BackButton from './BackButton.vue';
import CardHeader from './CardHeader.vue';
import NeedHelp from './NeedHelp.vue';
import PaymentFlowProgressBar from './PaymentFlowProgressBar.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseBtn from './BaseBtn.vue';

export default {
	name: 'PaymentFlowLeftColumnPersist',
    components: {
        BaseBtn,
        BackButton,
        CardHeader,
        NeedHelp,
        PaymentFlowProgressBar,
        BaseBottomSheet
    },
    data: () => ({
        account: null,
        cameFromBillSummary: false,
        cameFromEstimateSummary: false,
        needHelpBottomSheet: false,
    }),
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
        currentPaymentData() {
            return this.$store.getters.currentPaymentData;
        },
        backText() {
            switch (this.routeName) {
                case 'AccountPaymentFlowAccessOneStep':
                case 'AccountPaymentFlowDateStep':
                case 'AccountPaymentFlowCuraeAcceptStep':
                case 'AccountPaymentFlowCuraeApplicationStep':
                case 'AccountPaymentFlowCuraeExistingStep':
                case 'AccountPaymentFlowCuraeOffersStep':
                case 'EstimatePaymentFlowDateStep':
                case 'SubAccountPaymentFlowDateStep':
                case 'GuestAccountPaymentFlowDateStep':
                case 'GuestSubAccountPaymentFlowDateStep':
                    return this.$translate('paymentFlow.backButtons.amount');
                case 'AccountPaymentFlowMethodStep':
                case 'EstimatePaymentFlowMethodStep':
                case 'SubAccountPaymentFlowMethodStep':
                case 'GuestAccountPaymentFlowMethodStep':
                case 'GuestSubAccountPaymentFlowMethodStep':
                    if (this.shouldHideDateStep) {
                        return this.$translate('paymentFlow.backButtons.amount');
                    }
                    return this.$translate('paymentFlow.backButtons.date');
                case 'AccountPaymentFlowConfirmationStep':
                case 'EstimatePaymentFlowConfirmationStep':
                case 'SubAccountPaymentFlowConfirmationStep':
                case 'GuestAccountPaymentFlowConfirmationStep':
                case 'GuestSubAccountPaymentFlowConfirmationStep':
                    return this.$translate('paymentFlow.backButtons.method');
            }
            if (this.cameFromBillSummary) {
                return this.$translate('paymentFlow.backButtons.billSummary');
            }
            if (this.cameFromEstimateSummary) {
                return this.$translate('paymentFlow.backButtons.estimateSummary');
            }
            if (this.account?.accountNumber) {
                return this.$translate('paymentFlow.backButtons.accountDetails', {
                    accountNumber: this.account.accountNumber.substring(this.account.accountNumber.length - 4)
                });
            }
            return null;
        },
        isInAccessOneFinancingRoute() {
            return ['AccountPaymentFlowAccessOneStep'].includes(this.routeName);
        },
        isInCuraeFinancingRoute() {
            let curaeSteps = [
                'AccountPaymentFlowCuraeApplicationStep',
                'AccountPaymentFlowCuraeOffersStep',
                'AccountPaymentFlowCuraeAcceptStep'
            ];
            return curaeSteps.includes(this.routeName);
        },
        isInCuraeExistingRoute() {
            return ['AccountPaymentFlowCuraeExistingStep'].includes(this.routeName);
        },
        progressBarCurrentStep() {
            switch (this.routeName) {
                case 'AccountPaymentFlowAmountStep':
                case 'EstimatePaymentFlowAmountStep':
                case 'SubAccountPaymentFlowAmountStep':
                case 'GuestAccountPaymentFlowAmountStep':
                case 'GuestSubAccountPaymentFlowAmountStep':
                    return 1;
                case 'AccountPaymentFlowAccessOneStep':
                case 'AccountPaymentFlowCuraeApplicationStep':
                case 'AccountPaymentFlowDateStep':
                case 'EstimatePaymentFlowDateStep':
                case 'SubAccountPaymentFlowDateStep':
                case 'GuestAccountPaymentFlowDateStep':
                case 'GuestSubAccountPaymentFlowDateStep':
                    return 2;
                case 'AccountPaymentFlowCuraeOffersStep':
                case 'AccountPaymentFlowCuraeExistingStep':
                    return 3;
                case 'AccountPaymentFlowCuraeAcceptStep':
                    return 4;
                case 'AccountPaymentFlowMethodStep':
                case 'EstimatePaymentFlowMethodStep':
                case 'SubAccountPaymentFlowMethodStep':
                case 'GuestAccountPaymentFlowMethodStep':
                case 'GuestSubAccountPaymentFlowMethodStep':
                    return this.shouldHideDateStep ? 2 : 3;
                case 'EstimatePaymentFlowConfirmationStep':
                case 'AccountPaymentFlowConfirmationStep':
                case 'SubAccountPaymentFlowConfirmationStep':
                case 'GuestAccountPaymentFlowConfirmationStep':
                case 'GuestSubAccountPaymentFlowConfirmationStep':
                    return this.shouldHideDateStep ? 3 : 4;
                default:
                    return -1;
            }
        },
        progressBarSteps() {
            let amountStep, dateStep, methodStep, confirmationStep;
            if (this.isInAccessOneFinancingRoute) {
                return [
                    {
                        name: this.$translate('paymentFlow.steps.amount'),
                        path: 'AccountPaymentFlowAmountStep',
                    }, {
                        name: this.$translate('paymentFlow.steps.availableOffers'),
                        path: 'AccountPaymentFlowAccessOneStep',
                    },
                ]
            } else if (this.isInCuraeFinancingRoute) {
                return [
                    {
                        name: this.$translate('paymentFlow.steps.amount'),
                        path: 'AccountPaymentFlowAmountStep',
                    }, {
                        name: this.$translate('paymentFlow.steps.curaeApplication'),
                        path: 'AccountPaymentFlowCuraeApplicationStep',
                    }, {
                        name: this.$translate('paymentFlow.steps.availableOffers'),
                        path: 'AccountPaymentFlowCuraeOffersStep',
                    }, {
                        name: this.$translate('paymentFlow.steps.acceptOffer'),
                        path: 'AccountPaymentFlowCuraeAcceptStep',
                    },
                ]
            } else if (this.isInCuraeExistingRoute) {
                return [
                    {
                        name: this.$translate('paymentFlow.steps.amount'),
                        path: 'AccountPaymentFlowAmountStep',
                    }, {
                        name: this.$translate('paymentFlow.steps.curaeApplication'),
                        path: 'AccountPaymentFlowCuraeApplicationStep',
                    }, {
                        name: this.$translate('paymentFlow.steps.existingAccount'),
                        path: 'AccountPaymentFlowCuraeExistingStep',
                    },
                ]
            } else if (this.subAccountId) {
                amountStep = {
                    name: this.$translate('paymentFlow.steps.amount'),
                    path: this.isGuestPay ? 'GuestSubAccountPaymentFlowAmountStep' : 'SubAccountPaymentFlowAmountStep',
                };
                dateStep = {
                    name: this.$translate('paymentFlow.steps.date'),
                    path: this.isGuestPay ? 'GuestSubAccountPaymentFlowDateStep' : 'SubAccountPaymentFlowDateStep',
                };
                methodStep = {
                    name: this.$translate('paymentFlow.steps.method'),
                    path: this.isGuestPay ? 'GuestSubAccountPaymentFlowMethodStep' : 'SubAccountPaymentFlowMethodStep',
                };
                confirmationStep = {
                    name: this.$translate('paymentFlow.steps.confirmation'),
                    path: this.isGuestPay ? 'GuestSubAccountPaymentFlowConfirmationStep' : 'SubAccountPaymentFlowConfirmationStep',
                };
            } else if (this.estimateId) {
                amountStep = {
                    name: this.$translate('paymentFlow.steps.amount'),
                    path: 'EstimatePaymentFlowAmountStep',
                };
                dateStep = {
                    name: this.$translate('paymentFlow.steps.date'),
                    path: 'EstimatePaymentFlowDateStep',
                };
                methodStep = {
                    name: this.$translate('paymentFlow.steps.method'),
                    path: 'EstimatePaymentFlowMethodStep',
                };
                confirmationStep = {
                    name: this.$translate('paymentFlow.steps.confirmation'),
                    path: 'EstimatePaymentFlowConfirmationStep',
                };
            } else {
                amountStep = {
                    name: this.$translate('paymentFlow.steps.amount'),
                    path: this.isGuestPay ? 'GuestAccountPaymentFlowAmountStep' : 'AccountPaymentFlowAmountStep',
                };
                dateStep = {
                    name: this.$translate('paymentFlow.steps.date'),
                    path: this.isGuestPay ? 'GuestAccountPaymentFlowDateStep' : 'AccountPaymentFlowDateStep',
                };
                methodStep = {
                    name: this.$translate('paymentFlow.steps.method'),
                    path: this.isGuestPay ? 'GuestAccountPaymentFlowMethodStep' : 'AccountPaymentFlowMethodStep',
                };
                confirmationStep = {
                    name: this.$translate('paymentFlow.steps.confirmation'),
                    path: this.isGuestPay ? 'GuestAccountPaymentFlowConfirmationStep' : 'AccountPaymentFlowConfirmationStep',
                };
            }
            if (this.shouldHideDateStep) {
                return [amountStep, methodStep, confirmationStep];
            }
            return [amountStep, dateStep, methodStep, confirmationStep];
        },
        shouldHideDateStep() {
            if (!this.estimateId && this.account.bills && this.account.bills[0]?.providerDetails?.limits?.paymentMaxScheduleDays !== undefined) {
                return !Boolean(this.account.bills[0].providerDetails.limits.paymentMaxScheduleDays) || this.account.isBadDebt;
            } else if (this.account.estimates && this.account.estimates[0]?.providerDetails?.limits?.paymentMaxScheduleDays !== undefined) {
                return !Boolean(this.account.estimates[0].providerDetails.limits.paymentMaxScheduleDays)
            }
            return false;
        },
        accountId() {
            return this.$route.params.accountId;
        },
        subAccountId() {
            return this.$route.params.subAccountId;
        },
        estimateId() {
            return this.$route.params.estimateId;
        },
        routeName() {
            return this.$route.name;
        }
    },
    watch: {
        currentProvider() {
            if (!this.isGuestPay) {
                this.loadAccount();
            }
        },
    },
    methods: {
        closeBottomSheet() {
            this.needHelpBottomSheet = false;
        },
        composeMessage() {
            this.closeBottomSheet();
            this.emitter.emit('message:compose', { secureCode: this.account.secureCode });
        },
        loadAccount() {
            if (this.currentProvider && this.currentProvider.accounts) {
                this.currentProvider.accounts.forEach((a) => {
                    if (this.accountId.toString() === a.accountId.toString()) {
                        this.account = a;
                    }
                });
            }
        },
        onClickProgressBarStep(step) {
            this.emitter.emit('payment-flow-go-to-step', step);
        },
        backLink() {
            const providerId = this.currentProvider.id;
            let step;
            switch (this.routeName) {
                case 'AccountPaymentFlowCuraeApplicationStep':
                case 'AccountPaymentFlowAccessOneStep':
                case 'AccountPaymentFlowDateStep':
                case 'GuestAccountPaymentFlowDateStep':
                    step = this.isGuestPay ? 'GuestAccountPaymentFlowAmountStep' : 'AccountPaymentFlowAmountStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId});
                    return;
                case 'AccountPaymentFlowMethodStep':
                case 'GuestAccountPaymentFlowMethodStep':
                    if (this.shouldHideDateStep) {
                        step = this.isGuestPay ? 'GuestAccountPaymentFlowAmountStep' : 'AccountPaymentFlowAmountStep';
                        this.goToLink(step, {accountId: this.accountId, providerId: providerId});
                        return;
                    }
                    step = this.isGuestPay ? 'GuestAccountPaymentFlowDateStep' : 'AccountPaymentFlowDateStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId});
                    return;
                case 'AccountPaymentFlowConfirmationStep':
                case 'GuestAccountPaymentFlowConfirmationStep':
                    step = this.isGuestPay ? 'GuestAccountPaymentFlowMethodStep' : 'AccountPaymentFlowMethodStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId});
                    return; 
                case 'SubAccountPaymentFlowDateStep':
                case 'GuestSubAccountPaymentFlowDateStep':
                    step = this.isGuestPay ? 'GuestSubAccountPaymentFlowAmountStep' : 'SubAccountPaymentFlowAmountStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId, subAccountId: this.subAccountId});
                    return;
                case 'SubAccountPaymentFlowMethodStep':
                case 'GuestSubAccountPaymentFlowMethodStep':
                    if (this.shouldHideDateStep) {
                        step = this.isGuestPay ? 'GuestSubAccountPaymentFlowAmountStep' : 'SubAccountPaymentFlowAmountStep';
                        this.goToLink(step, {accountId: this.accountId, providerId: providerId, subAccountId: this.subAccountId});
                        return;
                    }
                    step = this.isGuestPay ? 'GuestSubAccountPaymentFlowDateStep' : 'SubAccountPaymentFlowDateStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId, subAccountId: this.subAccountId});
                    return;
                case 'SubAccountPaymentFlowConfirmationStep':
                case 'GuestSubAccountPaymentFlowConfirmationStep':
                    step = this.isGuestPay ? 'GuestSubAccountPaymentFlowMethodStep' : 'SubAccountPaymentFlowMethodStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId, subAccountId: this.subAccountId});
                    return;
                case 'EstimatePaymentFlowDateStep':
                    step = 'EstimatePaymentFlowAmountStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId, estimateId: this.estimateId});
                    return;
                case 'EstimatePaymentFlowMethodStep':
                    if (this.shouldHideDateStep) {
                        step = 'EstimatePaymentFlowAmountStep';
                        this.goToLink(step, {accountId: this.accountId, providerId: providerId, estimateId: this.estimateId});
                        return;
                    }
                    step = 'EstimatePaymentFlowDateStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId, estimateId: this.estimateId});
                    return;
                case 'EstimatePaymentFlowConfirmationStep':
                    step = 'EstimatePaymentFlowMethodStep';
                    this.goToLink(step, {accountId: this.accountId, providerId: providerId, estimateId: this.estimateId});
                    return;
                case 'AccountPaymentFlowCuraeExistingStep':
                case 'AccountPaymentFlowCuraeOffersStep':
                    this.goToLink('AccountPaymentFlowCuraeApplicationStep', {accountId: this.accountId, providerId: providerId});
                    return;
                case 'AccountPaymentFlowCuraeAcceptStep':
                    this.goToLink('AccountPaymentFlowCuraeOffersStep', {accountId: this.accountId, providerId: providerId});
                    return;
            }
            if (this.cameFromBillSummary) {
                this.goToLink('BillSummary', {});
            } else if (this.cameFromEstimateSummary) {
                this.goToLink('EstimateSummary', {});
            } else {
                this.goToLink('AccountDetails', {accountId: this.accountId, providerId: providerId});
            }
        },
        goToLink(name, params) {
            this.$router.push({
                name: name,
                params: params,
            });
        }
    },
    async mounted() {
        await this.$nextTick();
        if (this.isGuestPay) {
            this.account = this.currentPaymentData.account;
        } else {
            this.loadAccount();
        }
        if (document.referrer && document.referrer.includes('/billSummary')) {
            this.cameFromBillSummary = true;
        }
        if (document.referrer && document.referrer.includes('/estimateSummary')) {
            this.cameFromEstimateSummary = true;
        }
    },
};
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.payment-flow-left-column-persist {
    @include wider-than-tablet {
        padding-top: 3rem;
    }
    .back-button {
        flex: 1;
    }
}
</style>
