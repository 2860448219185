<template>
    <div class="patientco-logo">
        <base-icon icon="custom:PatientcoSVG"></base-icon>
    </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'PatientcoLogo',
    components: {
        BaseIcon
    }
};
</script>