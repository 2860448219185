import http from '../../utils/http';
import _ from 'lodash';

const state = {
    eventTypes: {
        bill : 'bill',
        collection : 'collection',
        collectionStop : 'collection-stop',
        collectionUpdate : 'collection-update',
        collectionExtraction: 'collection-extraction',
        correspondence : 'correspondence',
        correspondenceOnly : 'correspondenceOnly',
        message : 'message',
        changeOfAddress : 'changeOfAddress',
        payment : 'payment',
        paymentPlan : 'paymentPlan',
        returnMail : 'returnMail',
        reversePayment : 'reversePayment',
        balanceUpdate : 'balanceUpdate',
        voidedPayment : 'voidedPayment',
        declinedPayment : 'declinedPayment',
        canceledPayment : 'canceledPayment',
        voidedPaymentPlan : 'voidedPaymentPlan',
        canceledPaymentPlan : 'canceledPaymentPlan',
        paymentPlanCreated : 'paymentPlanCreated',
        paymentPlanCompleted : 'paymentPlanCompleted'
    },
    currentAccount: null,
};

const getters = {
    currentAccount(state) {
        return state.currentAccount;
    }
};

const mutations = {
    getFriendlyActivityDescription(state, event){
        if(event.itemType === state.eventTypes.message){
            // truncate the message
            var limit = 20;
            var words = event.description.split(' ');
            var len = words.length;
            event.description = _.slice(words, 0, limit || len).join(' ') + (len > limit ? '...' : '');
        }
    },
    setCurrentAccount(state, val) {
        state.currentAccount = val;
    }
};

const actions = {
    fetchAccountHistory({commit}, {id}) {
        var url = `accounts/${id}/activity`;
        return http.get(url).then((resp) => {
            var history = (resp.hasData()) ? resp.getData() : [];
            _.forEach(history, function(event){
                commit('getFriendlyActivityDescription', event);
            });
            return history;
        });
    },
};

export default {
    state, getters, mutations, actions,
};
