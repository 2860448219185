import vueEventTransmitter  from '../utils/vueEventTransmitter';

const onRequest = (config, store) => {
    if ('post' === config.method) {
        config.data = config.data || {};
        config.data['XSRF-TOKEN'] = localStorage.getItem('XSRF-TOKEN');
    }
    const apiPattern = /\/api\/services\/v1/;
    if (store.getters.subdomain && (apiPattern.test(config.baseURL) || apiPattern.test(config.url))) {
        config.params = config.params || {};
        config.params.subdomain = store.getters.subdomain;
    }
    return config;
};

const onResponse = (response, store) => {
    const serverResponse = response.data;
    if (serverResponse && serverResponse.xsrfToken){
        localStorage.setItem('XSRF-TOKEN', serverResponse.xsrfToken);
    }
    if (serverResponse && serverResponse.data) {
        if(serverResponse.devMessage){
            console.log('SERVER MESSAGE:', serverResponse.devMessage);
        }

        if(serverResponse.gatewayStatus){
            store.dispatch('updatePaymentStatus', serverResponse.gatewayStatus);
        }

        if(serverResponse.data && serverResponse.data.autologoutStatus){
            //autologoutstatus is delivered as part of the main payload - so, we have to look within the data property.
            //broadcast to autologoutService.  could have been set directly on the service itself but there would be a circular
            //dependancy if autologoutService were injected here
            vueEventTransmitter.emit('autologoutService:refresh', serverResponse.data.autologoutStatus);
        }

        // reject with errorCode
        if (serverResponse.errorCode){

            if (serverResponse.errorCode === 'UNAUTHORIZED_ACCESS'){

                // we also get an unauthorized response from our fetchUser call.
                // When we open the app for the first time, we try to get the currently
                // logged in user but since we know the user is not logged in on that call,
                // won't emit unauthed
                if (response.config.url !== 'patientUser' || (response.config.method !== 'GET' && response.config.method !== 'get')){
                    vueEventTransmitter.emit('unauthedAccessResponse');
                }
            }

            if (serverResponse.errorCode === 'LOGIN_NOT_VERIFIED'){
                vueEventTransmitter.emit('verification:prompt', {
                    email: serverResponse.data.email 
                });
            }

            return Promise.reject(serverResponse);
        }
    } else if (serverResponse) {
        // reject with errorCode
        if (serverResponse.errorCode){

            if (serverResponse.errorCode === 'UNAUTHORIZED_ACCESS'){
                // we also get an unauthorized response from our fetchUser call.
                // When we open the app for the first time, we try to get the currently
                // logged in user but since we know the user is not logged in on that call,
                // won't emit unauthed
                if (response.config.url !== 'patientUser' || (response.config.method !== 'GET' && response.config.method !== 'get')){
                    vueEventTransmitter.emit('unauthedAccessResponse');
                }
            } else if (serverResponse.errorCode == 'INVALID_PARAMETERS') {
                window.FS.event('invalid-parameter-response', {
                    url: response.config.baseURL,
                });
            }

            return Promise.reject(serverResponse);
        }
    }

    return response;
};

export { onRequest, onResponse };