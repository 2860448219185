<template>
    <div class="vuetify back-button">
        <base-btn :color="$vuetify.theme.themes.light.colors.secondary" @click="$emit('backbuttonclick')" :ripple="false" variant="plain">
            <base-icon icon="mdi-chevron-left"></base-icon>
            {{ text }}
        </base-btn>
    </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';

export default ({
    components: {
        BaseBtn,
        BaseIcon
    },
    name: 'BackButton',
    props: {
        text: String,
    },
});
</script>
<style lang="scss">
@import './../styles/variables.scss';
.back-button {
    button {
        font-size: 1.4rem;
    }
    .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
        opacity: 1;
    }
    .v-btn:not(.v-btn--round).v-size--default {
        padding: 0;
        font-weight: 500;
        letter-spacing: 0.125rem;
        line-height: 1.6rem;
        @include wider-than-tablet {
            margin-bottom: 0.6rem;
            margin-left: 1.6rem;
        }
    }
}
</style>