<template>
<div class="payment-flow-curae-offer-step vuetify">
    <div class="financing-logo-container">
        <img class="financing-logo" src="assets/images/curaeLogo.png" />
    </div>
    <div class="offer-title">
        <h1 v-if="curaeFinancingOptions.length > 1">{{ $translate('paymentFlow.financing.curae.mulitpleOffersTitle', { creditLimit: $formatCurrency(creditLimit) }) }}</h1>
        <h1 v-else-if="curaeFinancingOptions.length == 1">{{ $translate('paymentFlow.financing.curae.oneOfferTitle', { creditLimit: $formatCurrency(creditLimit) }) }}</h1>
    </div>
    <base-expansion-panels v-model="optionIndex" mandatory>
        <base-expansion-panel v-for="(option, index) in curaeFinancingOptions" :key="index" :selected="index === optionIndex" class="option-panel">
            <template #title>
                <div v-if="option.rate && option.deferredInterestMonths">
                    {{ $translate('paymentFlow.financing.curae.amountAprDeffered', { amount: $formatCurrency(option.amount), apr: option.rate }) }}
                </div>
                <div v-else-if="option.rate">
                    {{ $translate('paymentFlow.financing.curae.amountApr', { amount: $formatCurrency(option.amount), apr: option.rate }) }}
                </div>
                <div v-else-if="!option.rate">
                    {{ $translate('paymentFlow.financing.curae.amountNoApr', { amount: $formatCurrency(option.amount) }) }}
                </div>
            </template>
            <template #subtitle>
                {{ $translate('paymentFlow.financing.curae.months', { months: option.months }) }}
            </template>
            <template #text v-if="option.deferredInterestMonths">
                {{ $translate('paymentFlow.financing.curae.deferredInterest', { months: option.deferredInterestMonths }) }}
            </template>
        </base-expansion-panel>
    </base-expansion-panels>
    <div v-if="financingDisclaimers && financingDisclaimers.financingOptions" class="financing-disclaimer financing-options">
        {{ financingDisclaimers.financingOptions }}
    </div>
    <div v-if="financingDisclaimers && financingDisclaimers.interestBearing" class="financing-disclaimer interest-bearing">
        {{ financingDisclaimers.interestBearing }}
    </div>
    <div v-if="financingDisclaimers && financingDisclaimers.interestFree" class="financing-disclaimer interest-free">
        {{ financingDisclaimers.interestFree }}
    </div>
    <div class="navigation-buttons">
        <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <base-btn @click="selectCuraeAccept()" :disabled="isGettingFinancingOptions" class="continue-btn v-button-primary">
            {{ $translate('paymentFlow.actions.continue') }}
        </base-btn>
    </div>
    <div class="bank-account">{{  $translate('paymentFlow.financing.curae.bankAccount') }}</div>
</div>
</template>
<script>
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseBtn from './BaseBtn.vue';
import BaseExpansionPanels from './BaseExpansionPanels.vue';
import BaseSpacer from './BaseSpacer.vue';

export default {
    name: 'PaymentFlowCuraeOffersStep',
    components: {
        BaseExpansionPanel,
        BaseBtn,
        BaseExpansionPanels,
        BaseSpacer
    },
    props: {
        curaeFinancingOptions: Array,
        curaeSelectedOptionIndex: Number,
        financingDisclaimers: Object,
        isGettingFinancingOptions: Boolean,
    },
    data: () => ({
        optionIndex: 0,
    }),
    methods: {
        selectCuraeAccept() {
            this.$emit('select-curae-offer', { option: this.curaeFinancingOptions[this.optionIndex], index: this.optionIndex } );
        },
        cancel() {
            this.$emit('cancel');
        },
        back() {
            this.$emit('back');
        },
    },
    computed: {
        creditLimit() {
            return this.curaeFinancingOptions[0].creditLimit;
        }
    },
    created() {
        if (this.curaeSelectedOptionIndex) {
            this.optionIndex = this.curaeSelectedOptionIndex;
        }
        this.$emit('scroll-to-top');
    },
    mounted() {
        this.$emit('loaded');
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.payment-flow-curae-offer-step {
    .offer-title {
        padding-bottom: 2.5rem;
        h1 {
            color: $global-color-font-new;
            font-size: 2.0rem;
            line-height: 2.4rem;
            font-weight: 500;
            @include wider-than-tablet {
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }
    }
    .bank-account {
        color: $global-color-font-new;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .option-panel {
        .v-card-title {
            color: $global-color-font-new;
            font-size: 1.4rem;
            line-height: 1.6rem;
            font-weight: 500;
            @include wider-than-tablet {
                font-size: 1.4rem;
                line-height: 2.4rem;
            }
        }
        .v-card-subtitle, .v-card-text {
            color: $global-color-font-new;
            font-size: 1.2rem;
            line-height: 1.6rem;
            @include wider-than-tablet {
                font-size: 1.4rem;
                line-height: 2.4rem;
            }
        }
        .v-card-subtitle {
            padding-bottom: 0.5rem;
        }
        .v-card-text {
            padding: 0;
        }
    }
    .financing-disclaimer {
        font-size: 1.2rem;
        letter-spacing: 0.025rem;
        line-height: 1.6rem;
        @include wider-than-tablet {
            font-size: 1.4rem;
            line-height: 2rem;
            padding: 0 1.5rem;
        }
    }
    .financing-options, .interest-bearing {
        padding-bottom: 1rem;
    }
}
</style>
