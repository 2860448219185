<template>
<modal can-close :show="isVisible" @close="close" class="confirm-pass-modal-container">
    <div>
        <h2 class="modal-header h-style-2" v-html="modal.header" ></h2>
        <h3 class="modal-subheader h-style-4" v-html="modal.subheader"></h3>
        <h4 class="modal-additional-info h-style-4" v-html="modal.additionalInfo"></h4>

        <div class="confirm-pass-content">
            <form name="confirmPasswordForm" @submit.prevent @submit="confirm" novalidate>

                <div class="input-area-wrapper" :class="{errored: invalid && submitted, 'input-focused': dirty}">
                    
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': dirty }">
                            <base-icon icon="mdi-lock"></base-icon>
                        </span>

                        <input required name="password" type="password"
                            maxlength="255" id="confirm-current-pass"
                            class="input-with-icon fs-block"
                            :placeholder="$translate('labels.password')"
                            @focus="dirty = true"
                            @blur="dirty = false"
                            v-model="password" />
                    </div>

                    <div class="input-area-feedback">
                        <div class="vue-messages">
                            <div v-if="requiredError" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                        </div>
                    </div>
                </div>						
            </form>
        </div>

        <div class="modal-actions">
            <button class="button-primary modal-single-button" @click="confirm">
                {{ $translate('actions.submit') }}
            </button>
        </div>
    </div>
</modal>
</template>
<script>

import Modal from './Modal.vue';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'ConfirmPasswordModal',
    components: {
        Modal,
        BaseIcon
    },
    data: () => ({
        isVisible: false,
        requiredError: false,
        password: '',
        callback: null,
        modal: {
            header: null,
            subheader: null,
            additionalInfo: null,
        },
        invalid: false,
        submitted: false,
        dirty: false,
    }),
    methods: {
        confirm() {
            this.submitted = true;
            this.validateForm();
            if (!this.invalid) {
                this.callback(this.password);
                this.close();
            }
        },
        close() {
            this.isVisible = false;
            this.clearInputs();
        },
        clearInputs() {
            this.password = '';
            this.requiredError = false;
            this.submitted = false;
            this.dirty = false;
        },
        validateForm() {
            this.requiredError = !Boolean(this.password);
            this.invalid = this.requiredError;
        },
    },
    created() {
        this.emitter.on('confirmPassword:prompt', (promptProperties) => {
            this.isVisible = true;

            this.modal = Object.assign({
                header: this.$translate('dialogs.confirmPasswordHeader'),
                subheader: this.$translate('dialogs.confirmPasswordSubheader'),
                additionalInfo: ''
            }, promptProperties);

            this.callback = promptProperties.callback;
        });
    },
};
</script>
<style lang="scss">
.confirm-pass-modal-container {
    .modal-subheader {
        font-size: 1.8rem;
        margin-top: 10px;
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modal-additional-info {
        font-size: 1.8rem;
    }
}
</style>