import axios from "axios";

const config = {
    baseURL: '/api/services/v1',
};

function getAxios() {
    const axiosInstance = axios.create(config);
    // We set up custom interceptors elsewhere in the application when the store loads,
    // so we need to make sure those interceptors get copied over to any custom axios
    // instances.
    if (axios.interceptors) {
        if (axios.interceptors.request && axios.interceptors.request.handlers) {
            axios.interceptors.request.handlers.forEach((handler) => {
                axiosInstance.interceptors.request.use(handler.fulfilled, handler.rejected);
            });
        }
        if (axios.interceptors.response && axios.interceptors.response.handlers) {
            axios.interceptors.response.handlers.forEach((handler) => {
                axiosInstance.interceptors.response.use(handler.fulfilled, handler.rejected);
            });
        }
    }
    return axiosInstance;
}

function getData(res) {
    const value = res.data;
    if (value && 'object' === typeof value) {
        value.status = res.status;
        if ('data' in value) {
            value.hasData = () => {
                return Boolean(value.data);
            };
            value.getData = () => {
                return value.data;
            };

            value.hasErrorMessage = () => {
                return Boolean(value.errorCode === 'ERROR_WITH_MESSAGE' && (value.data && 'object' === typeof value.data) && value.data.error);
            };
    
            value.getErrorMessage = () => {
                if (value.hasErrorMessage()) {
                    return value.data.error;
                }
                return null;
            };
        } else {
            value.hasData = () => false;
        }
    }
    return value;
}

function get(url, params) {
    const http = getAxios();
    return http.get(url, { params }).then(getData);
}

function post(url, data) {
    const http = getAxios();
    return http.post(url, data).then(getData);
}

// called deleteWrapper because `delete` is a reserved keyword
function deleteWrapper(url, data) {
    const http = getAxios();
    return http.delete(url, data).then(getData);
}

function extractData({ resp, allowNullData, successFlag }) {
    if (resp && (allowNullData ? resp.data !== undefined : resp.hasData())) {
        if (successFlag && 'object' === typeof resp.getData() && null !== resp.getData() && !resp.getData()[successFlag]) {
            throw resp;
        }
        return resp.getData();
    }
    throw resp;
}

function getDataOrThrow({ url, params, method, data, allowNullData = false, successFlag }) {
    if ('GET' === method) {
        return get(url, params).then(resp => extractData({ resp, allowNullData, successFlag }));
    } else if ('POST' === method) {
        return post(url, data).then(resp => extractData({ resp, allowNullData, successFlag }));
    } else if ('DELETE' === method) {
        return deleteWrapper(url, data).then(resp => extractData({ resp, allowNullData, successFlag }));
    }
    return Promise.reject(`Method ${method} not supported`);
}

export default {
    // axios instance with all of our configuration set up
    getAxios,
    
    // vanilla axios wrappers
    get,
    post,
    delete: deleteWrapper,

    // complex helper methods specific to our usecase
    getData,
    getDataOrThrow,
};
