<template>
<div class="providers-summary-left-column-persist vuetify">
    <content-card>
        <div class="providers-summary-patient-name">{{ formattedPatientName }}</div>
        <div class="providers-summary-total-amount-due">
            <div class="providers-summary-total-amount-due-amount">{{ $formatCurrency(totalAmountDue) }}</div>
            <div>{{ $translate('labels.totalAmountDue') }}</div>
        </div>
        <div class="providers-summary-providers-count">
            <div class="providers-summary-providers-count-count">{{ providers.length }}</div>
            <div>{{ $translate('dashboard.dashboard') }}</div>
        </div>
    </content-card>
</div>
</template>
<script>
import _ from 'lodash';
import { MoneyUtils } from './../utils/utils';
import ContentCard from './ContentCard.vue';

export default {
    name: 'ProvidersSummaryLeftColumnPersist',
    components: {
        ContentCard,
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
        providers() {
            return this.$store.getters.providers;
        },
        totalAmountDue() {
            return MoneyUtils.add.apply(null, _.map(this.providers, (provider) => {
                return provider.active ? provider.balance : 0;
            }));
        },
        formattedPatientName() {
            if (this.currentUser && this.currentUser.firstName) {
                const name = this.currentUser.firstName;
                let key = 'dashboard.yourPatientWallet';
                if (name.length <= 14) {
                    key = name ? name.slice(-1) !== 's' ? 'dashboard.possessivePatientWallet' : 'dashboard.possessivePatientWalletEndsWithS' : 'dashboard';
                }
                return this.$translate(key, { name });
            }
            return null;
        },
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.providers-summary-left-column-persist {
    .v-card {
        padding: 2.5rem;
        text-align: center;
        color: $global-color-font-new;
        .providers-summary-patient-name {
            font-size: 2.4rem;
            line-height: 3.2rem;
            margin-bottom: 2.5rem;
            font-weight: normal;
        }
        .providers-summary-total-amount-due {
            margin-bottom: 2.5rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            .providers-summary-total-amount-due-amount {
                font-size: 4.8rem;
                line-height: 5.4rem;
            }
            @include wider-than-tablet {
                .providers-summary-total-amount-due-amount {
                    font-size: 3.4rem;
                    line-height: 3.6rem;
                }
            }
        }
        .providers-summary-providers-count {
            font-size: 1.6rem;
            line-height: 2.4rem;
            .providers-summary-providers-count-count {
                font-size: 2rem;
            }
        }
    }
    .content-card.v-card.v-sheet{ margin-bottom:  0;}
    .content-card-container { padding-bottom: 0.1rem; }
}
</style>