<template>
<div class="payment-flow-date-step vuetify">
    <div class="payment-flow-step-title">{{ $translate('paymentFlow.steps.date') }}</div>
    <div class="payment-flow-step-prompt">
        <div v-if="dateFromUpdatePlan">{{ $translate('paymentFlow.date.updatePlanYourCurrentPlan') }}</div>
        <div v-if="dateFromUpdatePlan" class="update-plan-block">
            <div class="update-plan-block-header">{{ $translate('paymentFlow.date.updatePlanHeader', { formattedDate: $formatDate(dateFromUpdatePlan, 'Do', 'YYYY-MM-DD') }) }}</div>
            <div class="update-plan-block-subheader">{{ $translate('paymentFlow.date.updatePlanSubheader', { formattedDate: $formatDate(dateFromUpdatePlan, 'MM/DD/YYYY', 'YYYY-MM-DD') }) }}</div>
        </div>
        <div>{{ (installmentCount && installmentCount > 1) ? $translate('paymentFlow.date.paymentPlanPrompt') : $translate('paymentFlow.date.prompt') }}</div>
    </div>
    <base-date-picker v-model="editDate" :min="minDate" :max="maxDate"></base-date-picker>
    <div class="navigation-buttons">
        <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <base-btn @click="updateDate()" class="continue-btn v-button-primary">{{ $translate('paymentFlow.actions.continue') }}</base-btn>
    </div>
</div>
</template>
<script>
import BaseDatePicker from './BaseDatePicker.vue';
import BaseBtn from './BaseBtn.vue';
import BaseSpacer from './BaseSpacer.vue';

export default {
    name: 'PaymentFlowDateStep',
    components: {
        BaseDatePicker,
        BaseBtn,
        BaseSpacer
    },
    props: {
        date: String,
        installmentCount: Number,
        maxDateForDiscountPolicy: String,
        maxScheduleDays: Number,
        updatePlanData: Object,
    },
    data: () => ({
        editDate: null,
    }),
    computed: {
        dateFromUpdatePlan() {
            if (this.updatePlanData && this.updatePlanData.nextDate) {
                const formattedUpdatePlanDate = this.$formatDate(this.updatePlanData.nextDate, 'YYYY-MM-DD', 'YYYY/MM/DD');
                if (formattedUpdatePlanDate <= this.maxDate && formattedUpdatePlanDate >= this.minDate) {
                    return formattedUpdatePlanDate;
                }
            }
            return null;
        },
        maxDate() {
            if (this.maxDateForDiscountPolicy) {
                return this.$formatDate(this.maxDateForDiscountPolicy, 'YYYY-MM-DD', 'YYYY/MM/DD');
            }
            const date = new Date();
            date.setDate(date.getDate() + this.maxScheduleDays);
            return this.$formatDate(date, 'YYYY-MM-DD');
        },
        minDate() {
            return this.$formatDate(new Date(), 'YYYY-MM-DD');
        },
    },
    methods: {
        updateDate() {
            this.$emit('update-date', this.editDate);
        },
        cancel() {
            this.$emit('cancel');
        },
        back() {
            this.$emit('back');
        },
    },
    created() {
        this.$emit('scroll-to-top');
    },
    mounted() {
        this.$emit('loaded');
        if (this.date) {
            this.editDate = this.date;
        } else {
            this.editDate = this.dateFromUpdatePlan || this.$formatDate(new Date(), 'YYYY-MM-DD');
        }
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.update-plan-block {
    /// TODO: Once we change to a white background, remove the darken effect here
    background-color: darken(#f5f5f5, 6%);
    border-radius: 0.4rem;
    color: $global-color-font-new;
    letter-spacing: 0.011rem;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    .update-plan-block-header {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
    }
    .update-plan-block-subheader {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
}
.vuetify.payment-flow-date-step {
    .patientapp-date-picker {
        width: 100%;
        padding: 0;
        margin: 0;
        .v-card {
            margin: 0;
        }
        @include smaller-than-mobile {
            width: 100vw;
            margin-left: -2.4rem; // general padding of the payment flow
        }
    }
}
</style>
