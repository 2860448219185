<template>
<div class="vuetify">
    <div class="sub-account-details-services">
        <div class="data-table" :class="[patientResponsibilityExpandable && !servicesExpandable ? 'expand-icon-padding' : null, !servicesExpandable && !patientResponsibilityExpandable ? 'no-expand-padding' : null]">
            <div class="header">
                <div class="header-content" :class="servicesExpandable ? 'expandable' : null" v-for="header in headers" :key="header.key">
                    {{ header.title }}
                </div>
            </div>
            <div class="body">
                <div class="service" v-for="service in currentServices" :key="(service.description) ? service.description : service.name">
                    <div class="unexpanded">
                        <!-- Keep the space if any service is expandable for alignment, but only show the icon if the service is expandable -->
                        <div v-if="servicesExpandable" class="expand-icon-container">
                            <base-icon @click="expandService(service)" :icon="service.serviceExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"></base-icon>
                        </div>
                        <div class="row-content">
                            {{ (service.description) ? service.description : service.name }} <span v-if="hasInsuranceUncoveredAmount(service)" class="alert"><base-icon icon="mdi-alert-circle-outline"></base-icon></span>
                        </div>
                        <div class="row-content">
                            {{ getAmount(service) }}
                        </div>
                    </div>
                    <div v-if="service.serviceExpanded" class="expanded expanded-header">
                        <div class="data-grid">
                            <div class="row expanded-header">
                                <div class="expanded-title">{{ service.description }}</div>
                            </div>
                            <div v-if="service.cptCode" class="row expanded-header">
                                <div class="expanded-subtitle">{{ $translate('labels.cptCode') }} {{ service.cptCode }}</div>
                            </div>
                            <div v-if="hasInsuranceUncoveredAmount(service)" class="expanded expanded-alert">
                                <div class="alert">
                                    <base-icon icon="mdi-alert-circle-outline"></base-icon>
                                    <span><b>{{service.uncoveredCode}}</b>: {{ $translate('labels.uncoveredAmountExplanation') }}</span>
                                </div>
                            </div>
                            <div v-if="service.totalCharge || service.insuranceAdjustment || service.providerAdjustment || service.insurancePaid" class="divider"></div>
                            <div v-if="service.totalCharge" class="row expanded-body">
                                <div class="col-left">{{ $translate('labels.chargeAmount') }}</div>
                                <div class="col-right">{{ $formatCurrency(service.totalCharge) }}</div>
                            </div>
                            <div v-if="service.insuranceAdjustment || service.providerAdjustment" class="row expanded-body">
                                <div class="col-left">{{ $translate('labels.adjustments') }}</div>
                                <div class="col-right">{{ $formatCurrency((service.insuranceAdjustment + service.providerAdjustment)) }}</div>
                            </div>
                            <div v-if="service.insurancePaid" class="row expanded-body">
                                <div class="col-left">{{ $translate('labels.insuranceCoverage') }}</div>
                                <div class="col-right">{{ $formatCurrency(service.insurancePaid) }}</div>
                            </div>
                            <div class="divider"></div>
                            <div class="row expanded-footer">
                                <div class="col-left">{{ $translate('labels.patientResponsibility') }}</div>
                                <div class="col-right">{{ $formatCurrency(service.patientResponsibility) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="see-all-button"><base-btn v-if="_services && Object.keys(_services).length > 3" class="cancel-btn" variant="text" @click="toggleItemsPerPage()"><base-icon :icon="buttonIcon"></base-icon>{{ buttonText }}</base-btn></div>
    </div>
</div>
</template>

<script>
import BaseBtn from './BaseBtn.vue';
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'SubAccountServices',
    props: {
        services: Array,
    },
    components: {
        BaseBtn,
        BaseIcon,
    },
    data() {
        return {
            itemsPerPage: 3,
            _services: [],
        }
    },
    computed: {
        currentBill() {
            return this.$store.getters.currentBill;
        },
        currentEstimate() {
            return this.$store.getters.currentEstimate;
        },
        servicesExpandable() {
            if (this.currentEstimate) {
                return false;
            }
            return this.$store.getters.servicesExpandable;
        },
        patientResponsibilityExpandable() {
            if (this.currentEstimate) {
                return false;
            }
            return this.$store.getters.patientResponsibilityExpandable;
        },
        headers() {
            let headers = [];
            if (this.servicesExpandable) {
                headers.push({ title: '', key: 'data-table-expand', width: '1rem'});
            }
            headers.push({ title: this.$translate('labels.services'), key: 'description', sortable: false });
            if (this.currentEstimate) {
                headers.push({ title: this.$translate('labels.charges'), key: 'patientResponsibility', sortable: false });
            } else {
                headers.push({ title: this.$translate('labels.patientResponsibility'), key: 'patientResponsibility', sortable: false });
            }
            return headers;
        },
        buttonText() {
            if (this.itemsPerPage == 3) {
                return this.$translate('labels.seeAll');
            } else {
                return this.$translate('labels.seeLess');
            }
        },
        buttonIcon() {
            if (this.itemsPerPage == 3) {
                return "mdi-chevron-down";
            } else {
                return "mdi-chevron-up";
            }
        },
        currentServices() {
            return this._services.slice(0, this.itemsPerPage);
        }
    },
    methods: {
        toggleItemsPerPage() {
            if (this.itemsPerPage == 3) {
                this.itemsPerPage = 100;
            } else {
                this.itemsPerPage = 3;
            }
        },
        getCarcCodeLabel(carcCode) {
            let code = 'carc.default';
            if (['1','2','3'].includes(carc.carcCode)) {
                code = 'carc.'+carc.carcCode;
            }
            return code;
        },
        hasInsuranceUncoveredAmount(item) {
            if (item.carcCodes) {
                let coveredCodes = ["1", "2", "3"];
                for (let c in item.carcCodes) {
                    if (!coveredCodes.includes(item.carcCodes[c].carcCode)) {
                        item.uncoveredCode = item.carcCodes[c].carcCode;
                        return true;
                    }
                }
            }
            return false;
        },
        expandService(service) {
            service.serviceExpanded = !service.serviceExpanded;
        },
        getAmount(service) {
            if (this.currentEstimate) {
                let totalCharge = service.totalCharge ? Math.abs(service.totalCharge) : 0;
                let insuranceAdjustment = service.insuranceAdjustment ? Math.abs(service.insuranceAdjustment) : 0;
                if (totalCharge) {
                    return this.$formatCurrency(totalCharge - insuranceAdjustment);
                }
            }
            return this.$formatCurrency((service.patientResponsibility) ? service.patientResponsibility : service.cost);
        }
    },
    mounted() {
        this._services = this.services;
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.vuetify {
    .sub-account-details-services {
        color: $global-color-font-new;

        .alert {
            i {
                top: -1px;
                color: red !important;
                font-size: 1.75rem !important;
            }
        }
        div.alert {
            background: #CE18181A;
            border-radius: 5px;
            margin-bottom: 0.5rem;
            text-align: left;
            padding: 1.2rem;
            line-height: 1.75rem;
            font-size: 1.4rem;
            i {
                color: red !important;
                font-size: 1.75rem !important;
                margin: 0.1rem .8rem 0 0;
            }
            display: flex;
        }

        .expansion-panel-header {
            .v-icon {
                padding: 0.8rem 0.8rem 0.6rem 0.8rem;
            }
        }

        .see-all-button {
            display: flex;
            justify-content: center;
            button {
                font-size: 1.2rem;
                line-height: 1.6rem;
                i {
                    font-size: 2.4rem;
                    color: $global-color-font-new !important;
                }
            }
        }

        .data-grid .row {
            margin: 0;
            margin-bottom: 1.6rem;
        }

        .force-mobile-font-size & .data-grid .row {
            font-size: 1.4rem;   
        }
    }

    .data-table {
        margin-bottom: 0;
        padding-right: 2.4rem;

        &.expand-icon-padding {
            padding-left: 4rem;
        }

        &.no-expand-padding {
            padding-left: 2.4rem;
        }

        .header {
            display: flex;
            margin-bottom: 0;
            width: 100%;
            background: white;
            font-size: 1.4rem;
            line-height: 2.4rem;
            height: 2.5rem;
            padding: 0;
            padding-left: .6rem;

            .header-content {
                display: flex;
                color: rgba(0, 0, 0, 0.87);
                font-weight: 500;
                justify-content: flex-start;
                align-items: flex-end;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &:empty {
                    border-bottom: none;
                    min-width: 4rem;
                }
                &:nth-last-child(2) {
                    flex-grow: 1;
                    justify-content: flex-start;
                    border-bottom: 1pt solid $global-color-main-grey;
                }

                &:last-child {
                    justify-content: flex-end;
                    align-items: flex-end;
                    border-bottom: 1pt solid $global-color-main-grey;
                }
            }
        }

        .body {
            font-size: 1.6rem;
            display: flex;
            flex-direction: column;
            .service {
                color: $global-color-font-new;
                display: flex;
                flex-direction: column;
                width: 100%;
                line-height: 4rem;

                base-btn {
                    box-shadow: 0;
                    i {
                        color: $global-color-font-new !important;
                        font-size: 2.4rem !important;
                    }
                }

                .unexpanded {
                    display: flex;
                    flex-direction: row;

                    .expand-icon-container {
                        height: 4rem;
                        width: 4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100%;
                        transition-property: background-color;
                        transition-duration: .2s;

                        &:hover:not(:empty) {
                            cursor: pointer;
                            background: $global-color-body-bg;
                        }
    
                        &:active:not(:empty) {
                            background-color: #9e9e9e;
                        }
    
                        i.mdi-chevron-up, i.mdi-chevron-down {
                            margin: auto;
                            color: $global-color-font-new !important;
                        }
                    }
    
                    .row-content {
                        &:nth-last-child(2) {
                            justify-content: flex-start;
                            flex-grow: 1;
                            padding: 0 .6rem;
                        }
        
                        &:last-child {
                            justify-content: flex-end;
                            align-items: flex-end;
                            text-align: end;
                        }
                    }
                }

                .expanded {
                    background: $global-color-body-bg;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    border-radius: 5px;
                    .row {
                        margin-bottom: 0;
                    }
                    .col-left {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                    .col-right {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                    td {
                        padding-left: 5rem;
                        padding-right: 2.5rem;
                    }
                    .expanded-header {
                        .expanded-title {
                            padding-top: 1.5rem;
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                            font-weight: bold;
                        }
                        .expanded-subtitle {
                            font-size: 1.2rem;
                            line-height: 2.6rem;
                            margin-top: -0.25rem;
                        }
                    }
                    .expanded-body {
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                        height: 3rem;
                    }
                    .expanded-footer {
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                        font-weight: bold;
                        margin-bottom: 1.8rem;
                        height: 3rem;
                    }

                    .data-grid {
                        padding-left: 5.7rem;
                        padding-right: 2.5rem;
                        .divider {
                            margin: 8px 0px;
                            border-top: solid 1px #DCDCDC;
                        }
                    }
                }
            }
        }
    }
}
</style>