<template>
    <div class="find-bill-card vuetify">
        <content-card :class="insideBottomSheet ? 'insideBottomSheet' : ''">
            <div class="find-bill-header"> {{ $translate('labels.findABill') }} </div>
            <div class="find-bill-description"> {{ $translate('labels.findABillDescription') }} </div>
             
            <div v-show="showSearchErrors" class="interaction-feedback">
                <div v-if="searchErrors.throttled" class="find-bill-search-error-throttled">
                    {{ $translate('errors.billThrottled') }}
                </div>
                <div v-else-if="searchErrors.notFound" class="find-bill-search-error-not-found">
                    {{ $translate('errors.unableToFindBill') }}
                </div>
            </div>

            <base-form>
                <div>
                    <base-text-field 
                        @keydown.enter="onClickFindBill"
                        @input="onInputSecureCode"
                        v-model="findData.sCode"
                        type="text"
                        required
                        :label="$translate('labels.enterSecureHealthCode')"
                        :error-messages="fields.sCodeFieldError"
                        class="text-field-shc fs-block"
                        maxlength="11"
                        name="scode"
                        autocomplete="off"
                    >
                    </base-text-field>
                </div>
                <div>
                    <base-text-field 
                        @keydown.enter="onClickFindBill"
                        @input="onInputBillAmount"
                        @blur="onBlurBillAmount"
                        v-model="findData.billAmount"
                        type="text"
                        required
                        :label="$translate('labels.enterBillAmount')"
                        :error-messages="fields.billAmountFieldError"
                        class="text-field-bill-amount fs-block"
                        min="0"
                        maxlength="20"
                        name="billAmount" 
                        :key="billAmountKey"
                        ref="billAmountInput"
                    > 
                    </base-text-field>
                </div>
            </base-form>
            <base-btn @click="onClickFindBill()" :disabled="submitting" class="button-bigger-text v-button-primary find-bill-btn find-bill-card-submit-button" tabindex="1">
                {{ $translate('actions.findBill') }}
            </base-btn>
            <div v-if="!insideBottomSheet" class="need-help-link" @click="needHelp()">
                <a> {{ $translate('actions.needHelp') }} </a>
            </div>
        </content-card>
    </div>
</template>

<script>

import ContentCard from './ContentCard.vue';
import BaseTextField from './BaseTextField.vue';
import BaseForm from './BaseForm.vue';
import BaseBtn from './BaseBtn.vue';
import { MoneyUtils, parseAmount } from './../utils/utils.js';

export default {
    name: 'FindBillCard',
    components: {
        BaseBtn,
        BaseTextField,
        BaseForm,
        ContentCard,
    },
    props: {
        searchErrors: Object,
        submitting: {
            type: Boolean,
            default: false,
        },
        insideBottomSheet: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        fields : {
            sCodeFieldError: null,
            billAmountFieldError: null,
        },
        findData: {
            billAmount: null,
            sCode: null,
        },
        billAmountKey: 0,
    }),
    computed: {
        brandingConfig() {
            return this.$store.getters.brandingConfig;
        },
        config() {
            return this.$store.getters.config;
        },
        subdomain() {
            return this.$store.getters.subdomain;
        },
        showSearchErrors() {
            return this.searchErrors.throttled || this.searchErrors.notFound;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },
    methods: {
        onInputBillAmount() {
            this.fields.billAmountFieldError = null;
            this.findData.billAmount = MoneyUtils.onInput(this.findData.billAmount);
        },
        onBlurBillAmount() {
            this.findData.billAmount = MoneyUtils.onBlur(this.findData.billAmount);
        },
        onInputSecureCode(e) {
            this.fields.sCodeFieldError = null;
            if (!this.findData.sCode) {
                return;
            }
            // strip out anything that's not a number or letter
            const cleaned = this.findData.sCode.replace(/[^a-zA-Z0-9]$/g, '').toUpperCase().slice(0, 11);
            let formatted = '';

            // Rebuild the string with proper formatting
            for (let i = 0; i < cleaned.length; i++) {
                const curChar = cleaned[i];
                if(/[A-Za-z0-9]/.test(curChar)){
                    formatted += curChar;
                }
                if (formatted.length === 3 || formatted.length === 7){
                    formatted += '-';
                }
            }
            this.findData.sCode = formatted;
        },
        onClickFindBill() {
            let hasErrors = false;
            if (!this.findData.billAmount || isNaN(this.findData.billAmount.replace(/[^\d\.]+/g, ''))) {
                this.fields.billAmountFieldError = this.$translate('errors.amountRequired');
                hasErrors = true;
            }
            if (!this.findData.sCode || !(this.findData.sCode.length === 11)) {
                if (this.subdomain) {
                    this.fields.sCodeFieldError = this.$translate('whitelabel.errors.scodeRequired');
                } else {
                    this.fields.sCodeFieldError = this.$translate('errors.scodeRequired');
                }
                hasErrors = true;
            } else if (/[0-9]{3}-[0-9]{3}-[0-9]{3}/.test(this.findData.sCode)) {
                this.fields.sCodeFieldError = this.$translate('errors.allNumericSCode');
                hasErrors = true;
            } else {
                this.fields.sCodeFieldError = null;
            }
            if (!hasErrors) {
                let notificationType = 'standard';
                if (!this.currentUser) {
                    notificationType = 'first';
                }
                this.$store.commit('setPaymentSource', {billType: 'bill', commType: null, notificationType: notificationType, origin: 'print'});
                this.$emit('findbill', this.findData.sCode, parseAmount(this.findData.billAmount));
            }
        },
        needHelp() {
            let needHelpSrc = 'assets/images/needhelp.png';
            if (this.brandingConfig && this.brandingConfig.needHelpImage) {
                needHelpSrc = this.brandingConfig.needHelpImage;
            } else if (this.brandingConfig) {
                needHelpSrc = 'assets/images/needhelpbranded.png';
            }
            this.emitter.emit('simpleModal:showPrompt',{
                header: this.$translate('landing.needHelpHeader'),
                subcontent: this.$translate('landing.needHelpContent') +
                    '<br /><br /><img class="needhelp" src="' + needHelpSrc + '"/>',
                actions: [{
                    label: this.$translate('actions.close'),
                    includeClasses: 'button-primary'
                }]
            });
        }
    }
};
</script>

<style lang="scss">
@import '../styles/vuetify-overrides.scss';
    .find-bill-card  {

        .insideBottomSheet {
            .content-card {
                box-shadow: none !important;
            }
        }

        .v-card {
            padding: 2.5rem 2.5rem 6rem 2.5rem;
            text-align: center;

            .find-bill-header {
                font-size: 2.4rem;
                letter-spacing: 0.18px;
                line-height: 2.4rem;
                color: $global-color-font-new;
                padding-bottom: 2.5rem;
            }

            .find-bill-description {
                font-size: 1.4rem;
                letter-spacing: 0.25px;
                line-height: 2rem;
                color: $global-color-font-new;
                padding-bottom: 0.313rem;
                text-align: left;
            }

            .v-form {
                padding-bottom: 2.5rem;

                .v-text-field {

                    .v-label {
                        font-size: 1.4rem;
                        color: $global-color-text-field-label;
                        line-height: 1.9rem;
                    }

                    input {
                        border-bottom-width: 0px;
                    }

                    fieldset {
                        height: 45px;
                    }
                    .v-field__field{
                        height: 45px;
                        min-height: 45px;;
                    }
                    .v-text-field__details {
                        margin-top: 0 !important;
                    }
                }
            }

            .find-bill-btn {
                width: 100%;
                height: 3.9rem;
            }

            .need-help-link {
                margin-top: 1.5rem;
                text-decoration: underline;
            }
        }
    }

</style>