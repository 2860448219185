<template>
    <div class="vuetify documents">
        <h1 class="page-callout"> {{ translate('documents.paymentHistory.paymentHistory') }} </h1>
        <div class="vuetify horizontal-fields">
            <base-select
                v-model="selectedYear"
                :items="years"
                :label="translate('documents.paymentHistory.year')"
                data-testid="years-filter">
            </base-select>
            <base-select
                v-model="selectedProvider"
                :items="providers"
                :label="translate('documents.paymentHistory.provider')"
                data-testid="providers-filter">
            </base-select>
            <base-select
                v-model="selectedAccount"
                :items="accounts"
                :label="translate('documents.paymentHistory.account')"
                data-testid="accounts-filter">
            </base-select>
        </div>
        <div id="downloadbuttoncontainer">
            <a id="downloadbutton" @click="handleDownloadClick()" color="primary">Download <base-icon icon="mdi-tray-arrow-down"></base-icon></a>
        </div>
        <v-data-table :headers="computedHeaders" :items="filteredItems" :show-expand="isExpandable" :items-per-page="100" mobile-breakpoint="0" v-model:expanded="expanded">
            <template v-slot:item.receiptPaymentId="{ item }">
                <a v-if="item.receiptPaymentId && item.receiptProviderId" color="primary" @click="handleReceiptClick(item)">
                    {{ translate('documents.paymentHistory.viewReceipt') }}
                </a>
                {{ item.receiptPaymentId && item.receiptProviderId ? '' : '-'}}
            </template>
            <template v-slot:item.paymentMethod="{ item }">
                <div v-if="item.paymentMethod">
                    {{ handlePaymentMethod(item) }}
                </div>
                {{ item.paymentMethod ? '' : '-'}}
            </template>
            <template v-slot:item.amount="{ item }">
                <div v-if="item.amount && parseFloat(item.amount) < 0" style="color: green">
                    {{ $formatCurrency(item.amount) }}
                    <div style="font-size: 1.0rem"> {{ $translate('documents.paymentHistory.refunded') }} </div>
                </div>
                <div v-else-if="item.amount">
                    {{ $formatCurrency(item.amount) }}
                </div>
            </template>
            <template v-slot:expanded-row="{ columns, item }" style="box-shadow: none">
                <td class="expanded-col" :colspan="columns.length">
                    <div class="expanded-row">
                        <span class="field-label"> {{ translate('documents.paymentHistory.provider') }}:</span>
                        <span class="field-value">{{ item.provider }}</span>
                    </div>
                    <div class="expanded-row">
                        <span class="field-label">{{ translate('documents.paymentHistory.account') }}:</span>
                        <span class="field-value">{{ item.account }}</span>
                    </div>
                    <div class="expanded-row">
                        <span class="field-label">{{ translate('documents.paymentHistory.paymentMethod') }}:</span>
                        <span class="field-value">{{ handlePaymentMethod(item) }}</span>
                    </div>
                </td>
            </template>
        </v-data-table>
        <div class="total" style="float: right">
            <div style="margin-right: 40px"> {{ $translate('documents.paymentHistory.total') }}: </div>
            <div data-testid="total-amount"> {{ $formatCurrency(total) }} </div>
        </div>
    </div>
</template>
<script>
import { CardUtils, getMethodDescriptor, translate } from '../utils/utils';
import { mdiMenuUp, mdiMenuDown, mdiDownload } from '@mdi/js';
import BaseSelect from '../components/BaseSelect.vue';
import BaseCard from '../components/BaseCard.vue';
import BaseIcon from '../components/BaseIcon.vue';
import BaseDataTable from '../components/BaseDataTable.vue';
import BaseDivider from '../components/BaseIcon.vue';

export default {
    name: 'DocumentsPaymentHistory',
    components: {
        BaseSelect,
        BaseCard,
        BaseIcon,
        BaseDataTable,
        BaseDivider
    },
    data() {
        return {
            mdiMenuUp,
            mdiMenuDown,
            mdiDownload,
            items: [],
            headers: [
                { title: translate('documents.paymentHistory.paymentDate'), value: 'paymentDate', sortable: false },
                { title: translate('documents.paymentHistory.provider'), value: 'provider', sortable: false },
                { title: translate('documents.paymentHistory.account'), value: 'account', sortable: false },
                { title: translate('documents.paymentHistory.paymentMethod'), value: 'paymentMethod', sortable: false },
                { title: translate('documents.paymentHistory.receipt'), value: 'receiptPaymentId', sortable: false },
                { title: translate('labels.amount'), value: 'amount', sortable: false }
            ],
            selectedAccount: null,
            selectedProvider: null,
            selectedYear: null,
            hideHeaders: new Set(['provider', 'account', 'paymentMethod']),
            expanded: []
        }
    },
    computed: {
        accounts() {
            return this.items.length > 0 ? [translate('documents.paymentHistory.filterAll'), ...new Set(this.items.filter(item => ((!this.selectedYear || this.selectedYear === "ALL") || item.paymentDate.split('/')[2] === this.selectedYear)
                && ((!this.selectedProvider || this.selectedProvider === "ALL") || item.provider === this.selectedProvider)).map(item => item.account))]  : [translate('documents.paymentHistory.filterAll')];
        },
        providers() {
            return this.items.length > 0 ? [translate('documents.paymentHistory.filterAll'), ...new Set(this.items.filter(item => ((!this.selectedYear || this.selectedYear === "ALL") || item.paymentDate.split('/')[2] === this.selectedYear)
            && ((!this.selectedAccount || this.selectedAccount === "ALL") || item.account === this.selectedAccount)).map(item => item.provider))] : [translate('documents.paymentHistory.filterAll')];
        },
        years() {
            return this.items.length > 0 ? [translate('documents.paymentHistory.filterAll'), ...new Set(this.items.filter(item => ((!this.selectedProvider || this.selectedProvider === "ALL") || item.provider === this.selectedProvider)
                && ((!this.selectedAccount || this.selectedAccount === "ALL") || item.account === this.selectedAccount)).map(item => item.paymentDate.split('/')[2]))] : [translate('documents.paymentHistory.filterAll')];
        },
        filteredItems() {
            return this.items.length > 0 ? this.items.filter(item => ((!this.selectedYear || this.selectedYear === "ALL") || item.paymentDate.split('/')[2] === this.selectedYear)
                && ((!this.selectedProvider || this.selectedProvider === "ALL") || item.provider === this.selectedProvider)
                && ((!this.selectedAccount || this.selectedAccount === "ALL") || item.account === this.selectedAccount)
            ) : [];
        },
        total() {
            return this.filteredItems.reduce((a, item) => (a + parseFloat(item.amount)), 0);
        },
        computedHeaders() {
            if (!this.$vuetify.display.lgAndUp) {
                return this.headers.filter(header => !this.hideHeaders.has(header.value));
            } else {
                return this.headers;
            }
        },
        isExpandable() {
            return !this.$vuetify.display.lgAndUp;
        }
    },
    methods: {
        handleDownloadClick() {
            window.print();
        },
        handleReceiptClick(item) {
            console.log("handleReceiptClick", item);
            if (item.receiptPaymentId && item.receiptProviderId) {
                let routeData = this.$router.resolve({
                    name: 'Receipt',
                    params: {
                        providerId: item.receiptProviderId,
                        paymentId: item.receiptPaymentId,
                    }
                });
                window.open(routeData.href, '_blank');
            }
       },
       handlePaymentMethod(item) {
            if (item.paymentMethod) {
                if (item.paymentMethod === 'ApplePay') {
                    return translate('receipt.paidWithApplePayDesc');
                }
                if (item.paymentMethod.split('-').length > 1) {
                    return [CardUtils.getIssuerFromCardType(item.paymentMethod.split('-')[0]), '****' + item.paymentMethod.split('-')[1]].join(' ');
                }
                switch (item.paymentMethod) {
                    case "Check":
                        return translate("documents.paymentHistory.check");
                    case "eCheck":
                        return translate("documents.paymentHistory.eCheck");
                    case "Cash":
                        return translate("documents.paymentHistory.cash");
                    case "Money Order":
                        return translate("documents.paymentHistory.moneyOrder");
                    default:
                        return item.paymentMethod;
                }
            }
       },
       getMethodDescriptor,
       translate
    },
    created() {
        this.$store.dispatch('fetchPaymentHistory').then(result => {
            this.items = result;
        })
    },
}

</script>
<style lang="scss">
@import '../styles/variables.scss';
@media print {
#downloadbuttoncontainer {
    display: none;
}
.v-field.v-field--variant-outlined .v-field__outline {
    border-width: 0px;
    display: none;
}
.v-field.v-field--variant-outlined .v-field__append-inner {
    display: none;
}
}
#downloadbuttoncontainer {
    text-align: right;
    height: 4rem;
}
#downloadbutton {
    width: auto;
    display: inline;
    border: 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

    i {
        color: inherit !important;
    }
}
.v-table .v-data-table-footer {
    display: none;
}

.total {
    font-weight: bold;
    text-align: right;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
    margin-right: 1rem;
    @include wider-than-phablet {
        margin-right: 5rem;
    }
}
.expanded-col {
    @include wider-than-phablet {
        display: none;
    }
}
</style>
