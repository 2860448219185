<template>
    <div class="messages-list-item">
        <a class="block-segment-full-link" @click="openMessageThread">
            <div class="block-segment-minimal-content">
                <button class="block-segment-primary-action button-primary button-inline button-sml"> {{ $translate('actions.open') }} </button>
                <h3 class="block-segment-header">
                    {{ message.reason }}
                </h3>
                <div class="block-segment-subheader">
                    {{ message.providerName }}
                    <div class="messages-time-updated"> {{ message.friendlyUpdateTime }} </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    
    export default {
        name: 'MessagesListItem',

        props: {
            message: Object,
        },
        methods: {
            // logic should be moved to vue when possible
            openMessageThread() {
                this.emitter.emit('message:openThread', this.message);
            }
        },
    };
</script>
