<template>
<div class="payment-flow-curae-accept-step vuetify" data-app>
    <div class="financing-logo-container">
        <img class="financing-logo" src="assets/images/curaeLogo.png" />
    </div>
    <h1 class="accept-title">{{ $translate('paymentFlow.financing.curae.reviewOffer') }}</h1>
    <base-card class="offer-summary" outlined>
        <template #text>
            <div class="subtitle">
                {{ $translate('paymentFlow.financing.curae.creditLimit', { creditLimit: $formatCurrency(curaeSelectedOption.creditLimit) }) }}
            </div>
            <div class="title">
                <div v-if="curaeSelectedOption.rate && curaeSelectedOption.deferredInterestMonths">
                    {{ $translate('paymentFlow.financing.curae.amountAprDeffered', { amount: $formatCurrency(curaeSelectedOption.amount), apr: curaeSelectedOption.rate }) }}
                </div>
                <div v-else-if="curaeSelectedOption.rate">
                    {{ $translate('paymentFlow.financing.curae.amountApr', { amount: $formatCurrency(curaeSelectedOption.amount), apr: curaeSelectedOption.rate }) }}
                </div>
                <div v-else-if="!curaeSelectedOption.rate">
                    {{ $translate('paymentFlow.financing.curae.amountNoApr', { amount: $formatCurrency(curaeSelectedOption.amount) }) }}
                </div>
            </div>
            <div class="text">
                <div class="deferred" v-if="curaeSelectedOption.deferredInterestMonths">
                    {{ $translate('paymentFlow.financing.curae.deferredInterest', { months: curaeSelectedOption.deferredInterestMonths }) }}
                </div>
                {{ $translate('paymentFlow.financing.curae.months', { months: curaeSelectedOption.months }) }}
            </div>
        </template>
    </base-card>
    <div class="once-accepted">{{ $translate('paymentFlow.financing.curae.onceAccepted') }}</div>
    <base-form ref="form" @submit.prevent>
        <div class="section cardholder-agreement">
            <h2>{{ $translate('paymentFlow.financing.curae.cardholderAgreement') }}</h2>
            <div v-html="getCardHolderAgreementText()"></div>
            <div class="horizontal-fields consent vuetify">
                <base-checkbox
                    v-model="cardholderAgreementCheck"
                    class="vuetify fs-block consent-checkbox"
                    name="cardholderAgreementCheck"
                    ref="cardholderAgreementCheck"
                    :error-messages="v$.cardholderAgreementCheck.$errors.map(e => e.$message)"
                    @action="v$.cardholderAgreementCheck.$touch"
                    @click="() => { cardholderAgreementCheckDirty = true }"
                ></base-checkbox>
                <div class="consent-label" v-html="getCardHolderAgreementCheckText()"></div>
            </div>
            <div v-if="!cardholderAgreementCheck && cardholderAgreementCheckDirty" class="error-message">
                <span class="error-message">{{  $translate('paymentFlow.financing.curae.cardholderAgreementError') }}</span>
            </div>
        </div>
        <div class="section authorize-transaction">
            <h2>
                {{ $translate('paymentFlow.financing.curae.authorizeTransactionTitle', { money: $formatCurrency(amountCovered) }) }}
                <span v-if="curaeSelectedOption.amountDueNotCovered">*</span>
            </h2>
            <div class="horizontal-fields consent vuetify">
                <base-checkbox
                    v-model="authorizeTransactionCheck"
                    class="vuetify fs-block consent-checkbox"
                    name="authorizeTransactionCheck"
                    ref="authorizeTransactionCheck"
                    :error-messages="v$.authorizeTransactionCheck.$errors.map(e => e.$message)"
                    @action="v$.authorizeTransactionCheck.$touch"
                    @click="() => { authorizeTransactionCheckDirty = true }"
                ></base-checkbox>
                <div class="consent-label" v-html="getAuthorizeTransactionCheckText()"></div>
            </div>
            <div v-if="!authorizeTransactionCheck && authorizeTransactionCheckDirty" class="error-message">
                <span class="error-message">{{  $translate('paymentFlow.financing.curae.authorizeTransactionError') }}</span>
            </div>
        </div>
    </base-form>
    <div v-if="financingError" class="financing-error">
        <base-icon :size="$vuetify.display.lgAndUp ? 24 : 20" icon="mdi-alert-circle"></base-icon>
        <div class="financing-error-message">{{ financingError }}</div>
    </div>
    <div v-if="curaeSelectedOption.amountDueNotCovered" class="partial-payment">
        * {{ $translate('paymentFlow.financing.curae.partialPaymentDisclaimer', { amount: $formatCurrency(curaeSelectedOption.amountDueNotCovered) }) }}
    </div>
    <div class="navigation-buttons">
        <base-btn @click="leaveCuraeDialog = true" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn @click="cancelCuraeDialog = true" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <base-btn @click="continueWithCurae()" :disabled="!cardholderAgreementCheck || !authorizeTransactionCheck || isGettingFinancingOptions" class="continue-btn v-button-primary">
            {{ $translate('paymentFlow.financing.curae.acceptAndProcess') }}
        </base-btn>
    </div>
    <div class="bank-account">{{ $translate('paymentFlow.financing.curae.bankAccount') }}</div>
    <base-dialog v-model="leaveCuraeDialog" content-class="leave-curae-dialog">
        <template #title>{{ $translate('paymentFlow.financing.curae.warning') }}</template>
        <template #text>{{ $translate('paymentFlow.financing.curae.leavingFlowWarning') }}</template>
        <template #actions>
            <base-btn class="cancel-btn dialog-btn" variant="text" @click="leaveCuraeDialog = false">{{ $translate('paymentFlow.confirmCancel.noContinuePaying') }}</base-btn>
            <base-btn class="continue-btn v-button-primary dialog-btn" variant="text" @click="leaveCurae()">{{ $translate('paymentFlow.confirmCancel.yesGoBack') }}</base-btn>
        </template>
    </base-dialog>
    <base-dialog v-model="cancelCuraeDialog" content-class="cancel-curae-dialog">
        <template #title>{{ $translate('paymentFlow.financing.curae.warning') }}</template>
        <template #text>{{ $translate('paymentFlow.financing.curae.leavingFlowWarning') }}</template>
        <template #actions>
            <base-btn class="cancel-btn dialog-btn" variant="text" @click="cancelCuraeDialog = false">{{ $translate('paymentFlow.confirmCancel.noContinuePaying') }}</base-btn>
            <base-btn class="continue-btn v-button-primary dialog-btn" variant="text" @click="cancelCurae()">{{ $translate('paymentFlow.confirmCancel.yesCancelPayment') }}</base-btn>
        </template>
    </base-dialog>
</div>
</template>
<script>
import BaseForm from './BaseForm.vue';
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseDialog from './BaseDialog.vue';
import BaseBtn from './BaseBtn.vue';
import { translate, formatCurrency, MoneyUtils } from './../utils/utils.js';
import BaseIcon from './BaseIcon.vue';
import BaseCard from './BaseCard.vue';
import BaseSpacer from './BaseSpacer.vue';
import BaseCheckbox from './BaseCheckbox.vue';

import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
    name: 'PaymentFlowCuraeAcceptStep',
    components: {
        BaseForm,
        BaseBtn,
        BaseExpansionPanel,
        BaseDialog,
        BaseIcon,
        BaseCard,
        BaseSpacer,
        BaseCheckbox
    },
    props: {
        cardholderAgreement: String,
        financingError: String,
        isGettingFinancingOptions: Boolean,
        curaeSelectedOption: Object,
        totalAmount: Number,
        providerName: String
    },
    data: () => ({
        v$: useValidate(),
        cardholderAgreementCheck: false,
        authorizeTransactionCheck: false,
        cardholderAgreementCheckDirty: false,
        authorizeTransactionCheckDirty: false,
        leaveCuraeDialog: false,
        cancelCuraeDialog: false
    }),
    validations() {
        return {
            cardholderAgreementCheck: { required: this.requiredMsg },
            authorizeTransactionCheck: { required: this.requiredMsg }
        }
    },
    computed: {
        requiredMsg() {
            return helpers.withMessage(this.$translate('errors.fieldRequired'), required);
        },
        getFinancingDownloadUrl() {
            return this.$store.getters.getFinancingDownloadUrl;
        },
        cardholderAgreementUrl() {
            return this.getFinancingDownloadUrl({ vendor: 'Curae', fileId: this.cardholderAgreement, fileName: 'Cardholder Agreement.pdf'});
        },
        summaryOfTermsUrl() {
            return this.getFinancingDownloadUrl({ vendor: 'Curae', fileId: this.curaeSelectedOption.summaryOfTerms, fileName: 'Summary of Terms.pdf'});
        },
        amountCovered() {
            return this.curaeSelectedOption.amountDueNotCovered ? this.curaeSelectedOption.creditLimit : this.totalAmount;
        },
    },
    methods: {
        translate: translate,
        subtract: MoneyUtils.subtract,
        greaterThan: MoneyUtils.greaterThan,
        continueWithCurae() {
            this.$emit('continue-with-curae', {
                cardholderAgreementCheck: this.cardholderAgreementCheck,
                authorizeTransactionCheck: this.authorizeTransactionCheck,
                cardholderAgreementUrl: this.cardholderAgreementUrl
            });
        },
        cancelCurae() {
            this.$emit('cancel-curae');
        },
        leaveCurae() {
            this.$emit('leave-curae');
        },
        getCardHolderAgreementText() {
            return translate('payment.financing.summary.reviewTerms', { termsUrl: this.summaryOfTermsUrl, vendor: 'Curae' });
        },
        getCardHolderAgreementCheckText() {
            return translate('payment.financing.confirm.cardholderAgreementDisclaimer', { cardholderAgreementUrl: this.cardholderAgreementUrl });
        },
        getAuthorizeTransactionCheckText() {
            return translate('paymentFlow.financing.curae.authorizeTransactionBody', { amount: formatCurrency(this.amountCovered), provider: this.providerName });
        }
    },
    created() {
        this.$emit('scroll-to-top');
    },
    mounted() {
        this.$emit('loaded');
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.payment-flow-curae-accept-step {
    h1 {
        color: $global-color-font-new;
        font-size: 1.4rem;
        line-height: 2.0rem;
        @include wider-than-tablet {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }
    .vuetify.offer-summary.v-card.v-sheet {
        margin: 0;
        margin-top: 0.5rem;
        border: 1px solid white;
        background-color: white;
    }
    .bank-account {
        color: $global-color-font-new;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .once-accepted {
        color: $global-color-font-new;
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .v-form {
        .section {
            margin-top: 2.5rem;
            h2 {
                margin-bottom: 0.3rem;
                color: $global-color-font-new;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 500;
            }
            .error-message span.error-message  {
                color: $global-color-warning;
                display: flex;
                padding-left: 6%;
                font-size: 1.2rem;
                line-height: 1.6rem;
            }
            .horizontal-fields.consent {
                display: flex;
                flex-flow: row;
                margin-top: 1.5rem;
                .consent-checkbox.vuetify {
                    margin-right: 2.5rem;
                }
                @include wider-than-tablet {
                    margin-right: 0;
                }
                div {
                    color: $global-color-font-new;
                    font-size: 1.4rem;
                    line-height: 1.9rem;
                }
            }
        }
    }
    .offer-summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .v-card-subtitle, .subtitle {
            padding-top: 4rem;
            padding-bottom: 0.5rem;
            color: $global-color-font-new;
            font-size: 1.4rem;
            line-height: 2.0rem;
            font-weight: normal;
        }
        .v-card-title, .title {
            padding-top: 0;
            padding-bottom: 0;
            color: $global-color-font-new;
            font-weight: bold;
            font-size: 2.0rem;
            line-height: 2.4rem;
            @include wider-than-tablet {
                font-size: 2.4rem;
                line-height: 2.4rem;
            }
        }
        .v-card-text, .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 0.5rem;
            padding-bottom: 2rem;
            color: $global-color-font-new;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.4rem;
            @include wider-than-tablet {
                font-size: 2.0rem;
                line-height: 2.4rem;
            }
            .deferred {
                color: $global-color-font-new;
                font-size: 1.4rem;
                line-height: 2.0rem;
            }
        }
    }
    .partial-payment {
        padding-top: 1rem;
    }
}
</style>
