<template>
    <v-icon v-bind="$attrs" :icon="icon" :color="iconColor" class="base-icon vuetify"></v-icon>
</template>

<script>
export default {
    name: 'BaseIcon',
    props: {
        icon: {
            type: String
        },
        color: {
            type: String
        }
    },
    computed: {
        iconColor() {
            if (this.color) {
                return this.color;
            }
        }
    }
};
</script>
<style lang="scss">
@import './../styles/variables.scss';
i.base-icon.vuetify {
    color: $global-color-secondary;
}
</style>