<template>
<div class="payment-flow-curae-application-step vuetify" data-app>
    <div class="financing-logo-container">
        <img class="financing-logo" src="assets/images/curaeLogo.png" />
    </div>
    <div class="application-title">
        <h1>{{ $translate('paymentFlow.financing.curae.completeFormTitle') }}</h1>
        <sub>{{ $translate('paymentFlow.financing.curae.completeFormSubtitle') }}</sub>
    </div>
    <base-form ref="form" @submit.prevent>
        <div class="section">
            <h2>{{ $translate('paymentFlow.financing.curae.personalInformation') }}</h2>
            <div class="horizontal-fields">
                <base-text-field
                    v-model="application.firstName"
                    :label="$translate('labels.firstName')"
                    :error-messages="v$.application.firstName.$errors.map(e => e.$message)"
                    class="fs-block first-of-type first-name"
                    name="firstName"
                    required
                    @blur="v$.application.firstName.$touch"
                    @input="v$.application.firstName.$touch"
                ></base-text-field>
                <base-text-field
                    v-model="application.lastName"
                    :label="$translate('labels.lastName')"
                    :error-messages="v$.application.lastName.$errors.map(e => e.$message)"
                    class="fs-block last-name"
                    name="lastName"
                    required
                    @blur="v$.application.lastName.$touch"
                    @input="v$.application.lastName.$touch"
                ></base-text-field>
            </div>
            <div class="horizontal-fields">
                <base-text-field
                    v-model="application.birthdate"
                    :label="$translate('labels.birthdate')"
                    :error-messages="v$.application.birthdate.$errors.map(e => e.$message)"
                    class="fs-block first-of-type birthdate"
                    name="birthdate"
                    maxlength="10"
                    autocomplete="off"
                    @keypress="onlyNumbers($event)"
                    required
                    @blur="v$.application.birthdate.$touch"
                    @input="v$.application.birthdate.$touch"
                ></base-text-field>
                <base-text-field
                    v-model="application.ssn"
                    :label="$translate('labels.socialSecurityNumber')"
                    :error-messages="v$.application.ssn.$errors.map(e => e.$message)"
                    class="fs-block ssn"
                    name="ssn"
                    maxlength="11"
                    autocomplete="off"
                    @keypress="onlyNumbers($event)"
                    required
                    @blur="v$.application.ssn.$touch"
                    @input="v$.application.ssn.$touch"
                ></base-text-field>
            </div>
            <div class="horizontal-fields income-row">
                <base-text-field
                    v-model="application.income"
                    :label="$translate('labels.income')"
                    :error-messages="v$.application.income.$errors.map(e => e.$message)"
                    class="fs-block first-of-type income"
                    name="income"
                    autocomplete="off"
                    @keypress="onlyNumbers($event)"
                    required
                    @blur="v$.application.income.$touch"
                    @input="v$.application.income.$touch"
                ></base-text-field>
                <span class="income-icon">
                    <base-icon @click="incomeBottomSheet = true" class="help-circle-icon vuetify" icon="mdi-help-circle-outline"></base-icon>
                </span>
            </div>
        </div>
        <div class="section">
            <h2>{{ $translate('setting.menu.contact') }}</h2>
            <div class="horizontal-fields">
                <base-text-field
                    v-model="application.email"
                    :label="$translate('labels.email')"
                    :error-messages="v$.application.email.$errors.map(e => e.$message)"
                    class="fs-block first-of-type email"
                    name="email"
                    required
                    @blur="v$.application.email.$touch"
                    @input="v$.application.email.$touch"
                ></base-text-field>
                <base-text-field
                    v-model="application.phone"
                    :label="$translate('labels.phone')"
                    :error-messages="v$.application.phone.$errors.map(e => e.$message)"
                    class="fs-block phone"
                    name="phone"
                    ref="phone"
                    maxlength="12"
                    @keypress="onlyNumbers($event)"
                    required
                    @blur="v$.application.phone.$touch"
                    @input="v$.application.phone.$touch"
                ></base-text-field>
            </div>
            <base-text-field
                v-model="application.address"
                :label="$translate('labels.address')"
                :error-messages="v$.application.address.$errors.map(e => e.$message)"
                class="fs-block address1"
                name="address1"
                ref="address1"
                required
                @blur="v$.application.address.$touch"
                @input="v$.application.address.$touch"
            ></base-text-field>
            <base-text-field
                v-model="application.address2"
                :label="$translate('labels.address2')"
                class="fs-block address2"
                name="address2"
            ></base-text-field>
            <div class="city-state-zip vuetify">
                <base-text-field
                    v-model="application.city"
                    :label="$translate('labels.city')"
                    :error-messages="v$.application.city.$errors.map(e => e.$message)"
                    class="fs-block city"
                    name="city"
                    required
                    @blur="v$.application.city.$touch"
                    @input="v$.application.city.$touch"
                ></base-text-field>
                <base-select
                    v-model="application.state"
                    :items="states"
                    item-title="text"
                    item-value="value"
                    :label="$translate('labels.state')"
                    :error-messages="v$.application.state.$errors.map(e => e.$message)"
                    class="state"
                    name="state"
                    required
                    @blur="v$.application.state.$touch"
                    @input="v$.application.state.$touch"
                ></base-select>
                <base-text-field
                    v-model="application.zip"
                    :label="$translate('labels.zip')"
                    :error-messages="v$.application.zip.$errors.map(e => e.$message)"
                    class="fs-block zip"
                    name="zip"
                    required
                    maxlength="5"
                    @keypress="onlyNumbers($event)"
                    inputmode="decimal"
                    @blur="v$.application.zip.$touch"
                    @input="v$.application.zip.$touch"
                ></base-text-field>
            </div>
        </div>
        <div class="section">
            <h2>{{ $translate('labels.infoAndDisclaimers') }}</h2>
            <base-card class="procedures" outlined>
                <template #title>{{ $translate('paymentFlow.financing.curae.newAccountProceduresHeader') }}</template>
                <template #text>{{ $translate('payment.financing.application.newAccountProceduresBody') }}</template>
            </base-card>
        </div>
        <div class="horizontal-fields consent vuetify">
            <base-checkbox
                v-model="application.electronicDisclosureConsent"
                class="vuetify fs-block consent-checkbox"
                name="electronicDisclosureConsent"
                :error-messages="v$.application.electronicDisclosureConsent.$errors.map(e => e.$message)"
                @action="v$.application.electronicDisclosureConsent.$touch"
                @click="() => { electronicDisclosureConsentDirty = true }"
            ></base-checkbox>
            <div class="consent-label" v-html="getElectronicDisclaimerText()"></div>
        </div>
        <div v-if="!application.electronicDisclosureConsent && electronicDisclosureConsentDirty" class="error-message">
            <span class="error-message">{{  $translate('payment.financing.application.electronicDisclosureConsentError') }}</span>
        </div>
    </base-form>
    <div v-if="financingError" class="financing-error">
        <base-icon :size="$vuetify.display.lgAndUp ? 24 : 20" icon="mdi-alert-circle"></base-icon>
        <div class="financing-error-message">{{ financingError }}</div>
    </div>
    <div class="navigation-buttons">
        <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <base-btn @click="getCuraeOffers()" :disabled="formInvalid || !application.electronicDisclosureConsent || isGettingFinancingOptions || noOffersFound" class="continue-btn v-button-primary">
            {{ $translate('paymentFlow.financing.curae.viewOffers') }}
        </base-btn>
    </div>
    <div class="bank-account">{{  $translate('paymentFlow.financing.curae.bankAccount') }}</div>
    <base-bottom-sheet class="income-sheet" v-model="incomeBottomSheet">
        <h1>{{  $translate('payment.financing.application.monthlyIncomeHeader') }}</h1>
        <div>{{  $translate('paymentFlow.financing.curae.newMonthlyIncomeBody') }}</div>
        <h2>{{  $translate('paymentFlow.financing.curae.wisconsinResidents') }}</h2>
        <div>{{  $translate('paymentFlow.financing.curae.wisconsinResidentsBody') }}</div>
    </base-bottom-sheet>
</div>
</template>
<script>
import { onlyNumbers, states } from './../utils/utils.js';
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseIcon from './BaseIcon.vue';
import BaseTextField from './BaseTextField.vue';
import BaseBtn from './BaseBtn.vue';
import BaseSelect from './BaseSelect.vue';
import BaseCard from './BaseCard.vue';
import BaseForm from './BaseForm.vue';
import BaseSpacer from './BaseSpacer.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import moment from 'moment';

import useValidate from '@vuelidate/core';
import { required, minLength, helpers, email } from '@vuelidate/validators';

export default {
    name: 'PaymentFlowCuraeApplicationStep',
    components: {
        BaseTextField,
        BaseBtn,
        BaseSelect,
        BaseExpansionPanel,
        BaseBottomSheet,
        BaseIcon,
        BaseCard,
        BaseForm,
        BaseSpacer,
        BaseCheckbox
    },
    props: {
        financingError: String,
        isGettingFinancingOptions: Boolean,
        secureCode: String,
        existingApplication: Object,
        noOffersFound: Boolean
    },
    data: () => ({
        v$: useValidate(),
        incomeBottomSheet: false,
        states: states,
        application: {
            firstName: '',
            lastName: '',
            birthdate: '',
            ssn: '',
            income: '',
            email: '',
            phone: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            electronicDisclosureConsent: false
        },
        electronicDisclosureConsentDirty: false
    }),
    validations() {
        return {
            application: {
                firstName: { required: this.requiredMsg },
                lastName: { required: this.requiredMsg },
                birthdate: {
                    required: this.requiredMsg,
                    incompleteBirthdate: helpers.withMessage(
                        this.$translate('errors.incompleteBirthdate'),
                        function() {
                            return Boolean(this.application.birthdate.match(/\d\d\/\d\d\/\d\d\d\d/));
                        }
                    ),
                    validDate: helpers.withMessage(
                        this.$translate('errors.invalidDate'),
                        function() {
                            return Boolean((this.application.birthdate.match(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)) || Boolean(moment(this.application.birthdate, 'MM/DD/YYYY').isValid()));
                        }
                    ),
                    futureDate: helpers.withMessage(
                        this.$translate('errors.futureBirthdate'),
                        function() {
                            return Boolean(moment(this.application.birthdate, 'MM/DD/YYYY').isBefore(moment()));
                        }
                    ),
                    adultRequired: helpers.withMessage(
                        this.$translate('errors.adultRequired'),
                        function() {
                            return Boolean(moment(this.application.birthdate, 'MM/DD/YYYY').isBefore(moment().subtract(18,'y')));
                        }
                    ),
                },
                ssn: {
                    required: this.requiredMsg,
                    minLength: helpers.withMessage(
                        this.$translate('errors.ssnFormat'),
                        minLength(11)
                    )
                },
                income: {
                    required: this.requiredMsg,
                    valid: helpers.withMessage(
                        this.$translate('payment.financing.application.incomeGreaterThanZeroError'),
                        function() {
                            let val = this.application.income;
                            if (val && val.length > 0) {
                                var income = val.replace(/[$,.\s]/g, '');
                                income = Number(income);
                                if (income) {
                                    return true;
                                }
                            }
                            return false;
                        }
                    )
                },
                phone: {
                    required: this.requiredMsg,
                    minLength: helpers.withMessage(
                        this.$translate('errors.phoneFormat'),
                        minLength(10)
                    )
                },
                email: {
                    required: this.requiredMsg,
                    email: helpers.withMessage(
                        this.$translate('errors.email'),
                        email
                    )
                },
                address: {
                    required: this.requiredMsg,
                    noPoBoxRule: helpers.withMessage(
                        this.$translate('errors.poBoxNotAllowed'),
                        function() {
                            return !Boolean(/(p[\s\.]*o|post)[\s\.]*(office)?[\s\.]*(box\b|bin\b)/i.test(this.application.address));
                        }
                    )
                },
                city: { required: this.requiredMsg },
                state: { required: this.requiredMsg },
                zip: {
                    required: this.requiredMsg,
                    minLength: helpers.withMessage(
                        this.$translate('errors.minZipCode'),
                        minLength(5)
                    )
                },
                electronicDisclosureConsent: { required: this.requiredMsg },
            }
        }
    },
    computed: {
        requiredMsg() {
            return helpers.withMessage(this.$translate('errors.fieldRequired'), required);
        },
        formInvalid() {
            return this.v$.application.$invalid;
        }
    },
    watch: {
        "application.phone": {
            handler() {
                this.formatPhoneNumberInput();
            }
        },
        "application.birthdate": {
            handler() {
                this.formatBirthdateInput();
            }
        },
        "application.ssn": {
            handler() {
                this.formatSsnInput();
            }
        },
        "application.income": {
            handler() {
                this.formatIncomeInput();
            }
        }
    },
    methods: {
        validateFields() {
            this.v$.$validate();
        },
        onlyNumbers:onlyNumbers,
        formatPhoneNumberInput() {
            if (this.application.phone && this.application.phone.length > 0) {
                var v = this.application.phone.replace(/-/g, '').replace(/[^0-9]/gi, '');
                var parts = [];
                parts.push(v.substring(0, 3));
                if (v.length > 3) {
                    parts.push(v.substring(3, 6));
                }
                if (v.length > 6) {
                    parts.push(v.substring(6, 10));
                }
                if (parts.length) {
                    this.application.phone = parts.join('-');
                }
            }
        },
        formatSsnInput() {
            if (this.application.ssn && this.application.ssn.length > 0) {
                var v = this.application.ssn.replace(/-/g, '').replace(/[^0-9]/gi, '');
                var parts = [];
                parts.push(v.substring(0, 3));
                if (v.length > 3) {
                    parts.push(v.substring(3, 5));
                }
                if (v.length > 5) {
                    parts.push(v.substring(5, 11));
                }
                if (parts.length) {
                    this.application.ssn = parts.join('-');
                }
            }
        },
        formatBirthdateInput() {
            if (this.application.birthdate && this.application.birthdate.length > 0) {
                var v = this.application.birthdate.replace(/\/+/g, '').replace(/[^0-9]/gi, '');
                var parts = [];
                parts.push(v.substring(0, 2));
                if (v.length > 2) {
                    parts.push(v.substring(2, 4));
                }
                if (v.length > 4) {
                    parts.push(v.substring(4, 15));
                }
                if (parts.length) {
                    this.application.birthdate = parts.join('/');
                }
            }
        },
        formatIncomeInput() {
            let num = String(this.application.income);
            if (num && num.length > 0) {
                var v = num.replace(/\/+/g, '').replace(/[^0-9]/gi, '');
                this.application.income = v.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                this.application.income = "";
            }
        },
        getCuraeOffers() {
            this.$emit("set-getting-financing", false);
            var birthdate = moment(this.application.birthdate, 'MM/DD/YYYY').format();
            var income = this.application.income.replace(/[$,.\s]/g, '');
            income = Number(income);
            var secureCode = this.secureCode;
            var app = {
                "firstName": this.application.firstName,
                "lastName": this.application.lastName,
                "email": this.application.email,
                "phone": this.application.phone,
                "ssn": this.application.ssn,
                "birthdate": birthdate,
                "secureCode": secureCode,
                "vendors": [
                    "Curae"
                ],
                "electronicDisclosureConsent": this.application.electronicDisclosureConsent,
                "income": income,
                "address": this.application.address,
                "address2": this.application.address2,
                "city": this.application.city,
                "state": this.application.state.value,
                "zip": this.application.zip
            };
            this.$emit('get-curae-offers', { application: app, data: this.application });
        },
        getElectronicDisclaimerText() {
            var url = 'https://portal.aquinahealth.com/DirectApplication/ElectronicDisclosureConsent/1033';
            return this.$translate('paymentFlow.financing.curae.electronicDisclosureConsentText', { electronicDisclosureConsentLink: url });
        },
        cancel() {
            this.$emit('cancel');
        },
        back() {
            this.$emit('back');
        },
    },
    created() {
        if (Object.keys(this.existingApplication).length) {
            this.application = this.existingApplication;
        }
        this.$emit('scroll-to-top');
    },
    mounted() {
        this.$emit('loaded');
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.payment-flow-curae-application-step {
    .application-title {
        h1 {
            font-weight: 500;
            color: $global-color-font-new;
            font-size: 2.0rem;
            line-height: 2.4rem;
            @include wider-than-tablet {
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }
        sub {
            color: $global-color-font-new;
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
    }
    .bank-account {
        color: $global-color-font-new;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .v-form {
        .section {
            margin-top: 2.5rem;
            h2 {
                margin-bottom: 0.3rem;
                color: $global-color-font-new;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 500;
            }
        }
        .horizontal-fields {
            display: flex;
            flex-flow: column wrap;
            @include wider-than-tablet {
                flex-flow: row wrap;
                & > .v-text-field.vuetify {
                    width: 50%;
                }
                .first-of-type {
                    padding-right: 5px;
                }
            }
        }
        .horizontal-fields.income-row {
            display: flex;
            flex-flow: row wrap;
            & > .v-text-field.vuetify {
                width: 90%;
            }
            & > span {
                width: 10%;
            }
            @include wider-than-tablet {
                flex-flow: row wrap;
                & > .v-text-field.vuetify, span {
                    width: 50%;
                }
            }
            .income-icon {
                margin-top: 15px;
                padding-left: 2%;
                @include wider-than-tablet {
                    padding-left: 0;
                }
                i {
                    color: $global-color-link-new;
                }
            }
        }
        .vuetify.procedures.v-card.v-sheet {
            margin: 0;
            margin-top: 0.5rem;
            border: 1px solid $global-color-greyed-out;
        }
        .error-message span.error-message  {
            color: $global-color-warning;
            display: flex;
            padding-left: 6%;
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
        .procedures {
            .v-card-title {
                color: $global-color-font-new;
                font-size: 1.4rem;
                line-height: 1.6rem;
                font-weight: 500;
            }
            .v-card-text {
                color: $global-color-font-new;
                font-size: 1.2rem;
                line-height: 1.6rem;
            }
        }
        .city-state-zip {
            display: flex;
            flex-flow: row wrap;
            & > .city {
                width: 100%;
            }
            & > .state {
                margin-right: 0.5rem;
                width: 33%;
            }
            & > .zip {
                width: 33%;
            }
            @include wider-than-tablet {
                & > .city {
                    margin-right: 0.5rem;
                    width: 42%;
                }
                & > .state {
                    margin-right: 0.5rem;
                    width: 24%;
                }
                & > .zip {
                    min-width: 8rem;
                    width: 32%;
                }
            }
        }
        .horizontal-fields.consent {
            display: flex;
            flex-flow: row;
            margin-top: 1.5rem;

            .consent-checkbox.vuetify {
                margin-right: 2.5rem;
            }
            @include wider-than-tablet {
                margin-right: 0;
            }
            div {
                color: $global-color-font-new;
                font-size: 1.4rem;
                line-height: 1.9rem;
            }
        }
        .v-text-field, .base-select {
            // Make outline gray when not in focus
            fieldset:not(.vuetify) {
                border-width: 1px;
            }

            .v-label {
                font-size: 1.4rem;
                color: $global-color-text-field-label;
                line-height: 1.9rem;
                padding-top: 2px;
            }

            input {
                border-bottom-width: 0px;
            }

            fieldset {
                height: 49px;
                padding-bottom: 6px;
                background-color: white;
            }

            .v-input__control {
                padding-bottom: 0.3rem;
            }

            .v-field__field {
                height: 49px;

                label {
                    margin-left: 7px;
                    transform-origin: center left;
                }
            }

            .v-select__slot {
                label {
                    margin-left: 7px;
                    transform-origin: center left;
                }
            }

            .v-input__icon {
                padding-top: 4px;
            }
            
            .v-field__field {
                min-height: 49px;
                padding-bottom: 6px;
            }

            .v-text-field__details {
                margin-top: 0 !important;
            }

            .v-select__selections {
                flex-wrap: nowrap;
                input {
                    display: inline; // this allows tabbing into the select field
                }
            }
        }
    }
}
.income-sheet {
    h1 {
        color: $global-color-font-new;
        font-size: 2.0rem;
        line-height: 2.4rem;
        font-weight: 500;
        padding-top: 4rem;
        padding-bottom: 1rem;
    }
    h2 {
        color: $global-color-font-new;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 500;
        padding-top: 2.5rem;
    }
    div {
        color: $global-color-font-new;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}
</style>
