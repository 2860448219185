<template>
    <div class="block-segment receipt-left-column" v-if="plan">
        <button class="button-primary" @click="print();">
            {{$translate('actions.printPlan')}}
        </button>
        <button v-show="plan.billViewable" @click="viewBill();">
            {{$translate('actions.viewBill')}}
        </button>

        <button v-show="!plan.terminalState && plan.remainingAmount > 0 && paymentPossible && provider && provider.active" @click="updatePaymentForm();" 
            class='update-method' data-testid="update-payment-form">
            {{$translate('actions.updatePaymentForm')}}
        </button>
        <button v-show="provider && provider.canMessage"
            @click="messageProvider()">
            {{$translate('actions.messageProvider')}}
        </button>
    </div>
</template>

<script>


export default {
    name: 'PlanReceiptLeftColumn',
    computed: {
        plan() {
            return this.$store.getters.plan;
        },
        provider() {
            return this.$store.getters.planProvider;
        },
        paymentPossible() {
            return this.$store.getters.planPaymentPossible;
        }
    },
    methods: {
        print() {
            try {
                window.print();
            } catch (error) {
                this.emitter.emit('simpleModal:showPrompt', {
                    header: this.$translate('planReceipt.printingNotSupportedHeader'),
                    subcontent: this.$translate('planReceipt.printingNotSupportedContent', {
                        viewBill: this.$translate('actions.viewBill')
                    }),
                    intent: 'information',
                    meta: 'warning'
                });
            }
        },
        viewBill() {
            if (this.plan) {
                this.$store.dispatch('openPdf', this.plan.secureCode);
            }
        },
        updatePaymentForm() {
            if (this.provider.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                    provider: this.provider,
                    context: {
                        stateGo: {
                            to: 'PlanReceipt',
                            toParams: {
                                id: this.provider.id,
                                planId: this.plan.id
                            }
                        },
                        emit: ['planReceipt:updatePaymentForm', null]
                    }
                });
            } else {
                this.emitter.emit('planReceipt:updatePaymentForm');
            }
        },
        messageProvider() {
            if (this.provider.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                    provider: this.provider,
                    context: {
                        stateGo: {
                            to: 'PlanReceipt',
                            toParams: {
                                id: this.provider.id,
                                planId: this.plan.id
                            }
                        },
                        emit: ['message:compose', { secureCode: this.plan.secureCode }]
                    }
                });
            } else {
                this.emitter.emit('message:compose', { secureCode: this.plan.secureCode });
            }
        },
    },
};

</script>

<style lang="scss">

</style>