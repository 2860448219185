<template>
    <svg data-name="Patientco Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.46 107.46" height="100%">
        <title>Patientco Logo</title>
        <path style="fill: #fff" class="cls-1" d="M147.32,39.43v66.7" transform="translate(-20.07 -18.67)"/>
        <path style="fill: #fff" class="cls-1" d="M73.8,31.62A40.9,40.9,0,0,0,33,72.4v53.73H20.07V72.4A53.73,53.73,0,1,1,73.8,126.13a53.33,53.33,0,0,1-27.49-7.63v-16A40.77,40.77,0,1,0,73.8,31.62Z" transform="translate(-20.07 -18.67)"/>
        <circle style="fill: transparent" cx="73.8" cy="44.91" r="19" transform="translate(-20.07 8.8)" />
        <path style="fill: #fff; fill-rule: evenodd" class="cls-2" d="M73.8,44.91h0A27.57,27.57,0,0,0,46.31,72.4h0A27.57,27.57,0,0,0,73.8,99.89h0A27.57,27.57,0,0,0,101.29,72.4h0A27.57,27.57,0,0,0,73.8,44.91Zm6.47,45.22H67.33V78.87H56.07V65.93H67.33V54.67H80.27V65.93H91.53V78.87H80.27Z" transform="translate(-20.07 -18.67)"/>
    </svg>
</template>

<script>
export default {
    name: 'PatientcoSVG'
};
</script>