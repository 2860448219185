import http from '../../utils/http';
import Bill from '../../models/Bill';
import { squashDescriptionlessDetailLines } from '../../utils/utils';

const state = {
    currentBill: null,
    detailLinesSummary: false,
    patientResponsibilityExpandable: false,
    servicesExpandable: false,
    linkedBills: [],
    authOptionsBill: {},
    firstBillToken: ""
};

const getters = {
    currentBill(state) {
        return state.currentBill;
    },
    billUncoveredCarcCodes(state) {
        return getters.billServices(state)
            .flatMap(s => s.carcCodes)
            .filter(c => c?.carcCode && !['1','2','3'].includes(c.carcCode) && !!c.carcCode)
            .reduce((acc,cur)=>{
                if (!acc.includes(cur.carcCode)) {
                    acc.push(cur.carcCode);
                }
                return acc;
            },[]);
    },
    billServices(state) {
        let services = [];
        state.detailLinesSummary = false;
        state.patientResponsibilityExpandable = false;
        state.servicesExpandable = false;
        if (state.currentBill && state.currentBill.subAccounts && state.currentBill.subAccounts.length) {
            let subAccounts = state.currentBill.subAccounts;
            subAccounts.forEach((subAccount) => {
                if (subAccount.detailLines) {
                    subAccount.detailLines.forEach((detailLine) => {
                        services.push(detailLine);
                        if (detailLine.carcCodes) {
                            state.patientResponsibilityExpandable = true;
                        }
                        if (Boolean(detailLine.cptCode || detailLine.totalCharge 
                            || detailLine.insuranceAdjustment|| detailLine.providerAdjustment 
                            || detailLine.insurancePaid || detailLine.carcCodes)) {
                            state.servicesExpandable = true;
                        }
                    });
                }
            });
            services = squashDescriptionlessDetailLines(services);
            if (Object.keys(services).length) {
                state.detailLinesSummary = true;
            }
        }
        if (!Object.keys(services).length && state.currentBill && state.currentBill.details && state.currentBill.details.visits && state.currentBill.details.visits.length) {
            state.currentBill.details.visits.forEach((visit) => {
                visit.detailLines.filter(d => 'chrg' === d.type).forEach((d) => {
                    if (d.name && !d.description) {
                        d.description = d.name;
                    }
                    if (d.cost && !d.patientResponsibility) {
                        d.patientResponsibility = d.cost;
                    }
                    services.push(d);
                });
            });
        }
        return services;
    },
    detailLinesSummary(state) {
        return state.detailLinesSummary;
    },
    patientResponsibilityExpandable(state) {
        return state.patientResponsibilityExpandable;
    },
    servicesExpandable(state) {
        return state.servicesExpandable;
    },
    authOptionsBill(state) {
        return state.authOptionsBill;
    },
    firstBillToken(state) {
        return state.firstBillToken;
    }
};

const mutations = {
    setCurrentBill(state, val) {
        state.currentBill = val;
    },
    setAuthOptionsBill(state, val) {
        state.authOptionsBill = val;
    },
    setFirstBillToken(state, val) {
        state.firstBillToken = val;
    },
};

const actions = {
    findBill({ commit }, { billAmount, secureCode }) {
        return http.post('bills/find', {
            amount: billAmount,
            secureCode,
        }).then((res) => {
            if (res && res.hasData()) {
                const bill = new Bill(res.getData(), secureCode);
                commit('setCurrentBill', bill);
                return bill;
            }
            throw res;
        }).catch(error => { throw error; });
    },
    payAsGuest({ state }, voucherNumber) {
        return new Promise((resolve, reject) => {
            if (state.currentBill === null) {
                reject('Bill must be loaded to pay as guest.');
            }

            const { secureCode, billAmount } = state.currentBill;

            http.post('bills/payAsGuest', {
                secureCode,
                amount: billAmount,
                voucherNumber,
            }).then((res) => {
                if (res && res.hasData() && res.getData().redirectUrl) {
                    window.FS.log('log', 'success bill payAsGuest');
                    window.location.href = res.getData().redirectUrl;
                    resolve(res);
                } else {
                    window.FS.log('error', 'bill payAsGuest');
                    reject(res);
                }
            }).catch((err) => {
                window.FS.log('error', 'bill payAsGuest');
                reject(err);
            });
        });
    },
    findNewBill({commit}, { token, verificationCode, guarantorLast4SSN, guarantorDOB, zip }) {
        const url = `bills/findNew`;
        return http.post(url, { token, verificationCode, guarantorLast4SSN, guarantorDOB, zip }).then((resp) => {
            if (resp && resp.hasData()) {
                const bill = new Bill(resp.getData());
                bill.token = token;
                commit('setCurrentBill', bill);
                return bill;
            } else {
                throw resp;
            }
        }).catch(error => { throw error; });
    },
    linkBill({dispatch, commit, getters, state}, { sCode, amount }) {
        if (state.linkedBills.includes(sCode)) {
            return;
        }
        state.linkedBills.push(sCode);
        const url = 'bills/link';
        return http.post(url, { secureCode: sCode, amount: amount }).then((resp) => {
            if(resp && resp.hasData()){
                const bill = new Bill(resp.getData());
                bill.linked = true;
                commit('setCurrentBill', bill);
                if (getters.currentPatientUserId) {
                    dispatch('syncProviders');
                }
                return bill;
            } else {
                throw resp;
            }
        }).catch(error => {throw error;});
    },
    firstBillGetCode({}, token) {
        const url = `bills/firstBillGetCode`;
        return http.post(url, { billToken: token }).then((resp) => {
            if (resp && resp.hasData() && resp.getData().success === true) {
                return resp.getData();
            } else {
                throw resp;
            }
        }).catch(error => { throw error; });
    },
    markOpenedBill({}, token) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token },
            url: 'bills/markOpened',
            allowNullData: true,
        });
    },
    findAuthOptionsBill({}, token) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { token },
            url: 'bills/findAuthOptions',
        });
    },
    /**
        Look up bill based on the sCode alone. This search will
        look only under the currently logged in user's account to find
        a bill that matches on that.
    */
    getAccountBill({ commit }, sCode) {
        const url = `bills/shc/${sCode}`;
        return http.post(url).then((resp) => {
            if (resp && resp.data) {
                const bill = new Bill(resp.getData(), sCode);
                bill.linked = true;
                commit('setCurrentBill', bill);
                return bill;
            } else {
                throw resp;
            }
        }).catch(error => { throw error; });
    },
    // Look up a bill based on the CCI pub id
    getCCIBill({ commit }, pubId) {
        const url = `bills/cci`;
        return http.post(url, { pubId }).then((resp) => {
            if (resp && resp.data) {
                const bill = new Bill(resp.getData());
                commit('setCurrentBill', bill);
                return bill;
            } else {
                throw resp;
            }
        }).catch(error => { throw error; });
    },
    openPdf({}, sCode) {
        if (!sCode) {
            throw "Secure Code not provided";
        }
        window.FS.event('bill view-bill open');
        window.open('/api/services/v1/bills/pdf/' + sCode, '_blank');
    },
    retrieveSundaySkyVideo({ state }, { sskyProgramId, type }) {
        let payload = { sskyProgramId, type };
        if (type == "bill") {
            payload.secureCode = state.currentBill.secureCode;
            payload.amount = state.currentBill.billAmount;
        }
        return http.getDataOrThrow({
            method: 'POST',
            data: payload,
            url: 'bills/retrieveSundaySkyVideo',
        });
    },
};

export default {
    state, getters, mutations, actions,
};
