class SubAccount {
    constructor(rawSubAccountObject) {
        // Fields directly from json
        this.id = rawSubAccountObject.id;
        this.amountDue = rawSubAccountObject.amountDue;
        this.appointmentInfo = rawSubAccountObject.appointmentInfo;
        this.facilityName = rawSubAccountObject.facilityName;
        this.formattedSubAccountNumber = rawSubAccountObject.formattedSubAccountNumber;
        this.patientName = rawSubAccountObject.patientName;
        this.serviceDate = rawSubAccountObject.serviceDate;
        this.services = rawSubAccountObject.services;
        this.subAccountNumber = rawSubAccountObject.subAccountNumber;
        this.detailLines = rawSubAccountObject.detailLines;

        // Calculated fields
        if (this.appointmentInfo) {
            this.appointmentDate = this.appointmentInfo.appointmentDate;
            this.appointmentTime = this.appointmentInfo.appointmentTime;
            this.serviceTimeZone = this.appointmentInfo.serviceTimeZone;
            this.providerLocation = this.appointmentInfo.providerLocation;
            this.inNetworkProvider = this.appointmentInfo.inNetworkProvider;
            this.doctorName = this.appointmentInfo.inNetworkProvider;
        }
    }

    getStatus() {
        if (this.serviceDate) {
            return 'DUE_DATE';
        }
        return null;
    }

    static fromEstimate(estimate) {
        const sa = new SubAccount({
            ...estimate,
            serviceDate: estimate.dateOfService,
        });
        return sa;
    }

    hasAppointmentInfo() {
        return Boolean(this.appointmentDate || this.appointmentTime || this.doctorName || this.providerLocation);
    }
}

export default SubAccount;
