<template>
    <div class="status-chip vuetify" v-if="showComponent">
        <base-chip v-if="'PROVIDER_INACTIVE' === status" color="#DCDCDC" text-color="#979797">
            {{ $translate('statusChips.providerInactive') }}
        </base-chip>
        <!-- Error/Warning Chips -->
        <base-chip v-else-if="'PAST_DUE' === status">
            {{ $translate('statusChips.pastDue') }}
        </base-chip>
        <base-chip v-else-if="'BAD_DEBT' === status">
            {{ $translate('statusChips.badDebt') }}
        </base-chip>
        <base-chip v-else-if="'PROMISE_TO_PAY_PLAN_HELD' === status">
            {{ $translate('statusChips.paymentPlanHeld') }}
        </base-chip>
        <base-chip v-else-if="'PAYMENT_PLAN_HELD' === status">
            {{ $translate('statusChips.paymentPlanHeld') }}
        </base-chip>
        <base-chip v-else-if="'VOID' === status" class="extra-padding">
            {{ $translate('statusChips.void') }}
        </base-chip>
        <!-- Pending Chips -->
        <base-chip v-else-if="'DUE_DATE_OVERRIDE' === status" color="#1867C0">
            {{ account.provider.patientDueDateOverrideText }}
        </base-chip>
        <base-chip v-else-if="'DUE_DATE' === status && dueDate" color="#1867C0">
            {{ $translate('statusChips.dueDate', { date: $formatDate(dueDate, 'MM/DD/YYYY') }) }}
        </base-chip>
        <base-chip v-else-if="'PENDING' === status" color="#1867C0">
            {{ $translate('statusChips.pending') }}
        </base-chip>
        <!-- No Action Needed Chips -->
        <base-chip v-else-if="'PROMISE_TO_PAY_PLAN_ACTIVE' === status" color="#1E8914">
            {{ $translate('statusChips.paymentPlanInProgress') }}
        </base-chip>
        <base-chip v-else-if="'PAYMENT_PLAN_INPROGRESS' === status" color="#1E8914">
            <base-icon color="white" icon="mdi-cached"></base-icon>{{ $translate('statusChips.paymentPlanInProgress') }}
        </base-chip>
        <base-chip v-else-if="'FINANCED' === status" color="#1E8914">
            <base-icon color="white" icon="mdi-check"></base-icon>{{ accountFinanced }}
        </base-chip>
    </div>
</template>

<script>
import Account from '../models/Account';
import SubAccount from '../models/SubAccount';
import Estimate from '../models/Estimate';
import BaseIcon from './BaseIcon.vue';
import BaseChip from './BaseChip.vue';

export default {
    name: 'StatusChip',
    components: {
        BaseChip,
        BaseIcon
    },
    props: {
        account: {
            type: Account,
            default: null,
        },
        subAccount: {
            type: SubAccount,
            default: null,
        },
        estimate: {
            type: Estimate,
            default: null,
        },
    },
    computed: {
        showComponent() {
            if (this.account && this.account.getStatus()) {
                return true;
            } else if (this.subAccount && this.subAccount.getStatus()) {
                return true;
            } else if (this.estimate && this.estimate.getStatus()) {
                return true;
            }
            return false;
        },
        status() {
            if (this.account && this.account.getStatus()) {
                return this.account.getStatus();
            } else if (this.subAccount && this.subAccount.getStatus()) {
                return this.subAccount.getStatus()
            } else if (this.estimate && this.estimate.getStatus()) {
                return this.estimate.getStatus();
            }
            return null;
        },
        accountFinanced() {
            if (this.account && this.account.getStatus()) {
                return this.account.financedBy || this.account.approvedFinanceApplication
            }
            return null;
        },
        dueDate() {
            if (this.account && this.account.getStatus()) {
                return this.account.dueDate;
            } else if (this.subAccount && this.subAccount.getStatus()) {
                return this.subAccount.serviceDate;
            } else if (this.estimate && this.estimate.getStatus()) {
                return this.estimate.subAccount.serviceDate;
            }
            return null;
        }
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';

.vuetify.status-chip {
    .v-chip {
        font-size: 1rem;
        line-height: 1.6rem;
        font-weight: 500;
        letter-spacing: 0.15rem;
        text-transform: uppercase;
        .v-icon {
            margin-right: 0.4rem;
            height: 1.6rem;
            width: 1.6rem;
        }
        @include wider-than-tablet {
            font-size: 1.2rem;
        }
    }
    .extra-padding span {
        padding: 0 1rem;
    }
}
</style>