<template>
<div class="input-area-field-wrapper">
    <span :class="{ 'icon-active': dirty }">
        <base-icon icon="mdi-email"></base-icon>
    </span>

    <input
        @input="onInput"
        @change="onInput"
        @focus="$emit('inputfocus')"
        @blur="$emit('inputblur')"
        :value="modelValue"
        :required="required"
        :placeholder="$translate('labels.email')"
        :disabled="disableAccountMangement"
        name="email"
        type="email"
        data-testid="settings-contact-form-email"
        class="fs-block input-with-icon details-email" />
</div>
</template>
<script>
import { validEmail } from '../utils/utils';

import BaseIcon from './BaseIcon.vue';

export default {
    name: 'LegacyEmailInput',
    components: {
        BaseIcon
    },
    props: {
        modelValue: String,
        required: {
            type: Boolean,
            default: false,
        },
        originalEmail: {
            type: String,
            default: null,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        disableAccountMangement: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        dirty: false,
    }),
    methods: {
        onInput(e) {
            this.dirty = true;
            this.$emit('update:modelValue', e.target.value);
        },
        async validate() {
            if (!this.modelValue) {
                return 'required';
            }
            if (!validEmail(this.modelValue)) {
                return 'invalid';
            }
            try {
                if (this.originalEmail !== this.modelValue) {
                    const available = await this.$store.dispatch('emailAvailable', { email: this.modelValue, isUpdate: this.isUpdate });
                    if (!available) {
                        return 'emailAvailable';
                    }
                }
            } catch {
                return 'emailAvailable';
            }
            return null;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.input-with-icon {
	display: inline;
}
.base-icon {
	color: $global-color-dark-grey !important;
}
</style>
