<template>
<div v-if="currentProvider" class="accounts-summary-left-column-persist vuetify" data-app>
    <div v-if="!$vuetify.display.lgAndUp" class="responsive-left-column-persist-mobile">
        <provider-logo :provider-id="currentProvider.id" :alt-text="currentProvider.name"></provider-logo>
        <base-btn
            @click="needHelpBottomSheet = true"
            class="need-help-sheet-btn"
            variant="outlined"
        >{{ $translate('labels.needHelp') }}</base-btn>
    </div>
    <provider-summary-card
        :providerName="currentProvider.name"
        :providerId="currentProvider.id"
        :amountDue="currentProvider.balance"
        :numAccounts="currentProvider.accounts ? currentProvider.accounts.length : 0"
        :numPastDue="numberOfPastDueAccounts"
        :showProviderLogo="$vuetify.display.lgAndUp"
    >
    </provider-summary-card>
    <base-bottom-sheet v-model="needHelpBottomSheet" content-class="need-help-sheet">
        <card-header
            :title="$translate('labels.needHelp')"
            icon="mdi-hospital-box-outline"
            class="sheet-card-header"
        ></card-header>
        <need-help
            @composemessage="composeMessage"
            :provider="currentProvider"
        ></need-help>
    </base-bottom-sheet>
</div>
</template>

<script>


import CardHeader from './CardHeader.vue';
import NeedHelp from './NeedHelp.vue';
import ProviderLogo from './ProviderLogo.vue';
import ProviderSummaryCard from './ProviderSummaryCard.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseBtn from './BaseBtn.vue';


export default {
    name: 'AccountsSummaryLeftColumnPersist',
    components: {
        CardHeader,
        NeedHelp,
        ProviderLogo,
        ProviderSummaryCard,
        BaseBottomSheet,
        BaseBtn
    },
    data: () => ({
        needHelpBottomSheet: false,
    }),
    computed: {
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        providers() {
            return this.$store.getters.providers;
        },
        numberOfPastDueAccounts() {
            if (this.currentProvider && this.currentProvider.accounts) {
                return this.currentProvider.accounts.filter(a => a.isPastDue).length;
            }
            return 0;
        },
    },
    methods: {
        composeMessage() {
            this.emitter.emit('message:compose');
        },
    },
};
</script>

<style lang="scss">
@import './../styles/variables.scss';
.vuetify.accounts-summary-left-column-persist {

    .v-card {
        margin: 0 1.5rem;
        margin-bottom: 0.25rem;
    }
    .back-button {
        flex: 1;
        @include wider-than-tablet {
            margin-bottom: 1rem;
        }
    }
    .responsive-left-column-persist-mobile {
        margin-bottom: 1rem;
    }
    @include wider-than-tablet {
        .v-card {
            margin-top: 0;
            margin-left: 0rem;
            margin-right: 1rem;
        }
    }
}
</style>