<template>
    <div class="activity-list-item vuetify" data-testid="activity-list-item">
        <div class="activity-list-item-date" data-testid="activity-list-item-date">
            {{ $formatDate(activity.date, "M/D/YYYY") }}
        </div>
        <div class="activity-list-item-row">
            <div class="activity-list-item-description" data-testid="activity-list-item-description">
                <a v-if="shouldLink(activity.itemType, activity.itemId)" @click="openActivity" :tabindex="0">
                    {{ activity.description }}
                </a>
                {{ shouldLink(activity.itemType, activity.itemId) ? '' : activity.description}}
            </div>
            <div :style="{visibility: activity.amount != null ? 'visible':'hidden'}" class="activity-list-item-amount" data-testid="activity-list-item-amount">
                {{ $formatCurrency(activity.amount) }}
            </div>
        </div>
        <base-divider :style="{visibility: divider ? 'visible':'hidden'}"></base-divider>
    </div>
</template>

<script>
import _ from 'lodash';
import BaseDivider from './BaseDivider.vue';

export default {
    name: 'ActivityListItem',
    components: {
        BaseDivider
    },
    props: {
        activity: Object,
        account: Object,
        divider: Boolean
    },
    methods: {
        shouldLink(itemType, itemId){
            // cant link if we have no item id or if it's
            // not a linking type
            return itemId !== null && _.includes([
                'bill',
                'collectionUpdate',
                'message',
                'payment',
                'declinedPayment',
                'canceledPayment',
                'reversePayment',
                'paymentPlanHeld',
                'canceledPaymentPlan',
                'paymentPlanCreated',
                'paymentPlanCompleted',
                'paymentPlanDeclined',
                'transitionedEbill',
                'ebill',
                'agreement',
                'agreementCancelled',
                'newEstimate',
                'updatedEstimate',
                'canceledEstimate'
            ], itemType);
        },
        // route the the event to it's proper location
        openActivity(){
            this.openEvent(this.activity.itemType, this.activity.itemId, this.account.providerId);
        },
        openEvent(itemType, itemId, providerId) {
            if(_.includes([ 'payment', 'paymentPlan', 'declinedPayment', 'canceledPayment', 'reversePayment'], itemType)){
                this.$router.push({
                    name: 'Receipt',
                    params: {
                        providerId: providerId,
                        paymentId: _.parseInt(itemId),
                    },
                });
            }
            else if(_.includes([ 'paymentPlanHeld', 'canceledPaymentPlan', 'paymentPlanCreated', 'paymentPlanDeclined', 'paymentPlanCompleted'], itemType)){
                this.$router.push({
                    name: 'PlanReceipt',
                    params: {
                        providerId: providerId,
                        planId: _.parseInt(itemId),
                    },
                });
            }
            else if (_.includes([ 'agreement', 'agreementCancelled' ], itemType)) {
                this.$router.push({
                    name: 'PaymentAgreementReceipt',
                    params: {
                        providerId: providerId,
                        agreementId: _.parseInt(itemId),
                    },
                });
            }
            else if(_.includes(['bill', 'collectionUpdate', 'transitionedEbill', 'ebill'], itemType)){
                this.$store.dispatch('openPdf', itemId);
            }
            else if(itemType === 'message'){
                this.$store.commit('setThreadId', itemId);
                this.$router.push({
                    name: 'MessageDetails',
                    params: {threadId: itemId},
                });
            }
            else if(_.includes([ 'newEstimate', 'updatedEstimate', 'canceledEstimate' ], itemType)) {
                this.$router.push({
                    name: 'SubAccountDetails',
                    params: {
                        providerId: providerId,
                        estimateId: _.parseInt(itemId),
                    },
                });
            }
        }
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
//reused styles
.activity-list-item-main-text {
    letter-spacing: .5px;
    font-size: 1.6rem;
}
.activity-list-item-left-margin {
    margin-left: 2.4rem;
    @include wider-than-tablet {
		margin-left: 7.2rem;
	}
}

.two-line-then-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;
}

//unique styles
.activity-list-item {
    padding-top: 1.3rem;
}

.activity-list-item-date {
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 1.35px;
    color: #707070;
    @extend .activity-list-item-left-margin;
}

.activity-list-item-description {
    a {
        cursor: pointer;
    }
    flex: 80%;
    @extend .activity-list-item-left-margin;
    @extend .activity-list-item-main-text;
    @extend .two-line-then-ellipsis;
}

.activity-list-item-amount {
    font-size: 1.6rem;
    text-align: right;
    flex: 30%;
    margin: 0 2.2rem;
    @include wider-than-tablet {
        margin: 0 3.6rem;
	}
    @extend .activity-list-item-main-text;
}

.activity-list-item-row {
    width: 100%;
    align-items: center;
    display: flex;
    margin-bottom: 1.8rem;
}
</style>
