<template>
<modal can-close :show="isVisible" @close="isVisible = false" class="login-modal-container">
    <notification class="notification-inline failure" v-show="codeNotSent">
        <base-icon color="white" icon="mdi-close-circle"></base-icon>
        <div>{{ $translate('confirmPhone.errors.codeNotSent') }}</div>
    </notification>
    <notification class="notification-inline failure" v-show="codeInvalid">
        <base-icon color="white" icon="mdi-close-circle"></base-icon>
        <div>{{ $translate('confirmPhone.errors.codeInvalid') }}</div>
    </notification>
    <notification class="notification-inline failure" v-show="serverError">
        <base-icon color="white" icon="mdi-close-circle"></base-icon>
        <div>{{ serverErrorMessage }}</div>
    </notification>
    
    <!-- LOGIN / CREATE ACCOUNT -->
    <div class="login-modal" :class="{login: sections.showLogin}" v-show="!(sections.showForgotPassword || sections.showForgotPasswordMethod || sections.showForgotPasswordVerify || sections.showUpdatePassword || sections.showForgotPasswordEmailSent) && !sections.showExternalAccountManagement">
        
        <h2 class="modal-header h-style-2" v-html="$translate((subdomain ? 'whitelabel.' : '') + 'dialogs.welcome')"></h2>
        <div class="button-group">
            <button :class="{'button-primary':!sections.showLogin}" @click.prevent="sections.showLogin=false;setFormFocus('register-first');">{{ $translate('actions.createAccount') }}</button>
            <button :class="{'button-primary':sections.showLogin}" @click.prevent="sections.showLogin=true;setFormFocus('login-email');" class="modal-login">{{ $translate('actions.login') }}</button>
        </div>
        
        <div v-show="!sections.showLogin">
            <form name="forms.createForm" @submit.prevent novalidate>
                <div class="split-input-areas-container">
                    <!-- FIRST NAME -->
                    <div class="input-area-wrapper" 
                            :class="{errored: forms.createForm.firstName.$invalid && forms.createForm.$submitted, 'input-focused': forms.createForm.firstName.$focused}">
                        
                        <div class="input-area-field-wrapper">
                            <span :class="{ 'icon-active': forms.createForm.firstName.$dirty }">
                                <base-icon icon="mdi-account"></base-icon>
                            </span>
                            
                            <input required name="firstName" type="text" id="register-first"
                                    class="fs-block input-with-icon" :placeholder="$translate('labels.firstName')"
                                    v-model="register.firstName" tabindex="1"
                                    @input="onInputRegisterFirstName(); forms.createForm.firstName.$dirty = true"
                                    @focus="forms.createForm.firstName.$focused = true"
                                    @blur="forms.createForm.firstName.$focused = false"/>
                        </div>
                        
                        <div class="input-area-feedback">
                            <div v-if="forms.createForm.firstName.$error" class="vue-messages">
                                <div v-if="forms.createForm.firstName.errors.required" class="vue-message">
                                    {{ $translate('errors.genericRequired') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- LAST NAME -->
                    <div class="input-area-wrapper"
                        :class="{errored: forms.createForm.lastName.$invalid && forms.createForm.$submitted, 'input-focused': forms.createForm.lastName.$focused}">

                        <div class="input-area-field-wrapper">
                            <span :class="{ 'icon-active': forms.createForm.lastName.$dirty }">
                                <base-icon icon="mdi-account"></base-icon>
                            </span>
                            
                            <input required name="lastName" type="text" id="register-last"
                                    class="fs-block input-with-icon" :placeholder="$translate('labels.lastName')"
                                    v-model="register.lastName" tabindex="2"
                                    @input="onInputRegisterLastName(); forms.createForm.lastName.$dirty = true"
                                    @focus="forms.createForm.lastName.$focused = true"
                                    @blur="forms.createForm.lastName.$focused = false"/>
                        </div>

                        <div class="input-area-feedback">
                            <div v-if="forms.createForm.lastName.$error" class="vue-messages">
                                <div v-if="forms.createForm.lastName.errors.required" class="vue-message">
                                    {{ $translate('errors.genericRequired') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                <!-- EMAIL -->
                <div class="input-area-wrapper"
                        :class="{errored: forms.createForm.email.$invalid && (forms.createForm.$submitted || forms.createForm.email.errors.emailAvailable), 'input-focused': forms.createForm.email.$focused}">
                    
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.createForm.email.$dirty }">
                            <base-icon icon="mdi-email"></base-icon>
                        </span>
                        
                        <input required name="email" type="email" class="fs-block input-with-icon"
                                autocapitalize="off" autocorrect="off" autosuggest="off" id="register-email"
                                :placeholder="$translate('labels.email')"
                                v-model="register.email" tabindex="3" maxlength="255"
                                @input="validateRegisterEmail(); forms.createForm.email.$dirty = true"
                                @focus="forms.createForm.email.$focused = true"
                                @blur="validateRegisterEmailAvailable(); forms.createForm.email.$focused = false" />
                    </div>
                    
                    <div class="input-area-feedback">
                        <div class="vue-messages" v-if="forms.createForm.email.$error && forms.createForm.email.$invalid && (forms.createForm.$submitted || forms.createForm.email.errors.emailAvailable)">
                            
                            <div v-if="forms.createForm.email.errors.required" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                            <div v-else-if="forms.createForm.email.errors.email" class="vue-message">
                                {{ $translate('errors.email') }}
                            </div>
                            <div v-else-if="forms.createForm.email.errors.emailAvailable" class="vue-message">
                                {{ $translate('errors.emailUnavailable') }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PHONE -->
                <div class="input-area-wrapper" :class="{errored: forms.createForm.phone.$invalid && forms.createForm.phone.$touched && register.phoneNumber, 'input-focused': forms.createForm.phone.$focused}">
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.createForm.phone.$dirty }">
                            <base-icon icon="mdi-phone"></base-icon>
                        </span>

                        <input name="phone" type="text" id="create-phone" phone-number sms-eligible
                                class="fs-block input-with-icon" :placeholder="$translate('labels.phoneNumber')"
                                v-model="register.phoneNumber" tabindex="4"
                                @input="onInputRegisterPhone"
                                @focus="forms.createForm.phone.$focused = true"
                                @blur="forms.createForm.phone.$touched = true; forms.createForm.phone.$focused = false"
                                maxlength="12" />
                    </div>

                    <div class="input-area-feedback">
                        <div v-if="forms.createForm.phone.$error" class="vue-messages">
                            <div v-if="forms.createForm.phone.errors.minlength" class="vue-message">
                                {{ $translate('errors.phoneFormat') }}
                            </div>
                            <div v-else-if="forms.createForm.phone.errors.smsEligible" class="vue-message">
                                {{ $translate('errors.smsEligible') }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PASSWORD -->
                <div class="input-area-wrapper login-password-input-area"
                        :class="{
                                errored: forms.createForm.password.$invalid && forms.createForm.$submitted,
                                'login-password-incorect-blurred': forms.createForm.password.$dirty && forms.createForm.password.$invalid,
                                'input-focused': forms.createForm.password.$focused
                                }">
                    
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.createForm.password.$dirty }">
                            <base-icon icon="mdi-lock"></base-icon>
                        </span>
                        
                        <input required name="password" type="password"
                            id="register-pass" maxlength="255"
                            class="fs-block input-with-icon" :placeholder="$translate('labels.password')"
                            v-model="register.password" tabindex="5"
                            @input="validateRegisterPassword(); forms.createForm.password.$dirty = true"
                            @focus="forms.createForm.password.$focused = true"
                            @blur="forms.createForm.password.$focused = false"/>
                    </div>
                    
                    <div class="input-area-rules register">
                        <ul>
                            <li :class="{'rule-met': !forms.createForm.password.errors.minCharactersRule}">
                                <span v-if="!forms.createForm.password.errors.minCharactersRule" class="rule-met-icon">
                                    <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                                </span>
                                <span v-else class="rule-met-icon">
                                    <base-icon icon="mdi-close"></base-icon>
                                </span>
                                {{ $translate('login.minCharactersRule') }}
                            </li>
                            <li :class="{'rule-met': !forms.createForm.password.errors.upperCaseRule}">
                                <span v-if="!forms.createForm.password.errors.upperCaseRule" class="rule-met-icon">
                                    <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                                </span>
                                <span v-else class="rule-met-icon">
                                    <base-icon icon="mdi-close"></base-icon>
                                </span>
                                {{ $translate('login.upperCaseRule') }}
                            </li>
                            <li :class="{'rule-met': !forms.createForm.password.errors.digitRule}">
                                <span v-if="!forms.createForm.password.errors.digitRule" class="rule-met-icon">
                                    <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                                </span>
                                <span v-else class="rule-met-icon">
                                    <base-icon icon="mdi-close"></base-icon>
                                </span>
                                {{ $translate('login.digitRule') }}
                            </li>
                        </ul>
                    </div>
                </div>
                
                
                <!-- CONFIRM PASSWORD -->
                <div class="input-area-wrapper"
                        :class="{errored: forms.createForm.password.$valid && forms.createForm.confirmPassword.$invalid && forms.createForm.$submitted, 'input-focused': forms.createForm.confirmPassword.$focused}">
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.createForm.confirmPassword.$dirty }">
                        </span>
                        
                        <input required name="confirmPassword" type="password" id="register-con-pass"
                                class="fs-block input-with-icon" :placeholder="$translate('labels.passwordConfirm')"
                                v-model="register.confirmPassword" tabindex="6"
                                @input="validateRegisterConfirmPassword(); forms.createForm.confirmPassword.$dirty = true"
                                @focus="forms.createForm.confirmPassword.$focused = true"
                                @blur="forms.createForm.confirmPassword.$focused = false"/>
                    </div>
                    <div class="input-area-feedback">
                        <div v-if="forms.createForm.confirmPassword.$error" class="vue-messages">
                            <div v-if="forms.createForm.confirmPassword.errors.required" class="vue-message">
                                {{ $translate('errors.genericRequired') }}
                            </div>
                            <div v-else-if="forms.createForm.confirmPassword.errors.mismatch" class="vue-message">
                                {{ $translate('errors.confirmPassword') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="register-checkboxes">
                    <!-- SMS OPT-IN -->
                    <div class="input-area-wrapper" :class="{errored: forms.createForm.receivesSms.$invalid && forms.createForm.$submitted}">
                        <div class="input-area-field-wrapper no-initial-border inline-input-type">
                            <input id="receivesSms" name="receivesSms" type="checkbox" v-model="register.receivesSms" tabindex="7"/>
                            <label for="receivesSms" class="create-account-terms">{{ $translate('labels.receivesSms') }}</label>
                            <base-tooltip location="top" :tip="$translate('labels.smsHelpText')" class="sms-consent">
                                <base-icon class="alert" icon="mdi-alert-circle-outline"></base-icon>
                            </base-tooltip>
                        </div>
                    </div>

                    <!-- TERMS OF USE -->
                    <div class="input-area-wrapper"
                            :class="{errored: forms.createForm.tou.$invalid && forms.createForm.$submitted}">
                        <div class="input-area-field-wrapper no-initial-border inline-input-type">

                            <input required id="tou" name="tou" type="checkbox"
                                    v-model="register.acceptTerms" tabindex="8"
                                    @change="validateRegisterTou()"/>

                            <label for="tou" class="create-account-terms" v-html="$translate('labels.acceptanceOfTerms')"></label>
                        </div>
                        <div class="input-area-feedback">
                            <div v-if="forms.createForm.tou.$error" class="vue-messages">
                                <div v-if="forms.createForm.tou.errors.required" class="vue-message">
                                    {{ $translate('errors.termsAcceptanceRequired') }}
                                </div>
                            </div>
                        </div>
                        </div>
                </div>

                <button class="button button-primary register-button"
                        @click.prevent="submitRegister()"
                        tabindex="8">{{ $translate(registerDialog) }}</button>
                <button class="button button-secondary"
                        @click.prevent="submitAsGuest()"
                        v-if="showPayAsGuestOption"
                        type="button"
                        tabindex="9">
                    {{ payAsGuestButtonText }}
                </button>
                <div v-if="submissionNotifications.guestPayErrored" class="interaction-feedback vue-messages">
                    <div v-if="submissionNotifications.guestPayErrored" class="vue-message">
                        {{ $translate('errors.guestPayNotAvailable') }}
                    </div>
                </div>
            </form>
        </div>

        <!-- LOGIN -->
        <div v-show="sections.showLogin">
            <form name="forms.loginForm" @submit.prevent novalidate>
                <!-- FORM SUBMISSION RESPONSE FEEDBACK -->
                <div v-if="submissionNotifications.loginFailed || submissionNotifications.userBlocked || submissionNotifications.resetPasswordComplete" class="interaction-feedback">
                    <div v-if="submissionNotifications.loginFailed" class="vue-message">
                        {{ $translate('errors.unableToLogin') }}
                    </div>
                    <div v-if="submissionNotifications.userBlocked" class="vue-message">
                        {{ $translate('errors.loginThrottled') }}
                    </div>
                    <div v-if="submissionNotifications.resetPasswordComplete" class="vue-message positive-feedback">
                        {{ $translate('login.resetPasswordCompleteDialog') }}
                    </div>
                </div>

                <!-- EMAIL -->
                <div class="input-area-wrapper" :class="{errored: forms.loginForm.email.$invalid && forms.loginForm.$submitted, 'input-focused': forms.loginForm.email.$focused}">
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.loginForm.email.$dirty }">
                            <base-icon icon="mdi-email"></base-icon>
                        </span>
                        <input class="fs-block input-with-icon" name="email" type="email" required id="login-email"
                                v-model="login.email" autocapitalize="off" maxlength="255"
                                @input="validateLoginEmail(); forms.loginForm.email.$dirty = true"
                                @focus="forms.loginForm.email.$focused = true"
                                @blur="forms.loginForm.email.$focused = false"
                                :placeholder="$translate('labels.email')"/>
                    </div>
                    
                    <div class="input-area-feedback">
                        <div v-if="forms.loginForm.email.$error" class="vue-messages">
                            <div v-if="forms.loginForm.email.errors.required" class="vue-message">
                                {{ $translate('errors.loginEmailRequired') }}
                            </div>
                            <div v-else-if="forms.loginForm.email.errors.email" class="vue-message">
                                {{ $translate('errors.email') }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PASSWORD -->
                <div class="input-area-wrapper" :class="{errored: forms.loginForm.password.$invalid && forms.loginForm.$submitted, 'input-focused': forms.loginForm.password.$focused}">
                    <div class="input-area-field-wrapper">
                        <span :class="{ 'icon-active': forms.loginForm.password.$dirty }">
                            <base-icon icon="mdi-lock"></base-icon>
                        </span>
                        <input class="fs-block input-with-icon" name="password" type="password" required id="login-pass"
                                :placeholder="$translate('labels.password')"
                                @input="validateLoginPassword(); forms.loginForm.password.$dirty = true"
                                @focus="forms.loginForm.password.$focused = true"
                                @blur="forms.loginForm.password.$focused = false"
                                v-model="login.password" />
                    </div>
                    
                    <div class="input-area-feedback">
                        <div v-if="forms.loginForm.password.$error" class="vue-messages">
                            <div v-if="forms.loginForm.password.errors.required" class="vue-message">
                                <div v-html="$translate((subdomain ? 'whitelabel.' : '') + 'errors.loginPasswordRequired')"></div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <button class="button button-primary" 
                        @click.prevent="submitLogin()">{{ $translate(loginDialog) }}</button>
                <button class="button button-secondary forgot-password"
                        @click.prevent="toggleForgotPassword()">{{ $translate('actions.forgotPassword') }}</button>
            </form>
        </div>
        
    </div>
    
    
    <!-- FORGOT PASSWORD -->
    <div v-show="sections.showForgotPassword">
        
        <h2 class="modal-header h-style-2">{{ $translate('login.forgotPasswordQuestion') }}</h2>

        <h3 class="modal-subheader">{{ $translate('login.forgotPasswordQuestionSubcontent') }}</h3>
        
        <form name="forms.forgotForm" @submit.prevent novalidate>
            
            <!-- EMAIL -->
            <div class="input-area-wrapper forgot-email-input" :class="{ errored: forms.forgotForm.email.$invalid && forms.forgotForm.$submitted, 'input-focused': forms.forgotForm.email.$focused }">
                
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.forgotForm.email.$dirty }">
                        
                        <base-icon icon="mdi-email"></base-icon>
                    </span>
                    <input class="fs-block input-with-icon" name="email" type="email" required maxlength="255" id="forgot-email"
                            v-model="forgotPassword.email" autocapitalize="off"
                            @input="validateForgotPassword(); forms.forgotForm.email.$dirty = true; $forceUpdate()"
                            @focus="forms.forgotForm.email.$focused = true; $forceUpdate()"
                            @blur="forms.forgotForm.email.$focused = false; $forceUpdate()"
                            @on-email-check-finished="emailRegisteredCheckFinished"
                            ng-model-options="{ updateOn: 'blur submit' }" 
                            :placeholder="$translate('labels.email')"/>
                </div>
                
                <div class="input-area-feedback">
                    <div v-if="forms.forgotForm.email.$error" class="vue-messages">
                        <div v-if="forms.forgotForm.email.errors.required" class="vue-message">
                            {{ $translate('errors.genericRequired') }}
                        </div>
                        <div v-if="forms.forgotForm.email.errors.email" class="vue-message">
                            {{ $translate('errors.email') }}
                        </div>
                        <div v-else-if="forms.forgotForm.email.errors.emailAvailable" class="vue-message">
                            {{ $translate('errors.nonexistentEmail') }}
                            <br><a href="#" @click.prevent="switchTo('create');">
                                    {{ $translate('actions.createAccount') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
            <button class="button button-primary"
                @click.prevent="submitForgot();">
                {{ $translate((retrieving ? 'actions.retrievingResetOptions' : 'login.nextButton')) }}
            </button>
        </form>
        
    </div>

    <!-- FORGOT PASSWORD METHOD SELECTION -->
    <div class="forgot method" v-show="sections.showForgotPasswordMethod">

        <h2 class="modal-header h-style-2">{{ $translate('login.forgotPasswordMethodHeader') }}</h2>

        <form name="forms.methodForm" @submit.prevent novalidate>
            <div class="contact-method">
                <button class="button button-primary button-block u-truncate fs-block forgot-password-email"
                    v-show="forgotPassword.hasVerifiedEmail"
                    :disabled="sendingForgotPassword"
                    @click.prevent="submitForgotMethod('email');">
                    {{ $translate('login.forgotPasswordMethodEmailButton', { email: forgotPassword.email }) }}
                </button>
                <button class="button button-primary button-block fs-block forgot-password-phone"
                    v-show="forgotPassword.smsAvailable"
                    @click.prevent="submitForgotMethod('sms');"
                    :disabled="sendingForgotPassword">
                    {{ $translate('login.forgotPasswordMethodSmsButton', { phone: forgotPassword.maskedPhone }) }}
                </button>
            </div>
        </form>

    </div>

    <!-- FORGOT PASSWORD CODE VERIFICATION -->
    <div class="forgot verify" v-show="sections.showForgotPasswordVerify">

        <h2 class="modal-header h-style-2">{{ $translate('login.forgotPasswordMethodCode') }}</h2>

        <form name="forms.verifyForm" @submit.prevent novalidate>

            <div class="input-area-wrapper" :class="{errored: (forms.verifyForm.code.errors.required || forms.verifyForm.code.errors.maxlength) &&  forms.verifyForm.$submitted, 'input-focused': forms.verifyForm.code.$dirty}">

                <div class="input-area-field-wrapper verification-code">
                    <input name="code" type="text" all-numeric required
                            :placeholder="$translate('labels.verificationCode')"
                            v-model="forgotPassword.code" tabindex="0"
                            @input="onInputVerify"
                            @focus="forms.verifyForm.code.$focused = true; $forceUpdate()"
                            @blur="forms.verifyForm.code.$focused = false; $forceUpdate()"
                            ng-model-options="{updateOn: 'default blur submit'}" maxlength="6" autocomplete="off" />
                </div>

                <div class="input-area-feedback">
                    <div v-if="forms.verifyForm.code.$error" class="vue-messages">
                        <div v-if="forms.verifyForm.code.errors.required" class="vue-message">
                            {{ $translate('errors.genericRequired') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-actions">
                <button type="button" class="button button-secondary" @click.prevent="sendAgain()">
                    {{ $translate('login.forgotPasswordMethodSendAgainButton') }}
                </button>
                <button class="button button-primary" @click.prevent="submitForgotVerify()">
                    {{ $translate('login.nextButton') }}
                </button>
            </div>
        </form>

    </div>

    <!-- FORGOT PASSWORD EMAIL SENT -->
    <div class="forgot emailsent" v-show="sections.showForgotPasswordEmailSent">

        <h3 class="modal-header h-style-3">{{ $translate('login.forgotPasswordCheckEmailTitle') }}</h3>

        <div class="fs-block" v-html="$translate('login.resetPasswordCheckEmailMessage', { email: forgotPassword.email })"></div>

        <br/>
        <button type="button" class="button button-secondary button-inline" @click.prevent="submitForgot()">
            {{ $translate('login.forgotPasswordEmailSentTryAgainButton') }}
        </button>
    </div>
    
    <!-- RESET PASSWORD -->
    <div v-show="sections.showUpdatePassword">
        
        <h2 class="modal-header h-style-2">{{ $translate('login.resetPasswordHeader') }}</h2>
        
        
        <form name="forms.resetPasswordForm" @submit.prevent novalidate>
            
            <!-- FORM SUBMISSION RESPONSE FEEDBACK -->
            <div v-if="resetNotifications.invalidAttempt" class="interaction-feedback">
                <div v-if="resetNotifications.invalidAttempt" class="vue-message">
                    {{ $translate('errors.unableToReset') }}
                </div>
            </div>
                            
            <!-- PASSWORD -->
            <div class="input-area-wrapper login-password-input-area"
                    :class="{
                            errored: forms.resetPasswordForm.password.$invalid && forms.resetPasswordForm.$submitted,
                            'login-password-incorect-blurred': forms.resetPasswordForm.password.$dirty && forms.resetPasswordForm.password.$invalid,
                            'input-focused': forms.resetPasswordForm.password.$dirty,
                            }">
                
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.resetPasswordForm.password.$dirty }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>
                    
                    <input required name="password" type="password" valid-password 
                            id="reset-pass" maxlength="255"
                            class="fs-block input-with-icon" :placeholder="$translate('labels.newPassword')"
                            v-model="resetPassword.password" tabindex="2"
                            @input="validateResetPassword(); forms.resetPasswordForm.password.$dirty = true"
                            @focus="forms.resetPasswordForm.password.$focused = true"
                            @blur="forms.resetPasswordForm.password.$focused = false"/>
                </div>
                
                <div class="input-area-rules password-reset">
                    <ul>
                        <li :class="{'rule-met': !forms.resetPasswordForm.password.errors.minCharactersRule}">
                            <span v-if="!forms.resetPasswordForm.password.errors.minCharactersRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-close"></base-icon>
                            </span>
                            {{ $translate('login.minCharactersRule') }}
                        </li>
                        <li :class="{'rule-met': !forms.resetPasswordForm.password.errors.upperCaseRule}">
                            <span v-if="!forms.resetPasswordForm.password.errors.upperCaseRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-close"></base-icon>
                            </span>
                            {{ $translate('login.upperCaseRule') }}
                        </li>
                        <li :class="{'rule-met': !forms.resetPasswordForm.password.errors.digitRule}">
                            <span v-if="!forms.resetPasswordForm.password.errors.digitRule" class="rule-met-icon">
                                <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                            </span>
                            <span v-else class="rule-met-icon">
                                <base-icon icon="mdi-close"></base-icon>
                            </span>
                            {{ $translate('login.digitRule') }}
                        </li>
                    </ul>
                </div>
            </div>
            
            
            <!-- CONFIRM PASSWORD -->
            <div class="input-area-wrapper"
                    :class="{errored: forms.resetPasswordForm.password.$valid && forms.resetPasswordForm.confirmPassword.$invalid && forms.resetPasswordForm.$submitted, 'input-focused': forms.resetPasswordForm.confirmPassword.$dirty}">
                <div class="input-area-field-wrapper">
                    <span :class="{ 'icon-active': forms.resetPasswordForm.confirmPassword.$dirty }">
                        <base-icon icon="mdi-lock"></base-icon>
                    </span>
                    
                    <input required name="confirmPassword" type="password" id="reset-con-pass"
                            match="resetPassword.password" valid-password
                            @input="validateResetConfirmPassword(); forms.resetPasswordForm.confirmPassword.$dirty = true"
                            @focus="forms.resetPasswordForm.confirmPassword.$focused = true"
                            @blur="forms.resetPasswordForm.confirmPassword.$focused = false"
                            class="fs-block input-with-icon" :placeholder="$translate('labels.passwordConfirm')"
                            v-model="resetPassword.confirmPassword" tabindex="3"/>
                </div>
                <div class="input-area-feedback">
                    <div v-if="forms.resetPasswordForm.confirmPassword.$error" class="vue-messages">
                        <div v-if="forms.resetPasswordForm.confirmPassword.errors.required" class="vue-message">
                            {{ $translate('errors.genericRequired') }}
                        </div>
                        <div v-else-if="forms.resetPasswordForm.confirmPassword.errors.mismatch" class="vue-message">
                            {{ $translate('errors.confirmPassword') }}
                        </div>
                    </div>
                </div>
            </div>

            <button class="button button-primary" 
                    tabindex="4"
                    @click.prevent="submitReset()">
                {{ resetDialog }}
            </button>
        </form>
    </div>

    <div v-show="sections.showExternalAccountManagement" class="external-account-management">
        <h2 class="modal-header h-style-2" v-html="$translate((subdomain ? 'whitelabel.' : '') + 'dialogs.welcome')"></h2>
        <button class="button button-primary" tabindex="1"  @click.prevent="goToExternalPortalLink()">
            {{ $translate('actions.logInWith', {portalName: portalName}) }}
        </button>
        <button class="button button-secondary" @click.prevent="submitAsGuest()" v-if="showPayAsGuestOption" type="button" tabindex="2">
            {{ payAsGuestButtonText }}
        </button>
        <div v-if="submissionNotifications.guestPayErrored" class="interaction-feedback vue-messages">
            <div v-if="submissionNotifications.guestPayErrored" class="vue-message">
                {{ $translate('errors.guestPayNotAvailable') }}
            </div>
        </div>
</div>
</modal>
</template>
<script>
import _ from 'lodash';

import { generateDeferredPromise, getValidName, validEmail } from '../utils/utils';
import Estimate from './../models/Estimate';
import Modal from './Modal.vue';
import Notification from './Notification.vue';
import BaseIcon from './BaseIcon.vue';
import BaseTooltip from './BaseTooltip.vue';

export default {
    name: 'LoginModal',
    components: {
        Modal,
        Notification,
        BaseIcon,
        BaseTooltip,
    },
    data: () => ({
        isVisible: false,
        // login
        login: {
            email: null,
            password: null,
            verifyPatientUserId: null,
            verifyToken: null,
        },
        loginDialog: 'actions.login',
        // register
        register: {
            email: null,
            phoneNumber: null,
            firstName: null,
            lastName: null,
            password: null,
            confirmPassword: null,
            receivesSms: false,
            acceptTerms: false,
        },
        registerDialog: 'actions.createAccount',
        payAsGuestButtonText: null,
        showPayAsGuestOption: false,
        guestPayVoucherNumber: null,
        sendingToBillPay: false,
        // forgot
        forgotPassword: {
            code: null,
            email: null,
            hasVerifiedEmail: false,
            maskedPhone: null,
            method: null,
            smsAvailable: false,
        },
        sendingForgotPassword: false,
        retrieveDialog: null,
        // reset
        resetDialog: null,
        resetting: false,
        resetPassword: {
            confirmPassword: null,
            password: null,
            token: null,
        },
        // overall
        loginNotVerified: false,
        deferWantingLogin: null,
        delayDeferWantingLogin: false,
        submitting: false,
        continuingSubmission: false,
        verifying: false,
        retrieving: false,
        forms: {
            createForm: {
                $error: false,
                $invalid: false,
                $submitted: false,
                confirmPassword: {
                    $dirty: false,
                    $focused: false,
                    $error: false,
                    errors: {
                        mismatch: false,
                        required: false,
                    },
                },
                email: {
                    $dirty: false,
                    $error: false,
                    $focused: false,
                    $invalid: false,
                    lastCheckedAvailable: null,
                    errors: {
                        email: false,
                        emailAvailable: false,
                        required: false,
                    },
                },
                firstName: {
                    $dirty: false,
                    $focused: false,
                    $error: false,
                    errors: {
                        required: false,
                    },
                },
                lastName: {
                    $dirty: false,
                    $focused: false,
                    $error: false,
                    errors: {
                        required: false,
                    },
                },
                password: {
                    $dirty: false,
                    $focused: false,
                    $error: true,
                    $invalid: true,
                    errors: {
                        digitRule: true,
                        minCharactersRule: true,
                        required: false,
                        upperCaseRule: true,
                    },
                },
                phone: {
                    $dirty: false,
                    $focused: false,
                    $error: false,
                    lastCheckedAvailable: null,
                    errors: {
                        minlength: false,
                        smsEligible: false,
                    },
                },
                receivesSms: {
                    $error: false,
                    $invalid: false,
                },
                tou: {
                    $error: false,
                    errors: {
                        required: false,
                    },
                }
            },
            forgotForm: {
                $error: false,
                $invalid: false,
                email: {
                    $error: false,
                    $invalid: false,
                    errors: {
                        required: false,
                        email: false,
                        emailAvailable: false,
                    },
                },
            },
            loginForm: {
                $invalid: false,
                $submitted: false,
                email: {
                    $dirty: false,
                    $error: false,
                    $focused: false,
                    errors: {
                        required: false,
                        email: false,
                    },
                },
                password: {
                    $dirty: false,
                    $error: false,
                    $focused: false,
                    errors: {
                        required: false,
                    },
                },
            },
            resetPasswordForm: {
                $invalid: true,
                confirmPassword: {
                    $dirty: false,
                    $error: false,
                    errors: {
                        required: false,
                    },
                },
                password: {
                    $dirty: false,
                    $error: true,
                    $invalid: true,
                    $valid: false,
                    errors: {
                        digitRule: true,
                        minCharactersRule: true,
                        required: false,
                        upperCaseRule: true,
                    },
                },
            },
            verifyForm: {
                $invalid: false,
                code: {
                    $error: false,
                    errors: {
                        required: false,
                    },
                },
            },
        },
        resetNotifications: {
            invalidAttempt: false,
        },
        submissionNotifications: {
            guestPayErrored: false,
            loginFailed: false,
            resetPasswordComplete: false,
            userBlocked: false,
        },
        sections: {
            showLogin: false,
            showForgotPassword: false,
            showForgotPasswordMethod: false,
            showForgotPasswordVerify: false,
            showUpdatePassword: false,
            showForgotPasswordEmailSent: false,
            showExternalAccountManagement: false,
        },
        serverErrorMessage: null,
        serverError: false,
        codeNotSent: false,
        codeInvalid: false,
        portalName: null,
        portalLink: null,
    }),
    computed: {
        currentBill() {
            return this.$store.getters.currentBill;
        },
        currentEstimate() {
            return this.$store.getters.currentEstimate;
        },
        isFirstBill() {
            return this.$store.getters.isFirstBill;
        },
        isFirstEstimate() {
            return this.$store.getters.isFirstEstimate;
        },
        brandingConfig() {
            return this.$store.getters.brandingConfig;
        },
        subdomain() {
            return this.$store.getters.subdomain;
        },
        FS() {
            return this.$store.getters.getFullstory;
        },
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        currentPaymentData() {
            return this.$store.getters.currentPaymentData;
        },
    },
    methods: {
        setFormFocus(fieldId) {
            window.setTimeout(() => {
                document.getElementById(fieldId).focus();
            });
        },
        submitForgot() {
            this.forms.forgotForm.$submitted = true;
            this.validateForgotPassword();
            // reset submitting status if we are invalid but resuming
            if (this.forms.forgotForm.$invalid && this.retrieving){
                this.retrieving = false;
                this.retrieveDialog = this.$translate('actions.retrievePassword');
            }

            // handle making sure we dont resubmit
            if (this.forms.forgotForm.$invalid || (this.retrieving && !this.continuingSubmission)){
                return;
            }

            // tell the user we are processing
            this.retrieving = true;
            this.retrieveDialog = this.$translate('labels.retrievingPassword');

            // submit data
            this.$store.dispatch('forgotPassword', this.forgotPassword.email)
                .then((resp) => {
                    this.FS.log('log', 'session user LoginCtrl::forgotPassword.then');

                    this.forgotPassword.hasVerifiedEmail = resp.data.hasVerifiedEmail !== false;
                    this.forgotPassword.smsAvailable = resp.data.hasVerifiedPhone;
                    this.forgotPassword.maskedPhone = resp.data.maskedPhone;

                    if (this.forgotPassword.hasVerifiedEmail !== false) {
                        this.applyOptions({
                            openSection: 'forgotPasswordMethod'
                        });
                    } else {
                        this.forms.forgotForm.email.$error = true;
                        this.forms.forgotForm.email.$invalid = true;
                        this.forms.forgotForm.email.errors = {
                            emailAvailable: true
                        };
                    }
                })
                .finally(() => {
                    this.retrieveDialog = this.$translate('actions.retrievePassword');
                    this.retrieving = false;
                });
            },
            async submitRegister() {
                this.forms.createForm.$submitted = true;
                await this.validateRegister();
                // reset submitting status if we are invalid but resuming
                if (this.forms.createForm.$invalid && this.submitting){
                    this.submitting = false;
                    this.registerDialog = 'actions.createAccount';
                }

                // supress duplicate submission attempts
                if (this.forms.createForm.$invalid || (this.submitting && !this.continuingSubmission)){
                    return;
                }

                this.submitting = true;
                this.registerDialog = 'actions.creatingAccount';

                this.$store.dispatch('register', this.register).then((user) => {
                    this.onLoginOrRegisterSuccess(user);
                })
                .catch((resp) =>{
                    if (resp.errorCode === 'LOGIN_NOT_VERIFIED') {
                        this.loginNotVerified = true;
                        return this.onLoginNotVerified(this.register.email);
                    }

                    console.error(resp);
                    this.submitting = false;
                    this.registerDialog = 'actions.createAccount';
                });
        },
        submitAsGuest() {
            this.$store.commit('setIsGuestPay', true);
            if (this.currentPaymentData.subAccount) {
                this.$router.push({
                    name: 'GuestSubAccountPaymentFlow',
                    params: {
                        accountId: this.currentPaymentData.account.accountId,
                        providerId: this.currentPaymentData.provider.id,
                        subAccountId: this.currentPaymentData.subAccount.id,
                    },
                });
            } else {
                this.$router.push({
                    name: 'GuestAccountPaymentFlowAmountStep',
                    params: {
                        accountId: this.currentPaymentData.account.accountId,
                        providerId: this.currentPaymentData.provider.id
                    },
                });
            }
            this.emitter.emit('login:closePrompt');
        },
        submitLogin() {
            this.forms.loginForm.$submitted = true;
            this.validateLogin();
            this.submissionNotifications = {};

            if (this.forms.loginForm.$invalid || this.submitting){
                return;
            }

            this.submitting = true;
            this.loginDialog = 'actions.loggingIn';

            this.$store.dispatch('logIn', this.login)
                .then((user) => {
                    this.onLoginOrRegisterSuccess(user);
                })
                .catch((resp) => {
                    if ('INVALID_PARAMETERS' === resp.errorCode) {
                        this.submissionNotifications.loginFailed = true;
                    } else if ('LOGIN_BLOCKED' === resp.errorCode) {
                        this.submissionNotifications.userBlocked = true;
                    } else if ('LOGIN_NOT_VERIFIED' === resp.errorCode) {
                        this.loginNotVerified = true;
                        return this.onLoginNotVerified(this.login.email)
                    }

                    this.login.password = '';
                    this.forms.loginForm.$submitted = false;
                    this.submitting = false;
                    this.loginDialog = 'actions.login';
                });
        },
        toggleForgotPassword() {
            // if the user has inputed an email from
            // another form but we don't have one assigned to the
            // forget form yet, we will use that as the initial value
            if (!this.forgotPassword.email){
                this.forgotPassword.email = this.login.email || this.register.email || '';
            }

            this.applyOptions({
                openSection: 'forgotPassword'
            });
        },
        switchTo(view) {
            this.resetForms();
            this.applyOptions({ openSection: view });
        },
        submitForgotMethod(method) {
            this.forgotPassword.method = method;
            this.FS.event('session user LoginCtrl::submitForgotMethod', {
                method: method
            });
            var forgotPasswordPromise, openSection = '';
            switch(method){
                case 'sms':
                    this.sendingForgotPassword = true;
                    forgotPasswordPromise = this.$store.dispatch('forgotPasswordGetCode', this.forgotPassword.email);
                    openSection = 'forgotPasswordVerify';
                    break;
                case 'email':
                    this.sendingForgotPassword = true;
                    forgotPasswordPromise = this.$store.dispatch('forgotPasswordSendEmail', this.forgotPassword.email);
                    openSection = 'forgotPasswordEmailSent';
                    break;
                default:
                    this.codeNotSent = true;
                    window.setTimeout(() => {
                        this.codeNotSent = false;
                    }, 15000);
                    return;
            }

            forgotPasswordPromise.then(() => {
                this.applyOptions({
                    openSection: openSection,
                });
            })
            .catch((resp) => {
                if(resp.hasData && !!resp.getData().error) {
                    if ('INVALID_PARAMETERS' === resp.getData().error) {
                        this.serverErrorMessage = this.$translate('errors.forgotPasswordInvalidParameters');
                    } else {
                        this.serverErrorMessage = resp.getData().error;
                    }
                    this.serverError = true;
                    window.setTimeout(() => {
                        this.serverError = false;
                    }, 15000);
                } else {
                    this.codeNotSent = true;
                    window.setTimeout(() => {
                        this.codeNotSent = false;
                    }, 15000);
                }
            }).finally(() => {
                this.sendingForgotPassword = false;
            });
        },
        sendAgain() {
            this.applyOptions({
                openSection: 'forgotPasswordMethod',
            });
        },
        setPristine(formName) {
            Object.keys(this.forms[formName]).forEach((fieldKey) => {
                if (typeof this.forms[formName][fieldKey] !== 'object') {
                    return;
                }
                const field = this.forms[formName][fieldKey];
                field.$dirty = false;
                field.$focused = false;
                field.$error = false;
                field.$invalid = false;
                field.$active = false;
            });
            this.forms[formName].$error = false;
            this.forms[formName].$invalid = false;
            this.forms[formName].$submitted = false;
        },
        submitForgotVerify() {
            this.validateVerify();
            if (this.forms.verifyForm.$invalid || this.verifying) {
                return;
            }

            this.verifying = true;

            this.FS.event('session user LoginCtrl::submitForgotVerify', {
                method: this.forgotPassword.method,
                code: this.forgotPassword.code
            });

            this.$store.dispatch('forgotPasswordVerifyCode', { email: this.forgotPassword.email, method: this.forgotPassword.method, code: this.forgotPassword.code })
                .then((resp) => {
                    // this.resetPassword.email = this.forgotPassword.email;
                    this.resetPassword.token = resp.token;

                    this.applyOptions({
                        openSection: 'resetPassword'
                    });
                })
                .catch((resp) => {
                    if(resp.hasData && !!resp.getData().error) {
                        if ('INVALID_PARAMETERS' === resp.getData().error) {
                            this.serverErrorMessage = this.$translate('errors.forgotPasswordInvalidParameters');
                        } else {
                            this.serverErrorMessage = resp.getData().error;
                        }
                        this.serverError = true;
                        window.setTimeout(() => {
                            this.serverError = false;
                        }, 15000);
                    } else {
                        this.codeInvalid = true;
                        window.setTimeout(() => {
                            this.codeInvalid = false;
                        }, 15000);
                    }
                    this.forgotPassword.code = '';
                    this.setPristine('verifyForm');
                })
                .finally(() => {
                    this.verifying = false;
                });
        },
        submitReset() {
            this.forms.resetPasswordForm.$submitted = true;
            this.validateReset();
            //if we're getting here from the legacy email route, grab the token from the state params
            if (!this.resetPassword.token) {
                // #/forgot/:tokenId
                console.error("Using legacy email password reset route");
                this.resetPassword.token = this.$route.params.tokenId ?? window.location.hash.split('/')[2];
            }

            if (this.forms.resetPasswordForm.$invalid || this.resetting){
                return;
            }

            this.resetting = true;
            this.resetDialog = this.$translate('labels.resettingPassword');

            // var email = this.resetPassword.email;

            this.$store.dispatch('resetPassword', { tokenId: this.resetPassword.token, password: this.resetPassword.password })
                .then(() => {

                    // clean all of the forms to make sure there are
                    // no other error states
                    this.resetForms();


                    // we we transition to login, make sure the email that we used to reset is there
                    // this.login.email = email;

                    // add dialog to let the user know to login
                    this.submissionNotifications.resetPasswordComplete = true;

                    this.applyOptions({
                        openSection: 'login'
                    });

                })
                .catch((resp) => {
                    if ('INVALID_PARAMETERS' === resp.errorCode) {
                        this.resetNotifications.invalidAttempt = true;
                    } else if ('INVALID_TOKEN' === resp.errorCode) {
                        this.resetNotifications.invalidAttempt = true;
                    }
                })
                .finally(() => {

                    //remove the forgot details from the url
                    if (window.location.hash.includes('#/forgot/') && window.location.hash.lenght > '#/forgot'.length) {
                        this.$router.replace({name: 'Home'});
                    }

                    this.resetting = false;
                    this.resetDialog = this.$translate('actions.resetPassword');
                });
        },
        resetForms() {
            this.register = {
                receivesSms: false
            };
            this.login = {
                email: '',
                password: ''
            };
            this.forgotPassword = {};
            this.resetPassword = {};

            this.submissionNotifications = {};
            this.resetNotifications = {};

            this.setPristine('loginForm');
            this.setPristine('createForm');
            this.setPristine('forgotForm');
            this.setPristine('verifyForm');
            this.setPristine('resetPasswordForm');

            this.codeInvalid = false;
            this.codeNotSent = false;
            this.serverError = false;

            this.validateRegister();
            this.validateLogin();
            this.validateForgotPassword();
            this.validateReset();
            this.validateVerify();
            this.loginDialog = 'actions.login';
            this.forms.loginForm.$submitted = false;
            this.submitting = false;
        },
        onLoginNotVerified(email) {
            //If the user was trying to link a bill/estimate pre-login, let's link it to the user in case
            //the user doesn't complete the email verification flow now
            this.linkCurrentBill();
            this.linkCurrentEstimate();
            
            //Now let's further defer the deferred promise until after the email is verified
            this.delayDeferWantingLogin = true;
            this.isVisible = false;
            var deferred = generateDeferredPromise();
            this.emitter.emit('verification:prompt', {
                email: email,
                defer: deferred
            });
            return deferred.promise.then((user) => {
                this.onLoginOrRegisterSuccess(user);
            });
        },
        applyOptions(options) {
            // inside this function will list all of the options we care about

            // selects the default section to open upon viewing the modal
            this.sections.showLogin = false;
            this.sections.showForgotPassword = false;
            this.sections.showForgotPasswordMethod = false;
            this.sections.showForgotPasswordVerify = false;
            this.sections.showUpdatePassword = false;
            this.sections.showForgotPasswordEmailSent = false;
            if(options.openSection){

                options.openSection = options.openSection.toLowerCase();

                if (options.openSection === 'login'){
                    this.sections.showLogin = true;
                } else if (options.openSection === 'forgotpassword') {
                    this.sections.showForgotPassword = true;
                } else if (options.openSection === 'forgotpasswordmethod') {
                    this.sections.showForgotPasswordMethod = true;
                } else if (options.openSection === 'forgotpasswordverify') {
                    this.sections.showForgotPasswordVerify = true;
                } else if (options.openSection === 'resetpassword') {
                    this.sections.showUpdatePassword = true;
                    if (options.token) {
                        this.resetPassword.token = options.token;
                        this.passwordResetInProgress = true;
                    }
                } else if (options.openSection === 'forgotpasswordemailsent') {
                    this.sections.showForgotPasswordEmailSent = true;
                }
            }
            if (options.prefillEmail) {
                this.login.email = options.prefillEmail;
            }

            if (options.verifyToken && options.verifyPatientUserId) {
                this.login.verifyToken = options.verifyToken;
                this.login.verifyPatientUserId = options.verifyPatientUserId;
            }
        },
        linkCurrentBill() {
            if (this.currentBill) {
                this.$store.dispatch('linkBill', {
                    sCode: this.currentBill.secureCode,
                    amount: this.currentBill.billAmount,
                });
            }
        },
        linkCurrentEstimate() {
            if (this.currentEstimate) {
                this.$store.dispatch('linkEstimate', this.currentEstimate);
            }
        },
        onLoginOrRegisterSuccess(user) {
            if(user) {
                this.loginNotVerified = false;
                
                var currentBill = this.currentBill;
                var currentEstimate = this.currentEstimate;
                var recipient = currentBill || currentEstimate;
                var confirmNewPassword = this.passwordResetInProgress;

                var find = (recipient) => {
                    if (recipient && recipient instanceof Estimate) {
                        return this.$store.dispatch('linkEstimate', recipient); // moving linking earlier to right after login/register success
                    } else if (recipient) {
                        return this.$store.dispatch('findBill', { secureCode: recipient.secureCode, billAmount: recipient.billAmount });
                    } else if (confirmNewPassword) {
                        this.$router.push({name: 'ProvidersSummary'});
                        return Promise.resolve();
                    } else {
                        return Promise.resolve();
                    }
                };

                // If we can't refresh data, let's silently fail so a user can still make a payment.
                return find(recipient)
                    .catch(() => {})
                    .finally(() => {
                        if (this.deferWantingLogin){
                            this.deferWantingLogin.resolve(user);
                        }
                        this.cleanup();
                    });
            }
        },
        cleanup() {
            this.isVisible = false;
            this.resetForms();
        },

        validateLogin() {
            this.validateLoginEmail();
            this.validateLoginPassword();
            this.forms.loginForm.$error = this.forms.loginForm.email.$error 
                || this.forms.loginForm.password.$error;
            this.forms.loginForm.$invalid = this.forms.loginForm.$error;
        },
        validateLoginEmail() {
            this.forms.loginForm.email.errors.required = !Boolean(this.login.email);
            this.forms.loginForm.email.errors.email = !validEmail(this.login.email);
            this.forms.loginForm.email.$error = this.forms.loginForm.email.errors.required
                || this.forms.loginForm.email.errors.email;
            this.forms.loginForm.email.$invalid = this.forms.loginForm.email.$error;
        },
        validateLoginPassword() {
            this.forms.loginForm.password.errors.required = !Boolean(this.login.password);
            this.forms.loginForm.password.$error = this.forms.loginForm.password.errors.required;
            this.forms.loginForm.password.$invalid = this.forms.loginForm.password.errors.required;
        },

        onInputRegisterFirstName() {
            this.register.firstName = getValidName(this.register.firstName);
            this.validateRegisterFirstName();
        },
        onInputRegisterLastName() {
            this.register.lastName = getValidName(this.register.lastName);
            this.validateRegisterLastName();
        },
        onInputRegisterPhone(e) {
            this.forms.createForm.phone.$dirty = true
            if (e.inputType !==  'deleteContentBackward') {
                let nums = (e.target.value || '').toString().replace(/(\s|\D)/g, '');
                nums = nums.substring(Math.max(0, nums.length - 10));
                if (nums.length >= 6) {
                    this.register.phoneNumber = nums.slice(0, 3) + '-' + nums.slice(3, 6) + '-' + nums.slice(6);
                } else if (nums.length >= 3) {
                    this.register.phoneNumber = nums.slice(0, 3) + '-' + nums.slice(3);
                } else {
                    this.register.phoneNumber = nums;
                }
            }
            this.validateRegisterPhone();
        },
        async validateRegister() {
            this.validateRegisterFirstName();
            this.validateRegisterLastName();
            this.validateRegisterEmail();
            this.validateRegisterPassword();
            this.validateRegisterConfirmPassword();
            this.validateRegisterTou();
            await this.validateRegisterEmailAvailable();
            await this.validateRegisterPhone();
    
            this.forms.createForm.$error = this.forms.createForm.firstName.$error 
                || this.forms.createForm.lastName.$error
                || this.forms.createForm.email.$error
                || this.forms.createForm.phone.$error
                || this.forms.createForm.password.$error
                || this.forms.createForm.confirmPassword.$error
                || this.forms.createForm.tou.$error;
            this.forms.createForm.$invalid = this.forms.createForm.firstName.$invalid 
                || this.forms.createForm.lastName.$invalid
                || this.forms.createForm.email.$invalid
                || this.forms.createForm.phone.$error
                || this.forms.createForm.password.$invalid
                || this.forms.createForm.confirmPassword.$invalid
                || this.forms.createForm.tou.$invalid;

            this.$forceUpdate();
        },
        validateRegisterFirstName() {
            this.forms.createForm.firstName.errors.required = !Boolean(this.register.firstName);
            this.forms.createForm.firstName.$error = this.forms.createForm.firstName.errors.required;
            this.forms.createForm.firstName.$invalid = this.forms.createForm.firstName.errors.required;
        },
        validateRegisterLastName() {
            this.forms.createForm.lastName.errors.required = !Boolean(this.register.lastName);
            this.forms.createForm.lastName.$error = this.forms.createForm.lastName.errors.required;
            this.forms.createForm.lastName.$invalid = this.forms.createForm.lastName.errors.required;
        },
        validateRegisterEmail() {
            this.forms.createForm.email.errors.required = !Boolean(this.register.email);
            this.forms.createForm.email.errors.email = !validEmail(this.register.email);
            this.forms.createForm.email.$error = this.forms.createForm.email.errors.required
                || this.forms.createForm.email.errors.email
                || this.forms.createForm.email.errors.emailAvailable;
            this.forms.createForm.email.$invalid = this.forms.createForm.email.$error;
        },
        async validateRegisterEmailAvailable() {
            if (this.forms.createForm.email.lastCheckedAvailable !== this.register.email) {
                if (validEmail(this.register.email)) {
                    try {
                        this.forms.createForm.email.errors.emailAvailable = !(await this.$store.dispatch('emailAvailable', { email: this.register.email, isUpdate: false }));
                    } catch {
                        this.forms.createForm.email.errors.emailAvailable = true;
                    }
                    this.forms.createForm.email.lastCheckedAvailable = this.register.email;
                }
            }
            this.forms.createForm.email.$error = this.forms.createForm.email.errors.required
                || this.forms.createForm.email.errors.email
                || this.forms.createForm.email.errors.emailAvailable;
            this.forms.createForm.email.$invalid = this.forms.createForm.email.$error;
        },
        async validateRegisterPhone() {
            this.forms.createForm.phone.errors.minlength = (this.register.phoneNumber || '').length < 12 && (this.register.phoneNumber || '').length > 0;
            if (!this.forms.createForm.phone.errors.minlength && (12 === (this.register.phoneNumber || '').length)) {
                if (this.register.phoneNumber !== this.forms.createForm.phone.lastCheckedAvailable) {
                    try {
                        await this.$store.dispatch('getSmsEligibility', this.register.phoneNumber.replace(/(\s|\D)/g, ''));
                        this.forms.createForm.phone.errors.smsEligible = false;
                    } catch {
                        this.forms.createForm.phone.errors.smsEligible = true;
                    }
                    this.forms.createForm.phone.lastCheckedAvailable = this.register.phoneNumber;
                }
            } else {
                this.forms.createForm.phone.errors.smsEligible = false;
                this.forms.createForm.phone.lastCheckedAvailable = null;
            }
            this.forms.createForm.phone.$error = this.forms.createForm.phone.errors.minlength
                || this.forms.createForm.phone.errors.smsEligible;
            this.forms.createForm.phone.$invalid = this.forms.createForm.phone.$error;
        },
        validateRegisterPassword() {
            this.forms.createForm.password.errors.required = !Boolean(this.register.password);
            this.forms.createForm.password.errors.minCharactersRule = (this.register.password || '').trim().length < 8;
            this.forms.createForm.password.errors.upperCaseRule = !/[A-Z]+/.test((this.register.password || '').trim());
            this.forms.createForm.password.errors.digitRule = !/\d+/.test((this.register.password || '').trim());
            this.forms.createForm.password.$error = this.forms.createForm.password.errors.required
                || this.forms.createForm.password.errors.minCharactersRule
                || this.forms.createForm.password.errors.upperCaseRule
                || this.forms.createForm.password.errors.digitRule;
            this.forms.createForm.password.$invalid = this.forms.createForm.password.$error;
            this.forms.createForm.password.$valid = !this.forms.createForm.password.$invalid;
        },
        validateRegisterConfirmPassword() {
            this.forms.createForm.confirmPassword.errors.required = !Boolean(this.register.confirmPassword);
            this.forms.createForm.confirmPassword.errors.mismatch = this.register.password !== this.register.confirmPassword;
            this.forms.createForm.confirmPassword.$error = this.forms.createForm.confirmPassword.errors.required
                || this.forms.createForm.confirmPassword.errors.mismatch;
            this.forms.createForm.confirmPassword.$invalid = this.forms.createForm.confirmPassword.$error;
        },
        validateRegisterTou() {
            this.forms.createForm.tou.errors.required = !Boolean(this.register.acceptTerms);
            this.forms.createForm.tou.$error = this.forms.createForm.tou.errors.required;
            this.forms.createForm.tou.$invalid = this.forms.createForm.tou.errors.required;
        },

        validateForgotPassword() {
            this.forms.forgotForm.email.errors.required = !Boolean(this.forgotPassword.email);
            this.forms.forgotForm.email.errors.email = !validEmail(this.forgotPassword.email);
            this.forms.forgotForm.email.$error = this.forms.forgotForm.email.errors.required
                || this.forms.forgotForm.email.errors.email
                || this.forms.forgotForm.email.errors.emailAvailable;
            this.forms.forgotForm.email.$invalid = this.forms.forgotForm.email.$error;
            this.forms.forgotForm.$error = this.forms.forgotForm.email.$error;
            this.forms.forgotForm.$invalid = this.forms.forgotForm.email.$error;
        },

        onInputVerify() {
            this.forms.verifyForm.code.$dirty = true;
            this.forgotPassword.code = (this.forgotPassword.code || '').replace(/(\s|\D)/g, '');
            this.validateVerify();
            this.$forceUpdate();
        },
        validateVerify() {
            this.forms.verifyForm.code.errors.required = !Boolean(this.forgotPassword.code);
            this.forms.verifyForm.code.$error = this.forms.verifyForm.code.errors.required;
            this.forms.verifyForm.code.$invalid = this.forms.verifyForm.code.$error;
            this.forms.verifyForm.$error = this.forms.verifyForm.code.$error;
            this.forms.verifyForm.$invalid = this.forms.verifyForm.$error;
        },

        validateReset() {
            this.validateResetPassword();
            this.validateResetConfirmPassword();
            this.forms.resetPasswordForm.$error = this.forms.resetPasswordForm.password.$error
                || this.forms.resetPasswordForm.confirmPassword.$error;
            this.forms.resetPasswordForm.$invalid = this.forms.resetPasswordForm.$error;
            this.$forceUpdate();
        },
        validateResetPassword() {
            this.forms.resetPasswordForm.password.errors.required = !Boolean(this.resetPassword.password);
            this.forms.resetPasswordForm.password.errors.minCharactersRule = (this.resetPassword.password || '').trim().length < 8;
            this.forms.resetPasswordForm.password.errors.upperCaseRule = !/[A-Z]+/.test((this.resetPassword.password || '').trim());
            this.forms.resetPasswordForm.password.errors.digitRule = !/\d+/.test((this.resetPassword.password || '').trim());
            this.forms.resetPasswordForm.password.$error = this.forms.resetPasswordForm.password.errors.required
                || this.forms.resetPasswordForm.password.errors.minCharactersRule
                || this.forms.resetPasswordForm.password.errors.upperCaseRule
                || this.forms.resetPasswordForm.password.errors.digitRule;
            this.forms.resetPasswordForm.password.$invalid = this.forms.resetPasswordForm.password.$error;
            this.forms.resetPasswordForm.password.$valid = !this.forms.resetPasswordForm.password.$invalid;
        },
        validateResetConfirmPassword() {
            this.forms.resetPasswordForm.confirmPassword.errors.required = !Boolean(this.resetPassword.confirmPassword);
            this.forms.resetPasswordForm.confirmPassword.errors.mismatch = this.resetPassword.password !== this.resetPassword.confirmPassword;
            this.forms.resetPasswordForm.confirmPassword.$error = this.forms.resetPasswordForm.confirmPassword.errors.required
                || this.forms.resetPasswordForm.confirmPassword.errors.mismatch;
            this.forms.resetPasswordForm.confirmPassword.$invalid = this.forms.resetPasswordForm.confirmPassword.$error;
        },
        goToExternalPortalLink() {
            window.location.href = this.portalLink;
        },
    },
    created() {
        this.submitting = false;
        this.sendingToBillPay = false;
        this.resumbitPostAsyncValidation = false;
        this.registerDialog = 'actions.createAccount';
        this.loginDialog = 'actions.login';
        this.payAsGuestButtonText = this.$translate('actions.payAsGuest');
        this.resetDialog = this.$translate('actions.resetPassword');
        this.payAsGuestButtonText = this.$translate('actions.payAsGuest');
        this.retrieveDialog = this.$translate('actions.retrievePassword');

        this.emitter.on('login:showPrompt', ({defer, options}) => {
            if (this.loginNotVerified) {
                return this.onLoginNotVerified(this.login.email);
            }

            if (!defer){
                console.error('Pass a deferred object via $emit');
            }

            this.resetForms();

            this.deferWantingLogin = defer;
            this.delayDeferWantingLogin = false;

            var currentBill = this.currentBill;
            this.showPayAsGuestOption = currentBill && options && options.canShowGuestPay && currentBill.accountBalance.amount > 0;
            this.guestPayVoucherNumber = options.guestPayVoucherNumber;

            if (!_.isEmpty(options)){
                this.applyOptions(options);
            } else {
                //defaults
                this.sections.showLogin = false;
                this.sections.showForgotPassword = false;
                this.sections.showForgotPasswordMethod = false;
                this.sections.showForgotPasswordVerify = false;
                this.sections.showUpdatePassword = false;
                this.sections.showForgotPasswordEmailSent = false;
                this.sections.showExternalAccountManagement = false;
            }

            if (this.isFirstBill) {
                this.register.email = currentBill.guarantorDetails.email;
                this.register.token = currentBill.token;
                this.login.email = currentBill.guarantorDetails.email;
            }
            if (this.isFirstEstimate) {
                this.register.email = this.currentEstimate.guarantorDetails.email;
                this.register.token = this.currentEstimate.token;
                this.login.email = this.currentEstimate.guarantorDetails.email;
            }

            if (this.sections.showLogin) {
                this.setFormFocus('login-email');
            } else {
                this.setFormFocus('register-first');
            }

            if (this.brandingConfig && this.brandingConfig.externalAccountManagement) {
                this.sections.showLogin = false;
                this.sections.showForgotPassword = false;
                this.sections.showForgotPasswordMethod = false;
                this.sections.showForgotPasswordVerify = false;
                this.sections.showUpdatePassword = false;
                this.sections.showForgotPasswordEmailSent = false;
                this.sections.showExternalAccountManagement = true;
                this.portalName = this.brandingConfig.portalName;
                this.portalLink = this.brandingConfig.portalLink;
            } else if (currentBill && currentBill.providerDetails && currentBill.providerDetails.externalAccountManagement) {
                this.sections.showLogin = false;
                this.sections.showForgotPassword = false;
                this.sections.showForgotPasswordMethod = false;
                this.sections.showForgotPasswordVerify = false;
                this.sections.showUpdatePassword = false;
                this.sections.showForgotPasswordEmailSent = false;
                this.sections.showExternalAccountManagement = true;
                this.portalName = currentBill.providerDetails.portalName;
                this.portalLink = currentBill.providerDetails.portalLink;
            }

            if (this.sections.showExternalAccountManagement) {
                let context = {};
                if (currentBill) {
                    context.bill = {
                        amount: currentBill.billAmount,
                        secureCode: currentBill.secureCode
                    };
                } else {
                    context.bill = options.bill;
                }
                context.state = options.state;
                context.params = options.params;
                context.emit = options.emit;
                sessionStorage.setItem("externalSSO", JSON.stringify(context));
            }

            this.isVisible = true;
        });

        this.emitter.on('login:closePrompt', () => {
            this.resetForms();
            this.isVisible = false;
        });

        this.emitter.on('$stateChangeSuccess', () => {
            this.submitting = false;
            this.registerDialog = 'actions.createAccount';
            this.loginDialog = 'actions.login';
        });
    },
};
</script>
<style lang="scss">
.external-account-management {
    min-width: 80%;
    max-width: 80%;
    margin: 0 auto;
}
.notification-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .v-icon {
        flex-grow: 0;
    }
    div {
        flex-grow: 1;
        margin: 10px;
    }
}
.sms-consent {
    display: inline;
    padding-left: 5px;
}
.create-account-terms {
    line-height: 12px;
}
</style>