<template>
<modal :show="isVisible" :can-close="false" class="terms-modal-container no-close">
    <div class="terms-modal">

        <h2 class="modal-subheader" v-html="$translate('dialogs.termsUpdated')"></h2>

        <p class="modal-subcontent text-left small" v-html="$translate('dialogs.updatesToTermsText')"></p>

        <button class="button-primary button-modal-inline" @click="onClickAcceptTerms()">{{ $translate('actions.accept') }}</button>

    </div>
</modal>
</template>
<script>


import Modal from './Modal.vue';

export default {
    name: 'TermsOfServiceModal',
    components: {
        Modal,
    },
    data: () => ({
        isVisible: false,
    }),
    computed: {
        config() {
            return this.$store.getters.config;
        }
    },
    methods: {
        onClickAcceptTerms() {
            this.$store.dispatch('acceptTerms');
            this.isVisible = false;
        },
    },
    created() {
        this.emitter.on('user:changed', (user) => {
            if (user && !user.latestTOSVersion && !user.bdLogin) {
                this.isVisible = true;
            }
        });
    },
};
</script>
<style lang="scss">
</style>