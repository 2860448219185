<template>
<v-banner v-bind="$attrs" class="base-banner notification-banner">
    <v-banner-text><slot></slot></v-banner-text>
</v-banner>
</template>

<script>
export default {
    name: 'BaseBanner',
    props: {
        color: {
            type: String,
            default: '#F9BD27'
        }
    },
};
</script>
<style lang="scss">
@import './../styles/variables.scss';
.notification-banner .v-banner {
	position: relative;
	width: 100%;
	display: flex;
	text-align: center;
    justify-content: center;
    margin: 0 auto;
    background-color: #F9BD27;
    font-size: 1.5rem;
    color: $global-color-font-new;

    .v-banner-text {
        padding-right: 0;
    }

    .v-banner__content {
        display: flex;
        justify-content: center;
    }
}
</style>