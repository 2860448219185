<template>
    <div @click="onClick" class="patientco-waystar-logo">
        <base-icon icon="custom:PatientcoWaystarSVG"></base-icon>
    </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'PatientcoWaystarLogo',
    components: {
        BaseIcon
    },
    data: () => ({
        timesClicked: 0,
    }),
    props: {
        footer: Boolean,
    },
    methods: {
        onClick() {
            if (this.footer) {
                this.timesClicked++;
                if (this.timesClicked >= 3) {
                    this.emitter.emit('show-browser-info');
                    this.timesClicked = 0;
                }
            }
        }
    }
};
</script>