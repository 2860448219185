<template>
    <v-bottom-sheet :modelValue="modelValue" v-bind="$attrs" :inset="isDesktop" :transition="transition" :scrollable="scrollable" :content-class="contentClasses" :class="contentClasses" :attach="true">
        <v-card v-if="scrollable">
            <div class="close-button" :modelValue="modelValue" @click="toggle"><base-icon icon="mdi-close"></base-icon></div>
            <v-card-text style="height:800px"><slot></slot></v-card-text>
        </v-card>
        <v-sheet v-else>
            <div class="close-button" :modelValue="modelValue" @click="toggle"><base-icon icon="mdi-close"></base-icon></div>
            <slot></slot>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
    name: 'BaseBottomSheet',
    props: {
        scrollable: {
            type: Boolean,
            default: false
        },
        modelValue: {
            default: null
        }
    },
    components: {
        BaseIcon
    },
    computed: {
        isDesktop() {
            return this.$vuetify.display.lgAndUp;
        },
        transition() {
            if (this.isDesktop) {
                return 'slide-x-reverse-transition';
            }
            return 'bottom-sheet-transition';
        },
        contentClasses() {
            if (this.$attrs['content-class']) {
                return this.$attrs['content-class']  + ' base-bottom-sheet vuetify';
            }
            return 'base-bottom-sheet vuetify';
        }
    },
    methods: {
        toggle() {
            this.$emit('update:modelValue', !this.modelValue);
        }
    }
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.v-bottom-sheet.base-bottom-sheet > .v-bottom-sheet__content.v-overlay__content > .v-card, .v-bottom-sheet.base-bottom-sheet > .v-bottom-sheet__content.v-overlay__content > .v-sheet {
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;
    box-shadow: none;
    @include wider-than-tablet {
        border-radius: 1rem 0 0 0;
    }
}

@include wider-than-tablet {
    .v-bottom-sheet.v-bottom-sheet--inset {
        max-width: 44rem !important;
        margin-right: 0;
    }
    .v-bottom-sheet.v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%;
    }
}

.base-bottom-sheet {
    // Styles specifc to lists with icons
    .need-help, .account-info, .sub-account-details-sheet, .appointment-info {
        padding: 0 1.6rem;
    }
}

.base-bottom-sheet:has(.v-sheet > .sub-account-details-sheet) {
    height: 100% !important;
    max-height: 100% !important;
}

.close-button {
    position: absolute;
    right: 2rem !important;
    top: 1.25rem !important;
}

.v-bottom-sheet > .v-bottom-sheet__content.v-overlay__content {
    min-height: 90%;
    background: white;
    border-radius: 1rem 1rem 0 0 !important;
    @include wider-than-tablet {
        min-height: calc(100% - 5.5rem);
        border-radius: 1rem 0 0 0 !important;
    }
}
</style>