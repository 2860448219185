<template>
    <v-card v-bind="$attrs" class="base-card vuetify">
        <div class="default"><slot></slot></div>
        <v-card-title class="title"><slot name="title"></slot></v-card-title>
        <v-card-text class="text"><slot name="text"></slot></v-card-text>
        <v-card-actions class="actions"><slot name="actions"></slot></v-card-actions>
    </v-card>
</template>

<!--
    The card component has 3 main sections that we use: title, text, and actions. To use:
    <base-card>
        <template #title>Title</template>
        <template #text>Text</template>
        <template #actions>Buttons</template>
    </base-card>
    Alteratively, you can just pass in content without a template and it would work fine. It just won't use the defined sub-components of card. To use:
    <base-card>
        Text here
    </base-card>
-->

<script>
export default {
    name: 'BaseCard'
}
</script>
<style lang="scss">
@import '../styles/variables.scss';
.v-card-title:empty, .v-card-text:empty, .v-card-actions:empty, div.default:empty {
    display:none
}
</style>