class NewFlowPayment {
    constructor({ secureCode, estimateId, voucherNumber }) {
        // Fields directly from json
        this.estimateId = estimateId;
        this.secureCode = secureCode;
        this.voucherNumber = voucherNumber;

        // Fields that get set outside the constructor
        this.amount = null;
        this.amountOptionType = null;
        this.billing = null;
        this.date = null;
        this.financingVendor = null;
        this.installmentCount = null;
        this.maxDateForDiscountPolicy = null;
        this.method = null;
    }

    // Setters

    setAmount(val) {
        this.amount = val;
    }

    setAmountOptionType(val) {
        this.amountOptionType = val;
    }

    setBilling(val) {
        this.billing = val;
    }

    setDate(val) {
        this.date = val;
    }

    setFinancingVendor(val) {
        this.financingVendor = val;
    }

    setInstallmentCount(val) {
        this.installmentCount = val;
    }

    setMaxDateForDiscountPolicy(val) {
        this.maxDateForDiscountPolicy = val;
    }

    setMethod(val) {
        this.method = val;
    }

    // Validators
    
    isAmountValid() {
        return Boolean(this.amount) && Boolean(this.amountOptionType);
    }

    isDateValid() {
        if (this.maxDateForDiscountPolicy) {
            return Boolean(this.date) && (this.date <= this.maxDateForDiscountPolicy);
        }
        return Boolean(this.date);
    }

    isMethodValid() {
        if (this.method && this.method.savedMethod) {
            return Boolean(this.method);
        }
        return Boolean(this.billing) && Boolean(this.method);
    }
}

export default NewFlowPayment;
