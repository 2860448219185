
const NUM_MILLISECONDS_BEFORE_EBILL_OPENED = 3271;  // calculated as the mean + 4 standard deviations time user agents spend on wallet

import vueEventTransmitter from '../utils/vueEventTransmitter';

async function handleQueryParams(store, to, from, next) {
  let user = store.state.UsersStore.currentUser;
  if (!store.getters.getUserStatusKnown) {
    try {
      store.dispatch('showLoadingIfNecessary');
      user = await store.dispatch('fetchCurrentUser');
    } catch {
      user = null;
    } finally {
      store.commit('setUserStatusKnown', true);
    }
  }
  const query = to.query || {};
  if (Object.keys(query).length) {
    if (query.dialog === 'composemessage') {
      const messageParams = {};
        if (query.providerId) {
          messageParams.providerId = query.providerId;
        }
        if (query.accountId) {
          messageParams.accountId = query.accountId;
        }
      if (user) {
        vueEventTransmitter.emit('message:compose', messageParams);
        next({ ...to, query: {} });
      } else {
        const bill = JSON.parse(window.sessionStorage.getItem("bill"));
        await store.dispatch('findBill', { secureCode: bill.secureCode, billAmount: bill.billAmount });
        next({ name: 'BillSummary' });
        const authParams = {};
        const onAuthSuccess = () => {
          store.dispatch('linkBill', { amount: bill.billAmount, sCode: bill.secureCode }).then(() => {
            if (bill.providerDetails.requiresRedirectToBranded) {
                vueEventTransmitter.emit('redirect:tab', {
                    provider: bill.providerDetails,
                    context: {
                      stateGo: {
                          to: 'BillSummary',
                          toParams: {
                              secureCode: bill.secureCode,
                          },
                      },
                      emit: ['message:compose', messageParams],
                    }
                });
            } else {
              vueEventTransmitter.emit('message:compose', messageParams);
            }
          }).catch((message) => {
              console.error(message);
          });
        };
        vueEventTransmitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
      }
    }
  }
}

function redirectFromLegacyLinksIfNecessary() {
  if (location.pathname !== '/') {
    // We are coming from a legacy url, likely from a link in an email.
    // We need to redirect the user to the proper link.
    const routes = {
      "\\/payment\\/receipt\\/([A-Z0-9]{8})\\/([0-9]+)": { "redirect": "/#/dashboard/provider/VAR1/receipt/VAR2", "complex": true },
      "\\/bill\\/pay": { "redirect": "/#/ebill", "complex": false },
      "\\/message\\/view\\/.*": { "redirect": "/#/messages", "complex": false },
      "\\/account\\/createPassword\\/\\?email.*token=": { "redirect": "/#/forgot/", "complex": false },
      "\\/openlink\\/": { "redirect": "/#/", "complex": false },
      "\\/c\\?": { "redirect": "/services/v1/bills/cciRedirect?", "complex": false },
      "\^\/tw$": { "redirect": "/#/tw", "complex": false },
      "\\/bill\\/first": { "redirect": "/#/firstBill", "complex": false },
      "\\/estimate\\/existing": { "redirect": "/#/estimate", "complex": false },
      "\\/estimate\\/first": { "redirect": "/#/firstEstimate", "complex": false },
    };

    for (var route in routes) {
      if (!routes.hasOwnProperty(route)) {
        continue;
      }

      //get the uri including the query string
      let uri = window.location.href.replace(window.location.origin, ""),
        //build a regex that will split the query string out into a separate capture group
        re = new RegExp(route + "((?:\\?|\\/)?.*)"),
        //test the uri against the regex
        matches = re.exec(uri),
        //define a variable for the path that we will assemble
        redirectPath;

      //check if the URI matches this routing pattern
      if (matches) {
        redirectPath = routes[route]['redirect'];

        //if we have a complex route replace the VAR declarations with capture groups
        if (routes[route]['complex']) {
          //minus one here because we're always capturing the full query string as the last group
          for (let i = 1; i < matches.length - 1; i++) {
            redirectPath = redirectPath.replace('VAR' + i, matches[i]);
          }
        }

        // window.location.origin is not available in IE < 11, polyfill here
        if (!window.location.origin) {
          window.location.origin = window.location.protocol + "//" + window.location.hostname;
        }

        //redirect to the appropriate path and append whatever query string was passed in originally
        window.location = window.location.origin + redirectPath + matches[matches.length - 1];
        throw new Error('This is not an error. This is just to abort javascript');
      }
    }
  }
}

async function routeWithAuthenticationGuards(store, to, from, next) {
  let currUser = store.state.UsersStore.currentUser;
  if (!store.getters.getUserStatusKnown) {
    try {
      store.dispatch('showLoadingIfNecessary');
      currUser = await store.dispatch('fetchCurrentUser');
    } catch {
      currUser = null;
    } finally {
      store.commit('setUserStatusKnown', true);
    }
  }
  let loggedIn = currUser !== null;
  if (loggedIn) {
    if (to.name === 'Landing') {
      next({ name: 'ProvidersSummary' });
    } else {
      next();
    }
  } else {
    if (to.matched && to.matched[to.matched.length - 1] && to.matched[to.matched.length - 1].meta && to.matched[to.matched.length - 1].meta.authenticationRequired) {
      next({ name: 'Landing' });
    } else {
      next();
    }
  }
}

export { handleQueryParams, redirectFromLegacyLinksIfNecessary, routeWithAuthenticationGuards };