import { formatDate } from "../utils/utils";
import Bill from './Bill';
import Estimate from './Estimate';

class Account {
    constructor(rawJson, provider) {
        // Fields directly from json
        this.accountId = rawJson.accountId;
        this.accountNumber = rawJson.accountNumber;
        this.amountDue = rawJson.amountDue;
        this.approvedFinanceApplication = rawJson.approvedFinanceApplication;
        this.isFinancingDefault = rawJson.isFinancingDefault;
        this.billViewable = true;
        this.canManageFinancedAccount = rawJson.canManageFinancedAccount;
        this.currentPlan = rawJson.currentPlan;
        this.dateOfService = rawJson.dateOfService;
        this.dueDate = rawJson.dueDate;
        this.estimateId = rawJson.estimateId;
        this.estimatePayable = rawJson.estimatePayable;
        this.financedBy = rawJson.financedBy;
        this.financedByPhone = rawJson.financedByPhone;
        this.guarantorNames = rawJson.guarantorNames;
        this.hasPendingBalance = rawJson.hasPendingBalance;
        this.isBadDebt = rawJson.isBadDebt;
        this.patientName = rawJson.patientName;
        this.primaryInsurance = rawJson.primaryInsurance;
        this.promiseToPayPlan = rawJson.promiseToPayPlan;
        this.secureCode = rawJson.secureCode;
        this.statementSentDate = rawJson.statementSentDate;
        this.totalAmountDue = rawJson.totalAmountDue;

        // Nested objects
        this.bills = rawJson.bills ? rawJson.bills.map(raw => new Bill(raw)) : [];
        this.estimates = rawJson.estimates ? rawJson.estimates.map(raw => new Estimate(raw)) : [];
        this.provider = provider;

        // Calculated fields
        this.friendlyDueDate = 'Due ' + formatDate(this.dueDate, 'MMMM D, YYYY');
        this.friendlyAccountNumber = 'Account # ' + this.accountNumber;
        if (this.dueDate && !this.financedBy && this.approvedFinanceApplication !== 'ClearBalance') {
            const today = new Date();
            const due = new Date(this.dueDate);
            // compare on midnight of the dates
            today.setHours(0, 0, 0, 0);
            due.setHours(0, 0, 0, 0);

            this.isPastDue = provider.active && (due < today) && !provider.patientDueDateOverrideText;
        } else {
            this.isPastDue = false;
        }

        // Optional fields that typically get set outside of constructor
        this.activity = rawJson.activity;
        this.activityLimit = rawJson.activityLimit;
        this.activityFetched = rawJson.activityFetched;
    }

    getStatus() {
        if (!this.provider.active) {
            return 'PROVIDER_INACTIVE';
        } else if (this.financedBy || (this.approvedFinanceApplication === 'ClearBalance')) {
            return 'FINANCED';
        } else if (this.isBadDebt) {
            return 'BAD_DEBT';
        } else if (this.hasPendingBalance) {
            return 'PENDING';
        } else if (this.amountDue === 0) {
            return 'ZERO_BALANCE';
        } else if (this.amountDue < 0) {
            return 'REFUND_ELIGIBLE';
        } else if (this.promiseToPayPlan && 'ACTIVE' === this.promiseToPayPlan.status) {
            return 'PROMISE_TO_PAY_PLAN_ACTIVE';
        } else if (this.promiseToPayPlan && 'HELD' === this.promiseToPayPlan.status) {
            return 'PROMISE_TO_PAY_PLAN_HELD';
        } else if (this.currentPlan && ['In Progress', 'Scheduled', 'Updated'].includes(this.currentPlan.planStatus)) {
            return 'PAYMENT_PLAN_INPROGRESS';
        } else if (this.currentPlan && 'On Hold' === this.currentPlan.planStatus) {
            return 'PAYMENT_PLAN_HELD';
        } else if (this.isPastDue) {
            return 'PAST_DUE';
        } else if (this.secureCode) {
            if (this.provider.patientDueDateOverrideText) {
                return 'DUE_DATE_OVERRIDE';
            } else {
                return 'DUE_DATE';
            }
        }
        return null;
    }

    canUpdatePaymentPlanMethod() {
        return Boolean(this.currentPlan && this.currentPlan.paymentPlanId);
    }
}

export default Account;
